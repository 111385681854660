import { css } from 'linaria'

import * as colors from '../../styles/colors'


export const stateListItem = css`
  /* list item bullet point */
  &::marker {
    font-size: 1.5em;
  }

  /* keep default text color, only color the bullet point */
  & * {
    color: ${colors.primaryColor[2]};
  }
`
