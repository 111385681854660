import { useEffect, useState } from 'react'
import type { FieldValues, UseFormMethods } from 'react-hook-form'
import { throttle, isEqual, omit } from 'lodash'


type FormMethods<T extends FieldValues = FieldValues>
  = Pick<UseFormMethods<T>, 'watch' | 'setValue' | 'reset' | 'trigger'>

interface Options {
  /**
   * @default window.localStorage
   */
  storage?: Storage

  /**
   * @default {}
   * @see useFormBackup
   */
  defaultValues?: FieldValues

  /**
   * Should a field be validated after restoring it?
   * @default false
   */
  validate?: boolean

  /**
   * Should the form be set to dirty after restoring it to default values?
   * @default false
   */
  dirty?: boolean

  /**
   * How often should the form be backed up (in milliseconds)?
   * @default 2000
   */
  wait?: number

  /**
   * Preprocess values before passing them to form
   */
  preprocess?: (data: any) => any

  /**
   * Preprocess values before storing them in backup
   */
  preprocessBackup?: (data: ReturnType<FormMethods['watch']>) => ReturnType<FormMethods['watch']>

  /**
   * @default []
   */
  ignoredFields?: string[]
}

interface UseFormBackup {
  clear: () => void
}

/**
 * Heavily inspired by https://github.com/tiaanduplessis/react-hook-form-persist.
 *
 * If the backed up form has any default values, then they will not be set
 * correctly unless this hook knows about them.
 */
function useFormBackup (
  key: string,
  { watch, setValue, reset, trigger }: FormMethods,
  // If any of these default values are changed, modify the documentation
  // of `Options` as well!
  {
    storage = window.localStorage,
    defaultValues = {},
    validate = false,
    dirty = false,
    wait = 2000,
    preprocess,
    preprocessBackup,
    ignoredFields = [],
  }: Options = {},
): UseFormBackup {
  // If the form is reset, then we perform form validation. This may not be the
  // optimal way of doing this. This is an example by the library author, see:
  // https://github.com/react-hook-form/react-hook-form/discussions/3700#discussioncomment-212026
  const [isReset, setIsReset] = useState(false)

  useEffect(() => {
    const handleFormValidate = async () => {
      await trigger()
      setIsReset(false)
    }

    if (isReset) {
      void handleFormValidate()
    }
  }, [isReset, trigger])

  const values = preprocessBackup
    ? preprocessBackup(omit(watch(), ignoredFields))
    : omit(watch(), ignoredFields)

  function restoreDefaultValues () {
    if (!defaultValues) {
      return
    }

    // We use `reset` and not `setValue` to be able to set `<form>` back to `formState.isDirty: false`
    reset(defaultValues, {
      isDirty: dirty,
    })
    setIsReset(true)
  }

  const restoreData = () => {
    const str = storage.getItem(key)

    if (str == null) {
      restoreDefaultValues()
      return
    }

    let retrievedBackup: any
    try {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      retrievedBackup = JSON.parse(str)
      if (preprocess) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        retrievedBackup = preprocess(retrievedBackup)
      }
    } catch (e) {
      console.error(`Could not parse back up #${key}, message: ${(e as Error).message}`)
      return
    }

    for (const [inputName, value] of Object.entries(retrievedBackup)) {
      // Setting a single field to `shouldDirty: true` will set form to unsaved state `formState.isDirty: true`
      // We have to compare original values to new
      const isDirty = !isEqual(defaultValues[inputName], value)
      setValue(inputName, value, { shouldDirty: isDirty, shouldValidate: validate })
    }
  }

  // Restore data on mount (or key change).
  useEffect(restoreData, [key])

  // Backup the form on mount (after restoring it of course) and whenever the values change.
  useEffect(throttle(() => {
    storage.setItem(key, JSON.stringify(values))
  }, wait))

  return {
    clear: () => {
      restoreDefaultValues()
      storage.removeItem(key)
    },
  }
}

export default useFormBackup
