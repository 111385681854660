import { StrictMode } from 'react'
import ReactDOM from 'react-dom'

import App from './App'
import './fonts'
import './styles/typography'
import './index.style'


ReactDOM.render(
  <StrictMode>
    <App />
  </StrictMode>,
  document.getElementById('root'),
)
