import { css, cx } from 'linaria'

import { white, boxShadow } from '../../styles/colors'
import { breakpoints, rel } from '../../styles/dimensions'
import { column } from '../../components/Card/Column.style'


export const cardPadding = css`
  padding: ${rel(8)}rem;

  @media screen and (min-width: ${breakpoints.md}) {
    padding: ${rel(16)}rem;
  }
`

export const cardInner = css`
  background-color: ${white};
  border-radius: ${rel(8)}rem;
  box-shadow: 0 ${rel(2)}rem ${rel(12)}rem ${boxShadow};

  & + & {
    margin-top: ${rel(24)}rem;
  }

  .${column} > &,
  .${column} > & + & {
    margin-top: 0;
  }
`

// The definition of `card` and `cardPadding` are split for reusability in <Card.Header />.
export const card = cx(cardInner, cardPadding)
