import { css, cx } from 'linaria'

import { breakpoints, rel, gridColumn } from '../../styles/dimensions'
import * as colors from '../../styles/colors'
import { downloadButton } from './ThesisPDFButton.style'
import { fontWeights } from '../../styles/typography'
import {
  labelFontSize, labelFontSizeForDesktop, labelLineHeight, labelMarginBottom,
} from '../../components/form/Form.style'
import { input } from '../../components/form/TextInput.style'
import { submitRequired } from './ThesisReportForm.style'
import { transitionEasing } from '../../styles/utils'


export { downloadIcon } from './ThesisEvaluationCard/ThesisReportLink.style'

export const fieldGroup = css`
  display: flex;
  flex-wrap: wrap;
  margin-right: -${rel(8)}rem;
  margin-left: -${rel(8)}rem;

  @media screen and (min-width: ${breakpoints.md}) {
    margin-right: -${rel(12)}rem;
    margin-left: -${rel(12)}rem;
  }

  & > * {
    ${gridColumn('100%')};
    padding-right: ${rel(8)}rem;
    padding-left: ${rel(8)}rem;

    @media screen and (min-width: ${breakpoints.md}) {
      ${gridColumn('50%')};
      padding-right: ${rel(12)}rem;
      padding-left: ${rel(12)}rem;
    }
  }

  & > * + * {
    margin-top: ${rel(16)}rem;

    @media screen and (min-width: ${breakpoints.md}) {
      margin-top: 0;
    }
  }
`

export { label } from '../../components/form/Form.style'

export const inputRequired = submitRequired

const fileButton = {
  margin: `-${rel(6)}rem ${rel(8)}rem -${rel(6)}rem -${rel(10)}rem`,
  padding: `${rel(6)}rem ${rel(10)}rem`,
  color: `${colors.secondaryColor[2]}`,
  fontWeight: `${fontWeights.normal}`,
  backgroundColor: `${colors.backgroundColor[2]}`,
  borderColor: `${colors.secondaryColor[2]}`,
  borderStyle: 'solid',
  borderWidth: `0 ${rel(2)}rem 0 0`,
  borderTopLeftRadius: `${rel(8)}rem`,
  borderBottomLeftRadius: `${rel(8)}rem`,
  cursor: 'pointer',
  transition: `color ${transitionEasing}, background-color ${transitionEasing}`,
}

const fileButtonHover = {
  color: `${colors.assignedColor}`,
  backgroundColor: `${colors.white}`,
  borderInlineEndWidth: `${rel(2)}rem`,
}

export const file = cx(input, css`
  width: 100%;
  overflow: hidden;
  color: ${colors.primaryColor[2]};
  font-weight: ${fontWeights.normal};
  cursor: pointer;
  appearance: none;

  &:not(:disabled):not([readonly]) {
    cursor: pointer;
  }

  /* selectors need to be separate, as an unrecognized selector invalidates the others */
  &::-ms-browse {
    ${fileButton}
  }

  &::-webkit-file-upload-button {
    ${fileButton}
  }

  /* https://rocketvalidator.com/html-validation/css-unknown-pseudo-element-or-pseudo-class-file-selector-button */
  /* stylelint-disable */
  &::file-selector-button {
    ${fileButton}
  }
  /* stylelint-enable */

  &:hover::-ms-browse {
    ${fileButtonHover}
  }

  &:hover::-webkit-file-upload-button {
    ${fileButtonHover}
  }

  /* stylelint-disable */
  &:hover::file-selector-button {
    ${fileButtonHover}
  }
  /* stylelint-enable */
`)

export const fileWrapper = css`
  display: flex;
  flex-wrap: wrap;
  margin-right: -${rel(8)}rem;
  margin-left: -${rel(8)}rem;

  @media screen and (min-width: ${breakpoints.md}) {
    margin-right: -${rel(12)}rem;
    margin-left: -${rel(12)}rem;
  }
`

export const fileInputWrapper = css`
  ${gridColumn('100%')}
  padding-right: ${rel(8)}rem;
  padding-left: ${rel(8)}rem;

  @media screen and (min-width: ${breakpoints.md}) {
    flex: 1;
    flex-basis: 0;
    flex-wrap: wrap;
    min-width: 0;
    max-width: 100%;
    padding-right: ${rel(12)}rem;
    padding-left: ${rel(12)}rem;
  }
`

export const fileButtonWrapper = css`
  ${gridColumn('100%')}
  margin-top: ${rel(8)}rem;
  padding-right: ${rel(8)}rem;
  padding-left: ${rel(8)}rem;

  @media screen and (min-width: ${breakpoints.md}) {
    ${gridColumn('auto')}

    /* label offset */
    margin-top: ${rel(20)}rem;
    padding-right: ${rel(12)}rem;
    padding-left: ${rel(12)}rem;
  }

  @media screen and (min-width: ${breakpoints.xl}) {
    margin-top: ${rel(21)}rem;
  }

  .${downloadButton} {
    padding: ${rel(4)}rem ${rel(8)}rem;
    font-size: ${rel(14)}rem;
    line-height: ${rel(16)}rem;
    column-gap: ${rel(8)}rem;

    & svg {
      max-width: ${rel(18)}rem;
    }
  }
`

export const flexBox = css`
  display: flex;
  gap: ${rel(4)}rem;
`

export const attachments = css`
  padding: ${rel(14)}rem ${rel(16)}rem ${rel(24)}rem;

  @media screen and (min-width: ${breakpoints.md}) {
    padding-top: ${rel(6)}rem;
    padding-bottom: ${rel(16)}rem;
  }

  @media screen and (min-width: ${breakpoints.xl}) {
    padding-top: ${rel(2)}rem;
  }
`

export const buttons = css`
  display: flex;
  gap: ${rel(16)}rem;
  justify-content: center;
`

export const removeButton = css`
  grid-area: button;
  width: ${rel(32)}rem;
  height: ${rel(38)}rem;
  margin: auto;
  padding: 0;
  color: ${colors.secondaryColor[2]};
  font-size: ${rel(52)}rem;
  line-height: ${rel(32)}rem;
  background-color: transparent;
  border: 0;
  transition: color ${transitionEasing};

  &:hover {
    color: ${colors.assignedColor};
  }

  @media screen and (min-width: ${breakpoints.md}) {
    width: ${rel(20)}rem;

    /* label offset */
    margin-top: ${rel(labelFontSize * labelLineHeight + labelMarginBottom)}rem;
    font-size: ${rel(32)}rem;
  }

  @media screen and (min-width: ${breakpoints.xl}) {
    /* label offset */
    margin-top: ${rel(labelFontSizeForDesktop * labelLineHeight + labelMarginBottom)}rem;
  }
`

export const attachment = css`
  display: grid;
  grid-template:
    "field button"
    "field button"
    "field button"
    "field button"
    / auto ${rel(32)}rem;
  gap: ${rel(8)}rem;
  margin-bottom: ${rel(24)}rem;
  border-radius: ${rel(8)}rem;

  @media screen and (min-width: ${breakpoints.md}) {
    grid-template: "field field field field button";
    grid-template-columns: 3fr 2fr 3fr 3fr ${rel(20)}rem;
    gap: ${rel(16)}rem;
    margin-bottom: ${rel(16)}rem;
  }

  @media screen and (min-width: ${breakpoints.xl}) {
    gap: ${rel(16)}rem;
  }

  input,
  select {
    width: 100%;
  }
`

export const noResult = css`
  margin-bottom: ${rel(10)}rem;
  text-align: center;
`

export const uploadedFile = css`
  display: grid;
  grid-template:
    "download-icon fileName"
    "download-icon fileSize"
    / auto 1fr;
  width: 100%;
  padding: ${rel(4)}rem ${rel(8)}rem;
  text-align: left;
  column-gap: ${rel(8)}rem;
  background-color: ${colors.backgroundColor[2]};
  border: 0;
  border-radius: ${rel(6)}rem;
  transition: background-color ${transitionEasing};

  &:hover,
  &:focus,
  &.active {
    background-color: ${colors.backgroundColor[1]};
  }

  svg {
    max-width: ${rel(18)}rem;
  }
`

export const fileName = css`
  grid-area: fileName;
  overflow: hidden;
  font-weight: bold;
  font-size: ${rel(16)}rem;
  line-height: ${rel(18)}rem;
  white-space: nowrap;
  text-overflow: ellipsis;
`

export const fileSize = css`
  grid-area: fileSize;
  overflow: hidden;
  font-size: ${rel(10)}rem;
  line-height: ${rel(12)}rem;
  white-space: nowrap;
  text-overflow: ellipsis;
`
