import { css } from 'linaria'

import { rel } from '../../styles/dimensions'
import { transitionEasing } from '../../styles/utils'
import * as colors from '../../styles/colors'


export { author, downloadIcon } from './ThesisEvaluationCard/ThesisReportLink.style'

export const downloadButton = css`
  display: grid;
  grid-template:
    "download-icon title"
    "download-icon author"
    / auto 1fr;
  width: 100%;
  padding: ${rel(6)}rem ${rel(8)}rem;
  text-align: left;
  column-gap: 1rem;
  background-color: ${colors.backgroundColor[2]};
  border: 0;
  border-radius: ${rel(6)}rem;
  transition: background-color ${transitionEasing};

  &:hover,
  &:focus,
  &:active {
    background-color: ${colors.backgroundColor[1]};
  }

  & + & {
    margin-top: ${rel(8)}rem;
  }
`

export const title = css`
  grid-area: title;
  font-weight: bold;
`
