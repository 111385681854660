import { css } from 'linaria'

import * as colors from '../../../styles/colors'
import { breakpoints, rel } from '../../../styles/dimensions'
import { fontWeights } from '../../../styles/typography'


export const columnFilterInput = css`
  width: 100%;
  padding: ${rel(6)}rem ${rel(10)}rem;
  font-weight: ${fontWeights.light};
  font-size: ${rel(14)}rem;
  line-height: ${rel(26)}rem;
  background: ${colors.backgroundColor[2]};
  border: none;
  border-radius: ${rel(6)}rem;

  @media screen and (min-width: ${breakpoints.xl}) {
    font-size: ${rel(16)}rem;
  }

  &:focus {
    outline: none;
  }

  &::placeholder {
    color: ${colors.secondaryColor[2]};
  }
`
