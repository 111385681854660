import type { Page } from '../common'
import type { Person, PersonRef } from './person'
import type { Specialization, SpecializationRef } from './specialization'

/**
 * Study of a Person.
 */
export interface Study {

  /** Unique KOS ID of the study (named as "student" in KOS). */
  readonly id: number

  /** Associated person. */
  readonly person: PersonRef | Person

  /** Associated person's username. */
  readonly username: Person['username']

  /** Study specialization. */
  specialization: SpecializationRef | Specialization

  /** Whether this study currently has a Thesis assigned  */
  hasThesis: boolean

  /**
   * Branch ID in KOS.
   */
  branchId: number

  /**
   * Study plan code.
   * @examples ["MIE-ZI.2013"]
   */
  studyPlanCode: string

  /**
   * Whether this Study is still active or not.
   * @default true
   */
  active: boolean

  /**
   * The last date and time at which this object was modified. This is being updated automatically.
   */
  readonly modifiedAt: Date
}

/**
 * A Study to be created.
 */
export type StudyNew = Pick<Study, 'active' | 'branchId' | 'studyPlanCode'> & {

  /** Associated person. */
  person: PersonRef,

  /**
   * Study specialization.
   *
   * If not specified, then it will be automatically assigned according to the given
   * `branchId` and `studyPlanCode`.
   */
  specialization?: SpecializationRef,
}

/**
 * [Merge Patch](https://tools.ietf.org/html/rfc7386) for a Study.
 * This is used for updating an existing Study using the PATCH method; merge patch contains only
 * the properties that should be modified.
 */
export type StudyPatch = Partial<Omit<StudyNew, 'person'>>

/**
 * Collection of Study objects.
 */
export type StudyList = Page<Study>

/**
 * Reference to a Study resource (used in request bodies).
 */
// XXX: When we define it using `Pick<Study, 'id'>` and use in Person,
//  schema generation fail on `Reference type not set yet`.
export type StudyRef = {
  /** ID of the study (student) in KOS. */
  readonly id: number,

  /** Associated person's username. */
  readonly username?: string,
}

export function isStudy (study: Study | StudyRef): study is Study {
  return 'studyPlanCode' in study
}
