import { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { isPerson, SpecializationRef, Topic, Thesis } from '@cvut/profit-api-types/lib/theses'
import { getPropertyWithSuffix } from '@cvut/profit-utils'
import { formatPersonFullName } from '@cvut/profit-theses-common'

import Card from '../../components/Card'
import LoadMore from '../../components/table/LoadMore'
import PageHeader from '../../components/PageHeader'
import pagePaths from '../../pages/paths'
import ThesisLanguageAgreementInfo from './ThesisLanguageAgreementInfo'
import ThesisStateLabel from '../../components/ThesisStateLabel'
import TruncatedText from '../../components/TruncatedText'
import { MetadataPair } from '../thesis/MetadataPair'
import { useLocale } from '../../locale'
import { ReactComponent as EditIcon } from '../../images/icons/EditRound.svg'
import { outlineDark } from '../../components/Button.style'
import * as style from './TopicDetail.style'
import { usePaginatedTheses } from '../../api/theses'


const shortDescriptionLength = 101
const thesesPageSize = 100

interface TopicThesesListItemProps {
  thesis: Thesis
}

const TopicThesesListItem = ({ thesis }: TopicThesesListItemProps) => {
  const { currentLang } = useLocale()
  const thesisTitle = getPropertyWithSuffix(thesis, 'title', currentLang)

  return (
    <li>
      <Link to={pagePaths.theses.view(thesis.id)} title={thesisTitle}>
        {thesisTitle}
      </Link>
      &nbsp;
      <span>
        {/* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */}
        ({formatPersonFullName(thesis.assigneeFrozenName!)})
      </span>
      &nbsp;
      <ThesisStateLabel state={thesis.mainState} />
    </li>
  )
}

interface TopicThesesListProps {
  isLastPage: boolean
  isLoading: boolean
  onLoadMoreClick: () => void
  theses: Thesis[]
}

const TopicThesesList = ({ isLastPage, isLoading, onLoadMoreClick, theses }: TopicThesesListProps) => (
  <div>
    {theses.length && (
      <ul className={style.thesesList}>
        {theses.map(thesis => (
          <TopicThesesListItem thesis={thesis} key={thesis.id} />
        ))}
      </ul>
    )}
    {!isLastPage && <LoadMore isLoadingMore={isLoading} onClick={onLoadMoreClick} />}
  </div>
)

interface Props {
  topic: Topic
  editLink?: string
  createThesisLink?: string
}

const TopicDetail = ({ topic, editLink, createThesisLink }: Props): JSX.Element => {
  const { l } = useLocale()

  const author = isPerson(topic.author) ? formatPersonFullName(topic.author) : topic.author.username
  const studyDegrees = topic.studyDegrees.length > 0
    ? topic.studyDegrees.map(degree => l.topic.studyDegreeOptions[degree]).join(', ')
    : '–'
  const specializations = topic.specializations as SpecializationRef[]
  const specializationCodes = specializations.length > 0 ? specializations.map(spec => spec.code).join(', ') : '–'
  const [thesesState, isLastPage, getTheses] = usePaginatedTheses({ topicId: topic.id }, false, thesesPageSize)

  useEffect(() => {
    getTheses()
  }, [topic.id])

  function handleLoadMoreClick () {
    getTheses()
  }

  const actionsCardNode = createThesisLink ? (
    <Card>
      <Card.Header title={l.topic.actions} />
      <Card.Content>
        <Link to={createThesisLink} title={l.topic.createAssignment}>
          <button type='button' className={outlineDark}>
            {l.topic.createAssignment}
          </button>
        </Link>
      </Card.Content>
    </Card>
  ) : null

  return (
    <section>
      <PageHeader title={l.topic.topic} />
      <Card.ColumnContainer>
        <Card.Column>
          <Card>
            <Card.Header title={topic.title}>
              {editLink && (
                <Link to={editLink} title={l.topic.edit}>
                  <EditIcon className={style.controlIcon} title={l.topic.edit} />
                </Link>
              )}
            </Card.Header>
            <Card.Content>
              <TruncatedText text={topic.description} length={shortDescriptionLength} defaultExpanded>
                {(text) => (
                  <p className={style.description}>{text}</p>
                )}
              </TruncatedText>
              <h3 className={style.keywordsTitle}>{l.topic.keywords}</h3>
              <p>{topic.keywords.join(', ') || '–'}</p>
            </Card.Content>
          </Card>
          <Card>
            <Card.Header title={l.topic.thesesList} />
            <Card.Content>
              {thesesState.state === 'success' && thesesState.data.length === 0
                ? <p>{l.topic.emptyThesesListMessage}</p>
                : (
                  <TopicThesesList
                    isLastPage={isLastPage}
                    isLoading={thesesState.state === 'loading'}
                    onLoadMoreClick={handleLoadMoreClick}
                    theses={thesesState.data}
                  />
                )}
            </Card.Content>
          </Card>
        </Card.Column>
        <Card.Column>
          <Card>
            <Card.Header title='' />
            <Card.Content>
              <dl className={style.metadataPairs}>
                <MetadataPair label={l.topic.author} value={author} />
                {isPerson(topic.author) && (
                  <MetadataPair
                    label={l.topic.email}
                    value={topic.author.email}
                    href={{ pathname: `mailto:${topic.author.email}` }}
                  />
                )}
                <MetadataPair label={l.topic.studyDegree} value={studyDegrees} />
                <MetadataPair label={l.topic.specialization} value={specializationCodes} />
                <MetadataPair label={l.topic.language} value={l.topic.languageOptions[topic.language]} />
                <MetadataPair label={l.topic.visibility} value={l.topic.visibilityOptions[topic.visibility]} />
                <ThesisLanguageAgreementInfo />
              </dl>
            </Card.Content>
          </Card>
          {actionsCardNode}
        </Card.Column>
      </Card.ColumnContainer>
    </section>
  )
}

export default TopicDetail
