import { css } from 'linaria'

import { rel } from '../styles/dimensions'
import * as colors from '../styles/colors'


export const infoMessage = css`
  display: inline-block;
  margin: 0;
  padding: ${rel(4)}rem ${rel(10)}rem;
  color: ${colors.white};
  background-color: ${colors.neutralColor[3]};
  border-radius: ${rel(6)}rem;
`
