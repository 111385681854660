import { css } from 'linaria'

import * as colors from './colors'
import { breakpoints } from './dimensions'


// Whenever we want to apply global typographical styles to all text, apply them on these elements as well.
const textElements = 'a, button, input, label, optgroup, select, textarea'

type FontSize = {
  mobile: number,
  desktop: number,
}

/*
 * Default root font-size value in most browsers is 16px and we will use that as basis for all calculations
 * These values are meant to be used with rem or em
 */
export const fontSizes: { [key: string]: FontSize } = {
  h1: {
    mobile: 1.25,
    desktop: 2,
  },
  h2: {
    mobile: 1.15,
    desktop: 1.5,
  },
  h3: {
    mobile: 1,
    desktop: 1.17,
  },
  body: {
    mobile: 1,
    desktop: 1.25,
  },
  captions: {
    mobile: 0.75,
    desktop: 1,

  },
} as const

export const typography = css`
  :global() {
    #root {
      color: ${colors.primaryColor[2]};
      font-size: ${fontSizes.body.mobile}rem;
      font-family: 'Nunito', sans-serif;
    }

    ${textElements} {
      color: ${colors.primaryColor[2]};
      font-size: ${fontSizes.captions.mobile}rem;
      font-family: 'Nunito', sans-serif;
    }

    @media screen and (min-width: ${breakpoints.md}) {
      font-size: ${fontSizes.body.desktop}rem;

      ${textElements} {
        font-size: ${fontSizes.captions.desktop}rem;
      }
    }

    h1 {
      font-size: ${fontSizes.h1.mobile}rem;

      @media screen and (min-width: ${breakpoints.md}) {
        font-size: ${fontSizes.h1.desktop}rem;
      }
    }

    h2 {
      font-size: ${fontSizes.h2.mobile}rem;

      @media screen and (min-width: ${breakpoints.md}) {
        font-size: ${fontSizes.h2.desktop}rem;
      }
    }
  }
`

export const fontWeights = {
  light: 300,
  normal: 400,
  bold: 700,
}
