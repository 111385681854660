import { css, cx } from 'linaria'

import { buttonIcon, outlineDark } from '../../components/Button.style'
import { breakpoints, gridColumn, rel } from '../../styles/dimensions'
import * as colors from '../../styles/colors'


export const labelFontSize = 14
export const labelFontSizeForDesktop = 16
export const labelLineHeight = 1.1
export const labelMarginBottom = 4

export const label = css`
  display: block;
  margin-bottom: ${rel(labelMarginBottom)}rem;
  font-size: ${rel(labelFontSize)}rem;
  line-height: ${labelLineHeight};

  @media screen and (min-width: ${breakpoints.xl}) {
    font-size: ${rel(labelFontSizeForDesktop)}rem;
  }
`

export const form = css`
  position: relative;
  display: flex;
  flex-direction: column;

  > *:not(:first-child) {
    margin-top: ${rel(16)}rem;
  }

  input,
  textarea {
    background-color: ${colors.backgroundColor[2]};
    border-color: ${colors.backgroundColor[2]};
  }
`

export const formGroup = css`
  margin-bottom: ${rel(12)}rem;
  text-align: left;

  & > * {
    display: block;
    width: 100%;
  }
`

export const formInput = css`
  display: flex;
  flex-direction: column;
`

export const inlineFieldGroup = css`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 0;

  > * {
    ${gridColumn('100%')}
    margin-top: ${rel(16)}rem;

    @media screen and (min-width: ${breakpoints.sm}) {
      ${gridColumn('auto')}
      margin-right: ${rel(12)}rem;
      margin-left: 0;
    }

    @media screen and (min-width: ${breakpoints.xl}) {
      margin-right: ${rel(24)}rem;
    }

    > div,
    input,
    select,
    textarea {
      display: block;
      width: 100%;
    }

    &:last-child {
      margin-right: 0;
    }
  }
`

export const formActions = css`
  text-align: right;

  > * + * {
    margin-left: ${rel(16)}rem;
  }
`

export const controls = css`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
`

export const controlsLeft = css`
  margin: -${rel(4)}rem;

  /* Vertical spacing when wrapped into multiple lines */
  padding-bottom: ${rel(8)}rem;

  > button,
  > a {
    margin: ${rel(4)}rem;
  }
`

export const controlsRight = css`
  margin: -${rel(4)}rem -${rel(4)}rem -${rel(4)}rem auto;
  text-align: right;

  > button,
  > a {
    margin: ${rel(4)}rem;
  }
`

export const deleteButton = cx(buttonIcon, outlineDark, css`
  svg {
    .pipeline {
      fill: ${colors.white};
    }

    .top {
      fill: ${colors.primaryColor[1]};
    }
  }
`)

export const fieldset = css`
  width: 100%;
  padding: ${rel(4)}rem ${rel(8)}rem ${rel(8)}rem;
  border: ${rel(4)}rem solid ${colors.backgroundColor[1]};
  border-radius: ${rel(6)}rem;

  @media screen and (min-width: ${breakpoints.md}) {
    padding: ${rel(4)}rem ${rel(16)}rem ${rel(16)}rem;
  }
`

export const legend = css`
  width: auto;
  margin-bottom: 0;
  padding: 0 ${rel(8)}rem;
  font-size: ${rel(18)}rem;
`
