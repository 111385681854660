import { HttpProblem } from '@cvut/profit-api-types/lib/theses'

import { HttpError, RequestWithResponseError } from './makeRequest'


export default function requestErrorFormatter<T> (err: RequestWithResponseError): string {
  console.dir(err)

  if ('problem' in err) {
    const httpError = err as HttpError<T>
    const httpProblem = httpError.problem as HttpProblem | string | undefined

    if (typeof httpProblem === 'string') {
      return httpProblem
    }

    if (httpProblem?.detail) {
      return httpProblem.detail
    }
  }

  return err.message
}
