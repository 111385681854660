import { Enum } from 'typescript-string-enums'
import { ThesisMainState, ThesisState } from '@cvut/profit-api-types/lib/theses'
import { objectOmit } from '@cvut/profit-utils'


// TODO: Name this properly.
export const DisplayedMyThesesStates = objectOmit(ThesisMainState, 'Deleted')
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type DisplayedMyThesesStates = Enum<typeof DisplayedMyThesesStates>

// TODO: Name this properly.
export const DisplayedThesesStates = objectOmit(DisplayedMyThesesStates, 'Draft', 'Archived')
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type DisplayedThesesStates = Enum<typeof DisplayedThesesStates>

// TODO: Name this properly.
export const DisplayedOfficerThesesStates = objectOmit(DisplayedMyThesesStates, 'Draft')
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type DisplayedOfficerThesesStates = Enum<typeof DisplayedOfficerThesesStates>

// TODO: OK, this is weird, we should probably translate all states.
export type LocalizedThesisState = Exclude<ThesisState, ThesisMainState | 'changeable' | 'changeable.assigned'
| 'changeable.assigned.submission' | 'changeable.assigned.reviewer' | 'changeable.review'
| 'changeable.review.reviewer' | 'changeable.review.supervisor' | 'changeable.reviewed'>
