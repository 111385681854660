import { useLocale } from '../../locale'
import InfoMessage from '../../components/InfoMessage'


const ThesisLanguageAgreementInfo = (): JSX.Element => {
  const { l } = useLocale()

  return <InfoMessage text={l.topic.assignmentLanguageNote} />
}

export default ThesisLanguageAgreementInfo
