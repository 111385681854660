import { Configuration } from '@cvut/profit-api-types/lib/theses/configuration'

import { apiPrefix } from '../config'
import { useLocale } from '../locale'
import { createRequestor } from './makeRequest'
import requestErrorFormatter from './requestErrorFormatter'
import { useRequest, UseRequestConfig, UseRequestStatus } from './useRequest'


const thesesApiPrefix = `${apiPrefix}/configuration`

function useConfigurationRequest<T> (config: UseRequestConfig, baseUrl = thesesApiPrefix) {
  return useRequest<T>(createRequestor(baseUrl), config)
}

export function useConfiguration (): [
  UseRequestStatus<Configuration>,
  () => Promise<void>,
] {
  const [requestStatus, sendRequest] = useConfigurationRequest<Configuration>({
    defaultErrorMessage: requestErrorFormatter,
  })

  return [
    requestStatus,
    async () => await sendRequest('GET', ''),
  ]
}

export function useUpdateConfiguration (): [
  UseRequestStatus<void>,
  (configuration: Configuration) => Promise<void>,
] {
  const { l } = useLocale()
  const [requestStatus, sendRequest] = useConfigurationRequest<void>({
    defaultErrorMessage: () => l.errorMessages.api.defaultPatch(l.configuration.configuration, ''),
  })

  return [requestStatus, async (configuration) => await sendRequest('PATCH', '', {}, {
    body: JSON.stringify(configuration),
  })]
}
