import { css } from 'linaria'

import * as colors from '../../styles/colors'
import { breakpoints, rel } from '../../styles/dimensions'
import { fontWeights } from '../../styles/typography'


export const row = css`
  /* TODO */
`

export const ascendingArrow = css`
  margin-bottom: ${rel(3)}rem;
  border-right: ${rel(5)}rem solid transparent;
  border-bottom: ${rel(5)}rem solid ${colors.secondaryColor[2]};
  border-left: ${rel(5)}rem solid transparent;
`

export const descendingArrow = css`
  border-top: ${rel(5)}rem solid ${colors.secondaryColor[2]};
  border-right: ${rel(5)}rem solid transparent;
  border-left: ${rel(5)}rem solid transparent;
`

export const sortIcon = css`
  display: inline-block;
  width: ${rel(10)}rem;
  height: ${rel(13)}rem;
  margin-left: ${rel(8)}rem;
`

export const ascendingArrowActive = css`
  border-bottom: ${rel(5)}rem solid ${colors.primaryColor[2]};
`

export const descendingArrowActive = css`
border-top: ${rel(5)}rem solid ${colors.primaryColor[2]};
`

export const thCell = css`
  padding-bottom: ${rel(16)}rem;

  @media screen and (min-width: ${breakpoints.xl}) {
    padding-bottom: ${rel(24)}rem;
  }
`

export const cell = css`
  padding: ${rel(4)}rem;
  border-bottom: ${rel(3)}rem solid ${colors.backgroundColor[2]};
  user-select: none;

  &.${thCell} {
    padding-bottom: ${rel(8)}rem;
    vertical-align: top;
  }

  & > div {
    white-space: nowrap;
  }

  &:first-child {
    padding-left: 0;
  }

  /* FIXME - duplicate from MyTheses.style.ts */
  &:last-child {
    text-align: center;
  }

  /* column titles */
  & > div:nth-child(1) {
    padding-bottom: ${rel(4)}rem;
    font-weight: ${fontWeights.light};
    font-size: ${rel(14)}rem;
    line-height: 1.375;
    white-space: nowrap;

    @media screen and (min-width: ${breakpoints.xl}) {
      font-size: ${rel(16)}rem;
    }
  }

  & > div:nth-child(2) {
    height: ${rel(38)}rem; /* prevent dropdown with checkboxes from expanding header height */
  }
`
