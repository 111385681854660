import { css } from 'linaria'

import { secondaryColor } from '../../../styles/colors'
import { rel } from '../../../styles/dimensions'


export const list = css`
  max-height: 50vh;
  margin: 0;
  padding: 0;
  overflow-y: auto;
`

export const optionDivider = css`
  height: ${rel(1)}rem;
  margin: ${rel(8)}rem 0;
  padding: 0;
  background-color: ${secondaryColor[2]};
`

export const optionHeader = css`
  padding-left: ${rel(16)}rem;
  font-size: ${rel(12)}rem;
`
