import type { Page, PersonName } from '../common'
import type { StudyRef } from './study'


export interface Person extends PersonName {
  /**
   * Database primary key. This is not exposed in REST API.
   * @hidden
   */
  // FIXME: This shouldn't be here at all, but we need it to be required in theses-backend.
  readonly id?: number

  /**
   * CTU username of the Person.
   *
   * @minLength 3
   * @pattern ^[a-z0-9_.-]+$
   * @examples ["mckayrod"]
   */
  readonly username: string

  /** ID of the Person in KOS. */
  kosId?: number | null

  /**
   * The preferred email address of the Person for sending notifications.
   * @format email
   */
  email: string

  /**
   * A list of the Person's roles (both business and technical) relevant to ProjectsFIT.
   * @uniqueItems true
   */
  roles: string[]

  /**
   * Whether this Person's account is still active or not.
   * @default true
   */
  active: boolean

  /**
   * Person's settings for ProjectFIT.
   */
  settings: PersonSettings

  /** A list of Studies of the Person, if any. */
  // NOTE: There's no `| Study[]` or `Pick<Study, 'id' | 'active'>` to avoid circular dependency in
  // JSON Schema which some OpenAPI tools cannot handle.
  readonly studies?: Array<Pick<StudyRef, 'id'> & { active: boolean }>

  /**
   * The last date and time at which this object was modified. This is being updated automatically.
   */
  readonly modifiedAt: Date
}

/**
 * Person to be created.
 */
export type PersonNew =
  & Partial<Pick<Person, 'active' | 'titlesPre' | 'titlesPost' | 'settings'>>
  & Pick<Person, 'firstName' | 'lastName' | 'kosId' | 'email' | 'roles'>

/**
 * [Merge Patch](https://tools.ietf.org/html/rfc7386) for a Person.
 * This is used for updating an existing Person using the PATCH method; merge patch contains only
 * the properties that should be modified.
 */
export type PersonPatch = Partial<PersonNew>

/**
 * Collection of Person objects.
 */
export type PersonList = Page<Person>

/**
 * Reference to a Person resource (used in request bodies).
 */
export type PersonRef = Pick<Person, 'username'>

export function isPerson (person: Person | PersonRef): person is Person {
  return 'lastName' in person
}


/**
 * Person (user) settings
 */
export interface PersonSettings {
  /**
   * Flag specifying whether user wants to receive notices by email.
   */
  noticeEmailsEnabled: boolean

  /**
   * Flag specifying whether user wants to receive tasks information by email.
   */
  taskEmailsEnabled: boolean
}

export interface PersonFilter {
  includeInactive: boolean
  q?: string
  role?: string
}
