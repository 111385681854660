import { Thesis } from '@cvut/profit-api-types/lib/theses'

import { useLocale } from '../../locale'
import { LocalizedThesisState } from '../../types/thesis'
import * as style from './ThesisStateDescription.style'


interface StateDescriptionProps {
  // TODO: Replace with ThesisState from api-types.
  state: Thesis['states'][number]
}

const ThesisStateDescription = ({ state }: StateDescriptionProps): JSX.Element | null => {
  const { l } = useLocale()

  if (!l.thesis.substate[state as LocalizedThesisState]) {
    return null
  }

  return (
    <li className={style.stateListItem}>
      <span>{l.thesis.substate[state as LocalizedThesisState]}</span>
    </li>
  )
}

export default ThesisStateDescription
