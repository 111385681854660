import _ from 'lodash'
import { cx } from 'linaria'
import { Enum } from 'typescript-string-enums'
import { Thesis, ThesisMainState } from '@cvut/profit-api-types/lib/theses'

import { useLocale } from '../../locale'
import Card from '../../components/Card'
import ThesisStateLabel, { stateClassLookup } from '../../components/ThesisStateLabel'
import ThesisStateDescription from './ThesisStateDescription'
import * as style from './ThesisStateCard.style'


interface ThesisStatesListProps {
  thesis: Thesis
}

const ThesisStatesList = ({ thesis }: ThesisStatesListProps) => {
  const substates = _.without(thesis.states, ...Enum.values(ThesisMainState))

  return substates.length
    ? (
      <Card.Content>
        <ul className={cx(stateClassLookup[thesis.mainState], style.stateList)}>
          {substates.map(state => (
            <ThesisStateDescription state={state} key={state} />
          ))}
        </ul>
      </Card.Content>
    )
    : null
}


interface Props {
  thesis: Thesis
}

const ThesisStateCard = ({ thesis }: Props): JSX.Element => {
  const { l } = useLocale()

  return (
    <Card>
      <Card.Header title={l.thesis.state}>
        <ThesisStateLabel state={thesis.mainState} />
      </Card.Header>
      <ThesisStatesList thesis={thesis} />
    </Card>
  )
}

export default ThesisStateCard
