import { MouseEventHandler, useState } from 'react'
import { uniqueId } from 'lodash'
import { PersonName } from '@cvut/profit-api-types/lib/common'

import { useLocale } from '../../../locale'
import { localeToLocaleString } from '../../../locale/utils'
import { formatPersonFullName } from '../../../utils/person'
import Card from '../../../components/Card'
import type { Props as CardHeaderProps } from '../../../components/Card/Header'
import * as style from './ThesisChangesCard.style'
import { ReactComponent as ArrowDownIcon } from '../../../images/icons/ArrowDown.svg'
import { ReactComponent as ArrowUpIcon } from '../../../images/icons/ArrowUp.svg'


export interface ThesisStateChange {
  id: number
  title: string
  description?: string
  author?: PersonName
  createdAt: Date
}

interface ThesisStateItemProps {
  state: ThesisStateChange
}

const ThesisStateItem = ({ state }: ThesisStateItemProps) => {
  const { currentLang } = useLocale()
  const datetime = state.createdAt.toLocaleDateString(localeToLocaleString(currentLang))

  return (
    <li className={style.thesisStateItem}>
      <time className={style.datetime}>{datetime}</time>
      {state.author && <div className={style.author}>{formatPersonFullName(state.author)}</div>}
      <h3 className={style.title}>{state.title}</h3>
      {state.description && <p className={style.description}>{state.description}</p>}

      <div className={style.thesisStateIcon} />
      <div className={style.thesisStateBar} />
    </li>
  )
}


interface ThesisStatesHistoryProps {
  changes: ThesisStateChange[]
}

const ThesisStatesHistory = ({ changes }: ThesisStatesHistoryProps) => (
  <ul className={style.thesisStatesList}>
    {changes.map(state => <ThesisStateItem key={state.id} state={state} />)}
  </ul>
)


interface ClickableCardHeaderProps extends CardHeaderProps {
  isCollapsed: boolean
  collapsibleBodyId: string
  onClick: MouseEventHandler
}
// TODO: Extract into a `<CollapsibleCard />` component.
const ClickableCardHeader = (props: ClickableCardHeaderProps) => (
  <div
    className={style.clickableCardHeader}
    onClick={props.onClick}
    role='button'
    aria-expanded={!props.isCollapsed}
    aria-controls={props.collapsibleBodyId}
  >
    <Card.Header {...props} />
    {props.isCollapsed ? <ArrowDownIcon /> : <ArrowUpIcon />}
  </div>
)


interface Props {
  changes: ThesisStateChange[]
  /**
   * If this function is undefined, then the component assumes that there are no more changes to be fetched and
   * hides the "get more state changes" button.
   * */
  getNextChanges?: () => unknown
  collapsed?: boolean
}

const ThesisChangesCard = ({ changes, getNextChanges, collapsed = false }: Props): JSX.Element | null => {
  const { l } = useLocale()
  const [isCollapsed, setIsCollapsed] = useState(collapsed)

  if (!changes.length) {
    return null
  }

  const collapsibleBodyId = uniqueId('collapsible-body-id')

  const getMoreEventsButton = getNextChanges ? (
    <button type='button' onClick={getNextChanges} className={style.getMoreEventsButton}>
      {l.eventLog.getMoreEvents}
    </button>
  ) : null

  return (
    <Card>
      <ClickableCardHeader
        title={l.eventLog.title}
        collapsibleBodyId={collapsibleBodyId}
        isCollapsed={isCollapsed}
        onClick={() => setIsCollapsed(old => !old)}
      />
      {!isCollapsed && (
        <div className={style.cardBodyContainer} id={collapsibleBodyId}>
          <Card.Content extraClassNames={[style.cardBodyContent]}>
            <ThesisStatesHistory changes={changes} />
            {getMoreEventsButton}
          </Card.Content>
        </div>
      )}
    </Card>
  )
}

export default ThesisChangesCard
