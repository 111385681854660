import { Enum } from 'typescript-string-enums'
import toast, { ToastBar, Toaster, ToastOptions } from 'react-hot-toast'

import { headerOffset } from '../../components/CollapseNavigation.style'
import { rel } from '../../styles/dimensions'
import { useLocale } from '../../locale'
import * as colors from '../../styles/colors'
import * as style from './ToastNotifications.style'
import { ReactComponent as InfoIcon } from '../../images/icons/InfoHint.svg'


export const ToastNotificationType = Enum('INFO', 'NEGATIVE', 'POSITIVE')
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type ToastNotificationType = Enum<typeof ToastNotificationType>

interface Toast {
  message: string
  type: ToastNotificationType
  options?: ToastOptions
}

export const addNotification = (notification: Toast): void => {
  const commonOptions: ToastOptions = {
    ...notification.options,
    ariaProps: {
      'role': 'alert',
      'aria-live': 'assertive',
    },
  }

  switch (notification.type) {
    case 'INFO':
      toast(notification.message, {
        ...commonOptions,
        icon: <InfoIcon className={style.infoIcon} />,
      })
      break
    case 'NEGATIVE':
      toast.error(notification.message, commonOptions)
      break
    case 'POSITIVE':
      toast.success(notification.message, commonOptions)
      break
  }
}

const ToastNotifications = (): JSX.Element => {
  const { l } = useLocale()
  const dismissButtonLocale = l.toasts.close

  return (
    <Toaster
      position='top-right'
      reverseOrder
      containerStyle={{
        // `inset` is shorthand for top, right, bottom, left
        inset: `${rel(headerOffset + 16)}rem ${rel(16)}rem`,
      }}
      toastOptions={{
        duration: 6000,
        className: style.infoToast,
        success: {
          className: style.successToast,
          iconTheme: {
            primary: colors.white,
            secondary: colors.positiveColor[2],
          },
        },
        error: {
          className: style.errorToast,
          iconTheme: {
            primary: colors.white,
            secondary: colors.negativeColor[2],
          },
        },
      }}
    >
      {(t) => (
        <ToastBar toast={t}>
          {({ icon, message }) => (
            <>
              {icon}
              {message}
              <button
                aria-label={dismissButtonLocale}
                className={style.dismissToastButton}
                onClick={() => toast.dismiss(t.id)}
                title={dismissButtonLocale}
              >
                &times;
              </button>
            </>
          )}
        </ToastBar>
      )}
    </Toaster>
  )
}

export default ToastNotifications
