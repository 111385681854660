/**
 * As there exists a crossbroser bug with 'rem' on breakpoints, it is recommended to use 'em' for this one purpose.
 *
 * Default root font-size value in most browsers is 16px and we use that as basis for all calculations.
 *
 * Breakpoints are defined Mobile-First.
 */
export const breakpointsConstants = {
  sm: 36,
  md: 48,
  lg: 62,
  xl: 75,
  xxl: 110,
} as const

/**
 * Example: @media screen and (min-width: ${breakpoints.xl}) {}
 *
 * const already contains em
 */
export const breakpoints = {
  /** @param sm - Resolution targeting mostly phones in landspace mode */
  sm: `${breakpointsConstants.sm}em`,
  /** @param md - Resolution targeting mostly tablets */
  md: `${breakpointsConstants.md}em`,
  /** @param lg - Resolution targeting mostly tablets in landspace mode */
  lg: `${breakpointsConstants.lg}em`,
  /** @param xl - Resolution targeting Desktops */
  xl: `${breakpointsConstants.xl}em`,
  /** @param xxl - Resolution targeting Bigger desktop monitors */
  xxl: `${breakpointsConstants.xxl}em`,
} as const

/**
 * @param value - returns rem / em value from px
 *
 * example: ${rel(8)}rem will change to 0.5rem
 */
export const rel = (value: number): number => value / 16

/**
 * Helper function to define column in flex based grid
 *
 * Parent element must have:
 *
 * display: flex;
 *
 * flex-wrap: wrap;
 */
export const gridColumn = (width: string): string => `
  flex: 0 0 ${width};
  width: ${width};
  max-width: ${width === 'auto' ? 'none' : width};
`
