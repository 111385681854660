import * as cs from './cs'
import * as en from './en'


const notificationSubjects = {
  subject: {
    cs: cs.subject,
    en: en.subject,
  },
}

export default notificationSubjects
