import { useEffect } from 'react'

import ConfigurationEdit from '../../features/configuration/ConfigurationEdit'
import PageLoadSpinner from '../../components/PageLoadSpinner'
import pagePaths from '../paths'
import RequestDependentRender from '../../api/RequestDependentRender'
import { errorCardRenderer } from '../../components/RequestErrorCard'
import { useConfiguration, useUpdateConfiguration } from '../../api/configuration'
import { useLocale } from '../../locale'


const ConfigurationEditPage = (): JSX.Element => {
  const { l } = useLocale()

  const [configurationState, getConfiguration] = useConfiguration()

  useEffect(() => {
    void getConfiguration()
  }, [])

  const [, updateConfiguration] = useUpdateConfiguration()

  return (
    <RequestDependentRender
      requestStatus={configurationState}
      renderOnLoading={() => <PageLoadSpinner message={l.configuration.loadingConfiguration} />}
      renderOnError={errorCardRenderer(l.configuration.errorGettingConfiguration, pagePaths.configuration)}
      renderOnSuccess={(configuration) => (
        <ConfigurationEdit
          onSave={updateConfiguration}
          defaultValues={configuration}
        />
      )}
    />
  )
}

export default ConfigurationEditPage
