import { css, cx } from 'linaria'
import { mix } from 'polished'

import * as colors from '../styles/colors'
import { breakpoints, rel } from '../styles/dimensions'
import { fontWeights, fontSizes } from '../styles/typography'


export const buttonIcon = css`
  svg {
    position: absolute;
    top: 0;
    right: ${rel(13)}rem;
    bottom: 0;
    max-width: ${rel(20)}rem;
    max-height: ${rel(20)}rem;
    margin: auto;
  }
`

const common = css`
  padding: ${rel(4)}rem ${rel(24)}rem;
  font-weight: ${fontWeights.bold};
  font-size: ${fontSizes.body.mobile}rem;
  line-height: 1.4;
  border: ${rel(2)}rem solid;
  border-radius: ${rel(6)}rem;

  @media screen and (min-width: ${breakpoints.md}) {
    font-size: ${fontSizes.body.desktop}rem;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }

  &.${buttonIcon} {
    position: relative;
    padding-right: ${rel(44)}rem;
  }
`

export const fill = cx(common, css`
  color: ${colors.backgroundColor[2]};
  background-color: ${colors.primaryColor[2]};
  border-color: ${colors.primaryColor[2]};

  &:hover:not(:disabled) {
    background-color: ${colors.primaryColor[3]};
  }

  &:active:not(:disabled) {
    background-color: ${colors.primaryColor[1]};
  }
`)

export const outlineDark = cx(common, css`
  color: ${colors.primaryColor[2]};
  background-color: ${colors.white};
  border-color: ${colors.primaryColor[2]};

  &:hover:not(:disabled) {
    background-color: ${colors.backgroundColor[2]};
  }

  &:active:not(:disabled) {
    background-color: ${colors.secondaryColor[2]};
  }
`)

export const outlineLight = cx(common, css`
  color: ${colors.backgroundColor[2]};
  background-color: transparent;
  border-color: ${colors.backgroundColor[2]};

  &:hover:not(:disabled) {
    background-color: ${colors.primaryColor[3]};
  }

  &:active:not(:disabled) {
    background-color: ${colors.primaryColor[1]};
  }
`)

export const ghost = cx(common, css`
  color: ${colors.primaryColor[2]};
  background-color: ${colors.white};
  border: none;

  &:hover:not(:disabled) {
    background-color: ${colors.backgroundColor[2]};
  }

  &:active:not(:disabled) {
    background-color: ${colors.secondaryColor[2]};
  }
`)

export const accept = cx(common, css`
  color: ${colors.white};
  background-color: ${colors.positiveColor[2]};
  border-color: ${colors.positiveColor[2]};

  &:hover:not(:disabled) {
    background-color: ${colors.positiveColor[3]};
  }

  &:active:not(:disabled) {
    background-color: ${colors.positiveColor[1]};
  }
`)

export const reject = cx(common, css`
  color: ${colors.negativeColor[2]};
  background-color: ${mix(0.1, colors.negativeColor[2], colors.white)};
  border-color: ${colors.negativeColor[2]};

  &:hover:not(:disabled) {
    color: ${colors.white};
    background-color: ${colors.negativeColor[3]};
  }

  &:active:not(:disabled) {
    color: ${colors.white};
    background-color: ${colors.negativeColor[1]};
  }
`)

export const action = cx(common, css`
  color: ${colors.white};
  background-color: ${colors.actionColor[2]};
  border-color: ${colors.actionColor[2]};

  &:hover:not(:disabled) {
    background-color: ${colors.actionColor[3]};
  }

  &:active:not(:disabled) {
    background-color: ${colors.actionColor[1]};
  }
`)

export const iconWrapper = css`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  background: none;
  border: none;
`
