import { css } from 'linaria'

import { primaryColor } from '../../styles/colors'
import { rel } from '../../styles/dimensions'
import { fontWeights, fontSizes } from '../../styles/typography'
import LeaveLinkIcon from '../../images/icons/LeaveLink.svg'


const thesisInfoDTWidth = 12.5

export const headerBtnLink = css`
  &:hover,
  &:focus,
  &:active {
    text-decoration: none;
  }
`

export const thesisInfo = css`
  & > dt {
    float: left;
    clear: left;
    width: ${thesisInfoDTWidth}em;
    font-weight: ${fontWeights.bold};
  }

  & > dt::after {
    content: ':';
  }

  & > dd {
    margin-bottom: 0;
    margin-left: ${thesisInfoDTWidth}em;
  }
`

export const score = css`
  color: ${primaryColor[2]};
  font-weight: ${fontWeights.bold};
`

export const scoreMax = css`
  font-size: 0.6rem;
`

export const headingRow = css`
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  break-after: avoid;

  & ~ p {
    margin-top: 0;
  }

  & > h3 ~ .${score} {
    font-size: ${fontSizes.h3.desktop}rem;
  }
`

export const checked = css`
  /* to be used in other selectors */
`

export const choices = css`
  margin-bottom: ${rel(24)}rem;
  padding-left: ${rel(16)}rem;
  list-style-type: none;
  counter-reset: li;

  & > li {
    ::before {
      content: '[' counter(li) '] ';
      counter-increment: li;
    }

    &.${checked} {
      color: ${primaryColor[2]};
      font-weight: ${fontWeights.bold};

      &::before {
        margin-left: -${rel(17)}rem;  /* width of "▶ " */
        content: '▶ [' counter(li) '] ';
      }
    }
  }
`

export const text = css`
  white-space: pre-wrap;
  text-align: justify;

  a {
    font-size: inherit;
    text-decoration: underline;

    &::after {
      display: inline-block;
      width: ${rel(10)}rem;
      height: ${rel(10)}rem;
      margin: 0 ${rel(2)}rem;
      background-image: url(${LeaveLinkIcon});
      background-repeat: no-repeat;
      background-size: ${rel(10)}rem ${rel(10)}rem;
      content: '';
    }
  }
`

export const instructions = css`
  font-size: 0.85rem;
`
