import type { ComponentProps } from 'react'

import { useLocale } from '../locale'
import Spinner from '../components/Spinner'


interface Props {
  loaderColor: ComponentProps<typeof Spinner.DualRings>['color']
}

const LoadingUserInfoMessage = ({ loaderColor }: Props): JSX.Element => {
  const { l } = useLocale()

  return (
    <Spinner.DualRings title={l.auth.gettingUserInfo} color={loaderColor} />
  )
}

export default LoadingUserInfoMessage
