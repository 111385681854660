
/**
 * @example
 * getPropertyWithSuffix({ nameCs: 'Ahoj', nameEn: 'Hello' }, 'name', 'en')  //=> 'Hello'
 */
export function getPropertyWithSuffix <
  O extends Partial<Record<`${B}${Capitalize<S>}`, unknown>>,
  B extends string,
  S extends string,
> (obj: O, baseName: B, suffix: S): O[`${B}${Capitalize<S>}`] {
  const suffixCap = suffix[0].toUpperCase() + suffix.slice(1) as Capitalize<S>

  return obj[`${baseName}${suffixCap}` as const]
}

/**
 * Returns a new object with all the **own** properties of the given `obj` expect the
 * specified `keys`.
 *
 * This is a type-safe variant of Lodash's `omit()` function. On the other hand, it cannot
 * omit paths of an object, this is the price for type-safety.
 *
 * The primary use case of this function is to omit properties of an object used for
 * generating an enum using `typescript-string-enums`.
 *
 * @param obj Object whose properties we want to omit.
 * @param keys Keys we want to omit from `obj`.
 * @returns A copy of `obj` without properties specified by `keys`.
 */
export function objectOmit <TObject extends object, TKeys extends Array<keyof TObject>> (
  obj: TObject,
  ...keys: TKeys
): Omit<TObject, TKeys[number]> {
  const res = {} as { [K in keyof TObject]: TObject[K] }

  for (const key of Object.keys(obj) as Array<keyof TObject>) {
    if (!keys.includes(key)) {
      res[key] = obj[key]
    }
  }
  return res
}
