import type { Language } from './common'
import type { Page } from '../common'
import type { Thesis, ThesisRef } from './thesis'
import type { ValueOf } from '../internal/utils'


/**
 * Type for thesis evaluation report.
 */
export const ThesisReportType = {
  Supervisor: 'supervisor',
  Reviewer: 'reviewer',
} as const
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type ThesisReportType = ValueOf<typeof ThesisReportType>


export interface ThesisReport {

  /** Unique ID. */
  readonly id: number

  /** Type of the report. */
  readonly type: ThesisReportType

  /** The Thesis for which this report was created. */
  readonly thesis: ThesisRef | Thesis

  /** Version of the report template in format `vYYYYMMDD`. */
  templateVersion: string

  /** Language of the report. */
  language: Language

  /**
   * The overall evaluation on scale `0` to `100` points (grade A to F),
   * or `-1` for undetermined. It must be ≥ `0` before submitting.
   *
   * @type integer
   * @minimum -1
   * @maximum 100
   */
  score: number

  /** The overall evaluation summary. */
  summary: string

  /**
   * Evaluation of the report criteria defined in the report template identified by
   * `templateVersion`.
   */
  criteriaEvaluation: ReportCriteriaEvaluation

  /** Questions for the defense. Relevant only for report of type `'reviewer'`. */
  defenseQuestions?: string

  /**
   * The ID which KOS assigned to this report.
   * This property is set after the report is submitted in KOS.
   */
  kosId?: number | null

  /**
   * URL of pre-generated PDF report. This is currently used only for old reports (template
   * `v20140114`) migrated from the legacy ZP system and it's URL to DSpace.
   */
  pdfUrl?: string | null

  /**
   * The date and time at which this report was submitted, or `null` if the report has
   * not been submitted yet, i.e. it's a draft.
   */
  submittedAt?: Date | null

  /** The date and time at which this object was created. */
  readonly createdAt: Date

  /**
   * The last date and time at which this object was modified.
   * This is being updated automatically.
   */
  readonly modifiedAt: Date
}

/**
 * Reference to a ThesisReport resource (used in request bodies).
 */
export type ThesisReportRef = Pick<ThesisReport, 'id'>

export type ThesisReportNew =
  & Pick<ThesisReport, 'language' | 'templateVersion' | 'score' | 'criteriaEvaluation' | 'defenseQuestions'>
  & Partial<Pick<ThesisReport, 'summary'>>

/**
 * [Merge Patch](https://tools.ietf.org/html/rfc7386) for a ThesisReport.
 * This is used for updating an existing ThesisReport using the PATCH method; merge patch contains
 * only the properties that should be modified.
 */
export type ThesisReportPatch = Partial<ThesisReportNew>

/**
 * Collection of ThesisReport objects.
 */
export type ThesisReportList = Page<ThesisReport>


export type ReportCriteriaEvaluation = Record<string, CriterionEvaluation>

type CriterionEvaluation = {
  /**
   * Result value. The meaning depends on the type of evaluation criteria; for type `'score'`,
   * it is a number from 0 to 100, and for type `'choice'` from 1 to # of evaluation choices,
   * where the value refers to a choice option at that position.
   *
   * A special value `-1` means undetermined. All criteria must have value ≥ `0` before
   * submitting the report.
   *
   * @type integer
   * @minimum -1
   */
  value: number,

  /** Textual comment justifying the evaluation. */
  comment: string,
}
