import { cx } from 'linaria'

import * as styles from './Hint.style'


interface Props {
  error?: string
  hint?: string
}

const Hint = ({ error, hint }: Props): JSX.Element | null => {
  if (error) {
    return (
      <div className={cx(styles.hint, styles.error)} role='alert' aria-live='assertive'>
        {error}
      </div>
    )
  }

  if (hint) {
    return (
      <div className={styles.hint}>
        {hint}
      </div>
    )
  }

  return null
}

export default Hint
