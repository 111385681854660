import { css } from 'linaria'

import { breakpoints, rel } from '../styles/dimensions'
import { fontWeights } from '../styles/typography'
import { transitionEasing } from '../styles/utils'
import * as colors from '../styles/colors'


export const dropdownButtonWrapper = css`
  position: relative;
  display: flex;
  justify-content: stretch;
  min-height: ${rel(40)}rem;
  margin-left: ${rel(12)}rem;
`

export const dropdownButton = css`
  display: flex;
  align-items: center;
  padding: 0;
  font-weight: ${fontWeights.bold};
  background: none;
  border: none;
`

export const themeDark = css`
  svg .all {
    stroke: ${colors.primaryColor[2]};
  }
`

export const themeLight = css`
  color: ${colors.white};

  svg .all {
    stroke: ${colors.backgroundColor[2]};
  }
`

export const dropdownButtonContent = css`
  position: absolute;
  top: 100%;
  z-index: 999;
  display: flex;
  flex-direction: column;
  margin-top: ${rel(8)}rem;
  padding: ${rel(7)}rem 0;
  background: #fff;
  border-radius: 0 0 ${rel(8)}rem ${rel(8)}rem;
  box-shadow: 0 ${rel(2)}rem ${rel(12)}rem ${colors.boxShadow};
  animation: fadeIn 0.35s;

  & > * {
    margin-top: ${rel(4)}rem;
  }

  & > *:first-child {
    margin-top: 0;
  }

  li {
    margin-top: 0;

    > a {
      display: block;
      padding: ${rel(4)}rem ${rel(16)}rem;
      font-size: ${rel(16)}rem;
      line-height: 1.5;
      transition: background-color ${transitionEasing};

      &:hover,
      &:focus,
      &:active {
        text-decoration: none;
        background-color: ${colors.backgroundColor[2]};
      }
    }
  }

  @keyframes fadeIn {
    0% {
      display: none;
      opacity: 0;
    }

    15% {
      display: flex;
    }

    30% {
      opacity: 0.3;
    }

    100% {
      opacity: 1;
    }
  }
`

export const dropdownButtonContentRight = css`
  right: 0;
  min-width: ${rel(196)}rem;
  margin-right: -${rel(32)}rem;
  margin-left: -${rel(32)}rem;

  @media screen and (min-width: ${breakpoints.md}) {
    margin-right: auto;
    margin-left: auto;
  }
`
