import { css } from 'linaria'

import { white, negativeColor } from '../styles/colors'
import { rel } from '../styles/dimensions'
import { fontWeights } from '../styles/typography'


export const badgeWrapper = css`
  position: relative;
  display: block;
  width: fit-content;
  max-width: ${rel(40)}rem;
  padding: 0;
`

export const badge = css`
  position: absolute;
  z-index: 1;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  min-width: ${rel(16)}rem;
  height: ${rel(16)}rem;
  padding: 0 ${rel(4)}rem;
  color: ${white};
  font-weight: ${fontWeights.bold};
  font-size: ${rel(11)}rem;
  line-height: 1;
  letter-spacing: ${rel(0.5)}rem;
  text-align: center;
  background-color: ${negativeColor[2]};
  border-radius: ${rel(16)}rem;
`

export const bottomLeft = css`
  bottom: -${rel(3)}rem;
  left: -${rel(3)}rem;
  transform-origin: 0 100%;
`

export const bottomRight = css`
  right: -${rel(3)}rem;
  bottom: -${rel(3)}rem;
  transform-origin: 100% 100%;
`

export const topLeft = css`
  top: -${rel(3)}rem;
  left: -${rel(3)}rem;
  transform-origin: 0 0;
`

export const topRight = css`
  top: -${rel(3)}rem;
  right: -${rel(3)}rem;
  transform-origin: 100% 0;
`
