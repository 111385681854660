import { useSession } from '../../session'
import { useAccessControl } from '../../access-control'
import { useLocale } from '../../locale'
import { formatPersonFullName } from '../../utils/person'
import PageHeader from '../../components/PageHeader'
import ThesesTable from '../../features/thesis/ThesesTable'
import * as style from '../../features/thesis/ThesesTable.style'


interface ThesesListPageProps {
  myThesesOnly?: boolean
}

const ThesesListPage = ({ myThesesOnly = false }: ThesesListPageProps): JSX.Element => {
  const { l } = useLocale()
  const { isAuthenticated, userInfo } = useSession()
  const accessControl = useAccessControl()

  const fullName = myThesesOnly
    && isAuthenticated()
    && userInfo.state === 'loaded'
    && formatPersonFullName(userInfo.person)

  return (
    <article className={style.thesesTableArticle}>
      <PageHeader
        title={myThesesOnly ? l.thesesList.myThesesTitle : l.thesesList.allThesesTitle}
        subtitles={(myThesesOnly && fullName) ? [fullName] : []}
      />
      <ThesesTable
        myThesesOnly={myThesesOnly}
        displayNewAssignmentButton={accessControl.state === 'loaded' && accessControl.globalRoles.isProponent}
        displayExportCsvButton={accessControl.state === 'loaded'}
      />
    </article>
  )
}

export default ThesesListPage
