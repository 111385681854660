import type { ValueOf } from '../internal/utils'
import type { Page } from '../common'


/**
 * Degree of study
 */
export const StudyDegree = {
  Bachelor: 'bachelor',
  Master: 'master',
} as const
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type StudyDegree = ValueOf<typeof StudyDegree>

export type StudyDegreeList = Array<StudyDegree>

// NOTE: Properties programName{Cs,En} are used only for generating thesis printouts. If another
// use is found in the future, consider moving it into a separate type/table StudyProgram.
/**
 * Study specialization.
 * This type aggregates study branch and study program from KOS.
 */
export interface Specialization {
  /**
   * ID of the specialization. This is not exposed in REST API.
   * @hidden
   */
  // FIXME: This shouldn't be here at all, but we need it to be required in theses-backend.
  readonly id?: number

  /**
   * Specialization code unique in the scope of ProjectsFIT.
   *
   * @pattern ^[a-zA-Z0-9_-]+$
   * @examples ["MI-ZI"]
   */
  readonly code: string

  /**
   * ID of the underlying study branch in KOS.
   */
  readonly branchId: number

  /**
   * A set of study plans codes that identify this specialization in a case when
   * there are more specializations with the same `branchId`.
   *
   * It may be empty if there's no ambiguity.
   * Each study plan code must be unique across all specializations.
   *
   * It's used only to assign a student's specialization based on the data from KOS.
   */
  studyPlanCodes: string[]

  /**
   * Numerical code of the organizational unit (department) guaranteeing
   * this specialization.
   *
   * @type integer
   * @minimum 0
   * @maximum 99999
   * @examples [18102]
   */
  departmentCode: number

  /**
   * Czech name.
   * @examples ["Znalostní inženýrství"]
   */
  nameCs: string

  /**
   * English name.
   * @examples ["Knowledge Engineering"]
   */
  nameEn: string

  /** Study degree (named as `programmeType` in KOSapi). */
  studyDegree: StudyDegree

  /**
   * Czech name of the study program of this specialization for the thesis printout.
   */
  programNameCs: string

  /**
   * English name of the study program of this specialization for the thesis printout.
   */
  programNameEn: string

  /**
   * Whether this specialization is still active or not.
   * @default true
   */
  active: boolean

  /** The last date and time at which this object was synchronized with KOS. */
  refreshedAt: Date

  /**
   * The last date and time at which this object was modified. This is being updated automatically.
   */
  readonly modifiedAt: Date
}

/**
 * Collection of Specialization objects.
 */
export type SpecializationList = Page<Specialization>

/**
 * Reference to a Specialization resource (used in request bodies).
 */
export type SpecializationRef = Pick<Specialization, 'code'>

export function isSpecialization (spec: Specialization | SpecializationRef): spec is Specialization {
  return 'studyDegree' in spec
}

export interface SpecializationFilter {
  studyDegree?: StudyDegree
}
