import { UseFormMethods } from 'react-hook-form'
import { FileAttachment } from '@cvut/profit-api-types/lib/theses'


interface AttachmentHiddenFileFieldsProps {
  nameRoot: string
  register: UseFormMethods['register']
  item: FileAttachment
}

// Because `<input type='file' />` is not compatible with form backup, we use hidden fields to simulate it’s values
const AttachmentHiddenFileFields = ({
  nameRoot, register, item,
}: AttachmentHiddenFileFieldsProps): JSX.Element => {
  const { currentSize, filename, href, totalSize } = item
  const rules = { valueAsNumber: true }

  return (
    <>
      <input type='hidden' name={`${nameRoot}.href`} ref={register()} value={href} />
      <input type='hidden' name={`${nameRoot}.filename`} ref={register()} value={filename} />
      <input type='hidden' name={`${nameRoot}.currentSize`} ref={register(rules)} value={currentSize} />
      <input type='hidden' name={`${nameRoot}.totalSize`} ref={register(rules)} value={totalSize} />
    </>
  )
}

export default AttachmentHiddenFileFields
