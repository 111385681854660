import { ComponentPropsWithRef, useEffect, useState } from 'react'
import { cx } from 'linaria'
import type { Except, SetRequired } from 'type-fest'
import { StudyList, isPerson, ThesisMainState } from '@cvut/profit-api-types/lib/theses'

import OptionsList from './OptionsList'
import { useLocale } from '../../../locale'
import { useStudies } from '../../../api/studies'
import { Person } from '../../../types/person'
import * as style from './PersonSearchBox.style'


// FIXME: This method should probably be somewhere else
const mapStudiesToPeople = (studies: StudyList): Person[] => {
  return studies.data.reduce<Person[]>((result, study) => {
    if (isPerson(study.person)) {
      result.push({
        titlesPre: study.person.titlesPre,
        firstName: study.person.firstName,
        lastName: study.person.lastName,
        titlesPost: study.person.titlesPost,
        username: study.person.username,
        email: study.person.email,
        study: {
          id: study.id,
          specialization: study.specialization,
          hasThesis: !!study.hasThesis,
        },
      })
    }
    return result
  }, [])
}

/**
 * TODO: Add SearchPerson icon to PersonSearchBox input
 * FIXME: optionsWrapper div should overflow outside the Card background (see prototype)
 */
interface Props {
  labelText?: string
  inputProps: SetRequired<Except<ComponentPropsWithRef<'input'>, 'className' | 'type' | 'value' | 'onChange'>, 'id'>
  extraClassNames?: string[]
  selectedPerson: Person | null
  onPersonSelect: (person: Person | null) => void
  loadingMsg?: string
  errorMsg?: string
  noResultsMsg?: string
  selectNoneText?: string
  selectedPersonText?: string
  thesisState: ThesisMainState
}

const PersonSearchBox = (props: Props): JSX.Element => {
  const [studiesStatus, getStudies] = useStudies()
  const [searchQuery, setSearchQuery] = useState('')
  const { l } = useLocale()

  useEffect(() => {
    if (searchQuery) {
      void getStudies(searchQuery)
    }
  }, [searchQuery])

  const inputClasses = [style.input]
  if (props.inputProps.required) {
    inputClasses.push(style.required)
  }

  const optionsWrapperClasses = [style.optionsWrapper]
  if (!searchQuery && !props.selectedPerson) {
    optionsWrapperClasses.push(style.hidden)
  }

  let message = null
  if (searchQuery) {
    if (studiesStatus.state === 'loading') {
      message = props.loadingMsg ?? l.personSearchBox.loading
    } else if (studiesStatus.state === 'error') {
      message = props.errorMsg ?? l.personSearchBox.error
    } else if (studiesStatus.state === 'success' && studiesStatus.data?.data.length === 0) {
      message = props.noResultsMsg ?? l.personSearchBox.noResults
    }
  }

  return (
    <div className={cx(style.wrapper, ...(props.extraClassNames ?? []))}>
      {props.labelText && (
        <label htmlFor={props.inputProps.id}>
          {props.labelText}
          {props.inputProps.required ? <span aria-hidden='true'>*</span> : null}
        </label>
      )}
      <div className={style.inputWrapper}>
        <input
          {...props.inputProps}
          type='search'
          value={searchQuery}
          onChange={event => setSearchQuery(event.target.value)}
          className={cx(...inputClasses)}
          autoComplete='off'
        />
        <div className={cx(...optionsWrapperClasses)}>
          {message ? (
            <div className={style.textMsg}>{message}</div>
          ) : (
            /* eslint-disable @typescript-eslint/no-non-null-assertion */
            <OptionsList
              suggestedPeople={studiesStatus.state === 'success' ? mapStudiesToPeople(studiesStatus.data!) : []}
              searching={!!searchQuery}
              selectedPerson={props.selectedPerson}
              selectedPersonText={props.selectedPersonText ?? l.personSearchBox.selectedPerson}
              selectNoneText={props.selectNoneText ?? l.personSearchBox.selectNone}
              onOptionClick={props.onPersonSelect}
              thesisState={props.thesisState}
            />
            /* eslint-enable @typescript-eslint/no-non-null-assertion */
          )}
        </div>
      </div>
    </div>
  )
}

export default PersonSearchBox
