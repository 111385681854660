import { ReactNode } from 'react'
import { Thesis } from '@cvut/profit-api-types/lib/theses'

import Card from '../../components/Card'
import { ReactComponent as LangCsIcon } from '../../images/icons/LangCS.svg'
import { ReactComponent as LangEnIcon } from '../../images/icons/LangEN.svg'
import { useLocale } from '../../locale'
import * as styles from './ThesisSubmitView.style'


interface SectionItemProps {
  abstractText: string
  icon: ReactNode
  keywords: string[]
}

const SectionItem = ({ abstractText, icon, keywords }: SectionItemProps) => {
  const { l } = useLocale()

  return (
    <section className={styles.mainSection}>
      <h3>{l.thesis.submission.view.label.abstract} {icon}</h3>
      <p>{abstractText}</p>
      <h3>{l.thesis.submission.view.label.keywords}:</h3>
      <p>{keywords.join(', ')}</p>
    </section>
  )
}

interface Props {
  thesis: Thesis
}

const ThesisSubmitViewMain = ({ thesis: { abstractCs, keywordsCs, abstractEn, keywordsEn } }: Props): JSX.Element => (
  <Card.Column>
    <article>
      <SectionItem abstractText={abstractCs ?? '-'} icon={<LangCsIcon />} keywords={keywordsCs ?? ['-']} />
      <SectionItem abstractText={abstractEn ?? '-'} icon={<LangEnIcon />} keywords={keywordsEn ?? ['-']} />
    </article>
  </Card.Column>
)

export default ThesisSubmitViewMain
