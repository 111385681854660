import { useEffect, useState } from 'react'


const useVisibilityChange = (handler: (isVisible: boolean) => unknown): void => {
  const [currentState, setCurrentState] = useState(document.visibilityState !== 'hidden')

  useEffect(() => {
    const listener = () => {
      const newState = document.visibilityState !== 'hidden'

      if (newState !== currentState) {
        handler(newState)
        setCurrentState(newState)
      }
    }

    window.addEventListener('visibilitychange', listener)
    window.addEventListener('pagehide', listener)

    return () => {
      window.removeEventListener('visibilitychange', listener)
      window.removeEventListener('pagehide', listener)
    }
  }, [handler])
}

export default useVisibilityChange
