import { css } from 'linaria'

import leaveLinkIcon from '../../images/icons/LeaveLink.svg'


export const attachmentLink = css`
  text-decoration: underline;

  &::after {
    display: inline-block;
    width: 1rem;
    height: 1rem;
    background: url(${leaveLinkIcon}) center center no-repeat;
    background-size: 50%;
    content: '';
  }
`
