import { Link } from 'react-router-dom'
import { cx } from 'linaria'
import type { ThesisReportType } from '@cvut/profit-api-types/lib/theses'
import type { PersonName } from '@cvut/profit-api-types/lib/common'
import { formatPersonFullName, scoreToGrade } from '@cvut/profit-theses-common'

import { useLocale } from '../../../locale'
import * as style from './ThesisReportLink.style'
import { ReactComponent as FileIcon } from '../../../images/icons/File.svg'
import { ReactComponent as ExternalLinkIcon } from '../../../images/icons/LeaveLink.svg'


interface ReportLink {
  type: 'internal' | 'external'
  link: string
}


interface Props {
  reportType: ThesisReportType
  author: PersonName
  score?: number
  reportDetailLink: ReportLink
  extraClassNames?: string[]
}

const ThesisReportLink = ({ reportType, author, score, reportDetailLink, extraClassNames }: Props): JSX.Element => {
  const { l: { thesis: { reports: { evaluationCard: l } } } } = useLocale()

  const linkProps = {
    title: l.displayLinkTitle[reportType],
    className: cx(style.content, ...(extraClassNames ?? [])),
  }

  const scoreNode = score != null ? (
    <div className={style.score}>
      {scoreToGrade(score) + ` | ${score} ${l.pointsAbbrev}`}
    </div>
  ) : null
  const LinkContents = (props: { external?: boolean }) => (
    <>
      <FileIcon className={style.downloadIcon} />
      <div className={style.reportType}>
        <span>{l.reportType[reportType]}</span>
        {props.external && <ExternalLinkIcon className={style.externalLinkIcon} />}
      </div>
      <div className={style.author}>{formatPersonFullName(author)}</div>
      {scoreNode}
    </>
  )

  switch (reportDetailLink.type) {
    case 'internal':
      return (
        <Link to={reportDetailLink.link} {...linkProps}>
          <LinkContents />
        </Link>
      )
    case 'external':
      return (
        <a href={reportDetailLink.link} {...linkProps}>
          <LinkContents external />
        </a>
      )
  }
}

export default ThesisReportLink
