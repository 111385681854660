import { ChangeEventHandler } from 'react'
import { FieldError, UseFormMethods } from 'react-hook-form'
import { Attachment, AttachmentKey, AttachmentType } from '@cvut/profit-api-types/lib/theses'

import AttachmentFileDownloadIcon from './AttachmentFileDownloadIcon'
import AttachmentFileInput from './AttachmentFileInput'
import AttachmentHiddenFileFields from './AttachmentHiddenFileFields'
import type { UploadQueueItem } from '../../api/theses'
import Hint from '../../components/form/Hint'
import Select from '../../components/form/Select'
import TextInput from '../../components/form/TextInput'
import locale, { useLocale } from '../../locale'
import * as style from './ThesisSubmitForm.style'


const AttachmentTypeKeys = [
  AttachmentType.Dataset, AttachmentType.Demo, AttachmentType.Paper,
  AttachmentType.SourceCode, AttachmentType.Website, AttachmentType.Other,
]

// FIXME: deduplicate
const attachmentsListName = 'attachments'
const getAttachmentFieldName = (index: number, id: string) => `${attachmentsListName}[${index}].${id}`
const hrefRegexPatternRaw = '^https?:\\/\\/((\\w|_|-)+\\.?)+(:\\d+)?($|\\/\\S*$)'
const hrefRegexPattern = new RegExp(hrefRegexPatternRaw)

const getAttachmentItemInitialValue = (id: AttachmentKey) => ({
  href: '',
  type: AttachmentType.SourceCode,
  titleCs: locale.cs.thesis.submission.form.attachmentType[AttachmentType.SourceCode].title,
  titleEn: locale.en.thesis.submission.form.attachmentType[AttachmentType.SourceCode].title,
})[id]

interface AttachmentHrefError {
  href: FieldError
}

interface AttachmentItemProps {
  id: AttachmentKey
  index: number
  addUploadItem: (uploadItem: UploadQueueItem) => void
  getQueuedFile: (filename: string) => File | undefined
  hasAttachment: (filename: string) => boolean
  item: Attachment
  register: UseFormMethods['register']
  onChange: ChangeEventHandler<HTMLInputElement | HTMLSelectElement>
  errors: UseFormMethods['errors']
}

const AttachmentItem = ({
  errors, id, index, item, register, onChange, getQueuedFile,
}: AttachmentItemProps): JSX.Element => {
  const { l } = useLocale()
  const extraClassNames = [style.inputRequired]
  const name = getAttachmentFieldName(index, id)
  const isHref = id === 'href'
  const nameRoot = `${attachmentsListName}[${index}]`
  const rules = isHref ? [{
    pattern: {
      value: hrefRegexPattern,
      message: l.thesis.submission.errorMessages.invalidURL,
    },
  }] : []
  const inputProps = {
    defaultValue: item[id] ?? getAttachmentItemInitialValue(id),
    id: name,
    name,
    ref: register(...rules),
    pattern: isHref ? hrefRegexPatternRaw : undefined,
  }
  const labelText = l.thesis.submission.form.label.attachments.attachment[id]

  if (id === 'type') {
    return (
      <Select
        {...{ extraClassNames, labelText }}
        key={id}
        selectProps={{
          ...inputProps,
          onChange,
        }}
      >
        {AttachmentTypeKeys.map((type) => (
          <option key={type} value={type}>{l.thesis.submission.form.attachmentType[type].option}</option>
        ))}
      </Select>
    )
  }

  // FIXME - move condition into a properly named function
  if (isHref && 'filename' in item) {
    const { totalSize, currentSize } = item

    if (currentSize === totalSize && totalSize > 0) {
      return (
        <div className={style.inputRequired}>
          <label className={style.label}>
            {l.thesis.submission.form.label.attachments.attachment.file}
          </label>
          <AttachmentFileDownloadIcon item={item} />
          <AttachmentHiddenFileFields {...{ nameRoot, register }} item={item} />
        </div>
      )
    }

    // FIXME - we don't care about this here - this should be shown while uploading (-> remove condition)
    return (
      <div className={style.inputRequired}>
        <label htmlFor={nameRoot} className={style.label}>
          {/* TODO - DRY with supported extensions here and in the accept attribute in the file input component */}
          {l.thesis.submission.form.label.attachments.attachment.file} (.iso, .zip)
        </label>
        <div className={style.flexBox}>
          <AttachmentFileInput
            nameRoot={nameRoot}
            onChange={onChange}
            selectedFile={getQueuedFile(item.filename)}
          />
          {item.filename && (
            <AttachmentFileDownloadIcon item={item} />
          )}
          <AttachmentHiddenFileFields {...{ nameRoot, register }} item={item} />
        </div>
      </div>
    )
  }

  return (
    <div>
      <TextInput {...{ extraClassNames, inputProps, labelText }} key={id} />
      {isHref && <Hint error={(errors.attachments as AttachmentHrefError[])?.[index]?.href.message} />}
    </div>
  )
}

export default AttachmentItem
