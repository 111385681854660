import { Link } from 'react-router-dom'
import { isPerson, isStudy, Thesis } from '@cvut/profit-api-types/lib/theses'

import Card from '../../components/Card'
import PersonCard from '../../components/PersonCard'
import { ReactComponent as LeaveLinkIcon } from '../../images/icons/LeaveLink.svg'
import { formatPersonFullName } from '../../utils/person'
import { useLocale } from '../../locale'
import * as style from './ThesisPeopleCard.style'


interface Props {
  thesis: Thesis
  reviewerLink?: string
}

const ThesisPeopleCard = ({ reviewerLink, thesis }: Props): JSX.Element => {
  const { l } = useLocale()

  const unassigned = <PersonCard name={l.thesis.unassigned} />

  const supervisorCard = thesis.supervisorFrozenName
    && isPerson(thesis.supervisor)
    ? (
      <PersonCard
        name={formatPersonFullName(thesis.supervisorFrozenName)}
        contact={thesis.supervisor.email}
      />
    ) : unassigned

  const assigneeCard = thesis.assigneeFrozenName
    && thesis.assignee
    && isStudy(thesis.assignee)
    && isPerson(thesis.assignee.person)
    ? (
      <PersonCard
        name={formatPersonFullName(thesis.assigneeFrozenName)}
        contact={thesis.assignee.person.email}
        specialization={thesis.assignee.studyPlanCode}
      />
    ) : unassigned

  const reviewerCard = thesis.reviewerFrozenName
    && thesis.reviewer
    && isPerson(thesis.reviewer)
    ? (
      <PersonCard
        name={formatPersonFullName(thesis.reviewerFrozenName)}
        contact={thesis.reviewer.email}
      />
    ) : reviewerLink && thesis.states.includes('changeable.assigned.reviewer.pending')
      ? (
        <PersonCard>
          <Link to={reviewerLink}>
            {l.thesis.candidatesList}&nbsp;
            <LeaveLinkIcon />
          </Link>
        </PersonCard>)
      : unassigned

  return (
    <Card>
      <Card.Header title={l.thesis.people} />
      <Card.Content extraClassNames={[style.people]}>
        <div>
          <div className={style.title}>{l.thesis.supervisor}</div>
          {supervisorCard}
        </div>
        <div>
          <div className={style.title}>{l.thesis.student}</div>
          {assigneeCard}
        </div>
        <div>
          <div className={style.title}>{l.thesis.reviewer}</div>
          {reviewerCard}
        </div>
      </Card.Content>
    </Card>
  )
}

export default ThesisPeopleCard
