import type { ChangeEventHandler } from 'react'

import * as style from './SwitcherFilters.style'
import SwitcherFilter from './SwitcherFilter'
import { ThesesFiltersLocale } from './SwitcherFilter'


export interface SwitcherFiltersProps {
  switcherIdList: ThesesFiltersLocale[]
  onSwitcherChange: ChangeEventHandler
}

const SwitcherFilters = ({ switcherIdList, onSwitcherChange }: SwitcherFiltersProps): JSX.Element => (
  <div className={style.thesesListFilters}>
    <div className={style.thesesListFiltersItems}>
      {switcherIdList.map(switcherProp =>
        <div className={style.thesesListFiltersItem} key={switcherProp}>
          <SwitcherFilter switcherId={switcherProp} onSwitcherChange={onSwitcherChange} />
        </div>
      )}
    </div>
  </div>
)

export default SwitcherFilters
