import { css } from 'linaria'

import { backgroundColor, primaryColor, white } from '../../../styles/colors'
import { rel } from '../../../styles/dimensions'


export const link = css`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  padding: ${rel(8)}rem ${rel(16)}rem;
  color: ${primaryColor[2]};
  white-space: nowrap;
  text-align: left;
  text-decoration: none;
  background-color: transparent;
  border: 0;
  outline: 0;

  &:hover,
  &:focus {
    background-color: ${backgroundColor[2]};
  }

  &:disabled {
    background-color: ${white};
    cursor: not-allowed;
  }
`

export const content = css`
  flex-grow: 2;
`
