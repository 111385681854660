import type { ReactNode } from 'react'
import { cx } from 'linaria'

import * as style from './Content.style'


// NOTE: It must be exported due to TS4023 in index.tsx.
export interface Props {
  children: ReactNode
  extraSpaceBelow?: boolean
  extraClassNames?: string[]
}

const Content = ({ children, extraSpaceBelow, extraClassNames }: Props): JSX.Element => (
  <div className={cx((extraSpaceBelow ? style.contentLargerSpace : style.content), ...(extraClassNames ?? []))}>
    {children}
  </div>
)

export default Content
