import Spinner from '../components/Spinner'


export const PageLoadSpinner = (props: { message: string }): JSX.Element => (
  <>
    <Spinner.DualRings color='primary' title={props.message + '…'} />
    <p>{props.message}</p>
  </>
)

export default PageLoadSpinner
