import { css, cx } from 'linaria'

import { breakpoints, rel } from '../../styles/dimensions'


export const multiselectTopInfo = css`
  @media screen and (min-width: ${breakpoints.xl}) {
    display: none;
  }
`

export const fieldsetRow = css`
  display: flex;
  flex-wrap: wrap;
  margin-right: -${rel(4)}rem;
  margin-left: -${rel(4)}rem;

  @media screen and (min-width: ${breakpoints.md}) {
    margin-right: -${rel(8)}rem;
    margin-left: -${rel(8)}rem;
  }
`

export const fieldsetCol = css`
  flex: 0 0 100%;
  max-width: 100%;
  margin-top: ${rel(10)}rem;
  padding-right: ${rel(4)}rem;
  padding-left: ${rel(4)}rem;

  @media screen and (min-width: ${breakpoints.md}) {
    padding-right: ${rel(8)}rem;
    padding-left: ${rel(8)}rem;
  }

  @media screen and (min-width: ${breakpoints.xl}) {
    margin-top: ${rel(4)}rem;
  }
`

export const fieldsetDegreeCol = cx(fieldsetCol, css`
  @media screen and (min-width: ${breakpoints.md}) {
    flex: 0 0 ${rel(316)}rem;
    max-width: ${rel(316)}rem;
  }
`)

export const fieldsetSpecializationsCol = cx(fieldsetCol, css`
  @media screen and (min-width: ${breakpoints.md}) {
    flex-basis: 0;
    flex-grow: 1;
  }
`)

export const hintIcon = css`
  display: none;
  width: ${rel(16)}rem;
  height: ${rel(16)}rem;
  margin-left: ${rel(8)}rem;
  cursor: help;

  @media screen and (min-width: ${breakpoints.xl}) {
    display: inline-block;
  }
`
