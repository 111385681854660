import { Enum } from 'typescript-string-enums'

import type { AvailableLocale } from './index'


const LocaleString = Enum('cs-CZ', 'en-GB')
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type LocaleString = Enum<typeof LocaleString>

export const localeToLocaleString = (lang: AvailableLocale): LocaleString => (({
  cs: 'cs-CZ',
  en: 'en-GB',
} as Record<AvailableLocale, LocaleString>)[lang])
