import type { ComponentPropsWithRef } from 'react'
import { cx } from 'linaria'
import type { Except, SetRequired } from 'type-fest'

import * as style from './Switch.style'


interface Props {
  inputProps: Except<SetRequired<ComponentPropsWithRef<'input'>, 'id'>, 'className' | 'type'>
  label: string
  extraClassNames?: string[]
}

const Switch = ({ extraClassNames, label, inputProps }: Props): JSX.Element => (
  <div className={cx(style.switcher, ...(extraClassNames ?? []))}>
    <input
      type='checkbox'
      className={style.switcherInput}
      {...inputProps}
    />
    <label htmlFor={inputProps.id} className={style.switcherLabel}>
      {label}
    </label>
  </div>
)

export default Switch
