import type { ComponentPropsWithRef, ReactNode } from 'react'
import { cx } from 'linaria'
import type { Except, SetRequired } from 'type-fest'

import * as styles from './Select.style'


interface Props {
  labelText: string
  selectProps: SetRequired<Except<ComponentPropsWithRef<'select'>, 'className'>, 'name' | 'id'>
  children: ReactNode
  extraClassNames?: string[]
}

const Select = ({ labelText, selectProps, children, extraClassNames }: Props): JSX.Element => {
  const requiredIndicator = selectProps.required
    ? <span aria-hidden className={styles.requiredIndicator}>*</span>
    : null

  return (
    <div className={cx(...(extraClassNames ?? []))}>
      <label htmlFor={selectProps.id} className={styles.label}>
        {requiredIndicator}
        {labelText}
      </label>
      <div>
        <select {...selectProps} className={styles.select}>
          {children}
        </select>
      </div>
    </div>
  )
}

export default Select
