import { css, cx } from 'linaria'

import * as colors from '../../styles/colors'
import { iconWrapper } from '../../components/Button.style'


export const controlButton = cx(iconWrapper, css`
  cursor: pointer;
`)

export const controlIcon = css`
  .background {
      fill: ${colors.white};
  }

  &:hover {
    .background {
      fill: ${colors.backgroundColor[2]};
    }
  }

  &:active {
    .background {
      fill: ${colors.backgroundColor[1]};
    }
  }
`
