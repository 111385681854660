import { ComponentProps } from 'react'
import { Link } from 'react-router-dom'


interface MetadataPairProps {
  label: string
  value: string
  tooltip?: string
  href?: ComponentProps<typeof Link>['to']
}

export const MetadataPair = ({ label, value, tooltip, href }: MetadataPairProps): JSX.Element => (
  <div>
    <dt title={tooltip}>{label}</dt>
    <dd>
      {href
        ? (
          <Link
            to={href}
            title={value}
            onClick={e => {
              if (e.currentTarget.href.startsWith('mailto:')) {
                e.preventDefault()
                window.open(e.currentTarget.href)
              }
            }}
          >
            {value}
          </Link>
        )
        : value}
    </dd>
  </div>
)
