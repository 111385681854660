import type { MouseEvent as ReactMouseEvent, Ref } from 'react'
import { cx } from 'linaria'
import { useRef, useState } from 'react'
import { FilterProps } from 'react-table'

import { OptionType } from './types'
import * as style from './SelectFilter.style'
import { useLocale } from '../../../locale'
import useOnClickOutside from '../../../hooks/useOnClickOutside'
import useOnKeyPress from '../../../hooks/useOnKeyPress'
import { ReactComponent as ArrowDownIcon } from '../../../images/icons/ArrowDown.svg'


// FIXME: lots of code duplication with MultiselectFilter

interface ClosedOptionsPanelProps {
  selectedOption?: OptionType
}

const ClosedOptionsPanel = ({ selectedOption }: ClosedOptionsPanelProps) => (
  <div className={cx(style.selectLabel, selectedOption?.key !== 'all' && style.activeFilter)}>
    {selectedOption ? selectedOption.text : ''}
  </div>
)


interface OptionListItemProps {
  option: OptionType
  activeOption: boolean
  onChange: (newValue?: string) => void
}

const OptionListItem = ({ option, activeOption, onChange }: OptionListItemProps) => (
  <li
    className={cx(style.optionsListItem, activeOption && style.active)}
    onClick={() => onChange(option.value)}
  >
    {option.text}
  </li>
)


interface OpenOptionsPanelProps {
  options: OptionType[]
  activeOption: OptionType | undefined
  listRef: Ref<HTMLUListElement>
  onClose: () => void
  onChange: (filterValue?: string) => void
}

const OpenOptionsPanel = ({ options, activeOption, listRef, onChange, onClose }: OpenOptionsPanelProps) => {
  const { l } = useLocale()

  useOnKeyPress('Escape', onClose)

  return (
    <div className={style.panelContainer}>
      <div className={style.selectLabel}>
        {l.thesesList.filters.select}
      </div>
      <ul ref={listRef} className={style.optionsList}>
        {options.map(option => (
          <OptionListItem
            key={option.key}
            option={option}
            activeOption={option === activeOption}
            onChange={onChange}
          />
        ))}
      </ul>
    </div>
  )
}

const OpenCloseToggleIcon = () => {
  const { l } = useLocale()

  return (
    <button className={style.openToggle} title={l.thesesList.filters.expandMenu}>
      <ArrowDownIcon />
    </button>
  )
}


interface OptionsPanelProps {
  isOpen: boolean
  options: OptionType[]
  filterValue: string
  optionsListRef: Ref<HTMLUListElement>
  onClose: () => void
  onChange: (filterValue?: string) => void
}

const OptionsPanel = ({ isOpen, options, filterValue, optionsListRef, onClose, onChange }: OptionsPanelProps) => {
  const selectedOption = options.find(option => option.value === filterValue)

  return (
    <div>
      <ClosedOptionsPanel selectedOption={selectedOption} />
      {isOpen && (
        <OpenOptionsPanel
          options={options}
          activeOption={selectedOption}
          listRef={optionsListRef}
          onChange={onChange}
          onClose={onClose}
        />
      )}
    </div>
  )
}


export type SelectFilterProps<T extends object = {}> = Pick<FilterProps<T>, 'column'> & {
  options: OptionType[],
}

export const SelectFilter = <T extends object = {}>({ column, options }: SelectFilterProps<T>): JSX.Element => {
  const [isOpen, setIsOpen] = useState(false)
  const { filterValue, setFilter } = column as { filterValue?: string, setFilter: (filterValue?: string) => void }
  const currentFilterValue = filterValue ?? ''
  const componentRef = useRef<HTMLDivElement | null>(null)
  const optionsListRef = useRef<HTMLUListElement | null>(null)

  const handleClose = () => setIsOpen(false)

  useOnClickOutside(componentRef, handleClose)

  const handleOpenToggleClick = (e: ReactMouseEvent<HTMLDivElement, MouseEvent>) => {
    const newOpenState = !isOpen
    const target = e.target as HTMLElement

    if (newOpenState || !optionsListRef.current?.contains(target)) {
      setIsOpen(newOpenState)
    }
  }

  const handleChange = (filterValue?: string) => {
    setFilter(filterValue)
    handleClose()
  }

  return (
    <div className={style.select} ref={componentRef} onClick={handleOpenToggleClick}>
      {/* TODO - visually impaired-friendly expand/collapse */}
      <OpenCloseToggleIcon />
      <OptionsPanel
        isOpen={isOpen}
        options={options}
        filterValue={currentFilterValue}
        optionsListRef={optionsListRef}
        onChange={handleChange}
        onClose={handleClose}
      />
    </div>
  )
}

export default SelectFilter
