import type { ValueOf } from '../internal/utils'

// TODO: Move one level up.

// TODO: Revise names and URNs.
export const ErrorType = {
  BadRequestError: 'urn:error:bad-request',
  BodyValidation: 'urn:error:validation:body',
  ConflictWithState: 'urn:error:conflict-with-state',
  ExternalServiceError: 'urn:error:external-service-error',
  FileTooLarge: 'urn:error:file-too-large',
  HttpPreconditionFailed: 'urn:error:http-precondition-failed',
  InsufficientPermission: 'urn:error:insufficient-permission',
  InternalServerError: 'urn:error:internal-server-error',
  InvalidHeader: 'urn:error:invalid:header',
  InvalidUriParam: 'urn:error:invalid:uri-param',
  NonExistentReference: 'urn:error:non-existent-reference',
  RangeNotSatisfiable: 'urn:error:range-not-satisfiable',
  ResourceNotFound: 'urn:error:not-found:resource',
  RouteNotFound: 'urn:error:not-found:route',
  // TODO: This is inconsistent with InvalidUriParam, unify it somehow.
  Unauthenticated: 'urn:error:unauthenticated',
  UnsupportedMediaType: 'urn:error:unsupported-media-type',
  UriParamsValidation: 'urn:error:validation:uri-params',
} as const
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type ErrorType = ValueOf<typeof ErrorType>

/**
 * HTTP error response per [RFC7807](https://tools.ietf.org/html/rfc7807).
 */
export interface HttpProblem {

  [key: string]: any

  /** A human-readable explanation specific to this occurrence of the problem. */
  detail?: string

  /**
   * A URI reference that identifies the specific occurrence of the problem.
   * @format uri-reference
   */
  instance?: string

  /**
   * The HTTP status code [RFC7231] generated by the origin server for this occurrence
   * of the problem.
   *
   * @minimum 400
   * @maximum 599
   */
  status: number

  /**
   * A short, human-readable summary of the problem type. It SHOULD NOT change from occurrence
   * to occurrence of the problem, except for purposes of localization
   */
  title: string

  /**
   * A URI reference [RFC3986] that identifies the problem type.
   * @format uri-reference
   */
  type?: string
}

export type HttpError =
  | ConflictWithStateError
  | ExternalServiceError
  | HttpPreconditionFailedError
  | InvalidParamError
  | NonExistentReferenceError
  | NotFoundError
  | PermissionError
  | SchemaValidationError
  | UnauthenticatedError

/**
 * HTTP error response that is returned when the requested action is not allowed in the current
 * state of the resource.
 */
export interface ConflictWithStateError extends HttpProblem {

  readonly status: 409

  readonly type: (typeof ErrorType)['ConflictWithState']

  readonly params: Readonly<{
    /**
     * The requested action.
     */
    requestedAction: string,

    /**
     * A list of actions allowed in this state.
     */
    allowedActions?: readonly string[],

    /**
     * The current state of the resource.
     *
     * @examples ["reviewerAcceptance.pending", "specOfficerApproval.approved"]
     */
    currentState: readonly string[],
  }>
}

/**
 * HTTP error response that is returned when the request can't fulfilled due to an
 * external service error (OAuth server, KOSapi, ...).
 */
export interface ExternalServiceError extends HttpProblem {

  readonly status: 503

  readonly type: (typeof ErrorType)['ExternalServiceError']

  readonly params: Readonly<{
    /**
     * Name of the external service.
     */
    service: string,
  }>
}

/**
 * HTTP error response that is returned when a given request header, URI path parameter,
 * or URI query parameter is malformed or invalid.
 */
export interface InvalidParamError extends HttpProblem {

  readonly status: 400

  readonly type: (typeof ErrorType)['InvalidHeader'] | (typeof ErrorType)['InvalidUriParam']

  readonly params: Readonly<{
    /**
     * Name of the parameter or header.
     */
    name: string,
    /**
     * The given value that does not match expected format.
     */
    value: string,
  }>
}

/**
 * HTTP error response that is returned when the request contains reference to a resource (entity)
 * that doesn't exist.
 */
export interface NonExistentReferenceError extends HttpProblem {

  readonly status: 422

  readonly type: (typeof ErrorType)['NonExistentReference']

  readonly params: Readonly<{
    /**
     * The given reference.
     *
     * @examples ["{ id: 123 }", "{ username: 'flynnkev' }"]
     */
    ref: Record<string, number | string>,

    /**
     * The reference type (target entity name).
     *
     * @minLength 1
     * @examples ["Person", "Thesis"]
     */
    refType: string,
  }>
}

/**
 * HTTP error response that is returned when the requested path does not match any route or
 * the resource doesn't exist.
 */
export interface NotFoundError extends HttpProblem {

  readonly status: 404

  readonly type: (typeof ErrorType)['ResourceNotFound'] | (typeof ErrorType)['RouteNotFound']

  readonly params: Readonly<{
    /**
     * The request path.
     * @format uri-reference
     */
    path: string,

    /**
     * The request method.
     */
    method: 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE' | 'HEAD',
  }>
}

/**
 * HTTP error response that is returned when the user is not authenticated.
 */
export interface UnauthenticatedError extends HttpProblem {

  readonly status: 401

  readonly type: (typeof ErrorType)['Unauthenticated']
}

/**
 * HTTP error response that is returned when the user is not authorized to perform the action.
 */
export interface PermissionError extends HttpProblem {

  readonly status: 403

  readonly type: (typeof ErrorType)['InsufficientPermission']
}

/**
 * HTTP error response that is returned for a conditional request with `If-Unmodified-Since`
 * header when the resource has been modified after the specified date.
 */
export interface HttpPreconditionFailedError extends HttpProblem {

  readonly status: 412

  readonly type: (typeof ErrorType)['HttpPreconditionFailed']

  readonly params: Readonly<{
    /**
     * Date and time at which the resource was last modified.
     * @format date-time
     */
    lastModifiedAt: string,

    /**
     * Date and time given via `If-Unmodified-Since` header.
     * @format date-time
     */
    givenDate: string,
  }>
}

/**
 * HTTP error response for data validation using JSON Schema.
 */
export interface SchemaValidationError extends HttpProblem {

  readonly status: 422

  readonly type: (typeof ErrorType)['BodyValidation'] | (typeof ErrorType)['UriParamsValidation']

  /**
   * A list of validation errors.
   */
  // FIXME: Replace object with specific type for dynamic validation errors.
  readonly errors: AjvErrorObject[] | object[]
}

export interface BadRequestError extends HttpProblem {

  readonly status: 400

  readonly type: (typeof ErrorType)['BadRequestError']
}

/**
 * HTTP error response that is returned when file exceeds size limit.
 */
export interface FileTooLargeError extends HttpProblem {

  readonly status: 413

  readonly type: (typeof ErrorType)['FileTooLarge']

  readonly params: Readonly<{
    /**
     * File size in bytes.
     */
    fileSize: number,

    /**
     * Max file size in bytes.
     */
    fileSizeLimit: number,
  }>
}

/**
 * HTTP error response that is returned when file content type is not supported.
 */
export interface UnsupportedMediaTypeError extends HttpProblem {

  readonly status: 415

  readonly type: (typeof ErrorType)['UnsupportedMediaType']
}

/**
 * HTTP error response that the server cannot satisfy the requested range.
 */
export interface RangeNotSatisfiableError extends HttpProblem {

  readonly status: 416

  readonly type: (typeof ErrorType)['RangeNotSatisfiable']
}

/**
 * Copied from AJV types (ErrorObject). We wanted to avoid dependency on AJV in profit-api-types
 * because it's not required for using the API.
 *
 * @see https://github.com/ajv-validator/ajv#validation-errors
 */
interface AjvErrorObject {
  /**
   * The validation keyword.
   */
  keyword: string
  /**
   * The path to the part of the data that was validated.
   */
  dataPath: string
  /**
   * The path (JSON-pointer as a URI fragment) to the schema of the keyword that failed validation.
   */
  schemaPath: string
  /**
   * The object with the additional information about error that can be used to create custom error
   * messages.
   */
  params: AjvErrorParameters
  /**
   * Added to validation errors of propertyNames keyword schema.
   */
  propertyName?: string
  /**
   * The standard error message.
   */
  message: string
}

/**
 * AJV ErrorParameters union merged into a single interface for simplification.
 */
interface AjvErrorParameters {
  ref?: string
  limit?: number
  additionalProperty?: string
  property?: string
  missingProperty?: string
  depsCount?: number
  deps?: string
  format?: string
  comparison?: string
  exclusive?: boolean
  multipleOf?: number
  pattern?: string
  type?: string
  i?: number
  j?: number
  keyword?: string
  missingPattern?: string
  propertyName?: string
  failingKeyword?: string
  caseIndex?: number
  allowedValues?: any[]
}
