import { css, cx } from 'linaria'

import { rel } from '../styles/dimensions'
import { backgroundColor } from '../styles/colors'
import { iconWrapper } from './Button.style'


export const expandTextButton = cx(css`
  width: 100%;
  margin: 0 auto;
  padding-top: ${rel(8)}rem;
  padding-bottom: ${rel(8)}rem;

  &:hover {
    background-color: ${backgroundColor[2]};
  }

  &:active {
    background-color: ${backgroundColor[1]};
  }
`, iconWrapper)
