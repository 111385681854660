import { css, cx } from 'linaria'

import { breakpoints, rel } from '../../../styles/dimensions'
import { cardPadding } from '../../../components/Card/Card.style'
import { fontWeights } from '../../../styles/typography'
import { select as selectInput } from '../../../components/form/Select.style'
import * as colors from '../../../styles/colors'


export const resultListItem = css`
  list-style: none;

  & + & {
    margin-top: ${rel(24)}rem;
  }

  .${cardPadding} {
    padding: ${rel(16)}rem;

    @media screen and (min-width: ${breakpoints.md}) {
      padding: ${rel(20)}rem;
    }
  }
`

export const title = css`
  margin-bottom: 0;
  font-weight: ${fontWeights.bold};
  font-size: ${rel(20)}rem;
  line-height: ${rel(26)}rem;

  @media screen and (min-width: ${breakpoints.md}) {
    font-size: ${rel(24)}rem;
    line-height: ${rel(30)}rem;
  }

  a {
    font-weight: inherit;
    font-size: inherit;
    line-height: inherit;
  }
`

export const info = css`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-top: ${rel(4)}rem;

  @media screen and (min-width: ${breakpoints.md}) {
    margin-top: ${rel(2)}rem;
  }
`

export const infoLabel = css`
  margin-top: ${rel(8)}rem;
  margin-right: ${rel(22)}rem;
  font-weight: ${fontWeights.bold};
  font-size: ${rel(16)}rem;
  line-height: ${rel(22)}rem;
`

export const fewerRecommendations = css`
  /* not empty */
`

export const recommendations = css`
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  &.${fewerRecommendations} > *:not(button):nth-child(n + 6) {
    /* Shows only 5 specialization if on mobile */
    display: none;

    @media screen and (min-width: ${breakpoints.md}) {
      /* This limitation is lifted on non-mobile resolutions */
      display: block;
    }
  }
`

export const capsuleLabel = css`
  display: inline-block;
  margin-top: ${rel(8)}rem;
  margin-right: ${rel(8)}rem;
  padding: ${rel(4)}rem ${rel(10)}rem;
  font-weight: ${fontWeights.bold};
  font-size: ${rel(12)}rem;
  line-height: ${rel(12)}rem;
  background-color: transparent;
  border: ${rel(1)}rem solid ${colors.primaryColor[2]};
  border-radius: ${rel(16)}rem;

  /* Last capsule on mobile is a collapse button and has inverse coloring */
  &:is(button) {
    color: ${colors.white};
    background-color: ${colors.primaryColor[2]};
    border-color: ${colors.primaryColor[2]};

    @media screen and (min-width: ${breakpoints.md}) {
      /* We don’t need it on non-mobile resolutions */
      display: none;
    }
  }
`

export const description = css`
  display: none;
  margin-top: ${rel(10)}rem;
  font-size: ${rel(16)}rem;
  line-height: ${rel(24)}rem;

  @media screen and (min-width: ${breakpoints.md}) {
    display: block;
  }

  *:last-child,
  p:last-of-type {
    margin-bottom: 0;
  }
`

export const select = cx(selectInput, css`
  position: relative;
  top: ${rel(4)}rem;
  margin-left: auto;
  padding-top: ${rel(2)}rem;
  padding-bottom: ${rel(2)}rem;
  font-weight: ${fontWeights.bold};
  line-height: ${rel(22)}rem;

  &:valid,
  &:invalid,
  &[aria-invalid=true] {
    border-color: ${colors.primaryColor[2]};
  }
`)
