import { ReactNode, useEffect, useMemo, useState } from 'react'
import {
  Column, Row as RowType, TableInstance, TableProps, TableState,
  useFilters, useSortBy, useTable,
} from 'react-table'
import { cx } from 'linaria'
import type { Thesis } from '@cvut/profit-api-types/lib/theses/thesis'

import Header from './Header'
import Row from './Row'
import { TextFilter } from './filter'
import * as style from './Table.style'
import { UsePaginatedRequestStatus } from '../../api/usePaginatedRequest'
import LoadingOverlay from './LoadingOverlay'


interface EmptyBodyRowProps {
  columnCount: number
  emptyDataMessage: string
}

const EmptyBodyRow = ({ columnCount, emptyDataMessage }: EmptyBodyRowProps) => (
  <tr>
    <td colSpan={columnCount} className={style.emptyBodyRow}>
      {emptyDataMessage}
    </td>
  </tr>
)


interface BodyRowsProps<T extends object> {
  rows: RowType<T>[]
  prepareRow: (row: RowType<T>) => void
}

const BodyRows = <T extends object>({ rows, prepareRow }: BodyRowsProps<T>) => (
  <>
    {rows.map(row => {
      prepareRow(row)
      return <Row key={row.getRowProps().key} row={row} />
    })}
  </>
)


interface BodyProps<T extends object> {
  columnCount: number
  emptyDataMessage: string
  getTableBodyProps: TableInstance<T>['getTableBodyProps']
  loadingStatus: UsePaginatedRequestStatus<Thesis>['state']
  prepareRow: TableInstance<T>['prepareRow']
  rows: RowType<T>[]
}

const Body = <T extends object>({
  columnCount, emptyDataMessage, getTableBodyProps, loadingStatus, prepareRow, rows,
}: BodyProps<T>) => {
  const [oldRows, setOldRows] = useState(rows)

  useEffect(() => {
    if (loadingStatus === 'not-initiated') {
      setOldRows(rows)
    }
  }, [loadingStatus])

  return (
    <tbody {...getTableBodyProps()}>
      {rows.length
        ? <BodyRows rows={rows} prepareRow={prepareRow} />
        : loadingStatus === 'loading'
          ? <BodyRows rows={oldRows} prepareRow={prepareRow} />
          : <EmptyBodyRow columnCount={columnCount} emptyDataMessage={emptyDataMessage} />}
    </tbody>
  )
}

type Props<T extends object = {}> = TableProps & {
  children?: ReactNode,
  columns: Array<Column<T>>,
  data: T[],
  disableMultiSort?: boolean,
  emptyDataMessage?: string,
  initialState?: Partial<TableState>,
  isLoadingMore?: boolean,
  loadingStatus?: UsePaginatedRequestStatus<Thesis>['state'],
  manual?: boolean,
  onStateChange?: (state: TableState) => void,
  useControlledState?: (state: TableState) => TableState,
}

// FIXME: This component should not be aware of concrete column ids.
// This could be fixed by extending the ColumnInterface in types/react-table-config.d.ts with
// extraClassName property and pass the style directly through columns definition of concrete table
export const columnsStyleMap: Record<string, string> = {
  type: style.typeCell,
  specialization: style.specializationCell,
  title: style.titleCell,
  mainState: style.mainStateCell,
  states: style.statesCell,
  verifiedByFtOfficer: style.verifiedByFtOfficerCell,
  printApproved: style.printApprovedCell,
  validUntil: style.validUntilCell,
  actions: style.actionsCell,
  assigneeFrozenName: style.assigneeFrozenNameCell,
  supervisorFrozenName: style.supervisorFrozenNameCell,
  reviewerFrozenName: style.reviewerFrozenNameCell,
  approvedAt: style.centerAlignedTextCell,
  evaluationYear: style.centerAlignedTextCell,
}

const Table = <T extends object = {}>({
  children, columns, data, emptyDataMessage, initialState, onStateChange,
  isLoadingMore = true,
  loadingStatus = 'success',
  manual = false,
  useControlledState,
  disableMultiSort = false,
}: Props<T>): JSX.Element => {
  const defaultColumn = useMemo<Partial<Column<T>>>(
    () => ({
      Filter: TextFilter,
    }),
    []
  )

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, state } = useTable<T>(
    {
      columns,
      data,
      initialState,
      defaultColumn,
      manualFilters: manual,
      manualSortBy: manual,
      useControlledState,
      disableMultiSort,
    },
    useFilters,
    useSortBy,
  )

  const shouldShowOverlay = loadingStatus === 'loading' && !isLoadingMore

  useEffect(() => {
    // FIXME: skip first render?
    onStateChange?.(state)
  }, [state])

  return (
    <div className={cx(style.wrapper, shouldShowOverlay && style.loadingOverlay)}>
      <table {...getTableProps()} className={style.table}>
        <Header headerGroups={headerGroups} />
        <Body
          columnCount={columns.length}
          emptyDataMessage={emptyDataMessage ?? ''}
          getTableBodyProps={getTableBodyProps}
          loadingStatus={loadingStatus}
          prepareRow={prepareRow}
          rows={rows}
        />
      </table>
      {children}
      {shouldShowOverlay && <LoadingOverlay />}
    </div>
  )
}

export default Table
