import UserArea from './UserArea'
import { useSession } from '../../session'
import * as style from './SiteHeader.style'


interface Props {
  profilePath: string
  userSettingsPath: string
  logoutPath: string
}

const SiteHeader = ({ profilePath, userSettingsPath, logoutPath }: Props): JSX.Element => {
  const { handleLogout } = useSession()

  return (
    <header className={style.header}>
      <UserArea
        profilePath={profilePath}
        userSettingsPath={userSettingsPath}
        logoutPath={logoutPath}
        onLogout={handleLogout}
      />
    </header>
  )
}

export default SiteHeader
