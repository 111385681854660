import type { ReactNode } from 'react'

import { ReactComponent as CheckIcon } from '../../../images/icons/Check.svg'
import * as style from './Option.style'


interface Props {
  selected?: boolean
  children: ReactNode
  onClick?: () => void
}

const Option = ({ selected = false, children, onClick }: Props): JSX.Element => (
  <li>
    <button className={style.link} disabled={!onClick} onClick={onClick} type='button'>
      <div className={style.content}>
        {children}
      </div>
      {selected && <CheckIcon />}
    </button>
  </li>
)

export default Option
