import { keys, pick } from 'lodash'
import { useEffect, useState } from 'react'
import { useForm, UseFormMethods } from 'react-hook-form'
import { Configuration } from '@cvut/profit-api-types/lib/theses/configuration'

import * as buttonStyle from '../../components/Button.style'
import DateInput from '../../components/form/DateInput'
import * as formStyle from '../../components/form/Form.style'
import Hint from '../../components/form/Hint'
import ModificationIndicator from '../../components/form/ModificationIndicator'
import TextInput from '../../components/form/TextInput'
import { useLocale } from '../../locale'


interface ConfigurationProps {
  register: UseFormMethods['register']
}

const textInputMinLength = 4

const CurrentSemester = ({ register }: ConfigurationProps): JSX.Element => {
  const { l } = useLocale()
  const id = 'currentSemester'

  return (
    <TextInput
      inputProps={{
        id,
        minLength: textInputMinLength,
        name: id,
        ref: register({
          required: true,
          pattern: {
            value: /^[AB]\d{2}[12]$/,
            message: l.form.validation.semesterCode,
          },
        }),
        required: true,
      }}
      labelText={l.configuration.currentSemester}
    />
  )
}

interface ConfigurationFormControlsProps {
  isSaveDisabled: boolean
}

const ConfigurationFormControls = ({ isSaveDisabled }: ConfigurationFormControlsProps): JSX.Element => {
  const { l } = useLocale()

  return (
    <div className={formStyle.controls}>
      <div className={formStyle.controlsLeft}>
        <button
          type='submit'
          className={buttonStyle.fill}
          disabled={isSaveDisabled}
        >
          {l.thesis.save}
        </button>
      </div>
    </div>
  )
}

interface Props {
  defaultValues?: Configuration
  onSave: (data: Configuration) => unknown
}

const ConfigurationForm = ({ defaultValues, onSave }: Props): JSX.Element => {
  const { control, errors, formState, handleSubmit, register, reset } = useForm<Configuration>({
    defaultValues,
    mode: 'all',
  })

  const { l } = useLocale()

  const [isFormModified, setIsFormModified] = useState(false)

  const shouldDisableSave = !formState.isValid || !formState.isDirty

  useEffect(() => {
    setIsFormModified(formState.isDirty)
  }, [formState.isDirty])

  function onSubmit (data: Configuration) {
    onSave(pick(data, keys(formState.dirtyFields)))
    reset(data, {
      isDirty: false,
    })
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={formStyle.form} noValidate>
      <div>
        <CurrentSemester register={register} />
        <Hint error={errors.currentSemester?.message} hint={l.form.validation.semesterCode} />
      </div>
      {(['reportDeadlineBachelor', 'reportDeadlineMaster'] as const).map(id => (
        <DateInput
          {...{ control, id }}
          key={id}
          labelText={l.configuration[id]}
          required
        />
      ))}
      <ConfigurationFormControls isSaveDisabled={shouldDisableSave} />
      <ModificationIndicator isModified={isFormModified} />
      <div>
        <Hint hint={l.form.formInfoRequired} />
      </div>
    </form>
  )
}

export default ConfigurationForm
