import { ChangeEvent, useEffect, useRef } from 'react'

import * as style from './ThesisSubmitForm.style'


interface AttachmentFileInputProps {
  nameRoot: string
  onChange: (e: ChangeEvent<HTMLInputElement>) => void
  selectedFile?: File
}

const AttachmentFileInput = ({
  nameRoot, onChange, selectedFile,
}: AttachmentFileInputProps): JSX.Element => {
  const name = `${nameRoot}.file`
  const fileInputRef = useRef<HTMLInputElement | null>(null)

  // XXX - ugly hack to avoid losing selected file indication in the UI
  useEffect(() => {
    if (fileInputRef.current && selectedFile) {
      const dt = new DataTransfer()
      dt.items.add(selectedFile)
      fileInputRef.current.files = dt.files
    }
  })

  // TODO - would be nice to indicate incompatible files cannot be dragged onto the input
  return (
    <input
      accept='.iso,.zip'
      className={style.file}
      id={name}
      type='file'
      onChange={onChange}
      ref={fileInputRef}
    />
  )
}
AttachmentFileInput.displayName = 'AttachmentFileInput'

export default AttachmentFileInput
