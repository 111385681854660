import { PersonName } from '../common'
import { Language } from './common'
import { Specialization, StudyDegree } from './specialization'
import { Thesis } from './thesis'


export interface ThesisAssignmentPrintout {
  approvedAt?: Date
  approvedBy?: PersonName
  departmentCode: Specialization['departmentCode']
  description: Thesis['description']
  specialization: string
  student: PersonName
  studyProgram: string
  supervisor: PersonName
  thesisTitle: string
  thesisType: StudyDegree
  validity: Thesis['validUntil']
  /**
   * Locale of the printout; defines language of the headers etc.
   * It's derived from the thesis language.
   */
  locale: Language
}
