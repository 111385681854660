import { MouseEvent, useEffect, useRef, useState } from 'react'
import _ from 'lodash'
import { Person, Thesis } from '@cvut/profit-api-types/lib/theses'
import { Role } from '@cvut/profit-theses-common'

import { addNotification } from '../toastNotifications'
import Card from '../../components/Card'
import InfoMessage from '../../components/InfoMessage'
import PersonCard from '../../components/PersonCard'
import { useLocale } from '../../locale'
import { usePeople } from '../../api/people'
import { useProposeThesisReviewer } from '../../api/theses/reviewerProposals'
import { formatPersonFullName } from '../../utils/person'
import { typingDelay } from '../../config'
import * as style from './ThesisReviewerSearchCard.style'


interface OptionProps {
  person: Person
  onClick: () => void
}

const PersonOption = ({ person, onClick }: OptionProps): JSX.Element => {
  const handleClick = (evt: MouseEvent<HTMLAnchorElement>) => {
    evt.preventDefault()
    onClick()
  }

  return (
    <li>
      <a href='#' className={style.link} onClick={handleClick}>
        <PersonCard name={formatPersonFullName(person)} contact={person.email} />
      </a>
    </li>
  )
}

interface PanelProps {
  message: string | null
  suggestedPeople: Person[]
  onPersonSelect: (person: Person) => unknown
}

const SearchSuggestionsPanel = ({ message, suggestedPeople, onPersonSelect }: PanelProps): JSX.Element | null => {
  if (!message && suggestedPeople.length === 0) {
    return null
  }

  return (
    <div className={style.searchContent}>
      {message && (
        <div className={style.message}>{message}</div>
      )}
      {suggestedPeople.length > 0 && (
        <ul className={style.searchResultsList}>
          {suggestedPeople.map(person => (
            <PersonOption key={person.id} person={person} onClick={() => onPersonSelect(person)} />
          ))}
        </ul>
      )}
    </div>
  )
}

const SupervisorWillProposeReviewerMessage = () => {
  const { l } = useLocale()

  return <InfoMessage text={l.thesis.supervisorWillProposeReviewer} />
}

interface Props {
  thesis: Thesis
  onActionCompleted: () => unknown
}

const ThesisReviewerSearchCard = ({ thesis, onActionCompleted }: Props): JSX.Element => {
  const inputRef = useRef<HTMLInputElement | null>(null)
  const [inputValue, setInputValue] = useState('')
  const [people, setPeople] = useState<Person[]>([])
  const [selectedPerson, setSelectedPerson] = useState<Person | null>(null)
  const [peopleStatus, getPeople] = usePeople()
  const [proposalStatus, proposeThesisReviewer] = useProposeThesisReviewer()
  const { l } = useLocale()

  const getPeopleDebounced = _.debounce(async () => await getPeople(inputValue, Role.REVIEWER), typingDelay)

  useEffect(() => {
    if (inputValue) {
      void getPeopleDebounced()
    } else {
      setPeople([])
    }

    return getPeopleDebounced.cancel
  }, [inputValue])

  useEffect(() => {
    if (peopleStatus.state === 'success') {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      setPeople(peopleStatus.data!.data)
    }
  }, [peopleStatus.state])

  useEffect(() => {
    if (proposalStatus.state === 'success' && selectedPerson) {
      addNotification({
        type: 'POSITIVE',
        message: l.thesisReviewerProposal.successMessages.propose(formatPersonFullName(selectedPerson)),
      })
      setSelectedPerson(null)
      onActionCompleted()
    }
  }, [proposalStatus.state, selectedPerson])

  const handlePersonSelect = async (person: Person) => {
    setInputValue('')
    inputRef.current?.focus()

    setSelectedPerson(person)
    await proposeThesisReviewer(thesis.id, person.username)
    // TODO: Handle loader.
  }

  let message = null
  if (inputValue) {
    if (peopleStatus.state === 'loading') {
      message = l.thesisReviewerProposal.search.searching
    } else if (peopleStatus.state === 'error') {
      message = l.thesisReviewerProposal.search.error
    } else if (peopleStatus.state === 'success' && peopleStatus.data?.data.length === 0) {
      message = l.thesisReviewerProposal.search.noResults
    }
  }

  const allowedPeople = people.filter(person => {
    return ![
      thesis.assignee?.username,
      thesis.supervisor?.username,
    ].includes(person.username)
  })

  return (
    <Card>
      <Card.Header title={l.thesisReviewerProposal.search.title} />
      <Card.Content>
        <div className={style.searchWrapper}>
          <span className={style.searchInputButton} />
          <div className={style.searchInputWrapper}>
            <input
              ref={inputRef}
              type='search'
              className={style.searchInput}
              placeholder={l.thesisReviewerProposal.search.placeholder}
              value={inputValue}
              onChange={e => setInputValue(e.target.value)}
            />
            <SearchSuggestionsPanel
              message={message}
              suggestedPeople={allowedPeople}
              onPersonSelect={handlePersonSelect}
            />
          </div>
        </div>
        {thesis.supervisorWillProposeReviewer && (
          <SupervisorWillProposeReviewerMessage />
        )}
      </Card.Content>
    </Card>
  )
}

export default ThesisReviewerSearchCard
