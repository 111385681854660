import type { ReactNode } from 'react'
import { cx } from 'linaria'

import Hint from './form/Hint'
import { ReactComponent as ProfileIcon } from '../images/icons/Profile.svg'
import * as style from './PersonCard.style'


interface PersonInfoProps {
  name: string
  contact?: string
  specialization?: string
  errorMessage?: string
}

const PersonInfo = ({ name, contact, specialization, errorMessage }: PersonInfoProps) => (
  <>
    <span className={style.name}>
      {name}
    </span>
    {contact && (
      <span>
        <em>{contact}</em>
      </span>
    )}
    {specialization && (
      <span>{specialization}</span>
    )}
    <Hint error={errorMessage} />
  </>
)

interface EmptyProps {
  children: ReactNode
}

/**
 * @param children At the moment of writing, this should only be a link to select a person.
 */
const EmptyPerson = ({ children }: EmptyProps) => (
  <span className={style.name}>
    {children}
  </span>
)

type Props = (PersonInfoProps | EmptyProps) & {
  extraClassNames?: string[],
}

/**
 * FIXME: Replace blankProfile icon with actual user avatar
 * TODO: Render language flag
 */
const PersonCard = (props: Props): JSX.Element => (
  <section
    className={cx(
      style.wrapper,
      'errorMessage' in props && props.errorMessage ? style.disabled : '',
      ...(props.extraClassNames ?? [])
    )}
  >
    <ProfileIcon />
    <div className={style.info}>
      {'name' in props
        ? <PersonInfo {...props} />
        : <EmptyPerson {...props} />}
    </div>
  </section>
)

export default PersonCard
