import { useEffect } from 'react'
import { Except } from '@cvut/profit-utils'
import { Topic } from '@cvut/profit-api-types/lib/theses'

import { errorCardRenderer } from '../../components/RequestErrorCard'
import { useTopic } from '../../api/topics'
import { ACLoaded as AccessControlLoaded } from '../../access-control'
import AccessControlledRender from '../../access-control/AccessControlledRender'
import PageLoadSpinner from '../../components/PageLoadSpinner'
import RequestDependentRender from '../../api/RequestDependentRender'
import TopicDetail from '../../features/topics/TopicDetail'
import ValidatedPathParams from '../utils/ValidatedPathParams'
import { useLocale } from '../../locale'
import pagePaths from '../paths'


interface Props {
  topicId: number
}

const TopicViewPage = ({ topicId }: Props): JSX.Element => {
  const { l } = useLocale()
  const [getStatus, getTopic] = useTopic()

  useEffect(() => {
    void getTopic(topicId)
  }, [topicId])

  const canEdit = (ac: Except<AccessControlLoaded, 'state'>, topic: Topic) => {
    return ac.topicRoles.isTopicAuthor(topic)
  }

  return (
    <AccessControlledRender loaderColor='primary'>
      {(ac) => (
        <RequestDependentRender
          requestStatus={getStatus}
          renderOnLoading={() => <PageLoadSpinner message={l.topic.loadingTopic} />}
          renderOnError={errorCardRenderer(l.topic.errorMessages.get, pagePaths.topics.view(topicId))}
          renderOnSuccess={topic => (
            <TopicDetail
              topic={topic}
              editLink={canEdit(ac, topic) ? pagePaths.topics.edit(topicId) : undefined}
              createThesisLink={canEdit(ac, topic) ? pagePaths.topics.createThesis(topicId) : undefined}
            />
          )}
        />
      )}
    </AccessControlledRender>
  )
}

const TopicViewPageWrapper = (): JSX.Element => (
  <ValidatedPathParams<Props>
    expectedPathPattern={pagePaths.topics.view()}
    expectedParams={{
      topicId: {
        validate: (param: string) => !isNaN(parseInt(param, 10)),
        convert: (param: string): number => parseInt(param, 10),
      },
    }}
  >
    {({ topicId }) => (
      <TopicViewPage topicId={topicId} />
    )}
  </ValidatedPathParams>
)

export default TopicViewPageWrapper
