import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import ulog from 'ulog'

import { addNotification } from '../features/toastNotifications'
import { useGetToken, authRedirectToPathUtils } from '../api/auth'
import { useUserInfo } from '../session/user-info'
import pagePaths from '../pages/paths'
import { useLocale } from '../locale'
import LoadingUserInfoMessage from '../access-control/LoadingUserInfoMessage'


const logger = ulog('LoginRedirectPage')

const LoginRedirect = (): JSX.Element => {
  const [userInfo, ,loadUserInfo] = useUserInfo()
  const [{ state: tokenRequestStatus }, getTokens] = useGetToken()
  const history = useHistory()
  const { l } = useLocale()

  function handleAuthError (devMessage: string): void {
    history.replace(pagePaths.home)

    logger.error(devMessage)
    addNotification({
      type: 'NEGATIVE',
      message: l.auth.authenticationFailed,
    })
  }

  const urlParams = new URLSearchParams(history.location.search)
  const [authorizationCode, stateString, error] = [
    urlParams.get('code'),
    urlParams.get('state'),
    urlParams.get('error'),
  ]

  useEffect(() => {
    if (error) {
      const errorDesc = urlParams.get('error_description')
      handleAuthError(`OAAS returned an error '${error}'. ${errorDesc ? `Description: '${errorDesc}'` : ''}`)
      return
    }

    if (!stateString) {
      handleAuthError('Login callback did not return a state.')
      return
    }

    // XXX: This check wasn't there in the original implementation.
    if (!authorizationCode) {
      handleAuthError('Login callback did not return an authorization code.')
      return
    }

    if (tokenRequestStatus === 'not-initiated') {
      getTokens(authorizationCode, stateString)
    }
    if (tokenRequestStatus === 'error') {
      handleAuthError('An error has occured while getting an access token.')
      return
    }
    if (tokenRequestStatus === 'success') {
      loadUserInfo()
    }
  }, [tokenRequestStatus])

  useEffect(() => {
    if (userInfo.state === 'loaded') {
      const redirectTo = authRedirectToPathUtils.get() ?? pagePaths.home
      authRedirectToPathUtils.clear()

      // If this is true, then we are trying to redirect to a frontend deployed on
      // another branch. In such cases, we need to do a browser redirect instead
      // of just a router redirect.
      if (redirectTo.startsWith('/@')) {
        window.location.assign(redirectTo)
      }

      addNotification({
        type: 'POSITIVE',
        message: l.auth.loginSuccessful,
      })
      history.replace(redirectTo)
    }

    if (userInfo.state === 'none') {
      handleAuthError('A GET request for UserInfo has failed.')
    }
  }, [userInfo.state])

  const loadingUserInfoMessage = tokenRequestStatus === 'loading' ? (
    <LoadingUserInfoMessage loaderColor='primary' />
  ) : null

  return (
    <section>
      {loadingUserInfoMessage}
    </section>
  )
}

export default LoginRedirect
