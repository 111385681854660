import * as style from './AttachmentLink.style'


interface Props {
  href: string
  title: string
  isDownload?: boolean
}

const AttachmentLink = ({ href, isDownload, title }: Props): JSX.Element => (
  <a className={style.attachmentLink} href={href} rel='noreferrer' target='_blank' download={isDownload}>
    {title}
  </a>
)

export default AttachmentLink
