import { css, cx } from 'linaria'
import { transparentize } from 'polished'

import { iconWrapper } from '../../components/Button.style'
import * as colors from '../../styles/colors'
import { breakpoints, rel } from '../../styles/dimensions'
import { fontSizes, fontWeights } from '../../styles/typography'


export const modalsContainer = css`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000; /* Modal should overlay everything */

  &:empty {
    display: none;
  }
`

export const modalsOverlay = css`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
  background: ${transparentize(0.75, colors.primaryColor[2])}; /* opacity extra breaks backdrop-filter */
`


export const modalDialog = css`
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 1001;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: calc(100% - ${rel(32)}rem);
  max-height: 90vh; /* prevent moving the controls off-screen */
  padding: ${rel(48)}rem ${rel(24)}rem;
  overflow: auto;
  background: ${colors.white};
  border-radius: ${rel(11)}rem;
  box-shadow: 0 ${rel(2)}rem ${rel(12)}rem ${transparentize(0.77, colors.primaryColor[2])};
  transform: translate(-50%, -50%);

  @media screen and (min-width: ${breakpoints.md}) {
    max-width: ${rel(720)}rem;
    padding: ${rel(48)}rem ${rel(56)}rem;
  }

  @media screen and (min-width: ${breakpoints.xl}) {
    max-width: ${rel(960)}rem;
  }
`

export const modalTitle = css`
  margin: 0;
  line-height: 1.1;
  text-align: center;
`

export const modalText = css`
  margin-top: ${rel(12)}rem;
  font-weight: ${fontWeights.normal};
  font-size: ${fontSizes.body.mobile}rem;
  line-height: 1.375;
  white-space: pre-line;
  text-align: center;

  @media screen and (min-width: ${breakpoints.md}) {
    margin-top: ${rel(20)}rem;
    font-size: ${fontSizes.body.desktop}rem;
  }
`

/* button group container - for spacing grouped buttons */
export const modalActionButtonsContainer = css`
  width: 100%;

  @media screen and (min-width: ${breakpoints.md}) {
    width: auto;
    padding-right: ${rel(16)}rem;
    padding-left: ${rel(16)}rem;
  }

  & + & {
    margin-top: ${rel(8)}rem;

    @media screen and (min-width: ${breakpoints.md}) {
      margin-top: 0;
    }
  }

  &:first-child {
    order: 99; /* move the cancel button to the last position */
    margin-top: ${rel(8)}rem;

    @media screen and (min-width: ${breakpoints.md}) {
      order: unset;
      margin-top: 0;
    }
  }
`

/* container for button groups */
export const modalButtonsContainer = css`
  display: flex;
  flex: 1 0 auto;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  margin: ${rel(8)}rem 0 0 0;

  @media screen and (min-width: ${breakpoints.md}) {
    margin: ${rel(28)}rem -${rel(16)}rem 0 -${rel(16)}rem;
  }

  button {
    width: 100%;
    cursor: pointer;

    @media screen and (min-width: ${breakpoints.md}) {
      width: auto;
    }
  }
`

export const closeButton = cx(iconWrapper, css`
  position: absolute;
  top: ${rel(16)}rem;
  right: ${rel(16)}rem;
  cursor: pointer;
`)

export const closeIcon = css`
  .all {
    stroke: ${colors.secondaryColor[2]};
  }
`

export const blurred = css`
  filter: blur(${rel(3)}rem);
`

export const blurContainer = css`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`
