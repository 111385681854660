import { LinkProps } from 'react-router-dom'
import _ from 'lodash'
import type { Specialization } from '@cvut/profit-api-types/lib/theses/specialization'
import type { Topic, TopicNew, TopicPatch } from '@cvut/profit-api-types/lib/theses/topic'

import TopicForm, { FormData as TopicFormData } from './TopicForm'
import Card from '../../components/Card'
import PageHeader from '../../components/PageHeader'
import type { OptionType } from '../../components/form/types'
import { useLocale } from '../../locale'


/**
 * @property specializations The component does not sort them.
 */
interface CommonProps {
  formBackupKey?: string
  specializations: Pick<Specialization, 'code'>[]
  defaultValues?: Partial<Topic>
  onModifiedState?: (isModified: boolean) => void
}

interface CreateProps extends CommonProps {
  mode: 'create'
  onSave: (data: TopicNew) => Promise<unknown>
}

interface EditProps extends CommonProps {
  mode: 'edit'
  backLink: LinkProps
  onSave: (data: TopicPatch) => Promise<unknown>
  onDelete?: () => Promise<unknown>
}

type Props = CreateProps | EditProps

/**
 * This component does not sort provided specializations in any way.
 */
const TopicEdit = (props: Props): JSX.Element => {
  const { l } = useLocale()
  const { formBackupKey = `topic-${props.mode}` } = props

  const handleSave = async (data: TopicFormData) => {
    return await props.onSave({
      ...data,
      keywords: data.keywords
        .split(',')
        .map(kw => kw.trim())
        .filter(Boolean),
      specializations: data.specializations.map(code => ({ code })),
    })
  }

  const transformedDefaultvalues = transformTopicToFormData(props.defaultValues ?? {})

  const componentTitle: Record<Props['mode'], string> = {
    create: l.topic.create,
    edit: l.topic.edit,
  }

  const backButtonProps: Record<Props['mode'], LinkProps | undefined> = {
    create: undefined,
    // TODO: Having to check `props.mode === 'edit'` is not ideal.
    edit: props.mode === 'edit' ? props.backLink : undefined,
  }

  const specializationOptions = props.specializations.map<OptionType>(spec => ({
    displayText: spec.code,
    key: spec.code,
    value: spec.code,
  }))

  return (
    <>
      <PageHeader
        backButton={backButtonProps[props.mode]}
        title={componentTitle[props.mode]}
      />
      <Card.ColumnContainer>
        <Card.Column>
          <Card>
            <Card.Header title={l.topic.topic} />
            <Card.Content>
              <TopicForm
                {...props}
                defaultValues={transformedDefaultvalues}
                formBackupKey={formBackupKey}
                onSave={handleSave}
                specializationOptions={specializationOptions}
              />
            </Card.Content>
          </Card>
        </Card.Column>
      </Card.ColumnContainer>
    </>
  )
}

function transformTopicToFormData (topic: NonNullable<Partial<Topic>>): Partial<TopicFormData> {
  return {
    ...topic,
    keywords: (topic.keywords ?? []).join(','),
    // studyDegrees and specializations are mutually exclusive in the form - the user is
    // allowed to select only one of them. However, if the specializations are selected,
    // studyDegrees is derived from them - backend automatically sets studyDegrees depending
    // on the specializations.
    studyDegrees: topic.specializations?.length ? [] : (topic.studyDegrees ?? []),
    // Transform `{ code: string }` into list of strings expected by `<MultiSelect/>` and stored by form backup
    specializations: (topic.specializations ?? []).map(s => s.code),
  }
}

export default TopicEdit
