import { ChangeEventHandler, useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import locale, { useLocale } from '../../../locale'
import * as style from './SwitcherFilter.style'
import Switch from '../../form/Switch'


export type ThesesFiltersLocale = keyof typeof locale.default.thesesFilters

export interface SwitcherFilterProps {
  switcherId: ThesesFiltersLocale
  onSwitcherChange: ChangeEventHandler<HTMLInputElement>
}

const SwitcherFilter = ({ switcherId, onSwitcherChange }: SwitcherFilterProps): JSX.Element => {
  const history = useHistory()
  const { l } = useLocale()
  const query = new URLSearchParams(history.location.search)
  const [checked, setChecked] = useState(!!query.toString().includes(switcherId))

  useEffect(() => {
    setChecked(query.get(switcherId) === 'true')
  }, [query])

  return (
    <div className={style.thesesListFilter}>
      <Switch
        inputProps={{
          id: switcherId,
          onChange: onSwitcherChange,
          checked,
        }}
        label={l.thesesFilters[switcherId]}
      />
    </div>
  )
}

export default SwitcherFilter
