import { css } from 'linaria'

import { fontWeights } from '../../../styles/typography'
import { rel } from '../../../styles/dimensions'
import { transitionEasing } from '../../../styles/utils'
import * as colors from '../../../styles/colors'


export const switcher = css`
  position: relative;
  padding-left: ${rel(26)}rem;
`

export const switcherInput = css`
  position: absolute;
  top: ${rel(4)}rem;
  left: 0;
  z-index: -1;
  width: ${rel(16)}rem;
  height: ${rel(16)}rem;
  opacity: 0;
`

export const switcherLabel = css`
  position: relative;
  display: inline-block;
  margin-bottom: 0;
  padding-left: ${rel(10)}rem;
  font-weight: ${fontWeights.bold};
  font-size: ${rel(15)}rem;
  line-height: ${rel(24)}rem;
  cursor: pointer;

  &::before {
    position: absolute;
    top: ${rel(4)}rem;
    left: -${rel(26)}rem;
    display: block;
    width: ${rel(28)}rem;
    height: ${rel(16)}rem;
    background-color: ${colors.white};
    border: ${colors.primaryColor[2]} solid ${rel(1)}rem;
    border-radius: ${rel(16)}rem;
    transition:
      background-color ${transitionEasing},
      border-color ${transitionEasing},
      box-shadow ${transitionEasing};
    content: '';
    pointer-events: all;
  }

  &::after {
    position: absolute;
    top: ${rel(6)}rem;
    left: -${rel(24)}rem;
    display: block;
    width: ${rel(12)}rem;
    height: ${rel(12)}rem;
    background-color: ${colors.primaryColor[2]};
    border-radius: 50%;
    transition:
      transform ${transitionEasing},
      background-color ${transitionEasing},
      border-color ${transitionEasing},
      box-shadow ${transitionEasing};
    content: '';
  }

  .${switcherInput}:focus ~ &::before {
    box-shadow: 0 0 0 ${rel(2)}rem ${colors.neutralColor[3]};
  }

  .${switcherInput}:active:not(:disabled) ~ &::before,
  .${switcherInput}:active:not(:disabled) ~ &::after {
    opacity: 0.7;
  }

  .${switcherInput}:checked ~ &::before {
    color: ${colors.white};
    background-color: ${colors.primaryColor[2]};
    border-color: ${colors.primaryColor[2]};
  }

  .${switcherInput}:checked ~ &::after {
    background-color: ${colors.white};
    transform: translateX(${rel(12)}rem);
  }

  .${switcherInput}:disabled ~ & {
    cursor: no-drop;
    opacity: 0.4;
  }
`
