import { Person, PersonSettings } from '@cvut/profit-api-types/lib/theses'

import { apiPrefix } from '../config'
import { createRequestor } from './makeRequest'
import { UseRequestStatus, useRequest } from './useRequest'
import { useLocale } from '../locale'


const meRequestor = createRequestor(`${apiPrefix}/me`)

/**
 * Retrieves information about current user using access token as an identifier.
 *
 * This hook should never be used to retrieve user info of the logged in user
 * outside of `useUserInfo` defined in `session/user-info.ts`.
 */
export function useGetUserInfo (): [
  UseRequestStatus<Person>,
  () => void,
] {
  const { l } = useLocale()

  const [requestStatus, sendRequest] = useRequest<Person>(meRequestor, {
    defaultErrorMessage: () => l.auth.errorGettingUserInfo,
  })

  return [requestStatus, () => {
    void sendRequest('GET', '')
  }]
}

export async function updateUserSettings (settings: PersonSettings, requestor = meRequestor): Promise<unknown> {
  return await requestor('PATCH', '', {}, {
    body: JSON.stringify({ settings }),
  })
}
