import { ChangeEventHandler, useState } from 'react'
import { cloneDeep } from 'lodash'
import AsyncSelect, { Props as AsyncSelectProps } from 'react-select/async'
import Select, { Props as SelectProps } from 'react-select'
import type { Person } from '@cvut/profit-api-types/lib/theses/person'
import type { Specialization, StudyDegree } from '@cvut/profit-api-types/lib/theses/specialization'
import { TopicFilter, TopicVisibility } from '@cvut/profit-api-types/lib/theses/topic'
import { formatPersonFullName } from '@cvut/profit-theses-common'
import { Enum } from '@cvut/profit-utils'

import TextInput from '../../../components/form/TextInput'
import ThesisLanguageAgreementInfo from '../ThesisLanguageAgreementInfo'
import Card from '../../../components/Card'
import { useLocale } from '../../../locale'
import { reactSelectStyles } from '../../../components/form/ReactSelect.style'
import * as style from './Filter.style'
import { ReactComponent as ResetFiltersIcon } from '../../../images/icons/FilterClear.svg'


/**
 * @internal Exported for testing only!
 */
export type TFilter = Pick<TopicFilter, 'q' | 'author' | 'specializations' | 'studyDegrees' | 'visibility'>

// Options of react-select must be an object like this otherwise it will shit
// itself. Fucking cancer dogshit nodejs ecosystem can go sit on a cactus.
export interface ReactSelectOptionType<T extends string = string> {
  label: string
  value: T
}

export interface Props {
  filterValue: TFilter
  onFilterValueChange: (f: TFilter) => void
  specializations: Pick<Specialization, 'code'>[]
  programs: StudyDegree[]
  queryPeople?: (query: string) => Promise<Person[]>
}

/**
 * @param queryPeople If this is `undefined`, then an input for an author will
 * be hidden.
 */
const Filter = ({
  filterValue, onFilterValueChange, specializations, programs, queryPeople,
}: Props): JSX.Element => {
  const { l } = useLocale()
  const filterL = l.topic.search.filter

  const myTopicsMode = queryPeople == null

  const mapVisibility = (v: TopicVisibility): ReactSelectOptionType => ({
    label: l.topic.visibilityOptions[v],
    value: v,
  })
  const mapSpecializationCode = (s: string): ReactSelectOptionType => ({ label: s, value: s })
  const mapStudyDegree = (d: StudyDegree): ReactSelectOptionType => ({ label: l.studyDegree[d], value: d })

  // `filterValue.author` is just the person's username. Their "full info" is
  // fetched by `<AsyncSelect />` itself. We need to control the value of the
  // currently selected person, i.e., this thing --+
  //                                               |
  //                  +----------------------------+
  // ```          vvvvv
  // <AsyncSelect value={hello} />
  // ```
  // to be able to react to filter value changes.
  const [selectedPerson, setSelectedPerson] = useState<Person | null>(null)
  // Wow react-select might be dogshit after all.
  const selectedVisibility = filterValue?.visibility?.map(mapVisibility) ?? []
  const selectedSpecializations = filterValue?.specializations?.map(mapSpecializationCode) ?? []
  const selectedPrograms = filterValue?.studyDegrees?.map(mapStudyDegree) ?? []

  // This wrapper makes sure that whenever `filterValue.author` becomes empty,
  // `selectedPerson` is set to null.
  const handleFilterValueChange = (f: TFilter) => {
    onFilterValueChange(f)
    if (!f.author) {
      setSelectedPerson(null)
    }
  }

  const handleTextInputChange: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> = e => {
    handleFilterValueChange({
      ...filterValue,
      [e.target.name]: e.target.value,
    })
  }

  // FIXME: This is precisely the default type. -+ Can't say "use the default",
  // Typescript is too shit for that.            |
  //                                         vvvvvvvvvvvvvvvvvvvvv
  const handleMultiselectChange: SelectProps<ReactSelectOptionType, true>['onChange'] = (
    v, { action, name },
  ) => {
    if (name == null) {
      throw new Error('There is a multiselect without a name attribute!')
    }

    if (action === 'select-option' || action === 'remove-value') {
      handleFilterValueChange({
        ...filterValue,
        [name]: v?.map(x => x.value),
      })
    }

    if (action === 'clear') {
      const newFilterValue = cloneDeep(filterValue)
      /* eslint-disable-next-line @typescript-eslint/no-dynamic-delete */
      delete newFilterValue[name as keyof TFilter]
      handleFilterValueChange(newFilterValue)
    }
  }

  const handleAuthorInputChange: AsyncSelectProps<Person, false>['onChange'] = (p, { action }) => {
    if (action === 'select-option') {
      handleFilterValueChange({
        ...filterValue,
        author: p?.username,
      })

      setSelectedPerson(p)
    }

    if (action === 'clear') {
      const newFilterValue = cloneDeep(filterValue)
      delete newFilterValue.author
      handleFilterValueChange(newFilterValue)
      setSelectedPerson(null)
    }
  }

  const handleResetFilters = () => {
    handleFilterValueChange({})
  }

  const authorFilterNode = (
    <div className={style.filter}>
      <label className={style.asyncLabel}>{filterL.author}</label>
      <AsyncSelect<Person>
        name='author'
        id='topic-search__filter__author'
        cacheOptions
        isClearable
        isSearchable
        loadOptions={queryPeople}
        value={selectedPerson}
        getOptionValue={p => p.username}
        getOptionLabel={p => formatPersonFullName(p, p.username)}
        onChange={handleAuthorInputChange}
        placeholder={filterL.authorPlaceholder}
        noOptionsMessage={() => filterL.authorNoResult}
        className={style.authorFilter}
        styles={reactSelectStyles}
      />
    </div>
  )

  const visibilityFilterNode = (
    <div className={style.filter}>
      <label className={style.asyncLabel}>{filterL.visibility}</label>
      <Select<ReactSelectOptionType, true>
        name='visibility'
        id='topic-search__filter__visibility'
        closeMenuOnSelect={false}
        closeMenuOnScroll={false}
        isMulti
        options={Enum(TopicVisibility).values().filter(v => v !== 'deleted').map(mapVisibility)}
        onChange={handleMultiselectChange}
        value={selectedVisibility}
        placeholder={filterL.select}
        noOptionsMessage={() => filterL.noResult}
        className={style.multiselect}
        styles={reactSelectStyles}
      />
    </div>
  )

  return (
    <section className={style.filters}>
      <Card>
        <div className={style.filterColumn}>
          <div className={style.mainFilter}>
            <TextInput
              labelText={filterL.q}
              inputProps={{
                name: 'q',
                id: 'topic-search__filter__q',
                value: filterValue.q ?? '',
                onChange: handleTextInputChange,
                placeholder: l.thesesList.filters.search,
              }}
              extraClassNames={[style.filterInput]}
            />
          </div>
          {myTopicsMode ? visibilityFilterNode : authorFilterNode}
          <div className={style.filter}>
            <label className={style.asyncLabel}>{filterL.specialization}</label>
            <Select<ReactSelectOptionType, true>
              name='specializations'
              id='topic-search__filter__specializations'
              closeMenuOnSelect={false}
              closeMenuOnScroll={false}
              isMulti
              options={specializations.map(s => s.code).map(mapSpecializationCode)}
              onChange={handleMultiselectChange}
              value={selectedSpecializations}
              placeholder={filterL.select}
              noOptionsMessage={() => filterL.noResult}
              className={style.multiselect}
              styles={reactSelectStyles}
            />
          </div>
          <div className={style.filter}>
            <label className={style.asyncLabel}>{filterL.studyDegree}</label>
            <Select<ReactSelectOptionType, true>
              name='studyDegrees'
              id='topic-search__filter__study-degrees'
              closeMenuOnSelect={false}
              closeMenuOnScroll={false}
              isMulti
              options={programs.map(mapStudyDegree)}
              onChange={handleMultiselectChange}
              value={selectedPrograms}
              placeholder={filterL.select}
              noOptionsMessage={() => filterL.noResult}
              className={style.multiselect}
              styles={reactSelectStyles}
            />
          </div>
        </div>
        <button
          type='button'
          title={l.filter.reset}
          className={style.resetFilter}
          onClick={handleResetFilters}
        >
          <ResetFiltersIcon />
        </button>
        <div className={style.infoMessageContainer}>
          <ThesisLanguageAgreementInfo />
        </div>
      </Card>
    </section>
  )
}

export default Filter
