import { css } from 'linaria'

import { outlineDark } from '../../../components/Button.style'
import { rel } from '../../../styles/dimensions'


export const resultList = css`
  margin: 0;
  padding: 0;
  list-style: none;

  * + &,
  & + * {
    margin-top: ${rel(24)}rem;
  }
`

export const loadMore = css`
  margin-top: ${rel(24)}rem;
  text-align: center;
`

export const noResults = css`
  text-align: center;
`

export const loadMoreButton = outlineDark
