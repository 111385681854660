import { css } from 'linaria'

import { form } from '../../../components/form/Form.style'
import { rel } from '../../../styles/dimensions'
import * as colors from '../../../styles/colors'


export const label = css`
  position: absolute;
  top: -${rel(10)}rem;
  right: 0;
  margin-top: 0;
  padding: ${rel(3)}rem ${rel(8)}rem;
  color: ${colors.white};
  font-size: ${rel(14)}rem;
  line-height: ${rel(14)}rem;
  background: ${colors.negativeColor[2]};
  border-radius: ${rel(20)}rem;

  .${form} & {
    margin-top: 0;
  }
`
