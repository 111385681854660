import AccessControlledRender from '../../access-control/AccessControlledRender'
import LoginArea from './LoginArea'
import AuthenticatedUserArea from './AuthenticatedUserArea'
import LangSwitcher from './LangSwitcher'
import * as style from './UserArea.style'


interface Props {
  profilePath: string
  userSettingsPath: string
  logoutPath: string
  onLogout: () => unknown
}

const UserArea = ({ profilePath, userSettingsPath, logoutPath, onLogout }: Props): JSX.Element => (
  <div className={style.wrapper}>
    <AccessControlledRender
      noUserRender={() => <LoginArea />}
      loaderColor='white'
    >
      {(ac) => (
        <AuthenticatedUserArea
          profilePath={profilePath}
          userSettingsPath={userSettingsPath}
          logoutPath={logoutPath}
          onLogout={onLogout}
          person={ac.person}
        />
      )}
    </AccessControlledRender>
    <LangSwitcher />
  </div>
)

export default UserArea
