import type { ValueOf } from '../internal/utils'
import type { Page } from '../common'
import type { Person, PersonRef } from '../theses/person'
import { ResourceType } from './common'


export const NotificationType = {
  Notice: 'notice',
  Task: 'task',
} as const
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type NotificationType = ValueOf<typeof NotificationType>

export const NotificationState = {
  Unseen: 'unseen',
  Seen: 'seen',
  Visited: 'visited',
  Resolved: 'resolved',
} as const
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type NotificationState = ValueOf<typeof NotificationState>

export const TaskNotificationSubtype = {
  AwaitsReviewer: 'awaitsReviewer',
  OfficerApproval: 'officerApproval',
  OfficerApprove: 'officerApprove',
  ReviewerAccept: 'reviewerAccept',
  ReviewerReport: 'reviewerReport',
  Revision: 'revision',
  StudentAccept: 'studentAccept',
  StudentRejected: 'studentRejected',
  Submission: 'submission',
  SupervisorReport: 'supervisorReport',
} as const
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type TaskNotificationSubtype = ValueOf<typeof TaskNotificationSubtype>

export const NoticeNotificationSubtype = {
  AcceptedByAssignee: 'acceptedByAssignee',
  AcceptedByReviewer: 'acceptedByReviewer',
  ApprovedByOfficer: 'approvedByOfficer',
  Archived: 'archived',
  AssignReviewer: 'assignReviewer',
  CancelledByProponent: 'cancelledByProponent',
  CancelledDueToAcceptanceOfAnother: 'cancelledDueToAcceptanceOfAnother',
  CancellationRequest: 'cancellationRequest',
  CancellationRequestApproved: 'cancellationRequestApproved',
  CancellationRequestRejected: 'cancellationRequestRejected',
  ChangeRequest: 'changeRequest',
  ChangeRequestApproved: 'changeRequestApproved',
  ChangeRequestRejected: 'changeRequestRejected',
  Deleted: 'deleted',
  Evaluated: 'evaluated',
  OfficerApprove: 'officerApprove',
  PrintsApproved: 'printsApproved',
  Proposed: 'proposed',
  ProposedForApproval: 'proposedForApproval',
  RejectedByAssignee: 'rejectedByAssignee',
  RejectedByOfficer: 'rejectedByOfficer',
  RejectedByReviewer: 'rejectedByReviewer',
  RequestForRemovingAssignee: 'requestForRemovingAssignee',
  RequestForRemovingAssigneeApproved: 'requestForRemovingAssigneeApproved',
  RequestForRemovingAssigneeRejected: 'requestForRemovingAssigneeRejected',
  Resubmitted: 'resubmitted',
  ReturnedForRevision: 'returnedForRevision',
  SubmitReviewerReport: 'submitReviewerReport',
  SubmitSupervisorReport: 'submitSupervisorReport',
  Submitted: 'submitted',
  UpdatedByFtOfficer: 'updatedByFtOfficer',
  Withdraw: 'withdraw',
} as const
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type NoticeNotificationSubtype = ValueOf<typeof NoticeNotificationSubtype>

export type NotificationSubtype = TaskNotificationSubtype | NoticeNotificationSubtype

export interface NotificationMeta {
  task: {
    [x in TaskNotificationSubtype]?: number
  }
  notice: {
    [x in NoticeNotificationSubtype]?: number
  }
}

/**
 * Base type for `NoticeNotification` and `TaskNotification`.
 *
 * @internal don't expose in JSON Schema
 */
export interface BaseNotification {

  readonly id: number

  /**
   * Type of the notification.
   */
  type: NotificationType

  /**
   * Subtype of the notification that also identifies the template.
   * @pattern ^[A-Za-z0-9_.-]+$
   */
  subtype: NotificationSubtype

  /**
   * State of the notification.
   * @default "unseen"
   */
  state: NotificationState

  /**
   * Recipient of the notification.
   */
  recipient: PersonRef | Person

  /**
   * The role or relationship of the recipient to which this notification relates.
   */
  recipientRole?: string

  /**
   * Sender of the notification, or `null` if sender is a system.
   */
  sender?: PersonRef | Person | null

  /**
   * Textual subject of the notification.
   */
  subject: string

  /**
   * Textual body of the notification.
   */
  body: string

  /**
   * Type of the related resource.
   */
  targetType: ResourceType

  /**
   * ID of the related resource (where the data comes from).
   */
  targetId: number

  /**
   * The state of the target resource to which this task is valid; when the target resource leaves
   * this state, the task is marked as resolved.
   *
   * This is set on 'task' notifications only.
   */
  targetState?: string

  /**
   * JSON object with parameters for rendering template of this notification subtype.
   */
  templateData: Record<string, any>

  /**
   * The date and time at which this object was created.
   */
  readonly createdAt: Date

  /**
   * The last date and time at which this object was modified. This is being updated automatically.
   */
  readonly modifiedAt: Date
}

/**
 * A Notification of type 'notice'.
 */
export type NoticeNotification =
  & Omit<BaseNotification, 'type' | 'subtype' | 'state'>
  & {
    type: (typeof NotificationType)['Notice'],
    subtype: NoticeNotificationSubtype,

    /**
     * State of the notification.
     * @default "unseen"
     */
    state: Exclude<NotificationState, 'resolved'>,
  }

/**
 * A Notification of type 'task'.
 */
export type TaskNotification =
  & Required<Pick<BaseNotification, 'targetState'>>
  & Omit<BaseNotification, 'type' | 'subtype' | 'targetState'>
  & {
    type: (typeof NotificationType)['Task'],
    subtype: TaskNotificationSubtype,
  }

export type Notification = NoticeNotification | TaskNotification

type NotificationNewRefs = {
  /** Recipient of the notification. */
  recipient: PersonRef,
  /** Sender of the notification, or `null` if sender is a system. */
  sender?: PersonRef | null,
}

/**
 * A Notification of type 'notice' to be created.
 *
 * `recipient` is optional for the case when it's selected based on the
 * `recipientRole`. Either of them must be provided!
 *
 */
export type NoticeNotificationNew =
  & Pick<NoticeNotification, 'type' | 'subtype' | 'targetType' | 'targetId'>
  & Partial<Pick<NoticeNotification, 'recipientRole' | 'templateData'>>
  & Partial<NotificationNewRefs>

/**
 * A Notification of type 'task' to be created.
 *
 * `recipient` is optional for the case when it's selected based on the `recipientRole`.
 */
export type TaskNotificationNew =
  & Pick<TaskNotification, 'type' | 'subtype' | 'recipientRole' | 'targetType' | 'targetId' | 'targetState'>
  & Partial<Pick<TaskNotification, 'templateData'>>
  & Partial<NotificationNewRefs>

export type NotificationNew = NoticeNotificationNew | TaskNotificationNew

/**
 * Collection of Notification objects.
 */
export type NotificationList = Page<Notification>

/**
 * An overall Status of Notifications by NotificationType.
 */
export interface NotificationsStatus {
  /**
   * Status of 'notice' notifications.
   */
  notices: {
    /**
     * Number of unseen notices.
     */
    unseen: number,
  }
  /**
   * Status of 'task' notifications.
   */
  tasks: {
    /**
     * Number of unresolved tasks.
     */
    unresolved: number,
  }
}
