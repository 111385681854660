import ResultList, { Props as ResultListProps } from './ResultList'
import Filter, { Props as FilterProps } from './Filter'


type Props = FilterProps & ResultListProps & {
  myTopics?: boolean,
}

const TopicSearch = (props: Props): JSX.Element => (
  <>
    <Filter {...props} />
    <ResultList {...props} />
  </>
)

export default TopicSearch
