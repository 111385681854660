import { useParams } from 'react-router-dom'

import InvalidPath from './InvalidPath'
import NotFound from '../NotFound'


interface Props<Params extends object> {
  expectedPathPattern: string
  expectedParams: Record<keyof Params, {
    validate: (param: string) => boolean,
    convert: (param: string) => Params[keyof Params],
  }>
  children: (params: Params) => JSX.Element
}

/**
 * If any parameter parsed from the url is undefined or an empty string, then this component evaluates it as invalid.
 * Currently we assume that pages do not receive any parameters aside from path parameters.
 *
 * Note that the properties of the passed `Params` type must match the placeholders of the parameterized url.
 */
const ValidatedPathParams = <Params extends object>({
  expectedPathPattern, expectedParams, children,
}: Props<Params>): JSX.Element => {
  // FIXME: This is not correctly typed because a param can be undefined if it's missing in the path.
  // It has to be typed this way otherwise calling `children(params)` becomes a piece of work.
  const params = useParams<Record<keyof Params, string>>()
  const expectedParamsKeys = Object.keys(expectedParams) as Array<keyof typeof expectedParams>

  // This checks against undefined params.
  if (expectedParamsKeys.some(param => params[param] === undefined)) {
    return (
      <section>
        <InvalidPath expectedPathPattern={expectedPathPattern} />
      </section>
    )
  }

  if (expectedParamsKeys.some(param => !expectedParams[param].validate(params[param]))) {
    return <NotFound />
  }

  return children(expectedParamsKeys.reduce<Params>((acc, param) => {
    acc[param] = expectedParams[param].convert(params[param])
    return acc
  }, {} as Params))
}

export default ValidatedPathParams
