import { Configuration } from '@cvut/profit-api-types/lib/theses/configuration'

import ConfigurationForm from './ConfigurationForm'
import Card from '../../components/Card'
import PageHeader from '../../components/PageHeader'
import { useLocale } from '../../locale'


interface Props {
  defaultValues?: Configuration
  onSave: (data: Configuration) => unknown
}

const ConfigurationEdit = (props: Props): JSX.Element => {
  const { l } = useLocale()

  return (
    <>
      <PageHeader title={l.configuration.editConfiguration} />
      <Card.ColumnContainer>
        <Card.Column>
          <Card>
            <Card.Header title={l.configuration.configuration} />
            <Card.Content>
              <ConfigurationForm {...props} />
            </Card.Content>
          </Card>
        </Card.Column>
      </Card.ColumnContainer>
    </>
  )
}

export default ConfigurationEdit
