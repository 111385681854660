import { useEffect } from 'react'
import { Except, getPropertyWithSuffix } from '@cvut/profit-utils'
import { Thesis, ThesisMainState } from '@cvut/profit-api-types/lib/theses'

import AccessControlledRender from '../../access-control/AccessControlledRender'
import { ACLoaded as AccessControlLoaded } from '../../access-control'
import { errorCardRenderer } from '../../components/RequestErrorCard'
import PageHeader from '../../components/PageHeader'
import PageLoadSpinner from '../../components/PageLoadSpinner'
import pagePaths from '../paths'
import ThesisReviewer from '../../features/thesis/ThesisReviewer'
import ValidatedPathParams from '../utils/ValidatedPathParams'
import { useLocale } from '../../locale'
import { useThesis } from '../../api/theses'
import RequestDependentRender from '../../api/RequestDependentRender'


export const canSeeReviewerProposals = (ac: Except<AccessControlLoaded, 'state'>, thesis: Thesis): boolean => (
  ((ac.globalRoles.isReviewer || ac.globalRoles.isFtOfficer) && thesis.mainState === ThesisMainState.Assigned)
  || ((ac.thesisRoles.isThesisSupervisor(thesis) || ac.thesisRoles.isThesisSpecOfficer(thesis))
    && (thesis.mainState === ThesisMainState.Draft || (
      thesis.mainState === ThesisMainState.Proposed && thesis.states.includes('proposed.revision')
    ))
  )
)

interface Props {
  thesisId: number
}

const ThesisReviewerPage = ({ thesisId }: Props) => {
  const [thesisState, getThesis] = useThesis()
  const { l, currentLang } = useLocale()

  useEffect(() => {
    void getThesis(thesisId)
  }, [thesisId])

  return (
    <AccessControlledRender loaderColor='primary'>
      {(ac) => (
        <RequestDependentRender
          requestStatus={thesisState}
          renderOnLoading={() => <PageLoadSpinner message={l.thesis.loadingThesis} />}
          renderOnError={errorCardRenderer(l.thesis.errorGettingThesis, pagePaths.theses.reviewerProposals(thesisId))}
          renderOnSuccess={thesis => {
            const subtitles = [{
              label: getPropertyWithSuffix(thesis, 'title', currentLang) ?? '',
              href: pagePaths.theses.view(thesisId),
            }]

            return canSeeReviewerProposals(ac, thesis)
              ? (
                <>
                  <PageHeader
                    backButton={{ to: pagePaths.theses.view(thesisId) }}
                    title={l.thesis.reviewerPageTitle}
                    subtitles={subtitles}
                  />
                  <ThesisReviewer thesis={thesis} />
                </>
              )
              : null
          }}
        />
      )}
    </AccessControlledRender>
  )
}

const ThesisReviewerPageWrapper = (): JSX.Element => (
  <ValidatedPathParams<Props>
    expectedPathPattern={pagePaths.theses.reviewerProposals()}
    expectedParams={{
      thesisId: {
        validate: (param: string) => !isNaN(parseInt(param, 10)),
        convert: (param: string): number => parseInt(param, 10),
      },
    }}
  >
    {({ thesisId }) => <ThesisReviewerPage thesisId={thesisId} />}
  </ValidatedPathParams>
)

export default ThesisReviewerPageWrapper
