import { Language } from '@cvut/profit-api-types/lib/theses'

import type { ThesisReportTexts, ThesisReportsTexts } from '..'
import type { ReviewerReportTexts, SupervisorReportTexts } from './internal'
import * as cs from './cs'
import * as en from './en'


const texts: ThesisReportsTexts = {
  version: 'v20140114',
  supervisor: {
    cs: cs.supervisor,
    en: en.supervisor,
  } as Record<Language, SupervisorReportTexts & ThesisReportTexts>,  // cast section type to indexed
  reviewer: {
    cs: cs.reviewer,
    en: en.reviewer,
  } as Record<Language, ReviewerReportTexts & ThesisReportTexts>,  // cast section type to indexed
}
export default texts
