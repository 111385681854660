import Option from './Option'
import PersonCard from '../../PersonCard'
import { Person } from '../../../types/person'
import { formatPersonFullName } from '../../../utils/person'
import { useLocale } from '../../../locale'
import * as style from './OptionsList.style'
import { ThesisMainState } from '@cvut/profit-api-types/lib/theses'


interface Props {
  suggestedPeople: Person[]
  searching: boolean
  selectedPerson: Person | null
  selectedPersonText: string
  selectNoneText: string
  onOptionClick: (person: Person | null) => void
  thesisState: ThesisMainState
}

const OptionsList = ({
  suggestedPeople, searching, selectedPerson, selectedPersonText, selectNoneText, onOptionClick, thesisState,
}: Props): JSX.Element | null => {
  const { l } = useLocale()
  const personSearchBoxLocale = l.personSearchBox
  let options = null
  if (searching) {
    options = suggestedPeople.map(person => {
      const hasSpecialization = person?.study?.specialization
      const hasThesis = !!person.study?.hasThesis
      const isSelectable = hasSpecialization && !hasThesis
      const noSpecializationMessage = hasSpecialization ? undefined : personSearchBoxLocale.noSpecialization
      const hasThesisAssignedMessage = hasThesis ? personSearchBoxLocale.hasThesisAssigned : undefined

      return (
        <Option
          key={person.study?.id ?? person.username}
          selected={person.username === selectedPerson?.username
            // We need to check persons `study` as they can have multiple
            && person.study?.specialization
            && person.study.specialization.code === selectedPerson.study?.specialization.code}
          onClick={isSelectable ? () => onOptionClick(person) : undefined}
        >
          <PersonCard
            name={formatPersonFullName(person)}
            contact={person.email}
            specialization={person.study?.specialization?.code}
            errorMessage={noSpecializationMessage ?? hasThesisAssignedMessage}
          />
        </Option>
      )
    })
  } else if (selectedPerson) {
    options = (
      <>
        {([ThesisMainState.Draft, ThesisMainState.Proposed] as ThesisMainState[]).includes(thesisState) && (
          <Option onClick={() => onOptionClick(null)}>{selectNoneText}</Option>
        )}
        <li className={style.optionDivider} />
        <li className={style.optionHeader}>{selectedPersonText}</li>
        <Option key={selectedPerson.username} selected onClick={() => onOptionClick(selectedPerson)}>
          <PersonCard
            name={formatPersonFullName(selectedPerson)}
            contact={selectedPerson.email}
            specialization={selectedPerson.study?.specialization.code}
          />
        </Option>
      </>
    )
  } else {
    return null
  }

  return (
    <ul className={style.list}>
      {options}
    </ul>
  )
}

export default OptionsList
