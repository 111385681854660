import { ChangeEvent, MouseEvent as ReactMouseEvent, ReactNode, Ref, useRef, useState } from 'react'
import _ from 'lodash'
import { cx } from 'linaria'
import { FilterProps } from 'react-table'

import { OptionType } from './types'
import useOnClickOutside from '../../../hooks/useOnClickOutside'
import useOnKeyPress from '../../../hooks/useOnKeyPress'
import { useLocale } from '../../../locale'
import { ReactComponent as ArrowDownIcon } from '../../../images/icons/ArrowDown.svg'
import { ReactComponent as CheckboxIconChecked } from '../../../images/icons/CheckboxTrue.svg'
import { ReactComponent as CheckboxIconUnchecked } from '../../../images/icons/CheckboxFalse.svg'
import * as style from './MultiselectFilter.style'


interface ClosedOptionsPanelProps {
  filterValue: string[]
}

const ClosedOptionsPanel = ({ filterValue }: ClosedOptionsPanelProps) => (
  <div className={style.selectLabel}>
    ({filterValue.length})
  </div>
)


interface OptionListItemProps {
  option: OptionType
  filterValue: string[]
  onChange: (newValue?: string[]) => void
}

const OptionListItem = ({ option, filterValue, onChange }: OptionListItemProps) => {
  const checkboxId = _.uniqueId('checkbox-')
  const isChecked = filterValue.includes(option.value)

  const handleCheckboxChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.stopPropagation()

    const changedOptionValue = e.target.value
    const newFilterValue = filterValue.includes(changedOptionValue)
      ? filterValue.filter(value => value !== changedOptionValue)
      : filterValue.slice().concat([changedOptionValue])

    onChange(newFilterValue)
  }

  return (
    <li className={cx(style.optionsListItem, style.basicCheckbox, ...(option.extraClassNames ?? []))}>
      <input
        type='checkbox'
        name={option.value}
        value={option.value}
        checked={isChecked}
        onChange={handleCheckboxChange}
        id={checkboxId}
      />
      {filterValue.includes(option.value)
        ? <CheckboxIconChecked />
        : <CheckboxIconUnchecked />}
      <label htmlFor={checkboxId}>{option.text}</label>
    </li>
  )
}


interface OpenOptionsPanelProps {
  options: OptionType[]
  filterValue: string[]
  listRef: Ref<HTMLUListElement>
  onChange: (filterValue?: string[]) => void
  onClose: () => void
}

const OpenOptionsPanel = ({ options, filterValue, listRef, onChange, onClose }: OpenOptionsPanelProps) => {
  const { l } = useLocale()

  useOnKeyPress('Escape', onClose)

  return (
    <div className={style.panelContainer}>
      <div className={style.selectLabel}>
        {l.thesesList.filters.select}
      </div>
      <ul ref={listRef} className={style.optionsList}>
        {options.map(option => (
          <OptionListItem
            key={option.key}
            option={option}
            filterValue={filterValue}
            onChange={onChange}
          />
        ))}
      </ul>
    </div>
  )
}

const OpenCloseToggleIcon = () => {
  const { l } = useLocale()

  return (
    <button className={style.openToggle} title={l.thesesList.filters.expandMenu}>
      <ArrowDownIcon />
    </button>
  )
}


export type MultiselectFilterProps<T extends object = {}> = Pick<FilterProps<T>, 'column'> & {
  options: OptionType[],
  closedOptionsPanelComponent?: ReactNode,
}

export const MultiselectFilter = <T extends object = {}>({
  column, options, closedOptionsPanelComponent,
}: MultiselectFilterProps<T>): JSX.Element => {
  const [isOpen, setIsOpen] = useState(false)
  const { filterValue, setFilter } = column as { filterValue?: string[], setFilter: (filterValue?: string[]) => void }
  const currentFilterValue = filterValue ?? []
  const componentRef = useRef<HTMLDivElement | null>(null)
  const optionsListRef = useRef<HTMLUListElement | null>(null)

  const handleClose = () => {
    setIsOpen(false)
  }

  useOnClickOutside(componentRef, handleClose)

  const handleOpenToggleClick = (e: ReactMouseEvent<HTMLDivElement, MouseEvent>) => {
    const newOpenState = !isOpen
    const target = e.target as HTMLElement

    if (newOpenState || !optionsListRef.current?.contains(target)) {
      setIsOpen(newOpenState)
    }
  }

  return (
    <div className={style.multiselect} ref={componentRef} onClick={handleOpenToggleClick}>
      {/* TODO - visually impaired-friendly expand/collapse */}
      <OpenCloseToggleIcon />
      {closedOptionsPanelComponent ?? <ClosedOptionsPanel filterValue={currentFilterValue} />}
      {isOpen && (
        <OpenOptionsPanel
          options={options}
          filterValue={currentFilterValue}
          listRef={optionsListRef}
          onChange={setFilter}
          onClose={handleClose}
        />
      )}
    </div>
  )
}

export default MultiselectFilter
