import { Language } from '@cvut/profit-api-types/lib/theses'

import v20210425 from './v20210425'
import v20140114 from './v20140114'


export interface ThesisReportsTexts {
  version: string
  reviewer: Record<Language, ThesisReportTexts>
  supervisor: Record<Language, ThesisReportTexts>
}

export interface ThesisReportTexts<TSections extends BaseReportSections = IndexedReportSections> {
  headers: Record<HeaderName, string>
  sectionsOrder: Array<keyof TSections>
  sections: TSections
}

// TODO: Move all or most of these headers to profit-theses-papers
// (frontend doesn't use them).
type HeaderName =
  | 'createdOn'
  | 'document'
  | 'evaluationCriteria'
  | 'instructions'
  | 'reportAuthor'
  | 'specialization'
  | 'student'
  | 'thesisTitle'

interface BaseReportSections {
  defenseQuestions?: ThesisReportSection
  summary: ThesisReportSection
}

type IndexedReportSections = BaseReportSections & Record<string, ThesisReportSection>

export interface ThesisReportSection<TType extends SectionType = SectionType> {
  type: TType
  title: string
  description: string
  choices?: Record<string | number, string>
}

type SectionType = 'choice' | 'plain' | 'score'


export const versions: Record<string, ThesisReportsTexts> = {
  v20210425,
  v20140114,
}

export const latest: ThesisReportsTexts = v20210425
