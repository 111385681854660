import { isEmpty } from 'lodash'
import { Thesis, ThesisMainState, ThesisPatch } from '@cvut/profit-api-types/lib/theses'
import { ThesisRel } from '@cvut/profit-theses-common'

import { ACLoaded } from '../../access-control'
import Card from '../../components/Card'
import { thesisSubmitMetadata } from './common'
import ThesisSubmitViewMain from './ThesisSubmitViewMain'
import ThesisSubmitViewSidebar from './ThesisSubmitViewSidebar'
import { useLocale } from '../../locale'


const thesisPublicStates = [ThesisMainState.ReadyForDefence, ThesisMainState.Evaluated] as ThesisMainState[]
const evaluatorRoles = [ThesisRel.REVIEWER, ThesisRel.SUPERVISOR] as ThesisRel[]
const reviewStates = [
  'archived',
  'changeable.assigned.submission.awaitsPrintsApproval',
  'changeable.assigned.submission.printsApproved',
  'changeable.review',
]

const isAccessible = (ac: Pick<ACLoaded, 'thesisRoles' | 'globalRoles'>, thesis: Thesis) => {
  const isThesisPublic = thesisPublicStates.includes(thesis.mainState)
  const isViewedByAuthor = ac.thesisRoles.isThesisAssignee(thesis)
  const isViewedByFTOfficer = ac.globalRoles.isFtOfficer
  const isViewedByEvaluator = ac.thesisRoles.list(thesis).some(role => evaluatorRoles.includes(role))
  const isViewedForReview = thesis.states.some(state => reviewStates.some(reviewState => (
    state === reviewState || state.startsWith(`${reviewState}.`)
  )))

  return isThesisPublic
      || isViewedByAuthor
      || isViewedByFTOfficer
      || (isViewedByEvaluator && isViewedForReview)
}

const isMetadataEmpty = (thesis: ThesisPatch) => !thesisSubmitMetadata.some(key => thesis[key]?.length)

interface Props {
  accessControl: Pick<ACLoaded, 'thesisRoles' | 'globalRoles'>
  onDownload: (url: string) => unknown
  thesis: Thesis
}

export const ThesisSubmitView = ({ accessControl, onDownload, thesis }: Props): JSX.Element | null => {
  const { l } = useLocale()

  if (
    (isMetadataEmpty(thesis) && isEmpty(thesis.files) && isEmpty(thesis.attachments))
    || !isAccessible(accessControl, thesis)
  ) {
    return null
  }

  return (
    <Card>
      <Card.Header title={l.thesis.submission.view.title} />
      <Card.ColumnContainer>
        <ThesisSubmitViewMain {...{ thesis }} />
        <ThesisSubmitViewSidebar {...{ thesis, onDownload }} />
      </Card.ColumnContainer>
    </Card>
  )
}

export default ThesisSubmitView
