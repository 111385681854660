import { ChangeEvent, FormEventHandler, useEffect, useState } from 'react'
import { Enum } from 'typescript-string-enums'
import { Thesis } from '@cvut/profit-api-types/lib/theses'

import { useLocale } from '../../locale'
import { useModals } from '../../features/modals'
import { useUserInfo } from '../../session/user-info'


const formKeys = Enum(
  'titleCs', 'titleEn', 'licenseType', 'language', 'validUntil', 'description', 'supervisorWillProposeReviewer',
)
type DefaultFormKeys = keyof typeof formKeys
type DefaultFormValuesType = Partial<Pick<Thesis, DefaultFormKeys>>

const readBackup = (key: string): DefaultFormValuesType => {
  const localStorageValue = window.localStorage.getItem(key) ?? '{}'

  try {
    return JSON.parse(localStorageValue) as DefaultFormValuesType ?? {}
  } catch {
    return {}
  }
}

const backupExists = (key: string): boolean => {
  return !!window.localStorage.getItem(key)
}

const formBackupHandler = (key: string) => (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
  const input = e.currentTarget as HTMLInputElement
  const backupValuesKey = input.name

  if (backupValuesKey in formKeys) {
    const value = (backupValuesKey === 'supervisorWillProposeReviewer')
      ? input.checked
      : input.value

    window.localStorage.setItem(key, JSON.stringify({
      ...readBackup(key),
      [backupValuesKey]: value,
    }))
  } else {
    throw new TypeError(`formBackupHandler(): Invalid thesis form input name: "${backupValuesKey}"`)
  }
}

export const useThesisFormBackup = (
  thesisId?: number,
): {
    forgetStoredValues: () => void,
    formBackupHandler: FormEventHandler,
    getDefaultValues: (thesisData?: Thesis) => DefaultFormValuesType,
    shouldReset: boolean,
    storedValues?: DefaultFormValuesType,
  } => {
  const { l } = useLocale()
  const { showModal } = useModals()
  const [storedValues, setStoredValues] = useState<DefaultFormValuesType | undefined>()
  const [userInfo] = useUserInfo()

  // when used in-app, user is always logged in here
  // 'anonymous' only for fixtures and while loading user info
  const username = userInfo.state === 'loaded' ? userInfo.person.username : 'anonymous'
  const key = `${username}-thesis-${thesisId ?? 'create'}`

  const forgetStoredValues = () => {
    window.localStorage.removeItem(key)
  }

  // show backup restoration offering modal in case backup is found
  useEffect(() => {
    if (!userInfo) {
      return
    }

    if (backupExists(key)) {
      // TODO: should probably let the user review diff between saved and backup version
      showModal({
        title: l.thesis.backupRestoreDialog.title,
        text: l.thesis.backupRestoreDialog.text,
        cancelButton: {
          focus: true,
        },
        negativeButton: {
          caption: l.thesis.backupRestoreDialog.forget,
          onClick: forgetStoredValues,
        },
        positiveButton: {
          caption: l.thesis.backupRestoreDialog.restore,
          onClick: () => setStoredValues(readBackup(key)),
        },
      })
    }
  }, [userInfo.state])

  // default form values - either thesis or empty, overriden by what's stored
  const getDefaultValues = (thesisData?: Thesis): DefaultFormValuesType => {
    const defaults = thesisData ?? {}

    return storedValues
      ? { ...defaults, ...storedValues }
      : defaults
  }

  return {
    forgetStoredValues,
    formBackupHandler: formBackupHandler(key),
    getDefaultValues,
    shouldReset: !!storedValues,
    storedValues,
  }
}
