import { useEffect } from 'react'
import { Enum } from 'typescript-string-enums'
import { getPropertyWithSuffix } from '@cvut/profit-utils'
import { ThesisReport, ThesisReportType } from '@cvut/profit-api-types/lib/theses'
import { assertValidThesisReport, thesisLangToLocale } from '@cvut/profit-theses-common'

import AccessControlledRender from '../../access-control/AccessControlledRender'
import { errorCardRenderer } from '../../components/RequestErrorCard'
import PageHeader from '../../components/PageHeader'
import PageLoadSpinner from '../../components/PageLoadSpinner'
import RequestDependentRender from '../../api/RequestDependentRender'
import ThesisReportDetail from '../../features/thesis/ThesisReportDetail'
import ValidatedPathParams from '../utils/ValidatedPathParams'
import { createThesisReportPdfUrl, useThesisReport } from '../../api/theses/thesisReports'
import { useThesis } from '../../api/theses'
import { useLocale } from '../../locale'
import pagePaths from '../paths'


interface Props {
  thesisId: number
  reportType: ThesisReportType
}

const ThesisReportViewPage = ({ thesisId, reportType }: Props): JSX.Element => {
  const { l } = useLocale()
  const [getThesisStatus, getThesis] = useThesis()
  const [getThesisReportStatus, getThesisReport] = useThesisReport(() => null)

  useEffect(() => {
    void getThesis(thesisId)
    void getThesisReport(thesisId, reportType)
  }, [thesisId, reportType])

  const renderThesisReportViewPage = (report: ThesisReport) => {
    assertValidThesisReport(report)

    const locale = thesisLangToLocale(report.thesis.language)

    const titleInThesisLanguage = getPropertyWithSuffix(report.thesis, 'title', locale)
    const titleInOtherLanguage = getPropertyWithSuffix(report.thesis, 'title', locale === 'cs' ? 'en' : 'cs')

    return (
      <article>
        <PageHeader
          backButton={{ to: pagePaths.theses.view(thesisId) }}
          title={titleInThesisLanguage}
          subtitles={[titleInOtherLanguage]}
        />
        <ThesisReportDetail
          report={report}
          downloadLink={createThesisReportPdfUrl(thesisId, reportType)}
        />
      </article>
    )
  }

  const reportViewPath = pagePaths.theses.reportView(thesisId, reportType)

  return (
    <AccessControlledRender loaderColor='primary'>
      {() => (
        <RequestDependentRender
          requestStatus={getThesisStatus}
          renderOnLoading={() => <PageLoadSpinner message={l.thesis.loadingThesis} />}
          renderOnError={errorCardRenderer(l.thesis.errorGettingThesis, reportViewPath)}
          renderOnSuccess={(thesis) => (
            <RequestDependentRender
              requestStatus={getThesisReportStatus}
              renderOnLoading={() => <PageLoadSpinner message={l.thesis.reports.loadingReport} />}
              renderOnError={errorCardRenderer(l.thesis.reports.errorMessages.load, reportViewPath)}
              renderOnSuccess={(report) => renderThesisReportViewPage({ ...report, thesis })}
            />
          )}
        />
      )}
    </AccessControlledRender>
  )
}

const ThesisReportViewPageWrapper = (): JSX.Element => (
  <ValidatedPathParams<Props>
    expectedPathPattern={pagePaths.theses.reportView()}
    expectedParams={{
      thesisId: {
        validate: (param: string) => !isNaN(parseInt(param, 10)),
        convert: (param: string) => parseInt(param, 10),
      },
      reportType: {
        validate: (param: string) => Enum.isType(ThesisReportType, param),
        convert: param => param as ThesisReportType,
      },
    }}
  >
    {(params) => (
      <ThesisReportViewPage {...params} />
    )}
  </ValidatedPathParams>
)

export default ThesisReportViewPageWrapper
