import { css } from 'linaria'

import searchIcon from '../../images/icons/SearchRectangle.svg'
import { breakpoints, rel } from '../../styles/dimensions'
import { fontWeights } from '../../styles/typography'
import * as colors from '../../styles/colors'


export const searchWrapper = css`
  display: flex;
  margin-bottom: ${rel(8)}rem;
`

export const searchInputButton = css`
  width: ${rel(38)}rem;
  height: ${rel(38)}rem;
  background: url(${searchIcon}) center center no-repeat;
  background-color: ${colors.primaryColor[1]};
  background-size: ${rel(30)}rem;
  border-radius: ${rel(6)}rem 0 0 ${rel(6)}rem;
`

export const searchInputWrapper = css`
  position: relative;
  display: flex;
  flex-grow: 1;
`

export const searchInput = css`
  width: 100%;
  padding: 0 ${rel(10)}rem;
  font-weight: ${fontWeights.light};
  font-size: ${rel(14)}rem;
  line-height: ${rel(38)}rem;
  background-color: ${colors.backgroundColor[2]};
  border: none;
  border-radius: 0 ${rel(6)}rem ${rel(6)}rem 0;

  @media screen and (min-width: ${breakpoints.xl}) {
    font-size: ${rel(16)}rem;
  }

  &:focus {
    outline: none;
  }

  &::placeholder {
    color: ${colors.secondaryColor[2]};
  }
`

export const searchContent = css`
  position: absolute;
  top: 100%;
  z-index: 999;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: ${rel(8)}rem;
  background-color: ${colors.backgroundColor[3]};
  border: ${rel(2)}rem solid ${colors.backgroundColor[2]};
  border-top: none;
  border-radius: 0 0 ${rel(6)}rem ${rel(6)}rem;

  > ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }
`

export const searchResultsList = css`
  max-height: 50vh;
  overflow-y: auto;

  & svg {
    max-width: ${rel(32)}rem;
    height: auto;
  }

  @media screen and (min-width: ${breakpoints.md}) {
    & svg {
      max-width: ${rel(48)}rem;
    }
  }
`

export const link = css`
  display: block;
  padding: ${rel(8)}rem ${rel(16)}rem;
  cursor: pointer;

  &:hover,
  &:focus {
    text-decoration: none;
    background-color: ${colors.backgroundColor[2]};
    outline: 0;
  }
`

export const message = css`
  padding: ${rel(8)}rem ${rel(16)}rem;
`
