import { ReactNode, useState, useRef, useEffect } from 'react'
import { Link, NavLink, useLocation } from 'react-router-dom'

import AccessControlledRender from '../../access-control/AccessControlledRender'
import { personRegistrationLink, issuesLink, manualLink, stateExamsLink } from '../../config'
import pagePaths from '../../pages/paths'
import { useCollapseNavigation } from '../../hooks/useCollapseNavigation'
import { useLocale } from '../../locale'
import { ReactComponent as MyWorkPersonRoundIcon } from '../../images/icons/MyWorkPersonRound.svg'
import { ReactComponent as PlusAddRoundIcon } from '../../images/icons/PlusAddRound.svg'
import { ReactComponent as SearchAdvancedRoundIcon } from '../../images/icons/SearchAdvancedRound.svg'
import { ReactComponent as SearchRoundIcon } from '../../images/icons/SearchRound.svg'
import { ReactComponent as LeaveLinkIcon } from '../../images/icons/LeaveLink.svg'
import * as style from './NavLinks.style'


interface NavLinkGroupProps {
  id: string
  label: string
  children: ReactNode
  isActive?: boolean
}

export const NavLinkGroup = ({ id, label, children, isActive = false }: NavLinkGroupProps): JSX.Element => {
  const [isOpen, setIsOpen] = useState(isActive)

  useEffect(() => {
    setIsOpen(isActive)
  }, [isActive])

  const navOpenToggle = () => {
    setIsOpen(!isOpen)
  }

  return (
    <li className={isOpen ? style.active : ''} role='none'>
      <Link
        to='#'
        id={id}
        className={style.dropdownToggle}
        title={label}
        onClick={navOpenToggle}
        aria-haspopup
        aria-expanded={isOpen}
        role='menuitem'
      >
        {label}
      </Link>
      <ul
        className={style.dropdown}
        aria-labelledby={id}
        aria-label={label}
        role='menu'
      >
        {children}
      </ul>
    </li>
  )
}

interface NavLinkItemProps {
  path: string
  label: string
  icon?: JSX.Element
}

export const NavLinkItem = ({ path, label, icon }: NavLinkItemProps): JSX.Element => {
  const { closeCollapse } = useCollapseNavigation()

  const handleNavLink = () => {
    closeCollapse(true)
  }

  return (
    <li role='none'>
      <NavLink
        to={path}
        exact
        activeClassName={style.active}
        onClick={handleNavLink}
        role='menuitem'
      >
        {icon && (
          <span className={style.linkIcon}>
            {icon}
          </span>
        )}
        {label}
      </NavLink>
    </li>
  )
}

interface UserHelpLinkItemProps {
  path: string
  label: string
  hasDivider?: boolean
}

export const UserHelpLinkItem = ({ path, label, hasDivider }: UserHelpLinkItemProps): JSX.Element => {
  const { closeCollapse } = useCollapseNavigation()

  const handleNavLink = () => {
    closeCollapse(true)
  }

  return (
    <li className={hasDivider ? style.userHelpDivider : ''} role='none'>
      <a
        href={path}
        className={style.userHelpLink}
        onClick={handleNavLink}
        target='_blank'
        rel='noreferrer'
        role='menuitem'
      >
        {label}
        <LeaveLinkIcon title={label} />
      </a>
    </li>
  )
}

interface Props {
  homePath: string
  configurationPath: string
  createTopicPath: string
  myTopicsPath: string
  searchTopicsPath: string
  createThesisPath: string
  myThesesPath: string
  searchThesesPath: string
  searchThesesOfficerPath: string
}

const SiteNavLinks = ({
  homePath,
  configurationPath,
  createTopicPath,
  myTopicsPath,
  searchTopicsPath,
  createThesisPath,
  myThesesPath,
  searchThesesPath,
  searchThesesOfficerPath,
}: Props): JSX.Element => {
  const { l } = useLocale()
  const nodeRef = useRef<HTMLDivElement | null>(null)
  const location = useLocation()

  const isThesesSubMenuActive = location.pathname.startsWith(pagePaths.theses.root)

  const isTopicsSubMenuActive = location.pathname.startsWith(pagePaths.topics.root)

  return (
    <nav ref={nodeRef} className={style.navigation} aria-label={l.siteHeader.navigation}>
      <ul className={style.navigationLinks} role='menubar' aria-label={l.siteHeader.navigation}>
        <AccessControlledRender loaderColor='white'>
          {(ac) => (
            <>
              <NavLinkItem path={homePath} label={l.siteHeader.home} />
              <NavLinkGroup
                id='topic'
                label={l.siteHeader.topics}
                isActive={isTopicsSubMenuActive}
              >
                {ac.globalRoles.isProponent && (
                  <NavLinkItem
                    path={createTopicPath}
                    label={l.siteHeader.createTopic}
                    icon={<PlusAddRoundIcon title={l.siteHeader.createTopic} />}
                  />
                )}
                <NavLinkItem
                  path={myTopicsPath}
                  label={l.siteHeader.myTopics}
                  icon={<MyWorkPersonRoundIcon title={l.siteHeader.myTopics} />}
                />
                <NavLinkItem
                  path={searchTopicsPath}
                  label={l.siteHeader.searchTopics}
                  icon={<SearchRoundIcon title={l.siteHeader.searchTopics} />}
                />
              </NavLinkGroup>
              <NavLinkGroup
                id='final-thesis'
                label={l.siteHeader.theses}
                isActive={isThesesSubMenuActive}
              >
                {ac.globalRoles.isProponent && (
                  <NavLinkItem
                    path={createThesisPath}
                    label={l.siteHeader.createThesis}
                    icon={<PlusAddRoundIcon title={l.siteHeader.createThesis} />}
                  />
                )}
                <NavLinkItem
                  path={myThesesPath}
                  label={l.siteHeader.seeMyTheses}
                  icon={<MyWorkPersonRoundIcon title={l.siteHeader.seeMyTheses} />}
                />
                <NavLinkItem
                  path={searchThesesPath}
                  label={l.siteHeader.searchTheses}
                  icon={<SearchRoundIcon title={l.siteHeader.searchTheses} />}
                />
                {ac.globalRoles.isFtOfficer && (
                  <NavLinkItem
                    path={searchThesesOfficerPath}
                    label={l.siteHeader.searchThesesAsOfficer}
                    icon={<SearchAdvancedRoundIcon title={l.siteHeader.searchThesesAsOfficer} />}
                  />
                )}
              </NavLinkGroup>
              {ac.globalRoles.isFtOfficer && (
                <NavLinkItem
                  path={configurationPath}
                  label={l.siteHeader.configuration}
                />
              )}
            </>
          )}
        </AccessControlledRender>
        <UserHelpLinkItem path={manualLink} label={l.footer.manualLinkCaption} hasDivider />
        <UserHelpLinkItem path={stateExamsLink} label={l.footer.stateExamsLinkCaption} />
        <UserHelpLinkItem path={issuesLink} label={l.footer.issuesLinkCaption} />
        <UserHelpLinkItem path={personRegistrationLink} label={l.footer.personRegistration} />
      </ul>
    </nav>
  )
}

export default SiteNavLinks
