import { ChangeEvent, useEffect, useMemo, useState } from 'react'
import _ from 'lodash'
import { CellProps, Column, FilterProps, FilterType, TableState } from 'react-table'
import { Link, useHistory } from 'react-router-dom'
import { Enum } from 'typescript-string-enums'
import { ParamMap } from 'urlcat'
import { cx } from 'linaria'
import {
  Person, Study, Specialization, Thesis, ThesisAction, ThesisMainState, ThesisLanguage,
} from '@cvut/profit-api-types/lib/theses'

import { addNotification } from '../toastNotifications'
import { DisplayedOfficerThesesStates } from '../../types/thesis'
import { deriveMainState, formatPersonFullNameReversed, sameContents } from '../../utils/theses'
import { useLocale } from '../../locale'
import {
  decodeArrayParameter, localizedTitle, ExportCsvLink, NewAssignmentLink, ResetFiltersLink, ThesesListSearch,
  TState, TPersonKey, TPersonNameKey,
} from './ThesesTable'
import MultiselectFilter from '../../components/table/filter/MultiselectFilter'
import ThesisStatesFilter, { stateToClassMap } from '../../components/table/filter/ThesisStatesFilter'
import type { OptionType } from '../../components/table/filter/types'
import { SelectFilter } from '../../components/table/filter'
import Switch from '../../components/form/Switch'
import SwitcherFilters from '../../components/table/filter/SwitcherFilters'
import Table, { ThesisStateRenderer } from '../../components/table'
import pagePaths from '../../pages/paths'
import { useThesesMeta, usePaginatedTheses, getThesesExportCsvUrl } from '../../api/theses'
import { useStudyDegrees } from '../../api/studyDegrees'
import { useThesisActions, useUpdateThesis } from '../../api/theses'
import { useThesisStates } from '../../api/thesisStates'
import { useModals } from '../modals'
import * as style from './OfficerThesesTable.style'
import * as thesesTableStyle from './ThesesTable.style'
import * as buttonStyle from '../../components/Button.style'
import * as statesFilterStyle from '../../components/table/filter/ThesisStatesFilter.style'
import LoadMore from '../../components/table/LoadMore'


const withoutReviewerFilterId = 'withoutReviewer'

// FIXME: lots of code duplication with ThesesTable

// TODO: ids do not match Thesis attributes, title contains titleCs/titleEn, type contains studyDegree
const defaultState: TState = {
  filters: [
    { id: 'title', value: '' },
    { id: 'language', value: [] },
    { id: 'type', value: [] },
    { id: 'specialization', value: [] },
    { id: 'states', value: [] },
    { id: 'supervisorFrozenName', value: '' },
    { id: 'reviewerFrozenName', value: '' },
    { id: 'assigneeFrozenName', value: '' },
    { id: 'verifiedByFtOfficer', value: '' },
    { id: 'validUntil', value: [] },
  ],
  sortBy: [],
}

// TODO: refactor into extra files (separate dir, index.ts exporting the component, importing utility functions etc),
// if possible, remove global variable, do together with fixing history navigation bug
const defaultStateFullValues: Pick<TableState<Thesis>, 'filters'> = { filters: [] }
const defaultSortBy: string[] = defaultState.sortBy?.map(({ id, desc }) => `${desc ? '-' : ''}${id}`) ?? []


// TODO unify parameter parsing with backend, unify parameter names, how to handle q that does not belong to table etc
export function stateFromSearchString (searchString: string): TState {
  const query = new URLSearchParams(searchString)
  query.delete('q') // q doesn't belong to table state

  // build lookup from the default state
  const stateLookup: Record<string, string | string[]> = {}
  for (const { id, value } of defaultState.filters.values()) {
    stateLookup[id] = Array.isArray(value) ? value : String(value)
  }
  stateLookup.sortBy = defaultState.sortBy.map(({ id, desc }) => `${desc ? '-' : ''}${id}`)

  const keyMap: Record<string, string> = {
    'specialization.code': 'specialization',
    'specialization.studyDegree': 'type',
    'titleCs': 'title',
    'titleEn': 'title',
    'studyDegree': 'type',
    'assignee': 'assigneeFrozenName',
    'reviewer': 'reviewerFrozenName',
    'supervisor': 'supervisorFrozenName',
  }

  const mappedColumnId = (id: string) => keyMap[id] ?? id

  // overwrite the lookup with values parsed from query string
  for (const [param, value] of query.entries()) {
    if (param === 'sort') {
      stateLookup.sortBy = value.split(',').filter((v: string) => !!v).map(val => decodeURIComponent(val))
    } else {
      // FIXME - url parameters an thesis attribute names should to be unified with backend to get rid of this nonsense
      const mappedParam = mappedColumnId(param)
      // FIXME: should not take type from current value in default array which is not necessarily set
      // interpret parameter value as either string or single value
      stateLookup[mappedParam] = Array.isArray(stateLookup[mappedParam])
        ? decodeArrayParameter(value)
        : decodeURIComponent(value)
    }
  }

  // build new state from the merged lookup
  const newInitialState: TState = { filters: [], sortBy: [] }
  for (const [id, value] of Object.entries(stateLookup)) {
    if (id === 'sortBy') {
      const sortBy: string[] = (stateLookup.sortBy.length > 0
        ? stateLookup.sortBy
        : defaultSortBy
      )
      newInitialState.sortBy.push(...sortBy.map(id => ({
        id: mappedColumnId(id.startsWith('-') ? id.substring(1) : id),
        desc: id.startsWith('-'),
      })) as typeof newInitialState.sortBy)
    } else {
      newInitialState.filters.push({
        id: id,
        value: value,
      })
    }
  }

  return newInitialState
}


/** @internal exported only for tests */
function stringifyFilterValue (id: string, obj: (string | number) | Array<string | number>): string {
  let value = null

  if (!Array.isArray(obj)) {
    obj = [obj]
  }

  const initialFilterState = defaultStateFullValues.filters?.filter(f => f.id === id) ?? []
  const initialFilterValue: string[] = initialFilterState.length > 0 ? initialFilterState[0].value as string[] : []

  // is obj same as default state ? null : serialized value
  if (!sameContents(new Set(obj), new Set(initialFilterValue)) && obj.length !== 0) {
    value = Array.prototype.join.call(obj.map(value => encodeURIComponent(value)), ',')
  }

  const keyMap: Record<string, string> = {
    type: 'studyDegree',
  }

  return (value !== null) ? [keyMap[id] ?? id, value].join('=') : ''
}


/** @internal exported only for tests */
export function searchStringFromState (state: TState, q: string, lang: string): string {
  // FIXME - unify parameter and attribut names between backend and frontend to get rid of this mess
  const keyMap: Record<string, string> = {
    title: localizedTitle[lang],
    specialization: 'specialization.code',
    type: 'specialization.studyDegree',
  }
  const sortBy = state.sortBy
    .map(({ id, desc }) => {
      return { id: keyMap[id] ?? id, desc }
    })
    .map(({ id, desc }) => `${desc ? '-' : ''}${id}`)
    .join(',')

  const searchString = `${state.filters
    .concat({ id: 'q', value: q })
    .map(f => f.value ? stringifyFilterValue(f.id, f.value) : '')
    .filter(f => !!f)
    .concat(sortBy ? [`sort=${sortBy}`] : [])
    .join('&')}`

  return searchString ? `?${searchString}` : ''
}

export const personAccessor = (who: TPersonKey) => (thesis: Thesis): string => {
  const personName = thesis[`${who}FrozenName` as TPersonNameKey]
  if (!personName) {
    return ''
  }

  return formatPersonFullNameReversed(personName, (thesis[who] as Person | Study).username)
}

const apiParametersFromLocationSearch = (search: string): ParamMap => {
  const originalQuery = new URLSearchParams(search)
  const keyMap: Record<string, string> = { // FIXME
    assigneeFrozenName: 'assignee',
    reviewerFrozenName: 'reviewer',
    supervisorFrozenName: 'supervisor',
  }
  const query = new URLSearchParams()
  for (const [key, value] of originalQuery.entries()) {
    query.append(keyMap[key] ?? key, value)
  }

  if (!query.has('states')) {
    query.append('states', Object.values(DisplayedOfficerThesesStates).join(','))
  }

  const queryEntries = [...query.entries()]

  if (!queryEntries.length) {
    return {}
  }

  return queryEntries.reduce<ParamMap>((acc, [k, v]) => {
    acc[k] = `${Array.isArray(v) ? v.join(',') : v}`
    return acc
  }, {})
}

interface OfficerThesesTableProps {
  displayNewAssignmentButton?: boolean
  displayExportCsvButton?: boolean
}

const OfficerThesesTable = ({
  displayNewAssignmentButton = false,
  displayExportCsvButton = false,
}: OfficerThesesTableProps): JSX.Element => {

  const { currentLang, l } = useLocale()
  const [thesesMetaStatus, getThesesMeta] = useThesesMeta()
  const [specializationOptions, setSpecializationOptions] = useState<OptionType[]>([])
  const [validUntilOptions, setValidUntilOptions] = useState<OptionType[]>([])

  useEffect(() => {
    if (thesesMetaStatus.state === 'not-initiated') {
      void getThesesMeta()
    } else if (thesesMetaStatus.state === 'success') {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const thesesMeta = thesesMetaStatus.data!.properties

      const validUntilOptions = thesesMeta.validUntil.values
        .filter(_.isString)
        .sort((a, b) => b.localeCompare(a))
        .map(value => ({ key: value, value: value, text: value }))
      setValidUntilOptions(validUntilOptions)

      const specializationOptions = thesesMeta.specialization.values
        .filter(_.isString)
        .sort((a, b) => a.localeCompare(b))
        .map(value => ({ key: value, value: value, text: value }))
      setSpecializationOptions(specializationOptions)
    }
  }, [thesesMetaStatus.state])

  const [thesisStateOptions, setThesisStateOptions] = useState<OptionType[]>([])
  const [thesisStates, getThesisStates] = useThesisStates()

  // TODO: use searchString to load only relevant thesis states
  useEffect(() => {
    if (thesisStates.state === 'not-initiated') {
      void getThesisStates()
    } else if (thesisStates.state === 'success') {
      const mainStateOptions = (thesisStates.data?.mainState ?? [])
        .map(state => ({
          key: state,
          value: state,
          text: l.thesis.mainState[state],
          extraClassNames: [statesFilterStyle.mainState, stateToClassMap[state]].filter(_.isString),
        }))
      const statesOptions = (thesisStates.data?.states ?? [])
        .map(state => {
          const derivedMainState = deriveMainState(state)
          return ({
            key: state,
            value: state,
            text: `${l.thesis.mainState[derivedMainState]} – ${l.thesis.substate[state]}`,
            extraClassNames: [statesFilterStyle.subState, stateToClassMap[derivedMainState]].filter(_.isString),
          })
        })
      const thesisStateOptions = [...mainStateOptions, ...statesOptions]
        .sort((a, b) => {
          const mainStateValues = Object.values(ThesisMainState)
          const derivedMainStateOfA = deriveMainState(a.value)
          const derivedMainStateOfB = deriveMainState(b.value)

          // primarily sort by main state (semantically)
          if (derivedMainStateOfA !== derivedMainStateOfB) {
            return mainStateValues.indexOf(derivedMainStateOfA) - mainStateValues.indexOf(derivedMainStateOfB)
          }

          // secondarily sort by translation in user's locale (alphabetically)
          return a.text.localeCompare(b.text)
        })
        .filter(option => Enum.isType(DisplayedOfficerThesesStates, deriveMainState(option.value)))
      setThesisStateOptions(thesisStateOptions)
    }
  }, [thesisStates.state, currentLang])

  // TODO: use searchString to load only relevant studyDegrees
  const [studyDegreeOptions, setStudyDegreeOptions] = useState<OptionType[]>([])
  const [studyDegrees, getStudyDegrees] = useStudyDegrees()

  useEffect(() => {
    if (studyDegrees.state === 'not-initiated') {
      void getStudyDegrees()
    } else if (studyDegrees.state === 'success') {
      const studyDegreeOptions = (studyDegrees.data ?? [])
        .sort((a, b) => {
          return a.localeCompare(b)
        })
        .map(studyDegree => ({
          key: studyDegree,
          value: studyDegree,
          text: l.thesis.thesisTypeOptionInitialism[studyDegree],
        }))
      setStudyDegreeOptions(studyDegreeOptions)
    }
  }, [studyDegrees.state, currentLang])

  // prepare default state - all options checked
  defaultStateFullValues.filters.splice(0)
  const keyMap: {[key: string]: { id: string, value: Array<string | number> }} = {
    type: {
      id: 'studyDegree',
      value: [...studyDegreeOptions.values()].map(x => x.value),
    },
    specialization: {
      id: 'states',
      value: [...specializationOptions.values()].map(x => x.value),
    },
    mainState: {
      id: 'states',
      value: Object.values(DisplayedOfficerThesesStates),
    },
  }
  defaultState.filters.forEach(f => {
    defaultStateFullValues.filters.push(keyMap[f.id] ?? {
      id: f.id,
      value: f.value as string,
    })
  })

  const history = useHistory()
  const currentPage = history.location.pathname.replace(/\/$/, '')
  const query = new URLSearchParams(history.location.search)
  const [fullTextSearchQuery, setFullTextSearchQuery] = useState(query.get('q') ?? '')

  const [tableData, setTableData] = useState<Thesis[]>([])
  const searchString = history.location.search
  const initialState = searchString ? stateFromSearchString(searchString) : { ...defaultState }
  const [tableState, setTableState] = useState<TState>(initialState)
  const params = apiParametersFromLocationSearch(searchString)
  const [thesesState, isLastPage, getNextPage] = usePaginatedTheses(params)
  const exportCsvUrl = getThesesExportCsvUrl(params)
  const [shouldOverrideState, setShouldOverrideState] = useState(false)
  const [isLoadingMore, setIsLoadingMore] = useState(false)

  useEffect(() => {
    if (thesesState.state === 'success') {
      setIsLoadingMore(false)
    }
  }, [thesesState.state])

  useEffect(() => {
    setTableData(thesesState.data)
  }, [thesesState.data])

  // this handles the initial render and language change rerenders
  useEffect(() => {
    if (thesesState.state === 'not-initiated') {
      getNextPage()
    }
  }, [thesesState.state, currentLang])

  // history navigation handing
  useEffect(() => {
    // history.listen returns reference to unlisten() -> clean up at exit
    return history.listen((location, action) => {
      // set fulltext search field's value from search string on page open
      if (action === 'POP') {
        const query = new URLSearchParams(location.search)
        setFullTextSearchQuery(query.get('q') ?? '')
      } else if (action === 'PUSH') {
        const newInitialState = stateFromSearchString(location.search)
        setTableState({ ...newInitialState })
      }

      // while history navigation occurs on this page (search string changes only),
      // load data matching state in search string
      // FIXME: table state does not change, thus filters are not updated (fix planned)
      const isSamePage = location.pathname.replace(/\/$/, '') === currentPage
      if (isSamePage) {
        const newTableState = stateFromSearchString(location.search)
        setTableState(newTableState)
        setShouldOverrideState(true)
      }
    })
  }, [history])

  useEffect(() => {
    const newInitialState = stateFromSearchString(history.location.search)
    setTableState(newInitialState)
  }, [])

  useEffect(() => {
    if (shouldOverrideState) {
      setShouldOverrideState(false)
    }
  })


  const filterMethod: FilterType<Thesis> = (rows, ids, filterValue: string[]) => rows.filter(
    row => filterValue.length > 0 ? filterValue.includes(row?.values[ids[0]] ?? '') : true
  )

  const ThesisTypeCell = ({ row }: CellProps<Thesis>) => (
    <>
      {l.thesis.thesisTypeOptionInitialism[(row.original.specialization as Specialization)?.studyDegree] ?? ''}
    </>
  )

  const ThesisTypeFilter = ({ column }: FilterProps<Thesis>) => (
    <MultiselectFilter
      column={column}
      options={studyDegreeOptions}
    />
  )


  const ThesisLanguageFilter = ({ column }: FilterProps<Thesis>) => {
    const languageOptions: OptionType[] = Enum.keys(ThesisLanguage).map(language => ({
      key: language,
      value: ThesisLanguage[language],
      text: l.thesis.languageOptions[ThesisLanguage[language]],
    }))

    return (
      <MultiselectFilter
        column={column}
        options={languageOptions}
      />
    )
  }

  const StudySpecializationCell = ({ row }: CellProps<Thesis>) => (
    <>
      {row?.original?.specialization?.code ?? ''}
    </>
  )

  const StudySpecializationFilter = ({ column }: FilterProps<Thesis>) => (
    <MultiselectFilter
      column={column}
      options={specializationOptions}
    />
  )

  const ThesisTitleCell = ({ row }: CellProps<Thesis>) => {
    const thesisTitle: string = currentLang === 'cs' ? row.original.titleCs : row.original.titleEn

    return (
      <Link to={pagePaths.theses.view(row.original.id)} title={l.thesesList.visitThesis(thesisTitle)}>
        {thesisTitle}
      </Link>
    )
  }

  const StateFilter = ({ column }: FilterProps<Thesis>): JSX.Element => (
    <ThesisStatesFilter
      column={column}
      options={thesisStateOptions}
    />
  )

  const StatesCell = ({ row }: CellProps<Thesis>) => {
    return <ThesisStateRenderer row={row} withSubstates />
  }

  const ClosedQuestionFilter = ({ column }: FilterProps<Thesis>) => (
    <SelectFilter
      column={column}
      options={[
        { key: 'all', value: '', text: l.misc.all },
        { key: 'true', value: 'true', text: l.misc.yes },
        { key: 'false', value: 'false', text: l.misc.no },
      ]}
    />
  )

  const VerifiedByFtOfficerCell = ({ row }: CellProps<Thesis>) => {
    const [updateStatus, updateThesis] = useUpdateThesis()

    function handleChange (e: ChangeEvent<HTMLInputElement>) {
      void updateThesis(row.original.id, { verifiedByFtOfficer: e.currentTarget.checked })
    }

    useEffect(() => {
      if (updateStatus.state === 'success') {
        reloadPage()
        addNotification({
          type: 'POSITIVE',
          message: l.successMessages.api.defaultPatch(l.thesis.thesis.toLocaleLowerCase(), row.original.id.toString()),
        })
      }
    }, [updateStatus.state])

    const isActive = row.original.mainState === ThesisMainState.Assigned

    return (
      <Switch
        inputProps={{
          id: `switch${row.original.id}`,
          checked: row.original.verifiedByFtOfficer,
          onChange: isActive ? handleChange : () => null,
          disabled: !isActive,
        }}
        label={row.original.verifiedByFtOfficer ? l.misc.yes : l.misc.no}
      />
    )
  }

  const ValidUntilFilter = ({ column }: FilterProps<Thesis>) => (
    <MultiselectFilter
      column={column}
      options={validUntilOptions}
    />
  )

  const ApprovedAtCell = ({ row }: CellProps<Thesis>) => {
    const date = row.original.approvedAt ? new Date(row.original.approvedAt) : null
    const tooltip = l.thesis.approvalDate

    return date && (
      <time dateTime={date.toISOString()} title={tooltip} aria-label={tooltip}>
        {date.toLocaleDateString(currentLang)}
      </time>
    )
  }

  const ActionsCell = ({ row }: CellProps<Thesis>) => (
    row.original.mainState === ThesisMainState.Draft
      ? (
        <Link
          to={pagePaths.theses.edit(row.original.id)}
          className={thesesTableStyle.editThesis}
          title={l.thesesList.editThesis}
        >
          {/* Detail */}
        </Link>
      )
      : ''
  )


  // FIXME: This is a horrible workaround and UX disaster to rerender row on
  // Thesis state change (OfficerPrintValidationCell and VerifiedByFtOfficerCell).
  function reloadPage () {
    history.go(0)
  }

  const OfficerPrintValidationCell = ({ row }: CellProps<Thesis>): JSX.Element | null => {
    const { showModal } = useModals()
    const [requestActionStatus, sendActionRequest] = useThesisActions(row.original.id)
    function handleOfficerPrintValidation () {
      sendActionRequest(ThesisAction.APPROVE_PRINTS)
    }

    useEffect(() => {
      if (requestActionStatus.state === 'success') {
        reloadPage()
        addNotification({
          message: l.thesis.proposeActionsSuccessMessage,
          type: 'POSITIVE',
        })
      }
    }, [requestActionStatus.state])

    const assignmentAction = () => {
      showModal({
        title: l.thesis.officerPrintValidationModal.title,
        cancelButton: {
          caption: l.thesis.officerPrintValidationModal.actionNo,
          focus: true,
        },
        positiveButton: {
          caption: l.thesis.officerPrintValidationModal.actionYes,
          onClick: handleOfficerPrintValidation,
        },
      })
    }

    if (row.original.states.includes('changeable.assigned.submission.awaitsPrintsApproval')) {
      return (
        <button
          type='button'
          className={cx(buttonStyle.fill, thesesTableStyle.tableBtn)}
          onClick={assignmentAction}
        >
          OK
        </button>
      )
    }

    return null
  }

  // TODO: localization
  const columns = useMemo(
    (): Array<Column<Thesis>> => [
      {
        Header: l.thesis.student,
        id: 'assigneeFrozenName',
        accessor: personAccessor('assignee'), // FIXME: change name format
      },
      {
        Header: l.thesis.specialization,
        id: 'specialization',
        accessor: (t: Thesis) => t?.specialization?.code,
        Cell: StudySpecializationCell,
        Filter: StudySpecializationFilter,
        filter: filterMethod,
      },
      {
        Header: l.thesis.title,
        id: 'title',
        // id: currentLang === 'cs' ? 'titleCs' : 'titleEn',
        accessor: (t: Thesis) => currentLang === 'cs' ? t.titleCs : t.titleEn,
        Cell: ThesisTitleCell,
      },
      {
        Header: l.thesis.supervisor,
        id: 'supervisorFrozenName',
        accessor: personAccessor('supervisor'),
      },
      {
        Header: l.thesis.reviewer,
        id: 'reviewerFrozenName',
        accessor: personAccessor('reviewer'),
      },
      {
        Header: l.thesis.status,
        accessor: 'states',
        Cell: StatesCell,
        Filter: StateFilter,
        filter: filterMethod,
      },
      {
        Header: l.thesis.type,
        id: 'type',
        accessor: (t: Thesis) => {
          return (t?.specialization as Specialization)?.studyDegree ?? ''
        },
        Cell: ThesisTypeCell,
        Filter: ThesisTypeFilter,
        filter: filterMethod,
      },
      {
        Header: l.thesis.language,
        id: 'language',
        accessor: (t: Thesis) => l.thesis.languageOptions[t.language],
        Filter: ThesisLanguageFilter,
        filter: filterMethod,
      },
      {
        Header: l.thesesList.verifiedByFtOfficer,
        id: 'verifiedByFtOfficer',
        accessor: 'verifiedByFtOfficer',
        Cell: VerifiedByFtOfficerCell,
        Filter: ClosedQuestionFilter,
      },
      {
        Header: l.thesesList.printApproved,
        id: 'printApproved',
        Cell: OfficerPrintValidationCell,
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: l.thesis.validUntil,
        accessor: 'validUntil',
        Filter: ValidUntilFilter,
        filter: filterMethod,
      },
      {
        Header: l.thesis.approvalDateShort,
        id: 'approvedAt',
        accessor: 'approvedAt',
        Cell: ApprovedAtCell,
        disableFilters: true,
      },
      {
        id: 'actions',
        accessor: 'id', // FIXME: this is workaround, Filter is not visible without this property
        Cell: ActionsCell,
        Filter: <ResetFiltersLink onClick={() => setFullTextSearchQuery('')} />,
        disableSortBy: true,
      },
    ],
    [currentLang, thesisStateOptions, specializationOptions, studyDegreeOptions]
  )

  const addStateToBrowserHistory = (state: TState, searchString: string) => {
    history.push(`${history.location.pathname}${searchString}`, { ...state })
  }

  const handleSearch = (q: string) => {
    setFullTextSearchQuery(q)

    const query = new URLSearchParams(history.location.search)
    query.delete('q')
    q && query.append('q', q)

    const currentSearchString = history.location.search
    const state = stateFromSearchString(query.toString())
    const newSearchString = searchStringFromState(state, q, currentLang)

    if (newSearchString !== currentSearchString) {
      addStateToBrowserHistory(state, newSearchString)
    }
  }

  const ThesesListActions = () => {
    if (!displayNewAssignmentButton && !displayExportCsvButton) {
      return null
    }

    return (
      <div className={thesesTableStyle.thesesListActionsWrapper}>
        {displayExportCsvButton && <ExportCsvLink url={exportCsvUrl} />}
        {displayNewAssignmentButton && <NewAssignmentLink />}
      </div>
    )
  }

  function handleSwitcherFilter (e: ChangeEvent<HTMLInputElement>) {
    const checked = e.currentTarget.checked
    const query = new URLSearchParams(history.location.search)

    if (checked) {
      query.set(withoutReviewerFilterId, 'true')
    } else {
      query.delete(withoutReviewerFilterId)
    }

    history.push(`${history.location.pathname}?${query.toString().replace(/%2C/g, ',')}`)
  }

  function handleLoadMoreClick () {
    getNextPage()
    setIsLoadingMore(true)
  }

  const shouldShowLoadMore = thesesState.state === 'loading' && !isLoadingMore

  return (
    <div className={style.officerThesesTable}>
      <div className={thesesTableStyle.thesesForm}>
        <div className={thesesTableStyle.thesesFormHeader}>
          <div className={thesesTableStyle.thesesFormHeaderLeft}>
            <ThesesListSearch
              handleSearch={handleSearch}
              value={fullTextSearchQuery}
            />
            <SwitcherFilters
              switcherIdList={[withoutReviewerFilterId]}
              onSwitcherChange={handleSwitcherFilter}
            />
          </div>
          <div className={thesesTableStyle.thesesFormHeaderRight}>
            <ThesesListActions />
          </div>
        </div>
        <Table
          columns={columns}
          data={[...tableData]}
          emptyDataMessage={l.thesesList.emptyResultsMessage}
          initialState={tableState}
          isLoadingMore={isLoadingMore}
          loadingStatus={thesesState.state}
          manual
          onStateChange={(state: TState) => {
            const newSearchString = searchStringFromState(state, fullTextSearchQuery, currentLang)
            const currentSearchString = history.location.search

            if (newSearchString !== currentSearchString) {
              addStateToBrowserHistory(state, newSearchString)
            }
          }}
          useControlledState={(state: TableState) => {
            // override filters with state from browser history
            if (shouldOverrideState) {
              state.filters = [...tableState?.filters]
              state.sortBy = [...tableState?.sortBy]
            }

            return state
          }}
        >
          {!(isLastPage || shouldShowLoadMore) && (
            <LoadMore isLoadingMore={isLoadingMore} onClick={handleLoadMoreClick} />
          )}
        </Table>
      </div>
    </div>
  )
}

export default OfficerThesesTable
