import type { ChangeEvent } from 'react'
import { Enum } from 'typescript-string-enums'
import { flatten, zipObjectDeep } from 'lodash'
import { latest as reportTemplate } from '@cvut/profit-theses-common/lib/texts/thesisReports'
import { ThesisReportTexts } from '@cvut/profit-theses-common/lib/texts/thesisReports'
import {
  isSpecialization, ReportCriteriaEvaluation, Specialization, Thesis, ThesisReportPatch, ThesisReportType,
} from '@cvut/profit-api-types/lib/theses'

import Card from '../../components/Card'
import { formatPersonFullName } from '../../utils/person'
import locales, { AvailableLocale } from '../../locale'
import ThesisReportForm, { reportInLocale } from './ThesisReportForm'
import * as styles from './ThesisReportCreate.style'


// ========================================== Report form types

// transforms thesis report section names to form ids
const getReportFormSectionIds = (sections: ThesisReportTexts['sections']) => (
  Object.keys(sections).reduce((acc: string[], id) => {
    // use <section>.value for all sections except summary, .score for summary
    const valueId = id === 'summary' ? 'score' : `${id}.value`
    // use section>.comment for all sections except summary and defenseQuestions - those are strings
    const commentId = ['defenseQuestions', 'summary'].includes(id) ? id : `${id}.comment`
    // key(s) for each section
    const inputParams = {
      choice: [valueId, commentId],
      score: [valueId, commentId],
      plain: [commentId],
    }[sections[id].type]

    return [...acc, ...inputParams]
  }, [])
)

export const ReviewerSectionIds = Enum(...getReportFormSectionIds(reportTemplate.reviewer.cs.sections))
export const SupervisorSectionIds = Enum(...getReportFormSectionIds(reportTemplate.supervisor.cs.sections))
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type ReportFormSectionIds = Enum<typeof SupervisorSectionIds> | Enum<typeof ReviewerSectionIds>

const supervisorCriteriaEvaluations = Object.keys(reportTemplate.supervisor.cs.sections)
const reviewerCriteriaEvaluations = Object.keys(reportTemplate.reviewer.cs.sections)

interface CommonSections {
  assignmentFulfillment: Evaluation
  mainWrittenPart: Evaluation
  nonWrittenPart: Evaluation
  outcomeEvaluation: Evaluation
}

type SupervisorSections = CommonSections & {
  studentActivity: Evaluation,
  studentSelfReliance: Evaluation,
}

type ReviewerSections = CommonSections & {
  defenseQuestions: string,
}

interface CommonFields {
  language?: AvailableLocale
  score?: number
  summary?: string
  templateVersion?: string
}

export type ReportFields = CommonFields & CommonSections & SupervisorSections & ReviewerSections

type Evaluation = ReportCriteriaEvaluation['CriterionEvaluation']

// ========================================== Helper methods

/**
 * empty value is saved as `-1`
 * @internal Exported for testing only!
 */
export const OptionalValue = {
  deserialize: (value?: number): string | undefined => [-1, undefined].includes(value) ? '' : String(value),
  serialize: (value?: string | number): number => Number.isNaN(parseInt(String(value))) ? -1 : parseInt(String(value)),
}

const reportTitle = (
  reportType: ThesisReportType,
  language: AvailableLocale,
) => ({
  supervisor: reportInLocale(language).supervisorReportTitle,
  reviewer: reportInLocale(language).reviewerReportTitle,
})[reportType]

const reportAuthor = (
  thesis: Thesis,
  reportType: ThesisReportType
): string => ({
  supervisor: formatPersonFullName(thesis.supervisorFrozenName),
  reviewer: thesis.reviewerFrozenName ? formatPersonFullName(thesis.reviewerFrozenName) : '',
}[reportType])

const getSpecialization = (
  thesis: Thesis,
  lang: AvailableLocale
): string => {
  if (!thesis.specialization) return ''

  const specialization = thesis.specialization as Specialization

  return { cs: specialization.nameCs, en: specialization.nameEn }[lang]
}

const reportAuthorLabel = (
  reportType: ThesisReportType,
  language: AvailableLocale,
) => ({
  supervisor: reportInLocale(language).supervisorReportNameLabel,
  reviewer: reportInLocale(language).reviewerReportNameLabel,
})[reportType]

const headerData = (
  thesis: Thesis,
  language: AvailableLocale,
  reportType: ThesisReportType,
) => [
  {
    label: locales[language].thesis.thesisType,
    value: thesis.specialization && isSpecialization(thesis.specialization)
      ? locales[language].studyDegree[(thesis.specialization).studyDegree]
      : '',
  },
  {
    label: reportInLocale(language).thesisStudentLabel,
    value: thesis.assigneeFrozenName ? formatPersonFullName(thesis.assigneeFrozenName) : '',
  },
  {
    label: reportAuthorLabel(reportType, language),
    value: reportAuthor(thesis, reportType),
  },
  {
    label: reportInLocale(language).thesisTitleLabel,
    value: { cs: thesis.titleCs, en: thesis.titleEn }[language],
  },
  {
    label: reportInLocale(language).thesisBranchLabel,
    value: getSpecialization(thesis, language),
  },
]

const getChoiceItem = (
  id: string,
  criterion?: Evaluation
) => (
  [
    [`${id}.value`, OptionalValue.deserialize(criterion?.value)],
    [`${id}.comment`, criterion?.comment],
  ]
)

const getScoreItem = (
  id: string,
  defaultValues: ThesisReportPatch,
  criterion?: Evaluation,
) => (
  id === 'summary'
    ? [
      ['score', OptionalValue.deserialize(defaultValues?.score)],
      ['summary', defaultValues?.summary],
    ] : [
      [`${id}.value`, OptionalValue.deserialize(criterion?.value)],
      [`${id}.comment`, criterion?.comment],
    ]
)

const getPlainItem = (
  id: string,
  defaultValues: ThesisReportPatch,
  criterion?: Evaluation,
) => (
  [
    [id, id === 'defenseQuestions'
      ? defaultValues?.defenseQuestions
      : criterion?.comment],
  ]
)

const getTemplateData = (
  templateData: ThesisReportTexts,
  idList: string[],
  defaultValues: ThesisReportPatch,
) => {
  const pathsAndValuesMap = (
    Object.fromEntries(
      flatten(
        idList.map(id => {
          const criterion = defaultValues?.criteriaEvaluation?.[id]

          return ({
            choice: getChoiceItem(id, criterion),
            score: getScoreItem(id, defaultValues, criterion),
            plain: getPlainItem(id, defaultValues, criterion),
          })[templateData.sections[id].type]
        }) as [[k: string], any]
      )
    )
  ) ?? {}

  return {
    ...zipObjectDeep(Object.keys(pathsAndValuesMap), Object.values(pathsAndValuesMap)),
    language: defaultValues.language,
  }
}

const getIdList = (reportType: ThesisReportType) => ({
  supervisor: supervisorCriteriaEvaluations,
  reviewer: reviewerCriteriaEvaluations,
})[reportType]

interface ThesisReportHeaderProps {
  label: string
  value: string
}

const ThesisReportHeader = ({ label, value }: ThesisReportHeaderProps) => (
  <div className={styles.dataRow} key={label}>
    <div className={styles.dataLabel}>{label}:</div>
    <div className={styles.dataValue}>{value}</div>
  </div>
)

interface ThesisReportHeadersProps {
  thesis: Thesis
  reportLanguage: AvailableLocale
  reportType: ThesisReportType
}

const ThesisReportHeaders = ({ thesis, reportLanguage, reportType }: ThesisReportHeadersProps) => (
  <div className={styles.dataList}>
    {headerData(thesis, reportLanguage, reportType).map(({ label, value }) => (
      <ThesisReportHeader label={label} value={value} key={label} />
    ))}
  </div>
)

interface Props {
  thesis: Thesis
  backupKey: string
  reportType: ThesisReportType
  reportLanguage: AvailableLocale
  onSave: (data: ThesisReportPatch) => Promise<void>
  onReportSubmit: () => void
  onReportLanguageChange: (e: ChangeEvent<HTMLSelectElement>) => void
  defaultValues?: ThesisReportPatch
  downloadLink?: string
  onModifiedState?: (isModified: boolean) => void
}

const ThesisReportCreate = (props: Props): JSX.Element => {

  const templateData = reportTemplate[props.reportType][props.reportLanguage]
  const handleSaveReport = async (data: ReportFields) => {

    const commonReportData = {
      language: props.reportLanguage,
      templateVersion: data.templateVersion ?? reportTemplate.version,
      score: OptionalValue.serialize(data.score),
      summary: data.summary,
    }

    const commonCriteriaEvaluationReportData = {
      assignmentFulfillment: {
        // <input type='radio' /> is submitted as string and `setValueAs` and `valueAsNumber` can’t be used here.
        // So we type them from string to number
        value: OptionalValue.serialize(data.assignmentFulfillment?.value),
        comment: data.assignmentFulfillment.comment,
      },
      mainWrittenPart: {
        value: OptionalValue.serialize(data.mainWrittenPart.value),
        comment: data.mainWrittenPart?.comment,
      },
      nonWrittenPart: {
        value: OptionalValue.serialize(data.nonWrittenPart.value),
        comment: data.nonWrittenPart?.comment,
      },
      outcomeEvaluation: {
        value: OptionalValue.serialize(data.outcomeEvaluation.value),
        comment: data.outcomeEvaluation?.comment,
      },
    }

    const supervisorReportData = {
      ...commonReportData,
      criteriaEvaluation: {
        ...commonCriteriaEvaluationReportData,
        studentActivity: {
          value: OptionalValue.serialize(data.studentActivity?.value),
          comment: data.studentActivity?.comment,
        },
        studentSelfReliance: {
          value: OptionalValue.serialize(data.studentSelfReliance?.value),
          comment: data.studentSelfReliance?.comment,
        },
      },
    }

    const reviewerReportData = {
      ...commonReportData,
      criteriaEvaluation: {
        ...commonCriteriaEvaluationReportData,
      },
      defenseQuestions: data.defenseQuestions,
    }

    await props.onSave(({
      supervisor: supervisorReportData,
      reviewer: reviewerReportData,
    })[props.reportType])
  }

  return (
    <Card.ColumnContainer>
      <Card.Column>
        <Card>
          <Card.Header title={reportTitle(props.reportType, props.reportLanguage)} />
          <Card.Content>
            <ThesisReportHeaders {...props} />
            <ThesisReportForm
              {...props}
              templateData={templateData}
              onSave={handleSaveReport}
              defaultValues={getTemplateData(templateData, getIdList(props.reportType), props.defaultValues ?? {})}
            />
          </Card.Content>
        </Card>
      </Card.Column>
    </Card.ColumnContainer>
  )
}

export default ThesisReportCreate
