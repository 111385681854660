import { ReactNode } from 'react'
import FocusTrap from 'focus-trap-react'
import _ from 'lodash'
import { cx } from 'linaria'

import useOnKeyPress from '../../hooks/useOnKeyPress'
import { ModalParameters, useModals } from '.'
import { useLocale } from '../../locale'
import * as styles from './ModalContainer.style'
import * as buttonStyle from '../../components/Button.style'
import { ReactComponent as CloseIcon } from '../../images/icons/Close.svg'


const ModalButtonsRow = (params: ModalParameters) => {
  const { hideModal } = useModals()
  const { l } = useLocale()

  const onButtonClick = (button: { onClick?: () => void }) => () => {
    button.onClick?.()
    params.onClose?.()
    hideModal()
  }

  const hasActionButtons = !!params.negativeButton || !!params.positiveButton

  return (
    <div className={styles.modalButtonsContainer}>
      {params.cancelButton && (
        <span className={styles.modalActionButtonsContainer}>
          <button className={buttonStyle.outlineDark} onClick={onButtonClick(params.cancelButton)}>
            {params.cancelButton.caption ?? l.modals.cancelCaption}
          </button>
        </span>
      )}
      {hasActionButtons && (
        <>
          {params.negativeButton && (
            <span className={styles.modalActionButtonsContainer}>
              <button className={buttonStyle.reject} onClick={onButtonClick(params.negativeButton)}>
                {params.negativeButton.caption ?? l.modals.defaultNegativeCaption}
              </button>
            </span>
          )}
          {params.positiveButton && (
            <span className={styles.modalActionButtonsContainer}>
              <button className={buttonStyle.accept} onClick={onButtonClick(params.positiveButton)}>
                {params.positiveButton.caption ?? l.modals.defaultPositiveCaption}
              </button>
            </span>
          )}
        </>
      )}
    </div>
  )
}


const focusedButtonSelector = (params: ModalParameters) => {
  const className
    = params.negativeButton?.focus ? buttonStyle.reject
    : params.positiveButton?.focus ? buttonStyle.accept
    : undefined
  return className?.replace(/^|\s+/g, '.')
}


interface ModalProps {
  params: ModalParameters
  onClose: () => void
}

const ModalDialog = ({ params, onClose }: ModalProps) => {
  const { l } = useLocale()
  const modalDialogId = 'modal-dialog-title'

  return (
    <FocusTrap focusTrapOptions={{ initialFocus: focusedButtonSelector(params) }}>
      <aside className={styles.modalDialog} role='dialog' aria-labelledby={modalDialogId}>
        <button
          type='button'
          className={styles.closeButton}
          onClick={onClose}
          title={l.modals.closeModal}
          aria-label={l.modals.closeModal}
          tabIndex={-1}
        >
          <CloseIcon className={styles.closeIcon} />
        </button>

        <h1 className={styles.modalTitle} id={modalDialogId}>
          {params.title}
        </h1>

        <div className={styles.modalText}>
          {params.text}
        </div>

        <ModalButtonsRow {...params} />
      </aside>
    </FocusTrap>
  )
}

interface Props {
  children?: ReactNode
}

const ModalContainer = ({ children }: Props): JSX.Element => {
  const { modal, hideModal } = useModals()

  const onClose = () => {
    modal?.onClose?.()
    hideModal()
  }

  useOnKeyPress('Escape', onClose)

  return (
    <>
      {modal && (
        <div className={styles.modalsContainer}>
          <div className={styles.modalsOverlay} />
          <ModalDialog params={modal} onClose={onClose} />
        </div>
      )}
      <div className={cx(styles.blurContainer, modal && styles.blurred)}>
        {children}
      </div>
    </>
  )
}

export default ModalContainer
