import { cx } from 'linaria'
import { Control, Controller } from 'react-hook-form'
import { toDateISOString, dateISOStringDatePart } from '@cvut/profit-utils'

import * as dateStyle from './DateInput.style'
import * as formStyle from './Form.style'


interface Props {
  control: Control
  id: string
  labelText: string
  required?: boolean
}

const DateInput = ({ id, control, labelText, required = false }: Props): JSX.Element => (
  <div>
    <label htmlFor={id} className={cx(formStyle.label, dateStyle.requiredIndicator)}>
      {labelText}
    </label>
    <Controller
      {...{ control }}
      name={id}
      render={({ onChange, onBlur, value }) => (
        <input
          {...{ onBlur, required }}
          className={dateStyle.input}
          onChange={e => onChange({
            ...e,
            target: {
              ...e.target,
              value: toDateISOString(e.target.value),
            },
          })}
          type='date'
          value={dateISOStringDatePart(value)}
        />
      )}
      rules={{ required }}
    />
  </div>
)

export default DateInput
