import { css } from 'linaria'

import { breakpoints, rel } from './styles/dimensions'
import { collapseNavigation, collapseIn, SIDE_NAVIGATION_WIDTH } from './components/CollapseNavigation.style'
import * as colors from './styles/colors'
import * as styleUtils from './styles/utils'


export const main = css`
  display: flex;
  flex: 1;
  flex-basis: 0;
  min-width: 0;
  max-width: 100%;
  margin-left: 0;
  padding-top: ${rel(32)}rem;
  padding-bottom: ${rel(32)}rem;
  background-color: ${colors.backgroundColor[2]};
  transition: none;

  @media screen and (min-width: ${breakpoints.xl}) {
    padding-top: ${rel(44)}rem;
    padding-bottom: ${rel(44)}rem;
  }

  .${collapseNavigation} ~ & {
    @media screen and (min-width: ${breakpoints.xl}) {
      margin-left: 0;
    }
  }

  .${collapseNavigation}.${collapseIn} ~ & {
    @media screen and (min-width: ${breakpoints.xl}) {
      margin-left: ${rel(SIDE_NAVIGATION_WIDTH)}rem;
    }
  }

  & > * {
    ${styleUtils.containerStyle};

    @media screen and (min-width: ${breakpoints.xl}) {
      padding: 0 calc(${rel(32)}rem + env(safe-area-inset-right)) 0 calc(${rel(32)}rem + env(safe-area-inset-left));
    }
  }

  @media print {
    &,
    & > * {
      margin: 0;
      padding: 0;
      background: none;
      border: 0;
    }
  }
`
