import { css } from 'linaria'

import { rel } from '../../styles/dimensions'
import { transitionEasing } from '../../styles/utils'
import * as colors from '../../styles/colors'


export const langDropdown = css`
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  width: ${rel(40)}rem;
  height: ${rel(40)}rem;
  margin-left: ${rel(12)}rem;
  padding: 0;
`

export const curLang = css`
  height: 100%;
  padding: 0;
  background-color: transparent;
  border: 0;
  transform: none;
  cursor: pointer;
  appearance: none;

  svg {
    width: ${rel(20)}rem;
  }
`

export const show = css`
  /* block-no-empty */
`

export const langDropdownMenu = css`
  position: absolute;
  top: 100%;
  right: 0;
  left: 0;
  display: none;
  margin: ${rel(8)}rem auto auto;
  padding: ${rel(6)}rem 0;
  background-color: #fff;
  border-radius: 0 0 ${rel(8)}rem ${rel(8)}rem;
  box-shadow: 0 ${rel(2)}rem ${rel(12)}rem ${colors.boxShadow};
  opacity: 0;

  &.${show} {
    display: block;
    opacity: 1;
    animation: fadeIn 0.35s;
  }

  @keyframes fadeIn {
    0% {
      display: none;
      opacity: 0;
    }

    15% {
      display: block;
    }

    30% {
      opacity: 0.3;
    }

    100% {
      opacity: 1;
    }
  }
`

export const isActive = css`
  /* block-no-empty */
`

export const langDropdownItem = css`
  display: block;
  padding: ${rel(6)}rem;
  line-height: 0;
  text-align: center;
  opacity: 0.7;
  transition: opacity ${transitionEasing}, background-color ${transitionEasing};

  &.${isActive} {
    opacity: 1;
  }

  &:hover {
    background-color: ${colors.backgroundColor[2]};
    opacity: 1;
  }

  svg {
    width: ${rel(20)}rem;
  }
`
