import { css } from 'linaria'

import { backgroundColor, primaryColor } from '../../../styles/colors'
import { rel } from '../../../styles/dimensions'
import { transitionEasing } from '../../../styles/utils'


export const content = css`
  display: grid;
  grid-template:
    "download-icon report-type grade"
    "download-icon author grade"
    / auto 1fr auto;
  padding: ${rel(6)}rem ${rel(8)}rem;
  column-gap: 1rem;
  background-color: ${backgroundColor[2]};
  border-radius: ${rel(6)}rem;
  transition: background-color ${transitionEasing};

  &:hover,
  &:focus,
  &:active {
    text-decoration: none;
    background-color: ${backgroundColor[1]};
  }
`

export const downloadIcon = css`
  grid-area: download-icon;
  align-self: center;
`

export const reportType = css`
  display: flex;
  grid-area: report-type;
  align-items: baseline;
  font-weight: bold;
`

export const author = css`
  grid-area: author;
`

export const score = css`
  grid-area: grade;
  justify-self: end;
  color: ${primaryColor[2]};
`

export const externalLinkIcon = css`
  margin-left: ${rel(8)}rem;
`
