import { css } from 'linaria'

import { fontWeights } from '../../styles/typography'
import { gridColumn } from '../../styles/dimensions'


export const dataList = css`
  margin-top: 0;
`

export const dataLabel = css`
  ${gridColumn('125pt')};
  font-weight: ${fontWeights.bold};
`

export const dataValue = css`
  flex: 0 0 calc(100% - 125pt);
  width: calc(100% - 125pt);
  max-width: calc(100% - 125pt);
  margin-bottom: 0;
`

export const dataRow = css`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 0;
`
