import { Attachment, FileAttachment, Study, Thesis } from '@cvut/profit-api-types/lib/theses'
import { getPropertyWithSuffix } from '@cvut/profit-utils'
import { isEmpty } from 'lodash'

import Card from '../../components/Card'
import AttachmentFileDownloadIcon from './AttachmentFileDownloadIcon'
import AttachmentLink from '../AttachmentLink'
import ThesisPDFButton from './ThesisPDFButton'
import { useLocale } from '../../locale'
import { assert } from '../../utils'
import * as styles from './ThesisSubmitView.style'


const areAllAttachmentFieldsFilled = (attachment: Attachment) => (
  Object.values(attachment).every(field => !!field)
)

const AttachmentAnchor = (props: Attachment) => {
  const { currentLang } = useLocale()

  if (!areAllAttachmentFieldsFilled(props)) {
    return null
  }

  const title = getPropertyWithSuffix(props, 'title', currentLang)
  assert(title, 'Attachment title cannot be empty')
  assert(props.href, 'Attachment href cannot be empty')

  const isDownload = 'filename' in props || undefined

  return isDownload
    ? <AttachmentFileDownloadIcon item={props as FileAttachment} />
    : <AttachmentLink href={props.href} title={title} isDownload={isDownload} />
}

interface AttachmentAnchorsProps {
  attachments: Attachment[]
}

const AttachmentAnchors = ({ attachments }: AttachmentAnchorsProps) => (
  <ul className={styles.attachments}>
    {attachments.map((attachment, index) => (
      'filename' in attachment
        ? (
          <li key={`attachment_${index}`}>
            <AttachmentAnchor {...attachment} />
          </li>
        )
        : null
    ))}
    {attachments.map((attachment, index) => (
      'filename' in attachment
        ? null
        : (
          <li key={`attachment_${index}`}>
            <AttachmentAnchor {...attachment} />
          </li>
        )
    ))}
  </ul>
)

interface Props {
  onDownload: (url: string) => unknown
  thesis: Thesis
}

export const ThesisSubmitViewSidebar = ({ onDownload, thesis }: Props): JSX.Element | null => {
  const { l } = useLocale()
  const submissionLabelLocale = l.thesis.submission.view.label
  const finalText = thesis.files.finalText
  const { attachments, dspaceUrl } = thesis

  if (!thesis.assignee && !finalText && isEmpty(attachments)) {
    return null
  }

  return (
    <Card.Column>
      <aside>
        {finalText && (
          <section className={styles.sidebarSection}>
            <h3>{submissionLabelLocale.thesisFile}</h3>
            <ThesisPDFButton assignee={thesis.assignee as Study} onClick={() => onDownload(finalText)} />
          </section>
        )}
        {!isEmpty(attachments) && (
          <section className={styles.sidebarSection}>
            <h3>{submissionLabelLocale.attachments}</h3>
            <AttachmentAnchors attachments={attachments} />
          </section>
        )}
        {dspaceUrl && (
          <section className={styles.sidebarSection}>
            <AttachmentLink href={dspaceUrl} title={l.thesis.submission.view.label.dspace} />
          </section>
        )}
      </aside>
    </Card.Column>
  )
}

export default ThesisSubmitViewSidebar
