import type { ComponentPropsWithRef } from 'react'
import { cx } from 'linaria'
import type { Except, SetRequired } from 'type-fest'
import { scoreToGrade } from '@cvut/profit-theses-common'

import * as styles from './EvaluationInput.style'
import * as textInputStyles from '../TextInput.style'


const minScore = 0
const maxScore = 100

interface Props {
  labelText: string
  inputProps: SetRequired<Except<ComponentPropsWithRef<'input'>, 'className' | 'min' | 'max' | 'type'>, 'name' | 'id'>
  currentValue?: string
  extraClassNames?: string[]
}

/**
 * Form elements for 'score & grade' functionality.
 * Grade is automatically calculated from score
 */
const EvaluationInput = ({ labelText, inputProps, currentValue, extraClassNames }: Props): JSX.Element => {
  // TODO: Replace required indicator to a dot according to the style guide.
  const requiredIndicator = inputProps.required
    ? <span aria-hidden className={textInputStyles.requiredIndicator}>*</span>
    : null

  const mark = currentValue !== undefined && parseInt(currentValue) >= 0 && scoreToGrade(parseInt(currentValue))

  return (
    <div className={cx(styles.studentMark, ...(extraClassNames ?? []))}>
      <label htmlFor={inputProps.id} className={textInputStyles.label}>
        {requiredIndicator}
        {labelText}
      </label>
      <input
        {...inputProps}
        type='number'
        min={minScore}
        max={maxScore}
        className={textInputStyles.input}
      />
      <div className={styles.grade}>{mark && `(${mark})`}</div>
    </div>
  )
}

export default EvaluationInput
