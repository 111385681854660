import {
  isSpecialization,
  isThesis,
  Language,
  Specialization,
  Thesis,
  ThesisLanguage,
  ThesisReport,
} from '@cvut/profit-api-types/lib/theses'
import { Grade, PersonName } from '@cvut/profit-api-types/lib/common'
import { getPropertyWithSuffix } from '@cvut/profit-utils'
import { Enum } from 'typescript-string-enums'
import { clamp } from 'lodash'
import { strict as assert } from 'assert'


export const CookieKey = Enum({
  AccessToken: 'oauthAccessToken',
  RefreshToken: 'oauthRefreshToken',
  CsrfToken: 'oauthCsrfToken',
})
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type CookieKey = Enum<typeof CookieKey>

export const Role = Enum({
  FT_OFFICER: 'B-18923-REFERENT-SZZ',
  PROPONENT: 'T-PROFIT-18000-ZADAVATEL-ZAVERECNE-PRACE',
  REVIEWER: 'T-PROFIT-18000-OPONENT-ZAVERECNE-PRACE',
  PERSON: 'B-00000-SUMA-OSOBA-CVUT',
  STUDENT: 'B-18000-SUMA-STUDENTI',
  // This role is parametrized, you have to append code of the specialization
  // (e.g. 'T-PROFIT-18000-REFERENT-SPECIALIZACE-BI-SI').
  SPEC_OFFICER_: 'T-PROFIT-18000-REFERENT-SPECIALIZACE',
})
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type Role = Enum<typeof Role>

/**
 * A type of relationship between Person and Thesis.
 */
export const ThesisRel = Enum(
  'ASSIGNEE',
  'REVIEWER',
  'SPEC_OFFICER',
  'SUPERVISOR',
)
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type ThesisRel = Enum<typeof ThesisRel>

export const ReviewerProposalRel = Enum(
  'REVIEWER',
  'PROPONENT',
  'THESIS_SPEC_OFFICER',
  'THESIS_SUPERVISOR'
)
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type ReviewerProposalRel = Enum<typeof ReviewerProposalRel>

export const MediaType = Enum({
  APPLICATION_JSON: 'application/json',
  APPLICATION_PDF: 'application/pdf',
  APPLICATION_XML: 'application/xml',
  TEXT_CSV: 'text/csv',
  TEXT_HTML: 'text/html',
})
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type MediaType = Enum<typeof MediaType>

export const formatPersonFullName = (name: PersonName, username = ''): string => {
  let fullName = ''

  if (name.titlesPre) {
    fullName += `${name.titlesPre} `
  }
  fullName += `${name.firstName} ${name.lastName}`

  if (name.titlesPost) {
    fullName += `, ${name.titlesPost}`
  }
  if (username) {
    fullName += ` (${username})`
  }

  return fullName
}

export function thesisLangToLocale (lang: ThesisLanguage): Language {
  return lang === 'en' ? 'en' : 'cs'
}

export type AssignedThesis = Thesis & {
  // Override type of properties from Thesis.
  assigneeFrozenName: PersonName,
  reviewerFrozenName: PersonName,
  specialization: Specialization,
}

export type ValidThesisReport = ThesisReport & {
  // Override type of property from ThesisReport.
  thesis: AssignedThesis,
}

export function assertValidThesisReport (report: ThesisReport): asserts report is ValidThesisReport {
  assert(isThesis(report.thesis), 'report.thesis must be Thesis, not ThesisRef')
  assert(report.thesis.assigneeFrozenName, 'report.thesis.assigneeFrozenName must be defined')
  assert(report.thesis.reviewerFrozenName, 'report.thesis.reviewerFrozenName must be defined')
  assert(report.thesis.specialization && isSpecialization(report.thesis.specialization),
    'report.thesis.specialization must be defined and be Specialization, not SpecializationRef')
}

export function extractReportAuthorName (report: Pick<ValidThesisReport, 'thesis' | 'type'>): string {
  return formatPersonFullName(getPropertyWithSuffix(report.thesis, report.type, 'FrozenName'))
}

const A_CHAR_CODE = 'A'.charCodeAt(0)

export function scoreToGrade (score: number): Grade {
  // s ≥ 90 -> 1, s ≥ 80 -> 2, ..., s ≥ 50 -> 5, s < 50 -> 6
  const grade = clamp(Math.ceil(10 - score / 10), 1, 6)

  // 1-6 -> 'A'-'F'
  return String.fromCharCode(A_CHAR_CODE + grade - 1) as Grade
}

export const doesLookLikeInteger = (value: string): boolean => (
  /^-?(0|[1-9]\d*)$/.test(value)
)

// Modified from:
// https://stackoverflow.com/questions/15900485/correct-way-to-convert-size-in-bytes-to-kb-mb-gb-in-javascript
export const formatFileSize = (bytes: number | string, decimals = 2): string => {
  const sizeInBytes = +bytes
  const units = ['B', 'KiB', 'MiB', 'GiB']
  if (!sizeInBytes) {
    return '0 B'
  }

  const k = 1024
  const dm = Math.max(0, decimals)

  const i = Math.floor(Math.log(sizeInBytes) / Math.log(k))

  return `${parseFloat((sizeInBytes / Math.pow(k, i)).toFixed(dm))} ${units[i]}`
}
