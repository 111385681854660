import { css, cx } from 'linaria'

import * as colors from '../../styles/colors'
import { breakpoints, rel } from '../../styles/dimensions'
import { fontWeights } from '../../styles/typography'
import { headerOffset } from '../../components/CollapseNavigation.style'


export const active = css`
  /* to be used in other selectors */
`

export const userArea = css`
  display: flex;
  align-items: center;
  margin-top: 0;
  margin-bottom: 0;
  margin-left: auto;

  & > * + button {
    margin-left: ${rel(12)}rem;
  }
`

export const userAreaIcon = css`
  max-width: ${rel(40)}rem;
`

export const profileIcon = cx(userAreaIcon, css`
  .background {
    fill: ${colors.primaryColor[3]};
  }

  .border {
    fill: ${colors.primaryColor[3]};
    stroke: ${colors.primaryColor[3]};
  }

  .person {
    fill: ${colors.backgroundColor[2]};
  }
`)

export const infoIcon = cx(userAreaIcon, css`
  .background {
    fill: ${colors.primaryColor[3]};
  }

  .bell {
    fill: ${colors.backgroundColor[2]};
  }

  &:hover {
    .background {
      fill: ${colors.primaryColor[3]};
    }

    .bell {
      fill: ${colors.secondaryColor[2]};
    }
  }

  &.${active} {
    .bell {
      fill: ${colors.positiveColor[3]};
    }
  }
`)

export const taskIcon = cx(userAreaIcon, css`
  .backgroundOuter {
    fill: ${colors.primaryColor[3]};
  }

  .backgroundInner {
    fill: ${colors.backgroundColor[2]};
  }

  .exclamationMark {
    fill: ${colors.primaryColor[3]};
  }

  &:hover {
    .backgroundInner {
      fill: ${colors.secondaryColor[2]};
    }
  }

  &.${active} {
    .backgroundInner {
      fill: ${colors.neutralColor[2]};
    }

    .exclamationMark {
      fill: ${colors.white};
    }
  }
`)

export const notificationsPanelContainer = css`
  position: fixed;
  top: ${rel(headerOffset)}rem;
  right: ${rel(16)}rem;
  left: ${rel(16)}rem;
  z-index: 998;

  @media screen and (min-width: ${breakpoints.md}) {
    position: absolute;
    top: 100%;
    right: 0;
    left: auto;
    margin-top: ${rel(8)}rem;
  }
`

export const badgeButton = css`
  display: flex;
  padding: 0;
  background: none;
  border: none;
  cursor: pointer;
`

export const userFullName = css`
  display: none;
  margin: 0 ${rel(8)}rem;
  font-weight: ${fontWeights.normal};
  font-size: ${rel(14)}rem;
  white-space: nowrap;

  @media screen and (min-width: ${breakpoints.md}) {
    display: block;
  }

  @media screen and (min-width: ${breakpoints.xl}) {
    font-size: ${rel(16)}rem;
  }

  & + svg {
    display: none;
    max-width: ${rel(14)}rem;

    @media screen and (min-width: ${breakpoints.md}) {
      display: block;
    }

    @media screen and (min-width: ${breakpoints.xl}) {
      max-width: ${rel(16)}rem;
    }
  }
`

export const dropdownContent = css`
  margin: 0;
  padding-left: 0;
  list-style: none;

  & > * + * {
    margin-top: ${rel(8)}em;
  }
`
