import type { UseRequestStatus } from './useRequest'


interface Props<T> {
  requestStatus: UseRequestStatus<T>
  renderOnLoading: () => JSX.Element | null
  renderOnError: (response?: Response) => JSX.Element | null
  renderOnSuccess: (data: T, response: Response) => JSX.Element | null
}

const RequestDependentRender = <T,>({
  requestStatus, renderOnLoading, renderOnError, renderOnSuccess,
}: Props<T>): JSX.Element | null => {
  switch (requestStatus.state) {
    case 'not-initiated':
      return null
    case 'loading':
      return renderOnLoading()
    // At the moment of writing, we never handle abortion distinctly from an error.
    case 'aborted':
      return renderOnError()
    case 'error':
      return renderOnError(requestStatus.response)
    case 'success':
      // Currently `makeRequest<T>` returns `T | undefined` however we certainly know that
      // the back end will return some data. We cannot remove `undefined` from the return
      // type of `makeRequest` because it is difficult to type it without runtime information.
      //
      // tl;dr: `getStatus.data` is not `undefined` if `getStatus.state === `success`
      // because that's how our API works.
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      return renderOnSuccess(requestStatus.data!, requestStatus.response)
  }
}

export default RequestDependentRender
