import { useLocation } from 'react-router-dom'

import { useLocale } from '../../locale'

interface Props {
  expectedPathPattern: string
}

const InvalidPath = ({ expectedPathPattern }: Props): JSX.Element => {
  const { l } = useLocale()
  const { pathname } = useLocation()

  return (
    <div>
      <h2>{l.navigation.invalidPath.title}</h2>
      <p>{l.navigation.invalidPath.currentPath}: <code>{pathname}</code>.</p>
      <p>{l.navigation.invalidPath.correctFormat}: <code>{expectedPathPattern}</code>.</p>
    </div>
  )
}

export default InvalidPath
