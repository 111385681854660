import { MouseEvent, ReactNode, useState, useRef } from 'react'
import { cx } from 'linaria'

import useOnClickOutside from '../hooks/useOnClickOutside'
import useOnKeyPress from '../hooks/useOnKeyPress'
import * as style from './DropdownButton.style'
import { ReactComponent as ArrowDownIcon } from '../images/icons/ArrowDown.svg'
import { ReactComponent as ArrowUpIcon } from '../images/icons/ArrowUp.svg'


interface Props {
  htmlId: string
  buttonContent: JSX.Element
  dropdownRight?: boolean
  mode?: 'dark' | 'light'
  children: ReactNode
}

const DropdownButton = ({ htmlId, buttonContent, dropdownRight, mode = 'dark', children }: Props): JSX.Element => {
  const [isOpen, setIsOpen] = useState(false)
  const nodeRef = useRef<HTMLDivElement | null>(null)

  const handleCloseDropdown = () => {
    setIsOpen(false)
  }
  useOnClickOutside(nodeRef, handleCloseDropdown)
  useOnKeyPress('Escape', handleCloseDropdown)

  const handleDropdownToggle = (evt: MouseEvent<HTMLButtonElement>) => {
    evt.preventDefault()
    setIsOpen(!isOpen)
  }

  const dropIcon = isOpen ? <ArrowUpIcon /> : <ArrowDownIcon />

  const contentClasses = [style.dropdownButtonContent]
  if (dropdownRight) {
    contentClasses.push(style.dropdownButtonContentRight)
  }

  return (
    <div ref={nodeRef} className={style.dropdownButtonWrapper}>
      <button
        type='button'
        onClick={handleDropdownToggle}
        aria-haspopup
        aria-expanded={isOpen}
        id={htmlId}
        className={cx(style.dropdownButton, mode === 'dark' ? style.themeDark : style.themeLight)}
      >
        {buttonContent}
        {dropIcon}
      </button>
      {isOpen ? (
        <div aria-labelledby={htmlId} className={cx(...contentClasses)}>
          {children}
        </div>
      ) : null}
    </div>
  )
}

export default DropdownButton
