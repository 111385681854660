import { ChangeEvent, ForwardedRef, forwardRef, useEffect, useRef, useState } from 'react'
import { FieldError, useForm, UseFormMethods } from 'react-hook-form'
import { curry, isEmpty, pick } from 'lodash'
import { cx } from 'linaria'
import { FileAttachment, LinkAttachment, Study, Thesis } from '@cvut/profit-api-types/lib/theses'
import { definitions } from '@cvut/profit-api-types/schema/theses.json'

import { addNotification, ToastNotificationType } from '../toastNotifications'
import { useUploadQueue } from '../../api/theses'
import Attachments from './Attachments'
import Hint from '../../components/form/Hint'
import ModificationIndicator from '../../components/form/ModificationIndicator'
import AttachmentsUploadPanel from '../../features/thesis/AttachmentsUploadPanel'
import TextInput from '../../components/form/TextInput'
import ThesisPDFButton from './ThesisPDFButton'
import { thesisSubmitData, thesisSubmitMetadata } from './common'
import { useLocale } from '../../locale'
import { useModals } from '../modals'
import * as buttonStyle from '../../components/Button.style'
import * as formStyle from '../../components/form/Form.style'
import * as style from './ThesisSubmitForm.style'


export type ThesisSubmitMetadata = Pick<Thesis, typeof thesisSubmitMetadata[number]>
export type FormData = Pick<Thesis, typeof thesisSubmitData[number]>

const areAllMetadataFilled = (values: (string | string[] | null | undefined)[]) => (
  values.every(value => (value ?? '').length > 0)
)

const areAllAttachmentsFilled = (attachments: LinkAttachment[]) => attachments.every(attachment => (
  Object.values(attachment).every(field => !!field)
  && (
    'filename' in attachment
      ? ((attachment as FileAttachment).currentSize === (attachment as FileAttachment).totalSize)
        && ((attachment as FileAttachment).totalSize > 0)
      : true)
))

interface FormControlsProps {
  canSave: boolean
  onDiscardChanges?: () => void
  onSubmit?: () => void
}

const FormControls = ({ canSave, onDiscardChanges, onSubmit }: FormControlsProps) => {
  const { l } = useLocale()
  const { showModal } = useModals()

  const handleSubmit = () => showModal({
    title: l.thesis.submission.form.modal.title,
    text: l.thesis.submission.form.modal.description,
    cancelButton: {
      focus: true,
    },
    positiveButton: {
      caption: l.misc.yes,
      onClick: onSubmit,
    },
  })

  return (
    <div className={formStyle.controls}>
      <div className={formStyle.controlsLeft}>
        <button
          type='button'
          disabled={!onDiscardChanges}
          onClick={onDiscardChanges}
          className={buttonStyle.outlineDark}
        >
          {l.form.discardChanges}
        </button>
      </div>
      <div className={formStyle.controlsRight}>
        <button
          type='submit'
          disabled={!canSave}
          className={buttonStyle.fill}
        >
          {l.form.save}
        </button>
        <button
          type='button'
          disabled={!onSubmit}
          onClick={handleSubmit}
          className={buttonStyle.action}
        >
          {l.thesis.submission.form.submitButton}
        </button>
      </div>
    </div>
  )
}

interface ThesisFileInputProps {
  onChange: (e: ChangeEvent<HTMLInputElement>) => void
}

const ThesisFileInput = forwardRef<HTMLInputElement, ThesisFileInputProps>(({ onChange }, ref) => {
  const { l } = useLocale()

  return (
    <div className={style.fileInputWrapper}>
      <label htmlFor='thesisSubmitThesisFile' className={style.label}>
        {l.thesis.submission.form.label.files.finalText}
      </label>
      <input
        type='file'
        id='thesisSubmitThesisFile'
        accept='application/pdf'
        className={style.file}
        ref={ref}
        onChange={onChange}
      />
      <Hint hint={l.thesis.submission.form.help.thesisFile} />
    </div>
  )
})
ThesisFileInput.displayName = 'ThesisFileInput'

// FIXME , or ; ? find how it is rendered, find where it is beign split elsewhere
type InputType = HTMLTextAreaElement | HTMLInputElement

type TextInputWrapperProps = {
  id: keyof ThesisSubmitMetadata,
  error?: FieldError,
  helpText?: string,
  multiline?: boolean,
}

const TextBox = forwardRef<InputType, TextInputWrapperProps>(({
  id, error, helpText, multiline,
}, ref) => {
  const { l } = useLocale()
  // FIXME unify me, if you can
  const textInput = multiline
    ? (
      <TextInput
        labelText={l.thesis.submission.form.label.inputs[id]}
        inputProps={{
          name: id,
          id: id,
          ref: ref as ForwardedRef<HTMLTextAreaElement>,
          rows: 8,
        }}
        extraClassNames={[formStyle.formInput, style.inputRequired]}
        multiline
      />
    ) : (
      <TextInput
        labelText={l.thesis.submission.form.label.inputs[id]}
        inputProps={{
          name: id,
          id: id,
          ref: ref as ForwardedRef<HTMLInputElement>,
        }}
        extraClassNames={[formStyle.formInput, style.inputRequired]}
      />)

  return (
    <div>
      {textInput}
      <Hint error={error?.message} hint={helpText} />
    </div>
  )
})
TextBox.displayName = 'TextBox'

interface AbstractInputsProps {
  register: UseFormMethods['register']
  errors: {
    abstractCs?: FieldError,
    abstractEn?: FieldError,
  }
}

const renderTextBox = curry((
  commonProps: object,
  errors: {[k: string]: FieldError | undefined},
  id: keyof ThesisSubmitMetadata
) => (
  <TextBox {...commonProps} error={errors[id]} id={id} key={id} />
))

const AbstractInputs = ({ register, errors }: AbstractInputsProps) => {
  const { l } = useLocale()
  const maxLength = definitions.Thesis.properties.abstractCs.maxLength

  const commonAbstractInputProps = {
    multiline: true,
    ref: register({
      maxLength: {
        value: maxLength,
        message: l.form.validation.maxLength(maxLength),
      },
    }),
  }

  return (
    <div className={style.fieldGroup}>
      {(['abstractCs', 'abstractEn'] as const).map(renderTextBox(commonAbstractInputProps, errors))}
    </div>
  )
}

interface KeywordsInputsProps {
  register: UseFormMethods['register']
  errors: {
    keywordsCs?: FieldError,
    keywordsEn?: FieldError,
  }
}

const KeywordsInputs = ({ register, errors }: KeywordsInputsProps) => {
  const { l } = useLocale()
  const maxKeywordsCount = definitions.Thesis.properties.keywordsCs.maxItems
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const maxKeywordLength = definitions.Thesis.properties.keywordsCs.anyOf[0]!.items!.maxLength

  // throw away empty words and multiple separators
  const splitIntoArray = (value: string) => (
    // TODO: _.compact()
    value.trim().split(/\s*,\s*/).filter(kw => kw.length > 0)
  )

  const commonKeywordsInputProps = {
    ref: register({
      setValueAs: splitIntoArray,
      validate: {
        maxKeywordsCount: (v: string[]) => (
          v.length <= maxKeywordsCount || l.form.validation.maxKeywordsCount(maxKeywordsCount)
        ),
        maxKeywordLength: (v: string[]) => (
          v.every(k => k.length <= maxKeywordLength) || l.form.validation.maxLength(maxKeywordLength)
        ),
      },
    }),
    helpText: l.thesis.submission.form.help.keywords,
  }

  return (
    <div className={style.fieldGroup}>
      {(['keywordsCs', 'keywordsEn'] as const).map(renderTextBox(commonKeywordsInputProps, errors))}
    </div>
  )
}

interface Props {
  thesis: Thesis
  onDownload: (url: string) => unknown
  onSave: (id: Thesis['id'], data: any) => Promise<unknown>
  onReloadRequired: () => void
  onSubmit: () => void
  onUpload: (data: Blob) => Promise<void>
  onModifiedState?: (isModified: boolean) => void
}

const ThesisSubmitForm = ({
  thesis, onDownload, onSave, onReloadRequired, onSubmit, onUpload, onModifiedState,
}: Props): JSX.Element => {
  const { l } = useLocale()
  // FIXME - replace with checking test from the hook
  const [isFileSelected, setIsFileSelected] = useState(false)
  const fileInputRef = useRef<HTMLInputElement>(null)
  const {
    addUploadItem, getQueuedFile, queue, queueState, removeUploadItem, startUpload,
  } = useUploadQueue(thesis.id)

  const defaultValues = pick(thesis, thesisSubmitData)

  const {
    control, errors, formState, handleSubmit, register, setValue, trigger, watch,
  } = useForm<FormData>({
    defaultValues, mode: 'all',
  })

  const isThesisFileUploaded = !!thesis.files.finalText
  const hasDirtyFields = !isEmpty(formState.dirtyFields)
  const isFormModified = hasDirtyFields || isFileSelected
  const formValues = watch()

  useEffect(() => {
    onModifiedState?.(isFormModified && !formState.isSubmitting)
  }, [isFormModified, formState.isSubmitting])

  const handleThesisUpload = async (fileInput: HTMLInputElement) => {
    const files = Array.from(fileInput.files ?? []).slice(0, 1)

    return await Promise.all(files.map(async file => (
      await onUpload(file)
    )))
  }

  const handleFileAttachmentsUpload = async () => {
    if (queue.length === 0) {
      return
    }

    let type: ToastNotificationType

    // FIXME - talks nonsense (files successfully uploaded when the upload failed)
    try {
      await startUpload()
      type = 'POSITIVE'
    } catch {
      type = 'NEGATIVE'
    }

    const message = type === 'POSITIVE'
      ? l.thesis.submission.successMessages.upload
      : l.thesis.submission.errorMessages.uploadFailure

    addNotification({ type, message })
  }

  const resultMessage = (type: 'POSITIVE' | 'NEGATIVE') => ({
    POSITIVE: l.successMessages,
    NEGATIVE: l.errorMessages,
  }[type]).api.defaultPatch(l.thesis.thesis.toLocaleLowerCase(), String(thesis.id))

  const processOnSave = async (data: FormData) => {
    let type: ToastNotificationType

    try {
      const isEmptyingAttachments = 'attachments' in formState.dirtyFields && !data.attachments
      await onSave(thesis.id, isEmptyingAttachments
        ? { ...data, attachments: [] }
        : data)
      type = 'POSITIVE'
    } catch {
      type = 'NEGATIVE'
    }

    addNotification({ type, message: resultMessage(type) })

    return type === 'POSITIVE'
  }

  const handleFormSave = async (data: FormData) => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    await handleThesisUpload(fileInputRef.current!)

    const savedSuccessfully = await processOnSave(data)
    if (savedSuccessfully) {
      await handleFileAttachmentsUpload()
      onReloadRequired()
    }
  }

  const handleFileSelect = (e: ChangeEvent<HTMLInputElement>) => {
    setIsFileSelected((e.currentTarget.files ?? []).length > 0)
  }

  const handleDiscardChanges = () => {
    onReloadRequired()
  }

  const canSubmit = formState.isValid
    && !isFormModified
    && isThesisFileUploaded
    && areAllMetadataFilled(Object.values(pick(formValues, thesisSubmitMetadata)))
    && areAllAttachmentsFilled(formValues.attachments ?? [])

  const canSave = (isFileSelected || (hasDirtyFields && formState.isValid) || queue.length > 0)
    && queueState !== 'running'

  return (
    <form onSubmit={handleSubmit(handleFormSave)} className={formStyle.form} noValidate>
      <AbstractInputs {...{ register, trigger }} errors={pick(errors, 'abstractCs', 'abstractEn')} />
      <KeywordsInputs {...{ register, trigger }} errors={pick(errors, 'keywordsCs', 'keywordsEn')} />
      <div className={cx(style.inputRequired, style.fileWrapper)}>
        <ThesisFileInput onChange={handleFileSelect} ref={fileInputRef} />
        {isThesisFileUploaded && (
          <div className={style.fileButtonWrapper}>
            {/* user can get here only with an assigned thesis and uploaded thesis file */}
            {/* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */}
            <ThesisPDFButton assignee={thesis.assignee as Study} onClick={() => onDownload(thesis.files.finalText!)} />
          </div>
        )}
      </div>
      {queueState === 'running'
        ? <AttachmentsUploadPanel uploadQueue={queue} />
        : (
          <Attachments
            {...{
              control,
              errors,
              register,
              addUploadItem,
              getQueuedFile,
              removeUploadItem,
              setValue,
              watch,
            }}
          />
        )}
      <FormControls
        canSave={canSave}
        onDiscardChanges={canSave ? handleDiscardChanges : undefined}
        onSubmit={canSubmit ? onSubmit : undefined}
      />
      <ModificationIndicator isModified={isFormModified} />
      <Hint hint={l.thesis.submission.form.modal.requiredInfo} />
    </form>
  )
}

export default ThesisSubmitForm
