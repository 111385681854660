import { apiPrefix } from '../config'
import { createRequestor } from '../api/makeRequest'
import { UseRequestStatus, useRequest } from '../api/useRequest'
import { useLocale } from '../locale'


const authApiPrefix = `${apiPrefix}/auth`
export const loginUri = `${authApiPrefix}/login`
const authRequestor = createRequestor(authApiPrefix)

/**
 * Exchanges authorization code for an access token from OAAS.
 * */
export function useGetToken (): [
  UseRequestStatus<void>,
  (code: string, state: string) => void,
] {
  const { l } = useLocale()

  const [requestStatus, sendRequest] = useRequest<void>(authRequestor, {
    defaultErrorMessage: () => l.auth.errorRetrievingOAuthToken,
  })

  return [requestStatus, (code, state) => {
    void sendRequest('GET', 'token', {
      code,
      state,
    })
  }]
}

export function useLogout (): [
  UseRequestStatus<void>,
  () => void,
] {
  const { l } = useLocale()

  const [requestStatus, sendRequest] = useRequest<void>(authRequestor, {
    defaultErrorMessage: () => l.auth.errorLoggingOut,
  })

  return [requestStatus, () => {
    void sendRequest('POST', 'logout')
  }]
}

const redirectToKeyName = 'redirectTo'
export const authRedirectToPathUtils = {
  set: (path: string): void => window.sessionStorage.setItem(redirectToKeyName, path),
  get: (): string | null => window.sessionStorage.getItem(redirectToKeyName),
  clear: (): void => window.sessionStorage.removeItem(redirectToKeyName),
}

function goToAuth (currentPath?: string): void {
  if (currentPath) {
    authRedirectToPathUtils.set(currentPath)
  }

  const form = document.createElement('form')
  form.method = 'post'
  form.action = loginUri
  form.id = 'auth-redirect-form' // FIXME: Probably redundant.
  document.body.append(form)
  form.submit()

  document.body.removeChild(form) // FIXME: Probably redundant.
}

export function goToAuthSaveCurrentLocation (): void {
  goToAuth(window.location.pathname + window.location.search + window.location.hash)
}
