import { css } from 'linaria'

import { breakpoints, gridColumn, rel } from '../../styles/dimensions'
import * as colors from '../../styles/colors'


const ACTIVE_COLOR = `${colors.assignedColor}`

export const navigation = css`
  position: relative;
`

export const active = css`
  /* not empty */
`

export const dropdown = css`
  position: relative;
  display: none;

  .${active} > & {
    display: block;
  }
`

export const dropdownToggle = css`
  position: relative;
`

export const userHelpDivider = css`
  * + & {
    margin-top: ${rel(44)}rem;
  }
`

export const userHelpLink = css`
  /* not empty */
`

// FIXME: Refactor into separate style for each icon.
export const navigationLinks = css`
  align-items: center;
  margin: 0;
  padding-left: 0;
  list-style: none;

  @media screen and (min-width: ${breakpoints.xl}) {
    position: static;
    display: flex;
    flex-wrap: wrap;
  }

  li {
    ${gridColumn('100%')};
    padding: ${rel(6)}rem ${rel(10)}rem;

    a {
      display: flex;
      align-items: center;
      padding: ${rel(10)}rem ${rel(16)}rem;
      color: ${colors.primaryColor[2]};
      font-size: ${rel(16)}rem;
      line-height: ${rel(24)}rem;
      text-decoration: none;
      border-radius: ${rel(8)}rem;

      :not(.${active}):hover,
      :not(.${active}):focus-visible,
      :not(.${active}):active {
        background-color: ${colors.backgroundColor[2]};
      }

      &.${active} {
        color: ${ACTIVE_COLOR};
        background-color: ${colors.backgroundColor[2]};

        svg {
          .background {
            fill: none;
          }

          .plus {
            stroke: ${ACTIVE_COLOR};
          }

          .home {
            .house {
              stroke: ${ACTIVE_COLOR};
            }

            .door {
              fill: ${ACTIVE_COLOR};
            }
          }

          .person {
            stroke: ${ACTIVE_COLOR};
          }

          .search {
            stroke: ${ACTIVE_COLOR};
          }
        }
      }
    }

    .${userHelpLink} {
      svg {
        position: relative;
        top: -${rel(4)}rem;
        width: ${rel(8)}rem;
        margin-left: ${rel(4)}rem;
      }
    }
  }

  li.${active} {
    margin-top: -${rel(1)}rem;
    margin-bottom: -${rel(1)}rem;
    padding-bottom: ${rel(3)}rem;
    border-top: ${rel(1)}rem solid ${colors.backgroundColor[1]};
    border-bottom: ${rel(1)}rem solid ${colors.backgroundColor[1]};
  }

  li.${active} + li.${active} {
    border-top: ${rel(1)}rem solid ${colors.white};
  }

  ul {
    margin-top: ${rel(3)}rem;
    padding-left: 0;
    list-style: none;

    li {
      padding: ${rel(3)}rem 0;
    }
  }
`

export const linkIcon = css`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${rel(24)}rem;

  /* Forces with to not squash icon on multiline label */
  min-width: ${rel(24)}rem;
  height: ${rel(24)}rem;
  margin-right: ${rel(6)}rem;
  margin-bottom: auto;

  svg {
    transform: scale(1.3);

    /* Default icon settings */
    .background {
      fill: none;
    }

    .plus {
      stroke: ${colors.primaryColor[2]};
    }

    .home {
      .house {
        stroke: ${colors.primaryColor[2]};
      }

      .door {
        fill: ${colors.primaryColor[2]};
      }
    }

    .person {
      stroke: ${colors.primaryColor[2]};
    }

    .search {
      stroke: ${colors.primaryColor[2]};
    }
  }

  .${dropdownToggle} & {
    svg {
      max-width: ${rel(16)}rem;
      transform: none;
    }
  }
`
