import { css } from 'linaria'

import { rel } from '../../../styles/dimensions'
import * as colors from '../../../styles/colors'


export const hint = css`
  color: ${colors.secondaryColor[2]};
  font-size: ${rel(14)}rem;
`

export const error = css`
  color: ${colors.negativeColor[2]};
`
