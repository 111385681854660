import { css } from 'linaria'


export const spinnerOuterWrapper = css`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
`

export const spinnerInnerWrapper = css`
  position: fixed;
  top: 50%;

  /* Stay above the head of table */
  z-index: 10;
`
