import { HeaderProps } from 'react-table'
import { cx } from 'linaria'

import { columnsStyleMap } from './Table'
import { ColumnSortState, getColumnSortState } from './utils'
import { useLocale } from '../../locale'
import * as style from './Header.style'


const SortIcon = <T extends object = {}>({ column }: Pick<HeaderProps<T>, 'column'>) => {
  const { l } = useLocale()
  const curSortState = getColumnSortState(column)
  const isSortedDesc = curSortState === 'DESCENDING'
  const isSortedAsc = curSortState === 'ASCENDING'

  return (
    <div className={style.sortIcon}>
      <div
        className={cx(style.ascendingArrow, isSortedAsc ? style.ascendingArrowActive : null)}
        role='button'
        title={l.thesesList.sortAscending}
        aria-pressed={isSortedAsc}
      />
      <div
        className={cx(style.descendingArrow, isSortedDesc ? style.descendingArrowActive : null)}
        role='button'
        title={l.thesesList.sortDescending}
        aria-pressed={isSortedDesc}
      />
    </div>
  )
}

const HeaderCell = <T extends object = {}>({ column }: Pick<HeaderProps<T>, 'column'>): JSX.Element => {
  const { l } = useLocale()
  const curSortState = getColumnSortState(column)
  const nextSortTitle: Record<ColumnSortState, string> = {
    UNSORTED: l.thesesList.sortAscending,
    ASCENDING: l.thesesList.sortDescending,
    DESCENDING: l.thesesList.sortReset,
  }

  return (
    <th
      {...column.getHeaderProps()}
      className={cx(style.cell, style.thCell, columnsStyleMap[column.id])}
    >
      <div {...column.getSortByToggleProps()} title={nextSortTitle[curSortState]}>
        {column.render('Header')}
        {column.canSort && <SortIcon column={column} />}
      </div>
      {column.canFilter
        ? <div>{column.render('Filter')}</div>
        : null}
    </th>
  )
}

const Header = <T extends object = {}>({ headerGroups }: Pick<HeaderProps<T>, 'headerGroups'>): JSX.Element => (
  <thead>
    {headerGroups.map(headerGroup => ( // eslint-disable-next-line react/jsx-key
      <tr {...headerGroup.getHeaderGroupProps()} className={style.row}>
        {headerGroup.headers.map((column, index) => (
          <HeaderCell column={column} key={index} /> // disabling eslint did not help
        ))}
      </tr>
    ))}
  </thead>
)

export default Header
