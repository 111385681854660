import { cx } from 'linaria'

import * as style from './Spinner.style'


interface DualRingsProps {
  title: string
  color: 'primary' | 'white'
  extraClassNames?: string[]
}

const loaderColor: Record<DualRingsProps['color'], string> = {
  primary: style.dualRingsPrimary,
  white: style.dualRingsWhite,
}

const DualRings = ({ title, color, extraClassNames }: DualRingsProps): JSX.Element => (
  <div
    title={title}
    className={cx(
      loaderColor[color],
      ...(extraClassNames ?? []),
    )}
  />
)

// FIXME: ARIA attributes are announced to the screen reader when their boolean (or whatever) value changes. Hence if a
// spinner is being conditionally rendered, then the screen reader will not observe a change. The spinner has to be
// always rendered and its rendering has to be controlled with a prop which flips a `display` css property and
// appropriate ARIA attributes.
export default {
  DualRings,
}
