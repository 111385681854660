import type { ReactNode } from 'react'

import * as style from './Header.style'


// NOTE: It must be exported due to TS4023 in index.tsx.
export interface Props {
  title: string
  id?: string
  children?: ReactNode
}

/**
 * @param children Control buttons of the card. It can be basically anything, the component only make sure it sticks
 * to the top edge of the card.
 */
const Header = ({ title, id, children }: Props): JSX.Element => (
  <div className={style.header}>
    <h2 id={id}>{title}</h2>
    {children ? (
      <div className={style.controls}>
        {children}
      </div>
    ) : null}
  </div>
)

export default Header
