import { CellProps } from 'react-table'
import _ from 'lodash'
import { Enum } from 'typescript-string-enums'
import { cx } from 'linaria'
import { Thesis, ThesisMainState } from '@cvut/profit-api-types/lib/theses'

import ThesisStateLabel, { stateClassLookup } from '../../ThesisStateLabel'
import ThesisStateDescription from '../../../features/thesis/ThesisStateDescription'
import * as style from './ThesisStateRenderer.style'


type ThesisStateRendererProps = Pick<CellProps<Thesis>, 'row'> & {
  withSubstates?: boolean,
}

const ThesisStateRenderer = ({ row, withSubstates = false }: ThesisStateRendererProps): JSX.Element => {
  const thesis = row.original
  const substates = _.without(thesis.states, ...Enum.values(ThesisMainState))

  return (
    <>
      <ThesisStateLabel state={row.original.mainState} />
      {withSubstates && substates.length > 0 && (
        <ul className={cx(stateClassLookup[thesis.mainState], style.stateList)}>
          {substates.map(state => (
            <ThesisStateDescription key={state} state={state} />
          ))}
        </ul>
      )}
    </>
  )
}

export default ThesisStateRenderer
