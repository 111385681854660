import { css } from 'linaria'

import * as colors from '../../../styles/colors'
import { breakpoints, rel } from '../../../styles/dimensions'
import { fontWeights } from '../../../styles/typography'


const multiselectHeight = `${rel(38)}rem`
const multiselectOpenBackgroundColor = colors.backgroundColor[3]
const multiselectClosedBackgroundColor = colors.backgroundColor[2]
const multiselectBorderColor = colors.backgroundColor[2]


export const basicCheckbox = css`
  color: ${colors.primaryColor[2]};

  .all {
    stroke: ${colors.primaryColor[2]};
  }

  .background {
    fill: ${colors.primaryColor[2]};
    stroke: ${colors.primaryColor[2]};
  }
`

export const openToggle = css`
  position: absolute;
  top: 0;
  right: ${rel(10)}rem;
  padding: 0;
  background: transparent;
  border: none;
  cursor: pointer;

  &:focus {
    outline: none;
  }

  svg {
    max-width: ${rel(16)}rem;
    vertical-align: baseline;
    zoom: 0.75;
  }
`

export const selectLabel = css`
  height: ${multiselectHeight};
  padding: ${rel(6)}rem ${rel(30)}rem ${rel(6)}rem ${rel(10)}rem;
  color: ${colors.primaryColor[2]};
  color: ${colors.secondaryColor[2]};
  font-weight: ${fontWeights.light};
  font-size: ${rel(14)}rem;
  line-height: ${rel(26)}rem;
  white-space: nowrap;
  text-align: right;

  @media screen and (min-width: ${breakpoints.xl}) {
    font-size: ${rel(16)}rem;
  }
`

export const panelContainer = css`
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  min-width: 100%;
  margin: -${rel(1)}rem;
  background: ${multiselectOpenBackgroundColor};
  border: ${rel(1)}rem solid ${multiselectBorderColor};
  border-radius: ${rel(6)}rem;
  box-shadow: 0 ${rel(5)}rem ${rel(5)}rem ${colors.boxShadow};

  & .${selectLabel} {
    color: ${colors.secondaryColor[2]};
  }
`

export const multiselect = css`
  position: relative;
  display: inline-block;
  width: 100%;
  height: ${multiselectHeight};
  overflow: visible;
  font-weight: ${fontWeights.light};
  line-height: ${multiselectHeight};
  background: ${multiselectClosedBackgroundColor};
  border-radius: ${rel(6)}rem;
  cursor: pointer;
  user-select: none;
`

export const optionsListItem = css`
  font-weight: ${fontWeights.normal};
  line-height: ${rel(25)}rem;
  text-align: left;
  border: none;

  svg {
    max-width: 1rem;
    height: auto;
    margin-top: ${rel(3)}rem;
    margin-right: ${rel(5)}rem;
    margin-left: -${rel(25)}rem;
    vertical-align: top;
    pointer-events: none;

    .check {
      stroke: ${colors.white};
    }
  }

  input[type="checkbox"] {
    box-sizing: border-box;
    width: ${rel(16)}rem;
    height: ${rel(16)}rem;
    margin-right: ${rel(9)}rem;
    padding: 0;
    vertical-align: top;
    border-radius: ${rel(5)}rem;
    outline: none;
    cursor: pointer;
    appearance: none;
  }

  label {
    display: inline-block;
    width: calc(100% - ${rel(25)}rem);
    margin-bottom: 0;
    font-size: ${rel(16)}rem;
    line-height: ${rel(20)}rem;
    white-space: nowrap;
    text-align: left;
    text-overflow: ellipsis;
    cursor: pointer;
  }
`

export const optionsList = css`
  z-index: 10;
  max-height: 33vh;
  margin: 0;
  padding: 0 ${rel(8)}rem ${rel(4)}rem;
  padding-top: 0;
  overflow-x: hidden;
  overflow-y: auto;
  color: ${colors.primaryColor[2]};
  font-size: ${rel(16)}rem;
  line-height: 1.5;
  list-style: none;

  .${optionsListItem} {
    /* not empty */
  }
`
