import { addNotification } from '../features/toastNotifications'
import { updateUserSettings } from '../api/me'
import { useUserInfo } from '../session/user-info'
import UserSettings from '../features/userSettings'
import { useLocale } from '../locale'
import PageHeader from '../components/PageHeader'
import Card from '../components/Card'
import Spinner from '../components/Spinner'


const UserSettingsPage = (): JSX.Element => {
  const [userInfo, { setUserInfo }] = useUserInfo()
  const { l } = useLocale()

  // Where is my pattern matching?
  const userSettingsNode = (() => {
    switch (userInfo.state) {
      case 'loading':
        return (
          <Card>
            <Card.Header title={l.userSettings.loading} />
            <Spinner.DualRings title={l.userSettings.loading} color='primary' />
          </Card>
        )
      case 'not-initiated':
      case 'none':
        return (
          <Card>
            <Card.Header title={l.userSettings.noUserSettings} />
            <Spinner.DualRings title={l.userSettings.noUserSettings} color='primary' />
          </Card>
        )
      case 'loaded':
        return (
          <UserSettings
            settings={userInfo.person.settings}
            onSettingsUpdate={async (settings) => {
              // In addition to the actual update, it notifies the user of the result (i.e. success or failure).
              try {
                await updateUserSettings(settings)
              } catch (err) {
                addNotification({
                  type: 'NEGATIVE',
                  message: l.userSettings.requestMessages.error,
                })

                throw err
              }

              addNotification({
                type: 'POSITIVE',
                message: l.userSettings.requestMessages.success,
              })
              setUserInfo({ ...userInfo.person, settings })
            }}
          />
        )
    }
  })()

  return (
    <article>
      <PageHeader title={l.userSettings.title} />
      <Card.Column>
        {userSettingsNode}
      </Card.Column>
    </article>
  )
}

export default UserSettingsPage
