import { cx } from 'linaria'

import * as style from './Badge.style'


const badgePositionStyle = {
  'bottom-left': style.bottomLeft,
  'bottom-right': style.bottomRight,
  'top-left': style.topLeft,
  'top-right': style.topRight,
}

interface Props {
  count: number
  children: JSX.Element
  countMax?: number
  position?: keyof typeof badgePositionStyle
}

const Badge = ({ count, children, countMax, position = 'top-right' }: Props): JSX.Element => {
  if (count <= 0) {
    return children
  }

  const badgeContent = countMax && count > countMax ? `${countMax}+` : `${count}`

  return (
    <div className={style.badgeWrapper}>
      <span className={cx(style.badge, badgePositionStyle[position])}>{badgeContent}</span>
      {children}
    </div>
  )
}

export default Badge
