import { useEffect, useState } from 'react'
import { FilterProps } from 'react-table'
import _ from 'lodash'

import { useLocale } from '../../../locale'
import * as style from './TextFilter.style'
import { typingDelay } from '../../../config'


const INPUT_FIELD_CHARACTERS = 5

interface Props<T extends object> extends Pick<FilterProps<T>, 'column'> {
  // optionally enforce allowed input value format
  validate?: (value: string) => boolean
}

export const TextFilter = <T extends object>({ column, validate }: Props<T>): JSX.Element => {
  const { l } = useLocale()
  const [value, setValue] = useState<string | undefined>(column.filterValue)

  const onChangeDebounced = _.debounce(() => column.setFilter(value), typingDelay)

  useEffect(() => {
    onChangeDebounced()
    return onChangeDebounced.cancel
  }, [value])

  // clear the value state if filterValue is reset (otherwise navigation in history doesn't affect TextFilter)
  useEffect(() => {
    if (column.filterValue !== undefined) {
      setValue(column.filterValue)
    }
  }, [column.filterValue])

  return (
    <input
      value={value ?? ''}
      size={INPUT_FIELD_CHARACTERS}
      onChange={e => {
        const newValue = e.target.value

        if (validate && !validate(newValue)) {
          return false
        }

        setValue(newValue || undefined)
      }}
      placeholder={l.thesesList.filters.search}
      className={style.columnFilterInput}
    />
  )
}
