import { css } from 'linaria'
import { transparentize } from 'polished'

import * as colors from '../styles/colors'
import { breakpoints, rel } from '../styles/dimensions'
import { transitionEasing } from '../styles/utils'


export const SIDE_NAVIGATION_WIDTH = 250
export const headerOffset = 56

export const collapseNavigation = css`
  position: fixed;
  top: ${rel(headerOffset)}rem;
  left: 0;
  z-index: 999;
  display: none;
  flex-direction: column;
  width: ${rel(SIDE_NAVIGATION_WIDTH)}rem;
  min-height: calc(100vh - ${rel(headerOffset)}rem);
  max-height: calc(100vh - ${rel(headerOffset)}rem);
  padding-top: ${rel(6)}rem;
  padding-bottom: ${rel(44)}rem;
  overflow-y: auto;
  background-color: ${colors.white};
  transition: none;

  @media screen and (min-width: ${breakpoints.xl}) {
    left: -${rel(SIDE_NAVIGATION_WIDTH)}rem;
    z-index: 997;
    display: flex;
    margin-left: -${rel(SIDE_NAVIGATION_WIDTH)}rem;
    padding-top: ${rel(37)}rem;
  }

  @media print {
    display: none;
  }
`

export const collapseIn = css`
  display: flex;

  @media screen and (min-width: ${breakpoints.xl}) {
    left: 0;
    margin-left: 0;
    box-shadow: ${rel(2)}rem 0 ${rel(12)}rem ${colors.boxShadow};
  }

  @media print {
    display: none;
  }
`

export const overlay = css`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 998;
  display: none;
  width: 100vw;
  height: 100vh;
  background-color: ${transparentize(0.3, colors.primaryColor[2])};
  backdrop-filter: blur(${rel(3)}rem);

  .${collapseIn} + & {
    display: block;

    @media screen and (min-width: ${breakpoints.xl}) {
      display: none;
    }

    @media print {
      display: none;
    }
  }
`

export const authorshipInfo = css`
  margin-top: auto;
  padding: ${rel(44)}rem ${rel(26)}rem 0;

  h2 + p {
    margin-top: ${rel(16)}rem;
  }

  p:last-child {
    margin-bottom: 0;
  }
`

export const collapseNavigationButton = css`
  position: fixed;
  top: ${rel(8)}rem;
  left: ${rel(16)}rem;
  z-index: 999;

  @media screen and (min-width: ${breakpoints.xl}) {
    left: ${rel(32)}rem;
  }
`

export const hamburgerCollapse = css`
  display: inline-block;
  margin-right: auto;
  padding: ${rel(1)}rem 0;
  overflow: visible;
  color: inherit;
  font: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  cursor: pointer;
  transition-timing-function: linear;
  transition-duration: 0.15s;
  transition-property: opacity, filter;
`

export const hamburgerOuter = css`
  position: relative;
  display: inline-block;
  width: ${rel(40)}rem;
  height: ${rel(31)}rem;
`

export const hamburgerInner = css`
  top: auto;
  bottom: 0;
  display: block;
  margin-top: -${rel(2)}rem;
  transition-delay: 0.13s;
  transition-timing-function: cubic-bezier(0.55,0.055,0.675,0.19);
  transition-duration: 0.13s;

  &,
  &::before,
  &::after {
    position: absolute;
    width: 2.5rem;
    height: ${rel(2)}rem;
    background-color: ${colors.white};
    border-radius: ${rel(4)}rem;
    transition: transform ${transitionEasing};
  }

  &::before,
  &::after {
    display: block;
    content: " ";
  }

  &::before {
    top: -${rel(10)}rem;
    transition:
      top 0.12s cubic-bezier(0.33333,0.66667,0.66667,1) 0.2s,
      transform 0.13s cubic-bezier(0.55,0.055,0.675,0.19);
  }

  &::after {
    top: -${rel(20)}rem;
    bottom: -${rel(10)}rem;
    transition: top 0.2s ${transitionEasing}, opacity ${transitionEasing};
  }
`

export const isActive = css`
  .${hamburgerInner} {
    transform: translate3d(0,-${rel(10)}rem,0) rotate(-45deg);
    transition-delay: 0.22s;
    transition-timing-function: cubic-bezier(0.215,0.61,0.355,1);

    &::before {
      top: 0;
      transform: rotate(-90deg);
      transition:
        top 0.1s cubic-bezier(0.33333,0,0.66667,0.33333) 0.16s,
        transform 0.13s cubic-bezier(0.215,0.61,0.355,1) 0.25s;
    }

    &::after {
      top: 0;
      opacity: 0;
      transition:
        top 0.2s cubic-bezier(0.33333,0,0.66667,0.33333),
        opacity 0.1s linear 0.22s;
    }
  }
`
