import { useRef } from 'react'
import { cx } from 'linaria'

import { useCollapseNavigation } from '../hooks/useCollapseNavigation'
import { useLocale } from '../locale'
import useOnKeyPress from '../hooks/useOnKeyPress'
import * as style from './CollapseNavigation.style'


export const COLLAPSE_NAVIGATION_ID = 'collapseNavigationButton'

const AuthorshipInfo = () => {
  const { l } = useLocale()

  return (
    <section className={style.authorshipInfo}>
      <h2>{l.appName}</h2>
      <p>{l.footer.copyrightNote(new Date().getFullYear())}</p>
      <p>{l.footer.authorshipAndMaintenanceNote}</p>
    </section>
  )
}

const ToggleNavigationButton = () => {
  const { l } = useLocale()
  const { collapseState, toggleCollapse } = useCollapseNavigation()
  const nodeRef = useRef<HTMLButtonElement | null>(null)

  return (
    <button
      ref={nodeRef}
      className={cx(style.hamburgerCollapse, style.collapseNavigationButton, collapseState && style.isActive)}
      type='button'
      onClick={toggleCollapse}
      aria-haspopup
      aria-expanded={collapseState}
      title={collapseState ? l.siteHeader.closeMenu : l.siteHeader.expandMenu}
      aria-label={collapseState ? l.siteHeader.closeMenu : l.siteHeader.expandMenu}
      id={COLLAPSE_NAVIGATION_ID}
    >
      <div className={style.hamburgerOuter}>
        <div className={style.hamburgerInner} />
      </div>
    </button>
  )
}

interface Props {
  children: JSX.Element
}

const CollapseNavigation = ({ children }: Props): JSX.Element => {
  const { collapseState, closeCollapse } = useCollapseNavigation()
  const nodeRef = useRef<HTMLDivElement | null>(null)

  const handleKeyPress = () => {
    closeCollapse(true)
  }

  const handleOverlay = () => {
    closeCollapse()
  }

  useOnKeyPress('Escape', handleKeyPress)

  return (
    <>
      <ToggleNavigationButton />
      <div
        ref={nodeRef}
        className={cx(style.collapseNavigation, collapseState && style.collapseIn)}
        aria-labelledby={COLLAPSE_NAVIGATION_ID}
      >
        {children}
        <AuthorshipInfo />
      </div>
      <div className={style.overlay} onClick={handleOverlay} />
    </>
  )
}

export default CollapseNavigation
