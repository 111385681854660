import { css, cx } from 'linaria'

import editThesisIcon from '../../images/icons/Edit.svg'
import searchIcon from '../../images/icons/SearchRectangle.svg'
import * as colors from '../../styles/colors'
import { breakpoints, rel, gridColumn } from '../../styles/dimensions'
import { fontWeights } from '../../styles/typography'


export const thesesTableArticle = css`
  display: flex;
  flex-direction: column;
  height: 100%;
`

export const thesesForm = css`
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-wrap: wrap;
  padding: ${rel(8)}rem;
  overflow-x: hidden;
  background: ${colors.backgroundColor[3]};
  border-radius: ${rel(8)}rem;
  box-shadow: 0 ${rel(2)}rem ${rel(12)}rem ${colors.boxShadow};

  @media screen and (min-width: ${breakpoints.xl}) {
    padding: ${rel(16)}rem;
  }
`

export const thesesFormHeader = css`
  display: flex;
  flex: 0 1 auto;
  flex-wrap: wrap;
  margin-right: -${rel(4)}rem;
  margin-left: -${rel(4)}rem;

  @media screen and (min-width: ${breakpoints.xl}) {
    margin-right: -${rel(8)}rem;
    margin-left: -${rel(8)}rem;
  }
`

export const thesesFormHeaderLeft = css`
  display: flex;
  flex: 0 0 100%;
  flex-wrap: wrap;
  width: 100%;
  max-width: 100%;
  padding-right: ${rel(4)}rem;
  padding-left: ${rel(4)}rem;

  @media screen and (min-width: ${breakpoints.lg}) {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  @media screen and (min-width: ${breakpoints.xl}) {
    padding-right: ${rel(8)}rem;
    padding-left: ${rel(8)}rem;
  }
`

export const thesesFormHeaderRight = css`
  ${gridColumn('100%')};
  margin-top: ${rel(8)}rem;
  padding-right: ${rel(4)}rem;
  padding-left: ${rel(4)}rem;

  @media screen and (min-width: ${breakpoints.lg}) {
    ${gridColumn('auto')};
    margin-top: 0;
  }

  @media screen and (min-width: ${breakpoints.xl}) {
    padding-right: ${rel(8)}rem;
    padding-left: ${rel(8)}rem;
  }
`

export const thesisHeaderBtn = css`
  display: inline-block;
  padding: ${rel(4)}rem ${rel(12)}rem;
  font-size: ${rel(17)}rem;
  line-height: ${rel(26)}rem;

  @media screen and (min-width: ${breakpoints.xl}) {
    margin-top: 0;
    padding: ${rel(4)}rem ${rel(18)}rem;
    font-size: ${rel(17)}rem;
    line-height: ${rel(26)}rem;
  }

  &:hover,
  &:focus,
  &:active {
    text-decoration: none;
  }

  svg {
    position: relative;
    top: 0;
    max-width: ${rel(14)}rem;
    max-height: ${rel(14)}rem;
    margin-left: ${rel(8)}rem;

    @media screen and (min-width: ${breakpoints.md}) {
      top: -${rel(2)}rem;
      max-width: ${rel(16)}rem;
      max-height: ${rel(16)}rem;
    }
  }
`

export const thesesListActionsWrapper = css`
  ${gridColumn('100%')};
  text-align: right;

  @media screen and (min-width: ${breakpoints.md}) {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
    margin-left: auto;
  }

  & > * + * {
    margin-left: ${rel(8)}rem;
  }
`

const lastColumnControl = css`
  display: block;
  width: ${rel(28)}rem;
  max-width: ${rel(28)}rem;
  height: ${rel(38)}rem;
`

export const thesesListResetFiltersLink = cx(css`
  line-height: 2.375rem;

  &:active {
    outline: none;
  }
`, lastColumnControl)


export const thesesListSearchInput = css`
  flex-basis: 0;
  flex-grow: 1;
  width: 40%;
  max-width: 100%;
  padding: 0 ${rel(10)}rem;
  font-weight: ${fontWeights.light};
  font-size: ${rel(14)}rem;
  line-height: ${rel(38)}rem;
  background: ${colors.backgroundColor[2]};
  border: none;
  border-radius: 0 ${rel(6)}rem ${rel(6)}rem 0;

  @media screen and (min-width: ${breakpoints.xl}) {
    font-size: ${rel(16)}rem;
  }

  &:focus {
    outline: none;
  }

  &::placeholder {
    color: ${colors.secondaryColor[2]};
  }
`

export const thesisListSearchWrapper = css`
  display: flex;
  flex: 0 0 100%;
  flex-wrap: wrap;
  width: 100%;
  max-width: 100%;

  @media screen and (min-width: ${breakpoints.sm}) {
    flex: 1 0 auto;
    width: auto;
    max-width: 100%;
  }

  @media screen and (min-width: ${breakpoints.xl}) {
    max-width: ${rel(600)}rem;
  }
`

export const thesisListSearchInputButton = css`
  display: inline-block;
  width: ${rel(38)}rem;
  height: ${rel(38)}rem;
  margin: 0;
  vertical-align: bottom;
  background: ${colors.primaryColor[1]} url(${searchIcon}) center center no-repeat;
  background-size: ${rel(30)}rem;
  border: none;
  border-radius: ${rel(6)}rem 0 0 ${rel(6)}rem;
  cursor: pointer;

  &:focus {
    outline: none;
  }
`

export const editThesis = cx(css`
  display: inline-block;
  background: transparent url(${editThesisIcon}) center center no-repeat;
  border: none;

  &:active {
    outline: none;
  }
`, lastColumnControl)


export const thesesTable = css`
  display: flex;
  flex: 1;
  color: ${colors.primaryColor[2]};

  table {
    position: relative;

    th {
      position: sticky;
      top: 0;
      z-index: 9;
      text-align: center;
      background-color: ${colors.white};

      &::before {
        position: absolute;
        top: auto;
        right: 0;
        bottom: -${rel(3)}rem;
        left: 0;
        display: block;
        width: 100%;
        height: ${rel(3)}rem;
        background-color: ${colors.backgroundColor[2]};
        content: " ";
      }
    }

    td {
      position: relative;

      a {
        font-size: inherit;
        text-decoration: none;

        /* position: relative on '<td>' and following css makes link take full space */
        &::before,
        &::after {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          z-index: 0;
          content: '';
        }
      }
    }
  }
`

export const tableBtn = css`
  padding: ${rel(4)}rem ${rel(9)}rem ${rel(2)}rem;
  font-weight: ${fontWeights.bold};
  font-size: ${rel(14)}rem;
  line-height: ${rel(18)}rem;
  letter-spacing: ${rel(0.5)}rem;
`
