import { css, cx } from 'linaria'

import { white, boxShadow } from '../../styles/colors'
import { rel, breakpoints } from '../../styles/dimensions'
import { fontSizes } from '../../styles/typography'
import { iconWrapper } from '../Button.style'
import { column } from '../../components/Card/Column.style'


export const pageHeaderPadding = css`
  padding: 0;
`

export const pageHeaderInner = css`
  background-color: ${white};
  border-radius: ${rel(8)}rem;
  box-shadow: 0 ${rel(2)}rem ${rel(12)}rem ${boxShadow};

  & + & {
    margin-top: ${rel(16)}rem;
  }
`

export const pageHeader = cx(css`
  display: flex;
  align-items: flex-start;
  margin-bottom: ${rel(16)}rem;

  @media print {
    display: none;
  }

  .${column} > & {
    margin-bottom: 0;
  }

  .${pageHeaderInner} & {
    padding: 0;
  }
`, pageHeaderPadding)

export const backButton = cx(iconWrapper, css`
  max-width: ${rel(38)}rem;
  margin-right: ${rel(16)}rem;
`)

export const titlesWrapper = css`
  flex: 1;

  > h1, h2, h3, h4, h5, h6 {
    margin: 0;
  }

  @media screen and (min-width: ${breakpoints.xl}) {
    h1 {
      line-height: ${rel(38)}rem;
    }
  }
`

export const subtitle = css`
  font-size: ${fontSizes.body.mobile}rem;

  @media screen and (min-width: ${breakpoints.md}) {
    font-size: ${fontSizes.body.desktop}rem;
  }
`
