import Card from '../Card'
import { useLocale } from '../../locale'
import * as styles from './RequestErrorCard.style'


export const errorCardRenderer = (
  fallbackMessage: string,
  url?: string,
): (response?: Response | undefined) => JSX.Element => {
  const responseHandler = (response?: Response): JSX.Element => {
    const { l } = useLocale()
    const status = response?.status

    if (!status) {
      return (
        <p>
          {fallbackMessage}
        </p>
      )
    }

    let title = l.defaultStatus
    let message = fallbackMessage

    if (status === 403 || status === 404) {
      title = l.pageStatus[status].title
      message = l.pageStatus[status].message
    }

    if (url) {
      message += ` ${url}`
    }

    return (
      <RequestErrorCard {...{ message, status, title }} />
    )
  }

  return responseHandler
}

interface Props {
  message: string
  status: number
  title: string
}

const RequestErrorCard = ({ message, status, title }: Props): JSX.Element => (
  <Card extraClassNames={[styles.card]}>
    <Card.Content>
      <section>
        <h1>{title}</h1>
        <p className={styles.status}>
          <code>{status}</code>
        </p>
        <p>
          {message}
        </p>
      </section>
    </Card.Content>
  </Card>
)

export default RequestErrorCard
