import { goToAuthSaveCurrentLocation } from '../../api/auth'
import { useLocale } from '../../locale'
import { outlineLight } from '../Button.style'
import * as style from './LoginArea.style'


const LoginArea = (): JSX.Element => {
  const { l } = useLocale()

  return (
    <div className={style.wrapper}>
      <span>{l.siteHeader.youAreNotLoggedIn}.</span>
      <button type='button' onClick={goToAuthSaveCurrentLocation} className={outlineLight}>
        {l.siteHeader.login}
      </button>
    </div>
  )
}

export default LoginArea
