import type {
  Specialization, SpecializationFilter, SpecializationList,
} from '@cvut/profit-api-types/lib/theses/specialization'

import { createRequestor, MakeRequest, RequestWithResponseError } from './makeRequest'
import { useWrappedRequest, UseRequestStatus } from './useRequest'
import { apiPrefix } from '../config'


export const specializationsApiPrefix = `${apiPrefix}/specializations`
const specializationsRequestor = createRequestor(specializationsApiPrefix)

interface GetSpecializationOpts {
  sort?: string
  limit?: number
  offset?: number
}

/**
 * @param makeRequest This function expects that `baseUrl` is set to the correct
 * specializations API prefix.
 */
export async function getSpecializations (
  makeRequest: MakeRequest = specializationsRequestor,
  filter: SpecializationFilter = {},
  opts: GetSpecializationOpts = {},
): Promise<[Response, SpecializationList]> {
  const [resp, specList]
    = await makeRequest<SpecializationList>('GET', '/', { ...filter, ...opts })

  if (!specList) {
    const url = new URL(resp.url)

    throw new RequestWithResponseError(
      { baseUrl: url.host, pathTemplate: specializationsApiPrefix },
      resp,
      'Received an empty body',
    )
  }

  return [resp, specList]
}

/**
 * @param pageSize How many specializations do we want to get in a single request?
 * @returns The last successful response and all paginated data.
 *
 * FIXME: The only reason the last response is returned is to be able to use this crap
 * in `useRequest` and co. Eventually, we may want to make `UseRequestSuccess['response']`
 * optional or return all requests. This way makes the least sense of the two written
 * in the previous sentence.
 */
export async function getAllSpecializations (
  makeRequest: MakeRequest = specializationsRequestor,
  filter: SpecializationFilter = {},
  sort = 'studyDegree,code',
  pageSize = 50,
): Promise<[Response, Specialization[]]> {
  const res = []
  let offset = 0
  let lastResponse

  // TODO: Extract this loop into a utility function.
  while (true) {
    const [resp, specList] = await getSpecializations(makeRequest, filter, { sort, offset, limit: pageSize })
    lastResponse = resp

    res.push(...specList.data)
    if (specList.pagination.hasNextPage) {
      offset += pageSize
    } else {
      break
    }
  }

  return [lastResponse, res]
}

export function useAllSpecializations (makeRequest: MakeRequest = specializationsRequestor): [
  UseRequestStatus<Specialization[]>,
  (filter?: SpecializationFilter, sort?: string, pageSize?: number) => Promise<void>,
] {
  return useWrappedRequest(async (filter = {}, sort = 'studyDegree,code', pageSize = 20) => (
    // eslint-disable-next-line @typescript-eslint/return-await
    getAllSpecializations(makeRequest, filter, sort, pageSize)
  ))
}
