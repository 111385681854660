import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import type { Thesis, ThesisPatch } from '@cvut/profit-api-types/lib/theses'

import AccessControlledRender from '../../access-control/AccessControlledRender'
import { addNotification } from '../../features/toastNotifications'
import { errorCardRenderer } from '../../components/RequestErrorCard'
import Card from '../../components/Card'
import LeavingPrompt from '../../components/form/LeavingPrompt'
import PageHeader from '../../components/PageHeader'
import PageLoadSpinner from '../../components/PageLoadSpinner'
import pagePaths from '../paths'
import RequestDependentRender from '../../api/RequestDependentRender'
import ThesisSubmitForm from '../../features/thesis/ThesisSubmitForm'
import { updateThesis, useSubmitThesis, useThesis, useThesisDownload, useThesisFinalTextUpload } from '../../api/theses'
import { useLocale } from '../../locale'
import ValidatedPathParams from '../utils/ValidatedPathParams'
import { attachmentFilenameFromHeaders, offerFileDownload } from '../../utils/theses/fileDownload'


// To be compatible with all versions of react-hook-form we set all `defaultValues`
const sanitizeDefaultValues = (thesis: Thesis) => ({
  ...thesis,
  abstractCs: thesis.abstractCs ?? '',
  abstractEn: thesis.abstractEn ?? '',
  keywordsCs: thesis.keywordsCs ?? [],
  keywordsEn: thesis.keywordsEn ?? [],
  attachments: thesis.attachments ?? [],
})

interface Props {
  thesisId: number
}

const ThesisSubmitPage = ({ thesisId }: Props): JSX.Element => {
  const history = useHistory()
  const { l } = useLocale()
  const [getStatus, getThesis] = useThesis()
  const [submitStatus, handleSubmit] = useSubmitThesis(thesisId)
  const [thesisDownloadStatus, downloadThesis] = useThesisDownload()
  const [, uploadThesisFile] = useThesisFinalTextUpload(thesisId)
  const [needsUpdate, setNeedsUpdate] = useState(true)
  const [isFormModified, setIsFormModified] = useState(false)

  const handleModifiedState = (isModified: boolean) => {
    setIsFormModified(isModified)
  }

  useEffect(() => {
    if (needsUpdate) {
      void getThesis(thesisId)
    }
    setNeedsUpdate(false)
  }, [needsUpdate])

  useEffect(() => {
    if (thesisDownloadStatus.state === 'success') {
      const filename = attachmentFilenameFromHeaders(thesisDownloadStatus.response.headers)
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      offerFileDownload(filename, thesisDownloadStatus.data!)
    }
  }, [thesisDownloadStatus.state])


  const goToThesisView = () => history.push(pagePaths.theses.view(thesisId))

  useEffect(() => {
    if (submitStatus.state === 'success') {
      addNotification({
        message: l.thesis.submission.successMessages.submit,
        type: 'POSITIVE',
      })

      goToThesisView()
    }
  }, [submitStatus.state])

  const handleSave = async (thesisId: Thesis['id'], data: ThesisPatch) => {
    await updateThesis(thesisId, data)
  }

  const handleThesisReload = () => {
    setNeedsUpdate(true)
  }

  // TODO: Check this condition.
  const isThesisSubmittable = (thesis: Thesis) => (
    thesis.mainState === 'assigned'
    && thesis.states.includes('changeable.assigned.submission.pending')
  )

  return (
    <AccessControlledRender loaderColor='primary'>
      {ac => (
        <RequestDependentRender
          requestStatus={getStatus}
          renderOnLoading={() => <PageLoadSpinner message={l.thesis.loadingThesis} />}
          renderOnError={errorCardRenderer(l.thesis.errorGettingThesis, pagePaths.theses.submit(thesisId))}
          renderOnSuccess={(thesis) => {
            if (!ac.thesisRoles.isThesisAssignee(thesis)) {
              addNotification({
                message: l.thesis.submission.errorMessages.onlyAssigneeCanSubmit,
                type: 'NEGATIVE',
              })

              goToThesisView()
              return null
            }

            if (!isThesisSubmittable(thesis)) {
              addNotification({
                message: l.thesis.submission.errorMessages.submitOnlyAssignedStateTheses,
                type: 'NEGATIVE',
              })

              goToThesisView()
              return null
            }

            return (
              <>
                <PageHeader
                  backButton={{ to: pagePaths.theses.view(thesisId) }}
                  title={l.thesis.submission.title}
                  subtitles={[thesis.language === 'en' ? thesis.titleEn : thesis.titleCs]}
                />
                <Card>
                  <Card.Header title={l.thesis.submission.subTitle} />
                  <Card.Content>
                    <ThesisSubmitForm
                      thesis={sanitizeDefaultValues(thesis)}
                      onDownload={downloadThesis}
                      onUpload={uploadThesisFile}
                      onSave={handleSave}
                      onReloadRequired={handleThesisReload}
                      onSubmit={handleSubmit}
                      onModifiedState={handleModifiedState}
                    />
                  </Card.Content>
                </Card>
                <LeavingPrompt when={isFormModified} />
              </>
            )
          }}
        />
      )}
    </AccessControlledRender>
  )
}

const ThesisSubmitPageWrapper = (): JSX.Element => (
  <ValidatedPathParams<Props>
    expectedPathPattern={pagePaths.theses.submit()}
    expectedParams={{
      thesisId: {
        validate: (param: string) => !isNaN(parseInt(param, 10)),
        convert: (param: string) => parseInt(param, 10),
      },
    }}
  >
    {(params) => (
      <ThesisSubmitPage thesisId={params.thesisId} />
    )}
  </ValidatedPathParams>
)

export default ThesisSubmitPageWrapper
