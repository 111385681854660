import { css, cx } from 'linaria'

import { primaryColor, white } from '../styles/colors'


const dualRings = css`
  display: inline-block;
  width: 2rem;
  height: 2rem;
  animation: spinning-animation 1.2s linear infinite;

  ::after {
    display: block;
    width: 80%;
    height: 80%;
    margin: 10%;
    border: 0.25rem solid ${white};
    border-radius: 50%;
    content: " ";
  }

  @keyframes spinning-animation {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
`

export const dualRingsPrimary = cx(dualRings, css`
  ::after {
    border-color: ${primaryColor[2]} transparent ${primaryColor[2]} transparent;
  }
`)

export const dualRingsWhite = cx(dualRings, css`
  ::after {
    border-color: ${white} transparent ${white} transparent;
  }
`)
