import { css } from 'linaria'

import { rel } from '../../styles/dimensions'
import { breakpoints } from '../../styles/dimensions'


export const wrapper = css`
  display: flex;
  align-items: center;

  > span {
    display: none;

    @media screen and (min-width: ${breakpoints.xl}) {
      display: initial;
    }
  }

  > * + * {
    margin-left: ${rel(8)}rem;
  }
`

export const btn = css`
  padding-top: ${rel(4)}rem;
  padding-bottom: ${rel(4)}rem;
`
