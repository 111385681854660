import { useLocation, useHistory } from 'react-router-dom'
import { arrify } from '@cvut/profit-utils'
import { doesLookLikeInteger } from '@cvut/profit-theses-common'


/**
 * In this file, the terms "query [string]", "search [path | query]" are used interchangeably
 * to mean this part of an URL. ----------+
 *                                        |
 *                                        vvvvvvvvvvvvvvvvvvv
 * https://projects.fit.cvut.cz/some-path?key=value&other=yes#header
 */

// FIXME: This should be more accurate (see `deserializeQuery`) but there is no time.
type TFilter = Record<string, any>

/**
 * Handles strings, integers and comma separated strings. The behavior is undefined
 * on any other type of query parameters.
 *
 * @param paramsToArrify Parameters which are an array of strings should be listed
 * here. Otherwise they get converted into a non-array value which will not correspond
 * to the type specified by T. This does not have a default value on purpose, you
 * have to think, think!
 */
export function deserializeQuery<T extends TFilter = {}> (qs: string, paramsToArrify: Array<keyof T>): T {
  const searchParams = new URLSearchParams(qs)

  const result: Record<string, number | string | (string | number)[]> = {}
  searchParams.forEach((value, key) => {
    const splitValues = value.split(',')

    if (splitValues.length === 1) {
      const singleValue = splitValues[0]

      result[key] = doesLookLikeInteger(singleValue) ? +singleValue : singleValue
      if (paramsToArrify.includes(key)) {
        result[key] = arrify(result[key])
      }
    } else {
      result[key] = splitValues
    }
  })

  return result as T
}

/**
 * Handles strings, integers and comma separated strings. The behavior is undefined
 * on any other type of filter value.
 */
export function stringifyFilterValues (filter: Record<string, any>): Record<string, string> {
  return Object.entries(filter).reduce<Record<string, string>>((res, [key, value]) => {
    let encodedValue: string = encodeURIComponent(value)
    if (Array.isArray(value)) {
      encodedValue = value.map(encodeURIComponent).join(',')
    }

    res[key] = encodedValue
    return res
  }, {})
}

/**
 * Handles strings, integers and comma separated strings. The behavior is undefined
 * on any other type of filter value.
 */
export function serializeToQuery (filter: Record<string, any>): string {
  return Object.entries(stringifyFilterValues(filter)).map(([key, value]) => {
    if (!value.length) {
      return
    }

    return `${key}=${value}`
  }).join('&')
}

/**
 * @param paramsToArrify See `deserializeQuery`.
 */
export function useQueryAsFilter<T extends TFilter = {}> (paramsToArrify: Array<keyof T>): [
  filter: T,
  setFilter: (newFilter: T) => void,
] {
  // Do *NOT* read the location from `history.location` because it is not updated
  // when the location changes. It principle, `history` should only be used to change
  // the location. See: https://reactrouter.com/core/api/history/history-is-mutable.
  const location = useLocation()
  const history = useHistory()

  return [
    deserializeQuery<T>(location.search, paramsToArrify),
    newFilter => {
      history.push({
        ...location,
        search: serializeToQuery(newFilter),
      })
    },
  ]
}
