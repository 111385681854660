import { useRef } from 'react'
import { cx } from 'linaria'
import { formatFileSize } from '@cvut/profit-theses-common'

import ProgressBar from '../../components/ProgressBar'
import { UploadQueueItem } from '../../api/theses'
import { useLocale } from '../../locale'
import * as style from './AttachmentsUploadPanel.style'
import * as formStyle from '../../components/form/Form.style'
import * as submitFormstyle from './ThesisSubmitForm.style'


const speedHistoryLength = 50

interface AttachmentUploadItemProps {
  queueItem: UploadQueueItem
}

const AttachmentUploadItem = ({ queueItem }: AttachmentUploadItemProps) => {
  const { l } = useLocale()
  const { currentSize, totalSize, state, file } = queueItem
  const previousSize = useRef(currentSize)
  const previousTimestamp = useRef(new Date().getTime())
  const remainingTime = useRef('??:??:??')
  const speed = useRef(0)
  const speedHistory = useRef<number[]>([])
  const currentTimestamp = new Date().getTime()
  const percentage = ((currentSize / totalSize) * 100).toFixed(2)

  const dt = (currentTimestamp - previousTimestamp.current) / 1000
  if (dt > (speed.current === 0 ? 0 : 1) && currentSize - previousSize.current > 0) {
    speed.current = (currentSize - previousSize.current) / dt
    previousSize.current = currentSize
    previousTimestamp.current = currentTimestamp

    let speeds = speedHistory.current
    speeds = [
      ...speeds,
      speed.current,
    ].slice(Math.max(0, speeds.length + 1 - speedHistoryLength))
    speedHistory.current = speeds

    const averageSpeed = speeds.reduce((sum, speed) => sum + speed, 0) / speeds.length
    const remainingSeconds = Math.round((totalSize - currentSize) / averageSpeed)
    const hh = Math.floor(remainingSeconds / 3600)
    const mm = Math.floor((remainingSeconds % 3600) / 60)
    const ss = Math.floor(remainingSeconds % 60)
    remainingTime.current = [hh, mm, ss].map(part => String(part).padStart(2, '0')).join(':')
  }

  const progressInfo = state === 'running'
    ? `${percentage}%  @ ${formatFileSize(Math.round(speed.current), 1)}/s (${remainingTime.current})`
    : state === 'succeeded'
      ? l.thesis.submission.form.label.attachments.uploadState.complete
      : state === 'pending'
        ? l.thesis.submission.form.label.attachments.uploadState.waiting
        : l.thesis.submission.form.label.attachments.uploadState.error

  return (
    <div key={file.name} className={style.attachmentsUploadQueueRow}>
      <div className={style.attachmentsUploadQueueRowItem}>
        <div title={file.name}>{file.name}</div>
      </div>
      <div className={style.attachmentsUploadQueueRowItem}>
        <ProgressBar
          isActive={state === 'running'}
          progress={state === 'succeeded' ? 1 : currentSize / totalSize}
        />
      </div>
      <div className={style.attachmentsUploadQueueRowItem}>
        <div title={progressInfo}>
          {progressInfo}
        </div>
      </div>
    </div>
  )
}

interface AttachmentsUploadPanelProps {
  uploadQueue: UploadQueueItem[]
}

const AttachmentsUploadPanel = ({ uploadQueue }: AttachmentsUploadPanelProps): JSX.Element => {
  const { l } = useLocale()

  return (
    <fieldset className={cx(formStyle.fieldset, submitFormstyle.attachments)}>
      <legend className={formStyle.legend}>
        {l.thesis.submission.form.label.attachments.title}
      </legend>
      <div className={style.attachmentsUploadQueuePanel}>
        {uploadQueue.map(queueItem => (
          <AttachmentUploadItem key={queueItem.file.name} queueItem={queueItem} />
        ))}
      </div>
    </fieldset>
  )
}

export default AttachmentsUploadPanel
