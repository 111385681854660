import type { ValueOf } from '../internal/utils'

/**
 * State of a proposal process.
 * This is used for Thesis Change Request and Reviewer Proposal.
 */
export const ProposalState = {
  Pending: 'pending',
  Accepted: 'accepted',
  Rejected: 'rejected',
  Cancelled: 'cancelled',
} as const
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type ProposalState = ValueOf<typeof ProposalState>

export const Language = {
  Cs: 'cs',
  En: 'en',
} as const
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type Language = ValueOf<typeof Language>

export type LanguageList = Array<Language>

export const ResourceType = {
  ChangeRequest: 'changeRequest',
  Notification: 'notification',
  Person: 'person',
  ReviewerProposal: 'reviewerProposal',
  Specialization: 'specialization',
  Study: 'study',
  Thesis: 'thesis',
} as const
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type ResourceType = ValueOf<typeof ResourceType>
