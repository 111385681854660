import type { ThesisReportSection, ThesisReportTexts } from '..'

// The types and constants in this file should be considered as package
// internal. Their purpose is to enforce symmetry between CS and EN texts.

/** @internal */
export type SupervisorReportTexts = ThesisReportTexts<SupervisorSections>

/** @internal */
export type ReviewerReportTexts = ThesisReportTexts<ReviewerSections>

/** @internal */
export const supervisorSectionsOrder: Array<keyof SupervisorSections> = [
  'assignmentDifficulty',
  'assignmentFulfillment',
  'mainWrittenPartSize',
  'factualAndLogicalLevel',
  'formalLevel',
  'bibliography',
  'outcomeEvaluation',
  'outcomeApplicability',
  'studentActivity',
  'studentSelfReliance',
  'summary',
]

/** @internal */
export const reviewerSectionsOrder: Array<keyof ReviewerSections> = [
  'assignmentDifficulty',
  'assignmentFulfillment',
  'mainWrittenPartSize',
  'factualAndLogicalLevel',
  'formalLevel',
  'bibliography',
  'outcomeEvaluation',
  'outcomeApplicability',
  'summary',
  'defenseQuestions',
]

interface CommonSections {
  assignmentDifficulty: ChoiceSection<1 | 2 | 3 | 4 | 5>
  assignmentFulfillment: ChoiceSection<1 | 2 | 3 | 4>
  mainWrittenPartSize: ChoiceSection<1 | 2 | 3 | 4>
  factualAndLogicalLevel: ThesisReportSection<'score'>
  formalLevel: ThesisReportSection<'score'>
  bibliography: ThesisReportSection<'score'>
  outcomeEvaluation: ThesisReportSection<'score'>
  outcomeApplicability: ThesisReportSection<'plain'>
  summary: ThesisReportSection<'score'>
}

interface SupervisorSections extends CommonSections {
  studentActivity: ChoiceSection<1 | 2 | 3 | 4 | 5>
  studentSelfReliance: ChoiceSection<1 | 2 | 3 | 4 | 5>
}

interface ReviewerSections extends CommonSections {
  defenseQuestions: ThesisReportSection<'plain'>
}

interface ChoiceSection<TChoice extends keyof any> extends ThesisReportSection<'choice'> {
  choices: Record<TChoice, string>
}
