import urlcat, { ParamMap } from 'urlcat'


interface StructuredUrl {
  baseUrl: string
  pathTemplate: string
  params?: ParamMap
}

export class RequestError extends Error {
  url: StructuredUrl

  constructor (url: StructuredUrl, message?: string) {
    super(message ?? 'An error has occurred while making a network request')

    this.url = url
  }
}

export class NetworkError extends RequestError {
  constructor (url: StructuredUrl, message?: string) {
    super(url, message ?? 'A networking error has occurred')
  }
}

export class AbortedError extends RequestError {
  constructor (url: StructuredUrl, message?: string) {
    super(url, message ?? 'The request has been aborted')
  }
}

export class RequestWithResponseError extends RequestError {
  response: Response

  constructor (url: StructuredUrl, response: Response, message?: string) {
    super(url, message ?? 'An error has occurred while working with the response')

    this.response = response
  }
}

export class HttpError<T> extends RequestWithResponseError {
  problem?: T | string

  constructor (
    url: StructuredUrl,
    response: Response,
    problem?: HttpError<T>['problem'],
    message?: string,
  ) {
    super(url, response, message ?? 'Response status code is not 2xx')

    this.problem = problem
  }
}

// TODO: Move to `@cvut/profit-utils`.
function normalizeMediaType (str: string): string | undefined {
  return /^(\w+)\/(?:[\w.-]+\+)?([\w.-]+)/.exec(str)?.slice(1)?.join('/')
}

/**
 * Generates a function which makes network requests. It helps with setting some defaults for the returned function.
 */
export const createRequestor = (baseUrl: string) => async <T>(
  method: 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH' | 'HEAD',
  pathTemplate: string,
  params?: ParamMap,
  customConfig?: Omit<RequestInit, 'method'>
): Promise<[Response, T | undefined]> => {
  const upperCaseMethod = method.toUpperCase()
  const structuredUrl: StructuredUrl = {
    baseUrl,
    pathTemplate,
    params,
  }
  // Occasionally `pathTemplate` is an empty string. To make urlcat properly
  // handle this case, it must be called without the `pathTemplate` argument.
  // See urlcat docs for more info.
  const finalUrl = pathTemplate.length
    ? urlcat(baseUrl, pathTemplate, params ?? {})
    : urlcat(baseUrl, params ?? {})

  const headers: RequestInit['headers'] = {
    Accept: 'application/json',
  }

  const configHeaders = new Headers(customConfig?.headers)

  if (upperCaseMethod !== 'GET' && customConfig?.body && !configHeaders.has('Content-Type')) {
    headers['Content-Type'] = 'application/json;charset=UTF-8'
  }

  // TODO: deep merge `customConfig` with the default config (if needed).
  const config: RequestInit = {
    ...customConfig,
    method: upperCaseMethod,
    headers: {
      ...Object.fromEntries(configHeaders.entries()),
      ...headers,
    },
  }

  let response: Response
  try {
    response = await fetch(finalUrl, config)
  } catch (err) {
    if (err instanceof DOMException && err.name === 'AbortError') {
      throw new AbortedError(structuredUrl, `A network request to '${finalUrl}' was aborted`)
    }

    throw new NetworkError(structuredUrl, `An error has occurred while making a request to '${finalUrl}'.`
      + ` Message: '${(err as TypeError).message}'.`)
  }

  const contentType = normalizeMediaType(response.headers.get('Content-Type') ?? '')
  if (!contentType) {
    if (!response.ok) {
      throw new RequestWithResponseError(structuredUrl, response, 'Invalid response (missing Content-Type header')
    }

    // Response with no body (e.g. to action POSTs)
    return [response, undefined]
  }

  let parsedBody: T

  // FIXME: Find a better way to parse the body depending on `Content-Type`.
  if (contentType === 'application/pdf') {
    try {
      parsedBody = await response.blob() as unknown as T
    } catch (err) {
      throw new RequestWithResponseError(structuredUrl, response)
    }
  } else if (contentType === 'application/json') {
    try {
      parsedBody = JSON.parse(await response.text()) as T
    } catch (err) {
      throw new RequestWithResponseError(structuredUrl, response, 'Invalid JSON')
    }
  } else {
    parsedBody = await response.text() as unknown as T
  }

  if (!response.ok) {
    throw new HttpError(structuredUrl, response, parsedBody)
  }

  if (contentType === 'application/json' && typeof parsedBody !== 'object') {
    throw new RequestWithResponseError(structuredUrl, response, 'Expected a JSON but received something else')
  }

  return [response, parsedBody]
}

export type MakeRequest = ReturnType<typeof createRequestor>
