import { css } from 'linaria'

import * as colors from '../../styles/colors'
import { breakpoints, rel } from '../../styles/dimensions'


export const row = css`
  &:nth-child(even) {
    background-color: ${colors.backgroundColor[2]};
  }
`

export const cell = css`
  padding: ${rel(8)}rem ${rel(4)}rem;
  font-size: ${rel(14)}rem;
  line-height: ${rel(20)}rem;

  @media screen and (min-width: ${breakpoints.xl}) {
    font-size: ${rel(16)}rem;
    line-height: ${rel(22)}rem;
  }

  /* FIXME - duplicates from MyTheses.style.ts */
  &:first-child {
    padding-left: ${rel(8)}rem;
  }

  a {
    color: ${colors.primaryColor[2]};
    text-decoration: none;
  }
`
