import dedent from 'dedent'
import { objectOmit } from '@cvut/profit-utils'

import {
  reviewerSectionsOrder,
  supervisorSectionsOrder,
  ReviewerReportTexts,
  SupervisorReportTexts,
} from './internal'


const sections: (ReviewerReportTexts & SupervisorReportTexts)['sections'] = {
  assignmentDifficulty: {
    title: 'Náročnost a další komentář k zadání',
    description: dedent`
      Podrobněji charakterizujte diplomovou (bakalářskou) práci a její případné návaznosti na \
      předchozí nebo běžící projekty. Dále posuďte, čím je zadání této ZP náročné (u obtížnější ZP \
      lze dále tolerovat některé nedostatky, které by u ZP standardní obtížnosti tolerovány nebyly; \
      a naopak u jednoduché ZP mohou být zjištěné nedostatky hodnoceny přísněji).`,
    type: 'choice',
    choices: {
      1: 'mimořádně náročné zadání',
      2: 'náročnější zadání',
      3: 'průměrně náročné zadání',
      4: 'lehčí, ale ještě dostatečně náročné zadání',
      5: 'nedostatečně náročné zadání',
    },
  },
  assignmentFulfillment: {
    title: 'Splnění zadání',
    description: dedent`
      Posuďte, zda předložená ZP splňuje zadání. V komentáři uveďte body zadání, které nebyly
      zcela splněny, případně rozšíření ZP oproti původnímu zadání. Nebylo-li zadání zcela splněno, \
      pokuste se posoudit závažnost, dopady a případně i příčiny jednotlivých nedostatků.`,
    type: 'choice',
    choices: {
      1: 'zadání splněno',
      2: 'zadání splněno s menšími výhradami',
      3: 'zadání splněno s většími výhradami',
      4: 'zadání nesplněno',
    },
  },
  mainWrittenPartSize: {
    title: 'Rozsah písemné zprávy',
    description: dedent`
      Porovnejte rozsah předložené písemné zprávy s požadovaným rozsahem, viz Směrnice děkana \
      č. 9/2011, článek 3. Pro hodnocení ZP je také důležité, zda všechny části písemné zprávy jsou \
      informačně bohaté a pro práci nezbytné. Text ZP by neměl obsahovat zbytečné části.`,
    type: 'choice',
    choices: {
      1: 'splňuje požadavky',
      2: 'splňuje požadavky s menšími výhradami',
      3: 'splňuje požadavky s většími výhradami',
      4: 'nesplňuje požadavky',
    },
  },
  factualAndLogicalLevel: {
    title: 'Věcná a logická úroveň práce',
    description: dedent`
      Posuďte, zda předložená ZP je po věcné stránce v pořádku, případně vyskytují-li se v práci \
      věcné chyby nebo nepřesnosti. Zhodnoťte dále logickou strukturu ZP, návaznosti jednotlivých \
      kapitol a pochopitelnost textu pro čtenáře.`,
    type: 'score',
  },
  formalLevel: {
    title: 'Formální úroveň práce',
    description: dedent`
      Posuďte správnost používání formálních zápisů obsažených v práci. Posuďte typografickou a \
      jazykovou stránku ZP, viz Směrnice děkana č. 9/2011, článek 3.`,
    type: 'score',
  },
  bibliography: {
    title: 'Práce se zdroji',
    description: dedent`
      Vyjádřete se k aktivitě studenta při získávání a využívání studijních materiálů k řešení ZP. \
      Charakterizujte výběr studijních pramenů. Posuďte, zda student využil všechny relevantní \
      zdroje nebo zda se pokoušel řešit již vyřešené problémy. Ověřte, zda jsou všechny převzaté \
      prvky řádně odlišeny od vlastních výsledků a úvah, zda nedošlo k porušení citační etiky a zda \
      jsou bibliografické citace úplné a v souladu s citačními zvyklostmi a normami.`,
    type: 'score',
  },
  outcomeEvaluation: {
    title: 'Hodnocení výsledků,publikační výstupy a ocenění',
    description: dedent`
      Vyjádřete se k úrovni dosažených hlavních výsledků ZP, např. k úrovni teoretických výsledků, \
      nebo k úrovni a funkčnosti technického nebo programového vytvořeného řešení, apod. Případně \
      také zhodnoťte, zda software nebo zdrojové texty, které nevytvořil sám student, byly v ZP \
      použity v souladu s licenčními podmínkami a autorským právem. Popište případnou publikační \
      činnost a získaná ocenění související s řešením této ZP.`,
    type: 'score',
  },
  outcomeApplicability: {
    title: 'Komentář o využitelnosti výsledků',
    description: dedent`
      Uveďte, zda hlavní výsledky ZP rozšiřují již publikované známé výsledky a/nebo přinášející \
      zcela nové poznatky. Uveďte možnosti využití výsledků ZP v praxi.`,
    type: 'plain',
  },
  studentActivity: {
    title: 'Aktivita studenta',
    description: dedent`
      Posuďte, zda byl student během řešení aktivní, zda dodržoval dohodnuté termíny, jestli své \
      řešení průběžně konzultoval a zda byl na konzultace dostatečně připraven.`,
    type: 'choice',
    choices: {
      1: 'výborná aktivita',
      2: 'velmi dobrá aktivita',
      3: 'průměrná aktivita',
      4: 'slabší, ale ještě dostatečná aktivita',
      5: 'nedostatečná aktivita',
    },
  },
  studentSelfReliance: {
    title: 'Samostatnost studenta',
    description: dedent`
      Posuďte schopnost studenta samostatné tvůrčí práce.`,
    type: 'choice',
    choices: {
      1: 'výborná samostatnost',
      2: 'velmi dobrá samostatnost',
      3: 'průměrná samostatnost',
      4: 'slabší, ale ještě dostatečná samostatnost',
      5: 'nedostatečná samostatnost',
    },
  },
  summary: {
    title: 'Celkové hodnocení',
    description: dedent`
      Shrňte stránky ZP studenta, které nejvíce ovlivnily Vaše celkové hodnocení. Celkové hodnocení \
      nemusí být aritmetickým průměrem či jinou hodnotou vypočtenou z hodnocení v předchozích \
      jednotlivých kritériích.`,
    type: 'score',
  },
  defenseQuestions: {
    title: 'Otázky k obhajobě',
    description: dedent`
      Uveďte případné dotazy, které by měl student zodpovědět při obhajobě ZP před komisí (body \
      oddělte odrážkami).`,
    type: 'plain',
  },
}

export const reviewer: ReviewerReportTexts = {
  headers: {
    createdOn: 'Vytvořeno dne',
    evaluationCriteria: 'Hodnotící kritéria',
    instructions: 'Instrukce',
    specialization: 'Obor / specializace',
    student: 'Student',
    thesisTitle: 'Název práce',
    document: 'Posudek oponenta závěrečné práce',
    reportAuthor: 'Oponent práce',
  },
  sections: objectOmit(sections, 'studentActivity', 'studentSelfReliance'),
  sectionsOrder: reviewerSectionsOrder,
}

export const supervisor: SupervisorReportTexts = {
  headers: {
    ...reviewer.headers,
    document: 'Hodnocení vedoucího závěrečné práce',
    reportAuthor: 'Vedoucí práce',
  },
  sections: objectOmit(sections, 'defenseQuestions'),
  sectionsOrder: supervisorSectionsOrder,
}
