import { useState } from 'react'

import { createContainer } from '../state-management'
import { breakpointsConstants } from '../styles/dimensions'


const COLLAPSE_NAVIGATION_KEY = 'nav-collapse'

function getRemWidth (): number {
  return window.innerWidth / parseFloat(getComputedStyle(document.documentElement).fontSize)
}

function useCollapseNavigationContainer (initialState?: boolean) {
  const savedState = window.localStorage.getItem(COLLAPSE_NAVIGATION_KEY)
  // We want default state of the navigation drawer on desktops to be open.
  const stateFromLocalStorage
    = (savedState == null && getRemWidth() >= breakpointsConstants.xl)
    || savedState === 'true'

  return useState(initialState ?? stateFromLocalStorage)
}

export const CollapseNavigationContainer = createContainer(useCollapseNavigationContainer)

export const useCollapseNavigation = (): {
  collapseState: boolean,
  toggleCollapse: () => void,
  closeCollapse: (ignoreDesktop?: boolean) => void,
} => {
  const [collapseState, setCollapseState] = CollapseNavigationContainer.useContainer()

  const toggleCollapse = () => {
    if (!collapseState) {
      window.localStorage.setItem(COLLAPSE_NAVIGATION_KEY, 'true')
      setCollapseState(previousState => !previousState)
    } else {
      window.localStorage.setItem(COLLAPSE_NAVIGATION_KEY, 'false')
      setCollapseState(previousState => !previousState)
    }
  }

  /**
   * @param ignoreDesktop - There are use cases, where you want it to work on only mobile
   *
   * example: escape button to close menu on phone
   */
  const closeCollapse = (ignoreDesktop?: boolean) => {
    const currentWindowWidth = getRemWidth()

    if (ignoreDesktop && currentWindowWidth >= breakpointsConstants.xl) {
      return null
    }

    if (collapseState) {
      window.localStorage.setItem(COLLAPSE_NAVIGATION_KEY, 'false')
      setCollapseState(false)
    }
  }

  return {
    collapseState,
    toggleCollapse,
    closeCollapse,
  }
}
