import type { Except } from 'type-fest'
import type { Page } from '@cvut/profit-api-types/lib/common'
import type { Person } from '@cvut/profit-api-types/lib/theses/person'
import type {
  Topic as _Topic, TopicFilter, TopicsMeta, TopicNew, TopicPatch, TopicRef,
} from '@cvut/profit-api-types/lib/theses'

import { apiPrefix } from '../../config'
import { createRequestor } from '../makeRequest'
import requestErrorFormatter from '../requestErrorFormatter'
import { useRequest, UseRequestStatus, UseRequestConfig } from '../useRequest'
import { useLocale } from '../../locale'
import { UsePaginatedRequestStatus, usePaginatedRequest } from '../usePaginatedRequest'

export type Topic =
  & Except<_Topic, 'author'>
  & { author: Person }
export type TopicList = Page<Topic>

// This is what a response body of a `Topic` looks like.
// TODO: Name it properly.
type TopicBody =
  & Except<Topic, 'modifiedAt'>
  & { modifiedAt: string }

export const topicsApiPrefix = `${apiPrefix}/topics`
const topicsRequestor = createRequestor(topicsApiPrefix)
const myTopicsApiUrl = `${apiPrefix}/me/topics`

function useTopicsRequest<T> (config: UseRequestConfig) {
  return useRequest<T>(topicsRequestor, config)
}

export async function getTopic (id: Topic['id'], requestor = topicsRequestor): Promise<[Response, Topic]> {
  const [resp, topic] = await requestor<TopicBody>('GET', '/:id', { id })

  // topic cannot be undefined - the API should always return body matching the requested type
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return [resp, convertBodyToTopic(topic!)]
}

export function useTopic (errorHandlers?: UseRequestConfig['errorHandlers']): [
  UseRequestStatus<Topic>,
  (topicId: Topic['id']) => Promise<void>,
] {
  const [requestStatus, sendRequest] = useTopicsRequest<Topic>({
    errorHandlers,
    defaultErrorMessage: requestErrorFormatter,
  })

  return [
    requestStatus,
    async (topicId) => await sendRequest('GET', '/:topicId', { topicId }),
  ]
}

export function usePaginatedTopics (pageSize: number, params: TopicFilter = {}, myTopics = false): [
  // FIXME: This should actually be `Topic` but I wrote most data fecthing so
  // shittily that it's hard to do the conversion here.
  //   -- Tung
  UsePaginatedRequestStatus<TopicBody>,
  boolean,
  () => void,
] {
  const { l } = useLocale()
  const topicInLocale = l.topic.topic.toLocaleLowerCase()
  return usePaginatedRequest<TopicBody>(
    {
      defaultErrorMessage: () => l.errorMessages.api.defaultGetList(topicInLocale),
    },
    pageSize,
    myTopics ? myTopicsApiUrl : topicsApiPrefix,
    params,
  )
}

export function useUpdateTopic (): [
  UseRequestStatus<void>,
  (topicId: Topic['id'], topicPatch: TopicPatch) => Promise<void>,
] {
  const { l } = useLocale()
  const topicInLocale = l.topic.topic.toLocaleLowerCase()
  const [requestStatus, sendRequest] = useTopicsRequest<void>({
    defaultErrorMessage: err => l.errorMessages.api.defaultPatch(topicInLocale, err.url.params?.topicId ?? ''),
  })

  return [requestStatus, async (topicId, topicPatch) => await sendRequest('PATCH', ':topicId', { topicId }, {
    body: JSON.stringify(topicPatch),
  })]
}

export function useDeleteTopic (): [
  UseRequestStatus<void>,
  (topicId: Topic['id']) => Promise<void>
] {
  const { l } = useLocale()
  const topicInLocale = l.topic.topic.toLocaleLowerCase()
  const [requestStatus, sendRequest] = useTopicsRequest<void>({
    defaultErrorMessage: err => l.errorMessages.api.defaultDelete(topicInLocale, err.url.params?.topicId ?? ''),
  })

  return [requestStatus, async (topicId) => await sendRequest('DELETE', ':topicId', { topicId })]
}

export function useTopicsMeta (): [
  UseRequestStatus<TopicsMeta>,
  () => Promise<void>,
] {
  const { l } = useLocale()
  const [requestStatus, sendRequest] = useTopicsRequest<TopicsMeta>({
    defaultErrorMessage: () => l.topic.errorMessages.getMetadata,
  })

  return [
    requestStatus,
    async () => await sendRequest('GET', '/meta'),
  ]
}

export async function createTopic (
  topic: TopicNew,
  requestor = topicsRequestor,
): Promise<[Response, TopicRef | undefined]> {
  return await requestor('POST', '', {}, {
    body: JSON.stringify(topic),
  })
}

export async function updateTopic (
  topicId: Topic['id'],
  topicPatch: TopicPatch,
  requestor = topicsRequestor,
): Promise<unknown> {
  return await requestor('PATCH', ':topicId', { topicId }, {
    body: JSON.stringify(topicPatch),
  })
}

function convertBodyToTopic (t: TopicBody): Topic {
  return {
    ...t,
    modifiedAt: new Date(t.modifiedAt),
  }
}
