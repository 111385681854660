import { useEffect } from 'react'
import { Except } from '@cvut/profit-utils'
import { Thesis, ThesisMainState, ThesisRef, ActionMessage, ThesisAction } from '@cvut/profit-api-types/lib/theses'
import { useHistory } from 'react-router-dom'

import AccessControlledRender from '../../access-control/AccessControlledRender'
import { addNotification } from '../../features/toastNotifications'
import { ACLoaded as AccessControlLoaded } from '../../access-control'
import { errorCardRenderer } from '../../components/RequestErrorCard'
import { useModals } from '../../features/modals'
import {
  createThesisAssignmentPrintoutPDFUrl, useThesis, useThesisActions, useThesisDownload, ThesisPanelAction,
} from '../../api/theses'
import { useThesisReports } from '../../api/theses/thesisReports'
import RequestDependentRender from '../../api/RequestDependentRender'
import PageLoadSpinner from '../../components/PageLoadSpinner'
import pagePaths from '../paths'
import ValidatedPathParams from '../utils/ValidatedPathParams'
import { useLocale } from '../../locale'
import ThesisDetail from '../../features/thesis/ThesisDetail'
import ThesisChangesCard from '../../features/thesis/ThesisChangesCard'
import { canSeeReviewerProposals } from './ThesisReviewerPage'
import { attachmentFilenameFromHeaders, offerFileDownload } from '../../utils/theses/fileDownload'


interface Props {
  thesisId: number
}

const ThesisViewPage = ({ thesisId }: Props): JSX.Element => {
  const history = useHistory()
  const { l } = useLocale()
  const { showModal } = useModals()
  const [getStatus, getThesis] = useThesis()
  const [requestStatus, sendRequest] = useThesisActions(thesisId)
  const [requestArchiveStatus, sendArchiveRequest] = useThesisActions(thesisId)
  const [supersessionStatus, supersedeThesis] = useThesisActions(thesisId)
  const [getThesisReportsStatus, getThesisReports] = useThesisReports()
  const [thesisDownloadStatus, downloadThesis] = useThesisDownload()

  useEffect(() => {
    if (thesisDownloadStatus.state === 'success') {
      const filename = attachmentFilenameFromHeaders(thesisDownloadStatus.response.headers)
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      offerFileDownload(filename, thesisDownloadStatus.data!)
    }
  }, [thesisDownloadStatus.state])

  function handleSendRequest (action: ThesisPanelAction, bodyData?: ActionMessage): void {
    switch (action) {
      case ThesisAction.SUPERSEDE:
        handleSupersession()
        break
      default:
        sendRequest(action, bodyData)
        break
    }
  }

  const handleSupersession = () => showModal({
    title: l.thesis.supersession.confirmationTitle,
    cancelButton: {
      caption: l.misc.no,
      focus: true,
      onClick: () => null,
    },
    positiveButton: {
      caption: l.misc.yes,
      onClick: () => supersedeThesis(ThesisAction.SUPERSEDE),
    },
  })

  function handleThesisArchive (): void {
    sendArchiveRequest(ThesisAction.ARCHIVE)
  }

  useEffect(() => {
    if (supersessionStatus.state === 'success') {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      history.push(pagePaths.theses.view((supersessionStatus.data! as unknown as ThesisRef).id))
      addNotification({
        type: 'POSITIVE',
        message: l.thesis.supersession.successMessage,
      })
    }

    if (supersessionStatus.state === 'error') {
      addNotification({
        type: 'NEGATIVE',
        message: l.thesis.supersession.errorMessage,
      })
    }

  }, [supersessionStatus.state])

  useEffect(() => {
    requestArchiveStatus.state === 'success' && history.push(pagePaths.theses.search)
  }, [requestArchiveStatus.state])

  useEffect(() => {
    void getThesis(thesisId)
    void getThesisReports(thesisId)
  }, [thesisId])

  useEffect(() => {
    if (requestStatus.state === 'success') {
      void getThesis(thesisId)
    }
  }, [requestStatus.state])

  useEffect(() => {
    if (getStatus.state === 'not-initiated') {
      void getThesisReports(thesisId)
    }
  }, [getStatus])

  const canEdit = (ac: Except<AccessControlLoaded, 'state'>, thesis: Thesis) => (
    (ac.globalRoles.isFtOfficer && thesis.mainState === ThesisMainState.Assigned)
    || (ac.thesisRoles.isThesisSupervisor(thesis) && (thesis.mainState === ThesisMainState.Draft
    || (thesis.mainState === ThesisMainState.Proposed && thesis.states.includes('proposed.revision'))))
  )

  const canPrint = (thesis: Thesis) => !!thesis.assignee
  const canArchive = (thesis: Thesis) => ThesisAction.ARCHIVE in (thesis._actions ?? {})

  return (
    <AccessControlledRender loaderColor='primary'>
      {(ac) => (
        <RequestDependentRender
          requestStatus={getStatus}
          renderOnLoading={() => <PageLoadSpinner message={l.thesis.loadingThesis} />}
          renderOnError={errorCardRenderer(l.thesis.errorGettingThesis, pagePaths.theses.view(thesisId))}
          renderOnSuccess={thesis => (
            <ThesisDetail
              onDownload={downloadThesis}
              thesis={thesis}
              editLink={canEdit(ac, thesis) ? pagePaths.theses.edit(thesisId) : undefined}
              assignmentPDFLink={canPrint(thesis) ? createThesisAssignmentPrintoutPDFUrl(thesisId) : undefined}
              changeHistoryComponent={<ThesisChangesCard thesisId={thesisId} collapsed />}
              reviewerLink={canSeeReviewerProposals(ac, thesis)
                ? pagePaths.theses.reviewerProposals(thesisId)
                : undefined}
              thesisArchiveHandler={canArchive(thesis) ? handleThesisArchive : undefined}
              accessControl={ac}
              thesisReports={getThesisReportsStatus}
              reportDetailLinkCreator={(thesisId, type) => pagePaths.theses.reportView(thesisId, type)}
              handleSendRequest={handleSendRequest}
            />
          )}
        />
      )}
    </AccessControlledRender>
  )
}

const ThesisViewPageWrapper = (): JSX.Element => (
  <ValidatedPathParams<Props>
    expectedPathPattern={pagePaths.theses.view()}
    expectedParams={{
      thesisId: {
        validate: (param: string) => !isNaN(parseInt(param, 10)),
        convert: (param: string): number => parseInt(param, 10),
      },
    }}
  >
    {({ thesisId }) => (
      <ThesisViewPage thesisId={thesisId} />
    )}
  </ValidatedPathParams>
)

export default ThesisViewPageWrapper
