import { css } from 'linaria'

import { rel, gridColumn, breakpoints } from '../../../styles/dimensions'


export const thesesListFilters = css`
  display: flex;
  flex: 0 0 auto;
  flex-wrap: wrap;
  width: auto;
  max-width: 100%;
  margin-top: ${rel(8)}rem;

  * + & {
    @media screen and (min-width: ${breakpoints.sm}) {
      margin-top: 0;
      margin-left: ${rel(8)}rem;
    }
  }
`

export const thesesListFiltersItems = css`
  display: flex;
  flex-wrap: wrap;
  margin: -${rel(4)}rem;
`

export const thesesListFiltersItem = css`
  ${gridColumn('auto')};
  padding: ${rel(4)}rem;
`
