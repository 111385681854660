import { css } from 'linaria'

import { rel } from '../../../styles/dimensions'
import * as colors from '../../../styles/colors'


export const thesesListFilter = css`
  padding: ${rel(7)}rem ${rel(14)}rem ${rel(7)}rem ${rel(10)}rem;
  background-color: ${colors.backgroundColor[2]};
  border-radius: ${rel(6)}rem;

  & + & {
    margin-left: ${rel(10)}rem;
  }
`
