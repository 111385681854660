import { css } from 'linaria'

import { breakpoints, rel } from '../../styles/dimensions'
import { fontWeights } from '../../styles/typography'
import * as color from '../../styles/colors'


export const card = css`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: ${rel(32)}rem;
  text-align: center;

  *:last-child {
    margin-bottom: 0;
  }
`

export const status = css`
  margin-bottom: 0;
  color: ${color.secondaryColor[3]};
  font-weight: ${fontWeights.bold};
  font-size: ${rel(64)}rem;
  line-height: 1.25;

  @media screen and (min-width: ${breakpoints.md}) {
    font-size: ${rel(100)}rem;
  }

  @media screen and (min-width: ${breakpoints.xl}) {
    font-size: ${rel(200)}rem;
  }
`
