import { useMemo } from 'react'
import { CellProps, Column, Row } from 'react-table'
import { Person, ReviewerProposal, Thesis } from '@cvut/profit-api-types/lib/theses'

import Card from '../../components/Card'
import PersonCard from '../../components/PersonCard'
import Table from '../../components/table'
import { formatPersonFullName } from '../../utils/person'
import {
  ApproveDisapproveControls,
  RemoveReviewerControls,
} from './ThesisReviewerProposalControls'
import { AvailableLocale, useLocale } from '../../locale'
import { useUserInfo } from '../../session/user-info'
import * as style from './ThesisReviewersTable.style'


const reviewerSortType = (lang: AvailableLocale) => (row1: Row<ReviewerProposal>, row2: Row<ReviewerProposal>) => {
  const r1 = row1.original.reviewer as Person
  const r2 = row2.original.reviewer as Person
  return r1.lastName.localeCompare(r2.lastName, lang)
      || r1.firstName.localeCompare(r2.firstName, lang)
      || r1.titlesPre.localeCompare(r2.titlesPre, lang)
      || r1.titlesPost.localeCompare(r2.titlesPost, lang)
}

type ThesisReviewersTableType = (props: {
  thesis: Thesis,
  reviewerProposals: ReviewerProposal[],
  showOfficerButtons: boolean,
  onActionCompleted: () => unknown,
}) => JSX.Element

const ThesisReviewersTable: ThesisReviewersTableType = ({
  thesis,
  reviewerProposals,
  showOfficerButtons,
  onActionCompleted,
}) => {
  const { currentLang, l } = useLocale()

  const ReviewerCell = ({ row }: CellProps<ReviewerProposal>) => {
    // FIXME reviewer should be Person by default
    const reviewer = row.original.reviewer as Person
    return (
      <PersonCard
        name={formatPersonFullName(reviewer)}
        contact={reviewer.email}
      />
    )
  }


  const ProposalStateCell = ({ row, column }: CellProps<ReviewerProposal>) => {
    const { l } = useLocale()
    const state = (column.id === 'reviewerAcceptance')
      ? row.original.reviewerAcceptance
      : row.original.specOfficerApproval

    return {
      accepted: (
        <span className={style.acceptanceAccepted}>
          {l.thesisReviewerProposalsList.reviewerAcceptanceCaption.accepted}
        </span>
      ),
      pending: <span className={style.acceptancePending} />,
      rejected: (
        <span className={style.acceptanceRejected}>
          {l.thesisReviewerProposalsList.reviewerAcceptanceCaption.rejected}
        </span>
      ),
    }[state]
  }


  const acceptanceStates = {
    accepted: 0,
    pending: 1,
    rejected: 2,
  }

  const acceptanceSortType = (columnId: 'reviewerAcceptance' | 'specOfficerApproval') =>
    (row1: Row<ReviewerProposal>, row2: Row<ReviewerProposal>) => (
      acceptanceStates[row1.original[columnId]] - acceptanceStates[row2.original[columnId]]
    )

  const ActionsCell = ({ row }: CellProps<ReviewerProposal>) => {
    const { l } = useLocale()
    const [userInfo] = useUserInfo()
    const reviewerProposal = row.original

    if (reviewerProposal.state === 'pending') {
      if (userInfo.state === 'loaded' && showOfficerButtons && reviewerProposal.specOfficerApproval === 'pending') {
        return (
          <ApproveDisapproveControls reviewerProposalId={reviewerProposal.id} onActionCompleted={onActionCompleted} />
        )
      }

      const isProponent = userInfo.state === 'loaded'
         && reviewerProposal.proponent?.username === userInfo.person.username
      if (!showOfficerButtons && isProponent) {
        return <RemoveReviewerControls reviewerProposalId={reviewerProposal.id} onActionCompleted={onActionCompleted} />
      }

      if (reviewerProposal.reviewerAcceptance === 'pending') {
        return l.thesisReviewerProposalsList.actions.waitingForCandidatesAcceptance
      }

      if (reviewerProposal.specOfficerApproval === 'pending') {
        return l.thesisReviewerProposalsList.actions.waitingForSpecOfficerApproval
      }
    }

    if (reviewerProposal.state === 'rejected') {
      if (reviewerProposal.reviewerAcceptance !== 'rejected' && reviewerProposal.specOfficerApproval !== 'rejected') {
        return l.thesisReviewerProposalsList.actions.canceledByProponent
      }

      if (reviewerProposal.reviewerAcceptance !== 'rejected') {
        return l.thesisReviewerProposalsList.actions.rejectedBySpecOfficer
      }

      if (reviewerProposal.specOfficerApproval !== 'rejected') {
        return l.thesisReviewerProposalsList.actions.rejectedByReviewerCandidate
      }
    }

    return null
  }

  const AuthorCell = ({ row }: CellProps<ReviewerProposal>) => {
    const { l } = useLocale()
    return (
      <>
        <span>{formatPersonFullName(row.original.proponent as Person)}</span>
        {row.original.proponent.username === thesis.supervisor.username && (
          <div className={style.supervisor}>
            {l.thesis.supervisor}
          </div>
        )}
      </>
    )
  }

  // TODO: move to parametrized hook or set of hooks for every table
  const columns = useMemo(
    (): Array<Column<ReviewerProposal>> => [
      {
        Header: l.thesisReviewerProposalsList.columnCaptions.name,
        id: 'reviewerName',
        accessor: (p: ReviewerProposal) => formatPersonFullName(p.reviewer as Person, p.reviewer.username),
        sortType: reviewerSortType(currentLang),
        disableFilters: true,
        Cell: ReviewerCell,
      },
      {
        Header: l.thesisReviewerProposalsList.columnCaptions.reviewerAcceptance,
        id: 'reviewerAcceptance',
        accessor: (p: ReviewerProposal) => acceptanceStates[p.reviewerAcceptance],
        sortType: acceptanceSortType('reviewerAcceptance'),
        disableFilters: true,
        Cell: ProposalStateCell,
      },
      {
        Header: l.thesisReviewerProposalsList.columnCaptions.specOfficerApproval,
        id: 'specOfficerApproval',
        accessor: (p: ReviewerProposal) => acceptanceStates[p.specOfficerApproval],
        sortType: acceptanceSortType('specOfficerApproval'),
        disableFilters: true,
        Cell: ProposalStateCell,
      },
      {
        Header: l.thesisReviewerProposalsList.columnCaptions.proponent,
        id: 'proponent',
        accessor: (p: ReviewerProposal) => formatPersonFullName(p.proponent as Person, p.proponent.username),
        disableFilters: true,
        Cell: AuthorCell,
      },
      {
        Header: '',
        id: 'actions',
        disableFilters: true,
        disableSortBy: true,
        Cell: ActionsCell,
      },
    ],
    [currentLang, reviewerProposals, showOfficerButtons, l]
  )

  return (
    <Card>
      <Card.Content>
        <div className={style.reviewersTable}>
          <Table
            columns={columns}
            data={[...reviewerProposals]}
            initialState={{
              sortBy: [
                { id: 'specOfficerApproval', desc: false },
                { id: 'reviewerAcceptance', desc: false },
                { id: 'reviewerName', desc: false },
              ],
            }}
            emptyDataMessage={l.thesisReviewerProposalsList.emptyResultsMessage}
          />
        </div>
      </Card.Content>
    </Card>
  )
}

export default ThesisReviewersTable
