import { css } from 'linaria'

import * as colors from '../../../styles/colors'
import { breakpoints, rel } from '../../../styles/dimensions'
import { fontWeights } from '../../../styles/typography'
import CheckIcon from '../../../images/icons/CheckDefault.svg'


// FIXME: lots of code duplication with MultiselectFilter.style.ts

const selectHeight = `${rel(38)}rem`
const selectOpenBackgroundColor = colors.backgroundColor[3]
const selectClosedBackgroundColor = colors.backgroundColor[2]
const selectBorderColor = colors.backgroundColor[2]


export const openToggle = css`
  position: absolute;
  top: 0;
  right: ${rel(10)}rem;
  padding: 0;
  background: transparent;
  border: none;
  cursor: pointer;

  &:focus {
    outline: none;
  }

  svg {
    max-width: ${rel(16)}rem;
    vertical-align: baseline;
    zoom: 0.75;
  }
`

export const selectLabel = css`
  height: ${selectHeight};
  padding: ${rel(6)}rem ${rel(30)}rem ${rel(6)}rem ${rel(10)}rem;
  color: ${colors.secondaryColor[2]};
  font-weight: ${fontWeights.light};
  font-size: ${rel(14)}rem;
  line-height: ${rel(26)}rem;
  white-space: nowrap;
  text-align: right;

  @media screen and (min-width: ${breakpoints.xl}) {
    font-size: ${rel(16)}rem;
  }
`

export const activeFilter = css`
  color: ${colors.primaryColor[2]};
`

export const panelContainer = css`
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  min-width: 100%;
  margin: -${rel(1)}rem;
  background: ${selectOpenBackgroundColor};
  border: ${rel(1)}rem solid ${selectBorderColor};
  border-radius: ${rel(6)}rem;
  box-shadow: 0 ${rel(5)}rem ${rel(5)}rem ${colors.boxShadow};

  & .${selectLabel} {
    color: ${colors.secondaryColor[2]};
  }
`

export const select = css`
  position: relative;
  display: inline-block;
  width: 100%;
  height: ${selectHeight};
  overflow: visible;
  font-weight: ${fontWeights.light};
  line-height: ${selectHeight};
  background: ${selectClosedBackgroundColor};
  border-radius: ${rel(6)}rem;
  cursor: pointer;
  user-select: none;
`

export const optionsListItem = css`
  margin-right: -${rel(8)}rem;
  margin-left: -${rel(8)}rem;
  padding: ${rel(2)}rem ${rel(32)}rem ${rel(2)}rem ${rel(8)}rem;
  font-weight: ${fontWeights.normal};
  line-height: ${rel(25)}rem;
  text-align: left;
  border: none;

  &:hover {
    background-color: ${colors.backgroundColor[2]};
  }
`

export const active = css`
  background-image: url(${CheckIcon});
  background-repeat: no-repeat;
  background-position: right ${rel(10)}rem center;
  background-size: ${rel(14)}rem auto;
`

export const optionsList = css`
  z-index: 10;
  max-height: 33vh;
  margin: 0;
  padding: 0 ${rel(8)}rem ${rel(4)}rem;
  padding-top: 0;
  overflow-x: hidden;
  overflow-y: auto;
  color: ${colors.primaryColor[2]};
  font-size: ${rel(16)}rem;
  line-height: 1.5;
  list-style: none;

  .${optionsListItem} {
    /* not empty */
  }
`
