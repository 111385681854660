export function truncateAtWord (text: string, length: number): string {
  if (!text || text.length <= length) {
    return text
  }

  const regex = new RegExp(`^.{0,${length}}\\s`, 'm')
  const [match] = regex.exec(text) ?? []

  return (match ?? text.slice(0, length)).trim() + '…'
}

export const assert: (value: any, message?: string, ...optionalParams: any[]) => asserts value = console.assert
