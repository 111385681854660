import { css } from 'linaria'

import { backgroundColor } from '../../../styles/colors'
import { fontWeights } from '../../../styles/typography'
import { rel, breakpoints } from '../../../styles/dimensions'


export const wrapper = css`
  display: flex;
  align-items: flex-end;
  padding: ${rel(12)}rem ${rel(16)}rem ${rel(10)}rem;
  background-color: ${backgroundColor[2]};
  border-radius: ${rel(6)}rem;

  @media screen and (min-width: ${breakpoints.xxl}) {
    padding: ${rel(12)}rem ${rel(16)}rem ${rel(8)}rem;
  }

  & + * {
    margin-top: ${rel(20)}rem;
  }
`

export const title = css`
  margin-bottom: 0;
  font-weight: ${fontWeights.bold};
  font-size: ${rel(22)}rem;
  line-height: ${rel(26)}rem;
  white-space: pre-line;

  @media screen and (min-width: ${breakpoints.xxl}) {
    font-size: ${rel(28)}rem;
    line-height: ${rel(36)}rem;
  }
`

export const grade = css`
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
  margin-left: auto;
  font-weight: ${fontWeights.bold};
  font-size: ${rel(54)}rem;
  line-height: ${rel(52)}rem;

  @media screen and (min-width: ${breakpoints.xxl}) {
    font-size: ${rel(76)}rem;
    line-height: ${rel(72)}rem;
  }
`
