import { css } from 'linaria'

import { backgroundColor } from '../../styles/colors'
import { breakpoints, rel } from '../../styles/dimensions'
import { fontSizes } from '../../styles/typography'


export const body = css`
  margin-top: ${rel(8)}rem;
  padding: ${rel(12)}rem;
  font-size: ${fontSizes.body.mobile}rem;
  line-height: 1.375;
  background-color: ${backgroundColor[2]};
  border-radius: ${rel(8)}rem;

  @media screen and (min-width: ${breakpoints.xl}) {
    padding: ${rel(16)}rem;
  }

  p:last-child {
    margin-bottom: 0;
  }
`
