import { useEffect, useState } from 'react'
import { ReviewerProposal, Thesis } from '@cvut/profit-api-types/lib/theses'

import ThesisReviewersTable from './ThesisReviewersTable'
import Spinner from '../../components/Spinner'
import ThesisReviewerActionsCard from './ThesisReviewerActionsCard'
import ThesisReviewerSearchCard from './ThesisReviewerSearchCard'
import { useLocale } from '../../locale'
import { useThesis } from '../../api/theses'
import { useThesisReviewerProposals } from '../../api/theses/reviewerProposals'
import { useThesisReviewerProposalActionsAccessControl } from '../../access-control/reviewerProposalActions'
import * as style from './ThesisReviewer.style'


interface Props {
  thesis: Thesis
}

const ThesisReviewer = ({ thesis }: Props): JSX.Element => {
  const { l } = useLocale()
  const [thesisReviewersState, getReviewers] = useThesisReviewerProposals()
  const [thesisState, getThesis] = useThesis()
  const [reviewerProposals, setReviewerProposals] = useState<ReviewerProposal[]>([])

  // updated thesis should come from the API; as fallback use thesis from parameter
  thesis = thesisState.state === 'success' ? thesisState.data ?? thesis : thesis

  const {
    shouldDisplayReviewerSearchCard,
    shouldDisplayReviewerActionsCard,
    shouldDisplayOfficerButtons,
  } = useThesisReviewerProposalActionsAccessControl(thesis)

  const reloadReviewerProposals = () => {
    void getThesis(thesis.id)
    void getReviewers(thesis.id)
  }

  useEffect(reloadReviewerProposals, [])

  useEffect(() => {
    if (thesisReviewersState.state === 'success') {
      setReviewerProposals(thesisReviewersState.data?.data ?? [])
    }
  }, [thesisReviewersState.state])

  return (
    <>
      {shouldDisplayReviewerSearchCard && (
        <ThesisReviewerSearchCard
          thesis={thesis}
          onActionCompleted={reloadReviewerProposals}
        />)}
      {shouldDisplayReviewerActionsCard && (
        <ThesisReviewerActionsCard
          thesis={thesis}
          reviewerProposals={reviewerProposals}
          onActionCompleted={reloadReviewerProposals}
        />)}
      {thesisReviewersState.state === 'success' && (
        <ThesisReviewersTable
          thesis={thesis}
          reviewerProposals={reviewerProposals}
          showOfficerButtons={shouldDisplayOfficerButtons}
          onActionCompleted={reloadReviewerProposals}
        />
      )}
      {thesisReviewersState.state === 'loading' && (
        <div className={style.spinnerContainer}>
          <Spinner.DualRings color='primary' title={l.thesis.loadingThesis} />
        </div>
      )}
    </>
  )
}

export default ThesisReviewer
