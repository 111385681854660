import { useState } from 'react'
import { StudyDegree } from '@cvut/profit-api-types/lib/theses'

import { UseRequestStatus } from './useRequest'


// TODO: useThesesMeta() should be used instead, after study degrees are included in theses/meta API response
export function useStudyDegrees (): [
  UseRequestStatus<StudyDegree[]>,
  () => Promise<void>,
] {
  const [requestStatus, setRequestStatus] = useState<UseRequestStatus<StudyDegree[]>>({ state: 'not-initiated' })

  return [
    requestStatus,
    async () => {
      await Promise.resolve(
        setRequestStatus({
          state: 'success',
          response: new Response(),
          data: Object.values(StudyDegree),
        })
      )
    },
  ]
}
