import { useState, useEffect } from 'react'
import urlCat from 'urlcat'

import { AbortedError, HttpError, MakeRequest, NetworkError, RequestWithResponseError } from './makeRequest'
import { addNotification } from '../features/toastNotifications'
import { useLocale } from '../locale'


export interface UseRequestSuccess<T> {
  state: 'success'
  data?: T
  response: Response
}

export interface UseRequestError {
  state: 'error'
  response?: Response
  httpProblem?: HttpError<unknown>['problem']
}

export interface UseRequestLoading {
  state: 'loading'
}

export interface UseRequestNotInitiated {
  state: 'not-initiated'
}

export interface UseRequestAborted {
  state: 'aborted'
  /**
   * This signal may not be the one which caused the request to be aborted (but it most likelly will be).
   * An example of such case would be if the requestor passed to `useRequest` had its separate `signal` property which
   * is used to cancel the request, in that case `useRequest` doesn't know about such signal.
   */
  signal?: AbortSignal
}

export type UseRequestStatus<T> =
  | UseRequestSuccess<T>
  | UseRequestError
  | UseRequestLoading
  | UseRequestNotInitiated
  | UseRequestAborted

export interface UseRequestConfig {
  errorHandlers?: Record<number, (err: RequestWithResponseError) => any>
  defaultErrorMessage: (err: RequestWithResponseError) => string
}

// TODO: Use `useMultipleRequests` to define this.
/**
 * @param makeRequest A function which makes requests.
 */
export function useRequest<T> (makeRequest: MakeRequest, config: UseRequestConfig): [
  result: UseRequestStatus<T>,
  sendRequest: (...requestParams: Parameters<MakeRequest>) => Promise<void>,
  abortRequest: () => void,
] {
  const [result, setResult] = useState<UseRequestStatus<T>>({ state: 'not-initiated' })
  const { l } = useLocale()
  const abortController = new AbortController()

  useEffect(() => {
    return () => abortController.abort()
  }, [])

  return [result, async (method, pathTemplate, params, requestConfig) => {
    setResult({ state: 'loading' })

    try {
      const [response, data] = await makeRequest<T>(method, pathTemplate, params, requestConfig)
      setResult({
        state: 'success',
        data,
        response,
      })
    } catch (err) {
      // Handle an aborted fetch request first.
      if (err instanceof AbortedError) {
        setResult({ state: 'aborted', signal: abortController.signal })
        return
      }

      const newResult: UseRequestError = {
        state: 'error',
      }

      if (err instanceof NetworkError) {
        addNotification({
          type: 'NEGATIVE',
          message: l.errorMessages.networkError(urlCat(pathTemplate, params ?? {})),
        })
      }

      if (err instanceof RequestWithResponseError) {
        newResult.response = err.response

        const statusCode = err.response.status
        if (config?.errorHandlers?.[statusCode]) {
          config?.errorHandlers?.[statusCode](err)
        } else {
          addNotification({
            type: 'NEGATIVE',
            message: config.defaultErrorMessage(err),
          })
        }
      }

      if (err instanceof HttpError) {
        newResult.httpProblem = err.problem
      }

      setResult(newResult)
    }
  }, () => abortController.abort()]
}

// FIXME: There is a lot of overlap with `useRequest`. I'm running out of time, sorry.
//   ~Tung
export function useWrappedRequest<T, A extends any[]> (makeRequest: () => ReturnType<MakeRequest>): [
  UseRequestStatus<T>,
  (...args: A) => Promise<void>,
] {
  const [result, setResult] = useState<UseRequestStatus<T>>({ state: 'not-initiated' })

  return [result, async () => {
    setResult({ state: 'loading' })

    try {
      const [response, data] = await makeRequest()
      setResult({
        state: 'success',
        response,
        data: data as T,
      })
    } catch (err) {
      if (err instanceof AbortedError) {
        setResult({ state: 'aborted' })
        return
      }

      setResult({ ...err, state: 'error' })
    }
  }]
}

// XXX: Instead of having a `data` array and a `response` array, consider an
// array of pairs of these things. At the time of writing, this wasn't done because
// there is no time to properly learn how to do it (in C++, the buzzword is "variadic
// template").
export interface UseWrappedMultipleRequestsSuccess<T extends any[] = any[]> {
  state: 'success'
  data?: T
  responses: Response[]
}

export type UseWrappedMultipleRequestsStatus<T extends any[] = any[]> =
  | UseRequestNotInitiated
  | UseRequestLoading
  | UseWrappedMultipleRequestsSuccess<T>
  | UseRequestError
  | UseRequestAborted

// FIXME: There is even more overlap now... Data fetching will have to be reworked,
// this is starting to become unmaintainable.
// TODO: Write documentation of this thing.
/**
 * @param request The function must be wrapped in `useCallback`, otherwise you
 * will infinitely rerender on error!
 */
export function useWrappedMultipleRequests<T extends any[] = any[]> (
  request: () => Promise<[Response, unknown]>[],
): [result: UseWrappedMultipleRequestsStatus<T>, reload: () => Promise<void>] {
  const [result, setResult] = useState<UseWrappedMultipleRequestsStatus<T>>({ state: 'not-initiated' })
  async function requestData () {
    try {
      const resps = await Promise.all(request())
      setResult({
        state: 'success',
        data: resps.map(resp => resp[1]) as T,
        responses: resps.map(resp => resp[0]),
      })
    } catch (err) {
      if (err instanceof AbortedError) {
        setResult({ state: 'aborted' })
        return
      }

      setResult({ ...err, state: 'error' })
    }
  }

  useEffect(() => {
    void requestData()
  }, [request])

  return [result, requestData]
}
