import type { Person, PersonRef } from './person'
import type { Thesis, ThesisAction } from './thesis'
import type { Page } from '../common'
import { ResourceType } from './common'


/**
 * This only serves to generate types of event logs of resources.
 *
 * @internal don't expose in JSON Schema
 */
export type EventLog<
  TAction extends string = string,
  TResource extends object = Record<string, any>,
  TResourceType extends string = string,
> = Readonly<{

  id: number,

  /**
   * Type of the resource whose events we log.
   */
  resourceType: TResourceType,

  /**
   * ID of the resource of type `resourceType` we log.
   */
  resourceId: number,

  action: TAction,

  /**
   * A `null` author means that the system itself is the author.
   */
  author: Person | PersonRef | null,

  /**
   * A comment from the author.
   * @default ''
   */
  comment: string,

  /**
   * The date and time at which this object was created.
   */
  createdAt: Date,

  /**
   * Changes of the resource described by JSON Merge patch, i.e. only changed fields are
   * present and `null` denotes the removal of the resource's value.
   */
  patch?: Partial<TResource>,
}>

/**
 * This is a companion of `EventLog` for creating lists of events.
 *
 * @internal don't expose in JSON Schema
 * @see EventLog
 */
export type EventLogList<
  TAction extends string,
  TResource extends object,
  TResourceType extends string = string
> = Page<EventLog<TAction, TResource, TResourceType>>

/* eslint-disable @typescript-eslint/indent */
export type ThesesEventLog = EventLog<
  ThesisAction,
  Omit<Thesis, 'id' | 'modifiedAt' | '_actions'>,
  (typeof ResourceType)['Thesis']
>
/* eslint-enable */
export type ThesesEventLogList = Page<ThesesEventLog>
