import type { PersonFilter, PersonList } from '@cvut/profit-api-types/lib/theses/person'

import { PaginationParams } from './paginatedRequests'
import { apiPrefix } from '../config'
import { createRequestor } from './makeRequest'
import { useRequest, UseRequestConfig, UseRequestStatus } from './useRequest'
import { useLocale } from '../locale'


const peopleRequestor = createRequestor(`${apiPrefix}/people`)

function usePeopleRequest<T> (config: UseRequestConfig) {
  return useRequest<T>(peopleRequestor, config)
}

export async function getPeople (
  filter: PersonFilter = { includeInactive: false },
  pagination: PaginationParams & { sort?: string } = {
    offset: 0,
    limit: 10,
    sort: 'username,lastName,firstName',
  },
  requestor = peopleRequestor,
): Promise<[Response, PersonList]> {
  const [resp, personList] = await requestor<PersonList>('GET', '/', { ...filter, ...pagination })

  // personList cannot be undefined - the API should always return body matching the requested type
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return [resp, personList!]
}

export function usePeople (): [
  UseRequestStatus<PersonList>,
  (q?: string, role?: string, limit?: number, sort?: string) => Promise<void>,
] {
  const { l } = useLocale()
  const personInLocale = l.person.toLocaleLowerCase()
  const [requestStatus, sendRequest] = usePeopleRequest<PersonList>({
    defaultErrorMessage: () => l.errorMessages.api.defaultGetList(personInLocale),
  })

  return [
    requestStatus,
    async (q, role, limit = 10, sort = 'lastName') => await sendRequest('GET', '/', { q, role, limit, sort }),
  ]
}
