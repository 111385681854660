import { useEffect } from 'react'
import { cx } from 'linaria'
import { Person, ReviewerProposal, Thesis } from '@cvut/profit-api-types/lib/theses'

import { addNotification } from '../toastNotifications'
import {
  useProposeThesisReviewer,
  useReviewerProposalAction,
} from '../../api/theses/reviewerProposals'
import { formatPersonFullName } from '../../utils/person'
import { useLocale } from '../../locale'
import { ReactComponent as CheckIcon } from '../../images/icons/Check.svg'
import { ReactComponent as DeleteIcon } from '../../images/icons/Delete.svg'
import * as style from './ThesisReviewerProposalControls.style'
import * as buttonStyle from '../../components/Button.style'


interface ReviewerProposalControlsProps {
  reviewerProposalId: ReviewerProposal['id']
  onActionCompleted: () => unknown
}

interface ApplyControlsProps {
  onActionCompleted: () => unknown
  person: Person
  thesisId: Thesis['id']
}


export const AcceptedProposalControls = ({
  reviewerProposalId, onActionCompleted,
}: ReviewerProposalControlsProps): JSX.Element => {
  const { l } = useLocale()
  const [cancelationStatus, cancelProposal] = useReviewerProposalAction('reject')

  useEffect(() => {
    if (cancelationStatus.state === 'success') {
      addNotification({
        type: 'POSITIVE',
        message: l.thesisReviewerProposal.successMessages.cancel,
      })
      onActionCompleted()
    }
  }, [cancelationStatus.state])

  const handleCancelClick = async () => await cancelProposal(reviewerProposalId, 'reviewer')

  return (
    <>
      <span className={style.acceptedMessage}>
        <CheckIcon /> {l.thesisReviewerProposal.actions.proposalAccepted}
      </span>
      <button
        type='button'
        className={cx(style.actionButton, buttonStyle.outlineDark)}
        onClick={handleCancelClick}
        disabled={cancelationStatus.state === 'loading'}
      >
        {l.thesisReviewerProposal.actions.cancel}
      </button>
    </>
  )
}


export const PendingProposalControls = ({
  reviewerProposalId, onActionCompleted,
}: ReviewerProposalControlsProps): JSX.Element => {
  const { l } = useLocale()
  const [acceptanceStatus, acceptProposal] = useReviewerProposalAction('accept')
  const [cancelationStatus, cancelProposal] = useReviewerProposalAction('reject')

  useEffect(() => {
    if (acceptanceStatus.state === 'success') {
      addNotification({
        type: 'POSITIVE',
        message: l.thesisReviewerProposal.successMessages.accept,
      })
      onActionCompleted()
    }
  }, [acceptanceStatus.state])

  useEffect(() => {
    if (cancelationStatus.state === 'success') {
      addNotification({
        type: 'POSITIVE',
        message: l.thesisReviewerProposal.successMessages.cancel,
      })
      onActionCompleted()
    }
  }, [cancelationStatus.state])

  const handleAcceptClick = async () => await acceptProposal(reviewerProposalId, 'reviewer')
  const handleCancelClick = async () => await cancelProposal(reviewerProposalId, 'reviewer')

  return (
    <>
      <button
        type='button'
        className={cx(style.actionButton, buttonStyle.accept)}
        onClick={handleAcceptClick}
        disabled={acceptanceStatus.state === 'loading' || cancelationStatus.state === 'loading'}
      >
        {l.thesisReviewerProposal.actions.accept}
      </button>
      <button
        type='button'
        className={cx(style.actionButton, buttonStyle.reject)}
        onClick={handleCancelClick}
        disabled={acceptanceStatus.state === 'loading' || cancelationStatus.state === 'loading'}
      >
        {l.thesisReviewerProposal.actions.reject}
      </button>
    </>
  )
}

export const ApplyControls = ({ onActionCompleted, person, thesisId }: ApplyControlsProps): JSX.Element => {
  const { l } = useLocale()
  const [proposalStatus, proposeReviewer] = useProposeThesisReviewer()

  useEffect(() => {
    if (proposalStatus.state === 'success') {
      addNotification({
        type: 'POSITIVE',
        message: l.thesisReviewerProposal.successMessages.propose(formatPersonFullName(person)),
      })
      onActionCompleted()
    }
  }, [proposalStatus.state])

  const handleProposeClick = async () => await proposeReviewer(thesisId, person.username)

  return (
    <button
      type='button'
      className={cx(style.actionButton, buttonStyle.accept)}
      onClick={handleProposeClick}
      disabled={proposalStatus.state === 'loading'}
    >
      {l.thesisReviewerProposal.actions.proposeMyself}
    </button>
  )
}


export const ApproveDisapproveControls = ({
  reviewerProposalId, onActionCompleted,
}: ReviewerProposalControlsProps): JSX.Element => {
  const { l } = useLocale()
  const [acceptanceStatus, acceptProposal] = useReviewerProposalAction('accept')
  const [rejectionStatus, rejectProposal] = useReviewerProposalAction('reject')

  useEffect(() => {
    if (acceptanceStatus.state === 'success') {
      addNotification({
        type: 'POSITIVE',
        message: l.thesisReviewerProposalsList.successMessages.accept,
      })
      onActionCompleted()
    }
  }, [acceptanceStatus.state])

  useEffect(() => {
    if (rejectionStatus.state === 'success') {
      addNotification({
        type: 'POSITIVE',
        message: l.thesisReviewerProposalsList.successMessages.reject,
      })
      onActionCompleted()
    }
  }, [rejectionStatus.state])

  const handleAcceptClick = async () => await acceptProposal(reviewerProposalId, 'specOfficer')
  const handleRejectClick = async () => await rejectProposal(reviewerProposalId, 'specOfficer')

  return (
    <>
      <button
        type='button'
        className={cx(style.actionButton, buttonStyle.accept)}
        onClick={handleAcceptClick}
        disabled={acceptanceStatus.state === 'loading' || rejectionStatus.state === 'loading'}
      >
        {l.thesisReviewerProposalsList.actions.approve}
      </button>
      <button
        type='button'
        className={cx(style.actionButton, buttonStyle.reject)}
        onClick={handleRejectClick}
        disabled={acceptanceStatus.state === 'loading' || rejectionStatus.state === 'loading'}
      >
        {l.thesisReviewerProposalsList.actions.disapprove}
      </button>
    </>
  )
}


export const RemoveReviewerControls = ({
  reviewerProposalId, onActionCompleted,
}: ReviewerProposalControlsProps): JSX.Element => {
  const { l } = useLocale()
  const [cancelationStatus, cancelProposal] = useReviewerProposalAction('reject')

  useEffect(() => {
    if (cancelationStatus.state === 'success') {
      addNotification({
        type: 'POSITIVE',
        message: l.thesisReviewerProposalsList.successMessages.cancel,
      })
      onActionCompleted()
    }
  }, [cancelationStatus.state])

  const handleCancelClick = async () => await cancelProposal(reviewerProposalId, 'proponent')

  return (
    <button
      type='button'
      className={cx(style.removeCandidateButton, style.actionButton, buttonStyle.outlineDark)}
      onClick={handleCancelClick}
      disabled={cancelationStatus.state === 'loading'}
    >
      {l.thesisReviewerProposalsList.actions.remove}
      <DeleteIcon />
    </button>
  )
}
