import * as style from './LoadingOverlay.style'
import Spinner from '../Spinner'
import { useLocale } from '../../locale'


const LoadingOverlay = (): JSX.Element => {
  const { l } = useLocale()

  return (
    <div className={style.spinnerOuterWrapper}>
      <div className={style.spinnerInnerWrapper}>
        <Spinner.DualRings title={l.thesis.loadingTheses} color='primary' />
      </div>
    </div>
  )
}

export default LoadingOverlay
