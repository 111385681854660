import { css, cx } from 'linaria'

import * as colors from '../../../styles/colors'
import { fontWeights } from '../../../styles/typography'
import { rel } from '../../../styles/dimensions'


export const boxGroup = css`
  & + & {
    margin-top: ${rel(16)}rem;
  }
`

export const boxGroupLabel = css`
  display: block;
  margin-bottom: 0;
  font-weight: ${fontWeights.bold};
  font-size: ${rel(18)}rem;
  line-height: ${rel(26)}rem;
`

export const boxInputItems = css`
  margin-top: ${rel(4)}rem;
`

export const boxInputItem = css`
  position: relative;

  & + & {
    margin-top: ${rel(4)}rem;
  }
`

export const boxInput = css`
  position: absolute;
  top: ${rel(3)}rem;
  left: 0;
  width: ${rel(18)}rem;
  height: ${rel(18)}rem;
  vertical-align: middle;
  opacity: 0;
`

export const label = css`
  margin-bottom: 0;
  padding-left: ${rel(26)}rem;
  font-size: ${rel(16)}rem;
  cursor: pointer;

  &::before,
  .${boxInput}:checked + &::after {
    position: absolute;
    display: block;
    cursor: pointer;
    content: "";
  }

  &::before {
    top: ${rel(3)}rem;
    left: 0;
    width: ${rel(18)}rem;
    height: ${rel(18)}rem;
    border: ${rel(2)}rem solid ${colors.primaryColor[2]};
  }

  .${boxInput}:checked + &::after {
    top: ${rel(8)}rem;
    left: ${rel(5)}rem;
    width: ${rel(8)}rem;
    height: ${rel(8)}rem;
    background-color: ${colors.primaryColor[2]};
    border: ${rel(2)}rem solid ${colors.primaryColor[2]};
  }
`

export const radioboxInput = cx(boxInput, css`
  & + .${label}::before,
  &:checked + .${label}::after {
    border-radius: 50%;
  }
`)
