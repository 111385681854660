import { kebabCase } from 'lodash'
import { useForm } from 'react-hook-form'
import type { PersonSettings } from '@cvut/profit-api-types/lib/theses/person'

import { useLocale } from '../../locale'
import Switch from '../../components/form/Switch'
import Card from '../../components/Card'
import Spinner from '../../components/Spinner'
import * as buttonStyle from '../../components/Button.style'
import * as style from './UserSettings.style'


type SettingKey = keyof PersonSettings

interface Props {
  settings: PersonSettings
  onSettingsUpdate: (settings: PersonSettings) => Promise<unknown>
}

const UserSettings = ({ settings, onSettingsUpdate }: Props): JSX.Element => {
  const { register, handleSubmit, formState: { isSubmitting } } = useForm<PersonSettings>({
    defaultValues: settings,
  })
  const { l } = useLocale()

  // If there are more categories of settings, then there will be some filtering
  // needed instead of just using `Object.keys`.
  const notificationSettingsNode = (Object.keys(settings) as Array<SettingKey>).map(setting => (
    <Switch
      key={setting}
      inputProps={{
        id: kebabCase(setting),
        defaultChecked: settings[setting],
        name: setting,
        ref: register,
      }}
      label={l.userSettings.labels[setting]}
      extraClassNames={[style.settingItem]}
    />
  ))

  return (
    <form onSubmit={handleSubmit(onSettingsUpdate)}>
      <Card>
        <Card.Header title={l.userSettings.notificationSettings.title} id='notifications' />
        <Card.Content>
          {notificationSettingsNode}
        </Card.Content>
      </Card>
      <div className={style.formControls}>
        <button type='submit' className={buttonStyle.fill} disabled={isSubmitting}>
          {
            // If the form is submitting, spin!
            isSubmitting
              ? <Spinner.DualRings color='white' title={l.userSettings.requestMessages.saving} />
              : l.form.save
          }
        </button>
      </div>
    </form>
  )
}

export default UserSettings
