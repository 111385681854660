import { useState, useEffect } from 'react'
import type { Thesis, ThesesEventLog, Person } from '@cvut/profit-api-types/lib/theses'

import ThesisChangesCard, { ThesisStateChange } from './ThesisChangesCard'
import { useThesesEventLog } from '../../../api/theses'
import RequestDependentRender from '../../../api/RequestDependentRender'
import { useLocale } from '../../../locale'
import Card from '../../../components/Card'
import Spinner from '../../../components/Spinner'


interface Props {
  thesisId: Thesis['id']
  collapsed?: boolean
}

/**
 * This component helps fetch the thesis event log.
 */
const ThesisChangesCardWrapper = ({ thesisId, collapsed = false }: Props): JSX.Element => {
  const [thesisEventLogStatus, noMoreEvents, _getMoreEvents] = useThesesEventLog({ thesisId })
  const { l } = useLocale()
  const thesisInLocale = l.thesis.thesis.toLocaleLowerCase()

  useEffect(_getMoreEvents, [])

  // FIXME: Without this, if we fetched more events and `collapsed === true`, then the change card would collapse
  // itself.
  const [passCollapsed, setPassCollapsed] = useState(true)
  function getMoreEvents () {
    setPassCollapsed(false)
    _getMoreEvents()
  }

  function convert (event: ThesesEventLog): ThesisStateChange {
    // FIXME: `event.author` is always expanded hence we never get `PersonRef`.
    const author = event.author
      ? event.author as Person
      : undefined

    // TODO: Write a proper type guard.
    const title = Object.keys(l.thesis.actionTitle).includes(event.action)
      ? l.thesis.actionTitle[event.action as keyof typeof l.thesis.actionTitle]
      : l.eventLog.unknownAction(event.action)

    return {
      id: event.id,
      title,
      author,
      createdAt: new Date(event.createdAt),
      description: event.comment,
    }
  }

  return (
    <RequestDependentRender
      requestStatus={thesisEventLogStatus}
      // FIXME: On subsequent `getMoreEvents` the change history will change into a spinner temporarily. This causes
      // the viewport to scroll up and on a successful fetch, the change history will expand itself hence we are
      // suddenly away from the "get more events" button.
      renderOnLoading={() => (
        <Spinner.DualRings
          title={l.eventLog.loadingEventLog}
          color='primary'
        />
      )}
      renderOnError={() => (
        <Card>
          <Card.Header title={l.eventLog.title} />
          <Card.Content>
            {l.eventLog.errorGettingEventLog(thesisInLocale, thesisId)}
          </Card.Content>
        </Card>
      )}
      renderOnSuccess={eventLog => (
        <ThesisChangesCard
          changes={eventLog.map(convert)}
          getNextChanges={noMoreEvents ? undefined : getMoreEvents}
          collapsed={passCollapsed ? collapsed : undefined}
        />
      )}
    />
  )
}

export default ThesisChangesCardWrapper
