import { StudyList } from '@cvut/profit-api-types/lib/theses'

import { apiPrefix } from '../config'
import { createRequestor } from './makeRequest'
import { useRequest, UseRequestConfig, UseRequestStatus } from './useRequest'
import { useLocale } from '../locale'


const studiesRequestor = createRequestor(`${apiPrefix}/studies`)

function useStudiesRequest<T> (config: UseRequestConfig) {
  return useRequest<T>(studiesRequestor, config)
}

export function useStudies (): [
  UseRequestStatus<StudyList>,
  (personQuery?: string) => Promise<void>,
] {
  const { l } = useLocale()
  const studyInLocale = l.study.toLocaleLowerCase()
  const [requestStatus, sendRequest] = useStudiesRequest<StudyList>({
    defaultErrorMessage: () => l.errorMessages.api.defaultGetList(studyInLocale),
  })

  return [
    requestStatus,
    async (personQuery) => await sendRequest('GET', '/', {
      limit: 10,
      sort: 'person.lastName',
      personQuery,
    }),
  ]
}
