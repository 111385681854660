import { css } from 'linaria'

import { fontWeights } from '../../styles/typography'
import { rel } from '../../styles/dimensions'
import * as colors from '../../styles/colors'


export const acceptedMessage = css`
  margin-right: ${rel(16)}rem;
  color: ${colors.positiveColor[2]};
  font-weight: ${fontWeights.bold};
`

export const actionButton = css`
  font-size: ${rel(14)}rem;

  & + & {
    margin-left: ${rel(16)}rem;
  }
`

export const removeCandidateButton = css`
  display: flex;
  align-items: center;
  margin: 0 auto;

  svg {
    max-width: 1rem;
    margin-left: 0.5rem;
  }
`
