import { useState } from 'react'

import { truncateAtWord } from '../utils'
import { useLocale } from '../locale'
import * as style from './TruncatedText.style'
import { ReactComponent as ArrowDownIcon } from '../images/icons/ArrowDown.svg'
import { ReactComponent as ArrowUpIcon } from '../images/icons/ArrowUp.svg'


interface Props {
  length: number
  text: string
  children: (text: string) => JSX.Element | null
  defaultExpanded?: boolean
}

const TruncatedText = ({ length = 200, text, children, defaultExpanded }: Props): JSX.Element => {
  const [entireTextShown, setEntireTextShown] = useState(defaultExpanded ?? false)
  const { l } = useLocale()

  const textAlreadyShort = text.length <= length
  const truncatedText = entireTextShown || textAlreadyShort ? text : truncateAtWord(text, length)
  const expandTextArrow = entireTextShown ? <ArrowUpIcon /> : <ArrowDownIcon />
  const expandTextTitle = entireTextShown ? l.navigation.displayLess : l.navigation.displayMore
  const expandTextButton = textAlreadyShort
    ? null
    : (
      <button
        type='button'
        title={expandTextTitle}
        onClick={() => setEntireTextShown(prev => !prev)}
        className={style.expandTextButton}
      >
        {expandTextArrow}
      </button>
    )

  return (
    <div>
      {children(truncatedText)}
      {expandTextButton}
    </div>
  )
}

export default TruncatedText
