import ActionPanel, { Props as ActionPanelProps } from './ActionPanel'


interface Props {
  panels: ActionPanelProps[]
}

const ActionPanelWrapper = ({ panels }: Props): JSX.Element =>
  <>
    {panels.map(panelProps =>
      <ActionPanel
        key={panelProps.panelId}
        panelId={panelProps.panelId}
        actions={panelProps.actions}
        thesisId={panelProps.thesisId}
        // eslint-disable-next-line react/jsx-handler-names
        onRequestReady={panelProps.onRequestReady}
      />
    )}
  </>


export default ActionPanelWrapper
