import { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import { cx } from 'linaria'
import type { NotificationsStatus, Person } from '@cvut/profit-api-types/lib/theses'

import { useNotificationStatus } from '../../api/notifications'
import { useLocale } from '../../locale'
import { formatPersonFullName } from '../../utils/person'
import NotificationsPanel from '../../features/notifications/NotificationsPanel'
import DropdownButton from '../DropdownButton'
import Badge from '../Badge'
import * as style from './AuthenticatedUserArea.style'
import { ReactComponent as ProfileIcon } from '../../images/icons/Profile.svg'
import { ReactComponent as InfoBellRoundIcon } from '../../images/icons/InfoBellRound.svg'
import { ReactComponent as TaskRoundIcon } from '../../images/icons/TaskRound.svg'
import useVisibilityChange from '../../hooks/useVisibilityChange'


const notificationsUpdateInterval = 10000

interface Props {
  profilePath: string
  userSettingsPath: string
  logoutPath: string
  person: Person
  onLogout: () => unknown
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars-experimental
const AuthenticatedUserArea = ({ profilePath, userSettingsPath, logoutPath, person, onLogout }: Props): JSX.Element => {
  const [getNotifStatusReqStatus, getNotificationsStatus] = useNotificationStatus()
  const [notificationsStatus, setNotificationsStatus] = useState<NotificationsStatus>()
  const [noticesPanelOpen, setNoticesPanelOpen] = useState(false)
  const [tasksPanelOpen, setTasksPanelOpen] = useState(false)
  const noticesTriggerBtn = useRef<HTMLButtonElement | null>(null)
  const notificationsUpdateHandleRef = useRef<number | null>(null)
  const tasksTriggerBtn = useRef<HTMLButtonElement | null>(null)
  const { l } = useLocale()

  const enableNotificationsUpdate = () => {
    if (!notificationsUpdateHandleRef.current) {
      void getNotificationsStatus()
      notificationsUpdateHandleRef.current = window.setInterval(() => {
        void getNotificationsStatus()
      }, notificationsUpdateInterval)
    }
  }

  const disableNotificationsUpdate = () => {
    if (notificationsUpdateHandleRef.current) {
      clearInterval(notificationsUpdateHandleRef.current)
      notificationsUpdateHandleRef.current = null
    }
  }

  useEffect(() => {
    enableNotificationsUpdate()
    return disableNotificationsUpdate
  }, [])

  const handleNotificationsUpdate = (isVisible: boolean) => isVisible
    ? enableNotificationsUpdate()
    : disableNotificationsUpdate()

  useVisibilityChange(handleNotificationsUpdate)

  useEffect(() => {
    if (getNotifStatusReqStatus.state === 'success') {
      setNotificationsStatus(getNotifStatusReqStatus.data)
    }
    // if the notification status request fails, user gets toast error notification and notification badges won't appear
  }, [getNotifStatusReqStatus.state])

  const toggleNoticesPanelOpen = () => {
    setNoticesPanelOpen(!noticesPanelOpen)
    // prevent the notices and tasks panel from displaying simultaneously
    setTasksPanelOpen(false)
  }

  const toggleTasksPanelOpen = () => {
    setTasksPanelOpen(!tasksPanelOpen)
    // prevent the notices and tasks panel from displaying simultaneously
    setNoticesPanelOpen(false)
  }

  const dropdownButtonContent = (
    <>
      <ProfileIcon className={style.profileIcon} />
      <span className={style.userFullName}>
        {formatPersonFullName(person)}
      </span>
    </>
  )

  const noticesPanelHtmlId = 'notices-panel'
  const tasksPanelHtmlId = 'tasks-panel'

  return (
    <div className={style.userArea}>
      <button
        type='button'
        ref={tasksTriggerBtn}
        className={style.badgeButton}
        title={l.siteHeader.seeTasks}
        onClick={toggleTasksPanelOpen}
        aria-haspopup
        aria-expanded={tasksPanelOpen}
        aria-controls={tasksPanelHtmlId}
      >
        <Badge count={notificationsStatus?.tasks?.unresolved ?? 0} countMax={99}>
          <TaskRoundIcon className={cx(style.taskIcon, tasksPanelOpen && style.active)} />
        </Badge>
      </button>
      <button
        type='button'
        ref={noticesTriggerBtn}
        className={style.badgeButton}
        title={l.siteHeader.seeNotices}
        onClick={toggleNoticesPanelOpen}
        aria-haspopup
        aria-expanded={noticesPanelOpen}
        aria-controls={noticesPanelHtmlId}
      >
        <Badge count={notificationsStatus?.notices?.unseen ?? 0} countMax={99}>
          <InfoBellRoundIcon className={cx(style.infoIcon, noticesPanelOpen && style.active)} />
        </Badge>
      </button>
      <DropdownButton
        htmlId='user-profile-dropdown'
        buttonContent={dropdownButtonContent}
        dropdownRight
        mode='light'
      >
        <ul className={style.dropdownContent} role='menu'>
          <li><Link to={userSettingsPath}>{l.userSettings.title}</Link></li>
          {/* TODO: uncomment once user profile page is ready. */}
          {/* <li><Link to={profilePath}>{l.siteHeader.profile}</Link></li> */}
          <li><Link to={logoutPath} onClick={onLogout}>{l.siteHeader.logout}</Link></li>
        </ul>
      </DropdownButton>
      <div className={style.notificationsPanelContainer}>
        {noticesPanelOpen && (
          <NotificationsPanel
            type='notice'
            htmlId={noticesPanelHtmlId}
            triggerBtnRef={noticesTriggerBtn}
            onMarkedSeen={getNotificationsStatus}
            onClose={toggleNoticesPanelOpen}
          />
        )}
        {tasksPanelOpen && (
          <NotificationsPanel
            type='task'
            htmlId={tasksPanelHtmlId}
            triggerBtnRef={tasksTriggerBtn}
            onMarkedSeen={getNotificationsStatus}
            onClose={toggleTasksPanelOpen}
          />
        )}
      </div>
    </div>
  )
}

export default AuthenticatedUserArea
