import dedent from 'dedent'
import { objectOmit } from '@cvut/profit-utils'

import {
  reviewerSectionsOrder,
  supervisorSectionsOrder,
  ReviewerReportTexts,
  SupervisorReportTexts,
} from './internal'


const sections: (ReviewerReportTexts & SupervisorReportTexts)['sections'] = {
  assignmentFulfillment: {
    title: 'Fulfillment of the assignment',
    description: dedent`
      Assess whether the submitted FT defines the objectives sufficiently and in line with the \
      assignment; whether the objectives are formulated correctly and fulfilled sufficiently. \
      In the comment, specify the points of the assignment that have not been met, assess the \
      severity, impact, and, if appropriate, also the cause of the deficiencies. If the assignment \
      differs substantially from the standards for the FT or if the student has developed the FT \
      beyond the assignment, describe the way it got reflected on the quality of the assignment’s \
      fulfilment and the way it affected your final evaluation.`,
    type: 'choice',
    choices: {
      1: 'assignment fulfilled',
      2: 'assignment fulfilled with minor objections',
      3: 'assignment fulfilled with major objections',
      4: 'assignment not fulfilled',
    },
  },

  mainWrittenPart: {
    title: 'Main written part',
    description: dedent`
      Evaluate whether the extent of the FT is adequate to its content and scope: are all the \
      parts of the FT contentful and necessary? Next, consider whether the submitted FT is actually \
      correct – are there factual errors or inaccuracies?

      Evaluate the logical structure of the FT, the thematic flow between chapters and whether the \
      text is comprehensible to the reader. Assess whether the formal notations in the FT are used \
      correctly. Assess the typographic and language aspects of the FT, follow the \
      <a href="https://courses.fit.cvut.cz/SFE/download/files/Smernice_52_2021_EN.pdf" \
      target="_blank" rel="noreferrer">Dean’s Directive No. 52/2021</a>, Art. 3.

      Evaluate whether the relevant sources are properly used, quoted and cited. Verify that all \
      quotes are properly distinguished from the results achieved in the FT, thus, that the \
      citation ethics has not been violated and that the citations are complete and in accordance \
      with citation practices and standards. Finally, evaluate whether the software and other \
      copyrighted works have been used in accordance with their license terms.`,
    type: 'score',
  },

  nonWrittenPart: {
    title: 'Non-written part, attachments',
    description: dedent`
      Depending on the nature of the FT, comment on the non-written part of the thesis. For \
      example: SW work – the overall quality of the program. Is the technology used (from the \
      development to deployment) suitable and adequate? HW – functional sample. Evaluate the \
      technology and tools used. Research and experimental work – repeatability of the experiment.`,
    type: 'score',
  },

  outcomeEvaluation: {
    title: 'Evaluation of results, publication outputs and awards',
    description: dedent`
      Depending on the nature of the thesis, estimate whether the thesis results could be deployed \
      in practice; alternatively, evaluate whether the results of the FT extend the already \
      published/known results or whether they bring in completely new findings.`,
    type: 'score',
  },

  studentActivity: {
    title: 'Activity of the student',
    description: dedent`
      From your experience with the course of the work on the thesis and its outcome, review the \
      student’s activity while working on the thesis, his/her punctuality when meeting the \
      deadlines and whether he/she consulted you as he/she went along and also, whether he/she was \
      well prepared for these consultations.`,
    type: 'choice',
    choices: {
      1: 'excellent activity',
      2: 'very good activity',
      3: 'average activity',
      4: 'weaker, but still sufficient activity',
      5: 'insufficient activity',
    },
  },

  studentSelfReliance: {
    title: 'Self-reliance of the student',
    description: dedent`
      From your experience with the course of the work on the thesis and its outcome, assess the \
      student’s ability to develop independent creative work.`,
    type: 'choice',
    choices: {
      1: 'excellent self-reliance',
      2: 'very good self-reliance',
      3: 'average self-reliance',
      4: 'weaker, but still sufficient self-reliance',
      5: 'insufficient self-reliance',
    },
  },

  summary: {
    title: 'The overall evaluation',
    description: dedent`
      Summarize which of the aspects of the FT affected your grading process the most. The overall \
      grade does not need to be an arithmetic mean (or other value) calculated from the evaluation \
      in the previous criteria. Generally, a well-fulfilled assignment is assessed by grade A.`,
    type: 'score',
  },

  defenseQuestions: {
    title: 'Questions for the defense',
    description: dedent`
      Formulate questions that the student should answer during the Presentation and defense of the \
      FT in front of the SFE Committee (use a bullet list).`,
    type: 'plain',
  },
}

export const reviewer: ReviewerReportTexts = {
  headers: {
    createdOn: 'Created on',
    evaluationCriteria: 'Evaluation criteria',
    instructions: 'Instructions',
    specialization: 'Branch / specialization',
    student: 'Student',
    thesisTitle: 'Thesis title',
    document: 'Review report of a final thesis',
    reportAuthor: 'Reviewer',
  },
  sections: objectOmit(sections, 'studentActivity', 'studentSelfReliance'),
  sectionsOrder: reviewerSectionsOrder,
}

export const supervisor: SupervisorReportTexts = {
  headers: {
    ...reviewer.headers,
    document: 'Supervisor’s statement of a final thesis',
    reportAuthor: 'Supervisor',
  },
  sections: objectOmit(sections, 'defenseQuestions'),
  sectionsOrder: supervisorSectionsOrder,
}
