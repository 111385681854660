import { cx } from 'linaria'
import { useForm } from 'react-hook-form'
import { ActionMessage } from '@cvut/profit-api-types/lib/theses'

import * as actionButtonStyle from './ActionButton.style'
import * as btnStyle from '../../components/Button.style'
import * as formStyle from '../../components/form/Form.style'
import * as style from './ResponseSection.style'
import TextInput from '../../components/form/TextInput'
import { ThesisPanelAction } from '../../api/theses'
import { useLocale } from '../../locale'


interface Props {
  action: ThesisPanelAction
  onRequestReady: (action: ThesisPanelAction, bodyData?: ActionMessage) => void
}

const ResponseSection = ({ action, onRequestReady }: Props): JSX.Element => {
  const { l } = useLocale()
  const { register, handleSubmit } = useForm<ActionMessage>()

  return (
    <div className={style.body} aria-labelledby={`${action}_dropdown`}>
      <form onSubmit={handleSubmit((data: ActionMessage) => onRequestReady(action, data))}>
        <TextInput
          multiline
          extraClassNames={[formStyle.formGroup]}
          labelText={l.actionPanel.form.label}
          inputProps={{
            id: `${action}_message`,
            name: 'message',
            required: true,
            ref: register,
          }}
        />
        <div className={formStyle.formActions}>
          <button className={cx(btnStyle.reject, actionButtonStyle.btn)}>
            {l.actionPanel.form.btn}
          </button>
        </div>
      </form>
    </div>
  )
}

export default ResponseSection
