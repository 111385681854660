import { label } from './ModificationIndicator.style'
import { useLocale } from '../../../locale'


interface Props {
  isModified?: boolean
}

/* UI component to show that form is modified */
const ModificationIndicator = ({ isModified = false }: Props): JSX.Element | null => {
  const { l } = useLocale()

  if (!isModified) {
    return null
  }

  return (
    <div className={label}>
      {l.form.formUnsaved}
    </div>
  )
}

export default ModificationIndicator
