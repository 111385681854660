import { ChangeEvent, FormEventHandler, useEffect } from 'react'
import { cx } from 'linaria'
import { Enum } from 'typescript-string-enums'
import { isEmpty } from 'lodash'
import { ThesisReportTexts, ThesisReportSection } from '@cvut/profit-theses-common/lib/texts/thesisReports'
import { ThesisReportPatch, ThesisReportType } from '@cvut/profit-api-types/lib/theses'
import { useForm, FieldError, FormState, get, UseFormMethods } from 'react-hook-form'

import BoxInput from '../../components/form/BoxInput'
import EvaluationInput from '../../components/form/EvaluationInput'
import Hint from '../../components/form/Hint'
import ModificationIndicator from '../../components/form/ModificationIndicator'
import Select from '../../components/form/Select'
import TextInput from '../../components/form/TextInput'
import locales, { useLocale, AvailableLocale } from '../../locale'
import * as buttonStyle from '../../components/Button.style'
import * as formStyle from '../../components/form/Form.style'
import * as styles from './ThesisReportForm.style'
import { ReportFields, ReportFormSectionIds } from './ThesisReportCreate'
import useFormBackup from '../../hooks/useFormBackup'
import { useModals } from '../../features/modals'


type ReportFormValue = string | number | { [x: string]: { value: number, comment: string }} | undefined

type ReportFormFieldEntries = [string, ReportFormValue][]

type ReportLocale = typeof locales.default.thesis.reports

export const reportInLocale = (language: AvailableLocale): ReportLocale => locales[language].thesis.reports

// checks whether required fields are filled
const hasRequiredFieldsFilled = (formEntries: ReportFormFieldEntries): boolean => (
  formEntries.filter(([id, inputValue]) => (
    // FIXME which items is this filtering out?
    ['score', 'summary', 'language'].includes(id)
    || typeof inputValue === 'object'
  ))
    .map(([id, inputValue]) => {
      // primitive values
      if (['score', 'summary', 'language'].includes(id)) {
        return inputValue
      }

      // sections
      if (typeof inputValue === 'object') {
        return inputValue.value
      }

      // FIXME when can this happen? shouldn't this be a simple if-else?
      return null
    })
    .every(value => !!value || String(value) === '0')
)

const canSubmit = (
  watch: UseFormMethods['watch'],
  hasUnsavedContent: boolean,
): boolean => (
  hasRequiredFieldsFilled(Object.entries(watch()))
  && !hasUnsavedContent
)

const shouldDisableSave = (
  formState: FormState<ThesisReportPatch>,
  hasUnsavedContent: boolean,
  hasErrors: boolean
): boolean => (
  !formState.isValid
  || !hasUnsavedContent
  || formState.isSubmitting
  || hasErrors
)

interface ReportLanguageSelectProps {
  reportLanguage: AvailableLocale
  register: UseFormMethods['register']
  errors?: UseFormMethods['errors']
  onChange?: (e: ChangeEvent<HTMLSelectElement>) => void
}

const ReportLanguageSelect = ({
  reportLanguage, register, errors, onChange,
}: ReportLanguageSelectProps): JSX.Element => {
  const { currentLang } = useLocale()
  const id = 'language'
  const error = get(errors, id, {}) as FieldError

  return (
    <>
      <Select
        selectProps={{
          'name': id,
          'id': id,
          'defaultValue': reportLanguage,
          'onChange': onChange,
          'aria-required': true,
          'aria-invalid': !isEmpty(error) || undefined,
          'ref': register({
            required: {
              value: true,
              message: locales[currentLang].form.required,
            },
          }),
        }}
        labelText={reportInLocale(currentLang).language}
        extraClassNames={[formStyle.formInput]}
      >
        <option disabled value=''>{locales[currentLang].thesis.selectLanguagePrompt}</option>
        {Enum.keys(AvailableLocale).map((language) => (
          <option key={language} value={language}>
            {locales[currentLang].thesis.languageOptions[language]}
          </option>
        ))}
      </Select>
      <Hint error={error.message} />
    </>
  )
}

interface FormControlsProps {
  isSaveDisabled: boolean
  onSubmit?: () => void
  downloadButtonText: string
  downloadLink?: string
  onClear?: () => void
}

const FormControls = ({
  isSaveDisabled,
  onSubmit,
  downloadButtonText,
  downloadLink,
  onClear,
}: FormControlsProps): JSX.Element => {
  const { l } = useLocale()

  return (
    <div className={formStyle.controls}>
      <div className={formStyle.controlsLeft}>
        <button
          type='button'
          onClick={onClear}
          className={buttonStyle.outlineDark}
          disabled={!onClear}
        >
          {l.form.discardChanges}
        </button>
      </div>
      <div className={formStyle.controlsRight}>
        <button
          type='submit'
          className={buttonStyle.fill}
          disabled={isSaveDisabled}
        >
          {l.thesis.save}
        </button>
        <a href={downloadLink} download>
          <button
            type='button'
            className={buttonStyle.fill}
            disabled={!isSaveDisabled || !downloadLink}
          >
            {downloadButtonText}
          </button>
        </a>
        <button
          type='button'
          className={buttonStyle.action}
          disabled={!onSubmit}
          onClick={onSubmit}
        >
          {l.thesis.reports.submitButton}
        </button>
      </div>
    </div>
  )
}

interface CommentItemProps {
  id: ReportFormSectionIds
  register: UseFormMethods['register']
  language: AvailableLocale
}

// textarea for multi-line textual comment
const CommentItem = ({ id, register, language }: CommentItemProps) => {
  const isSummary = id === 'summary'
  const isDefenseQuestions = id === 'defenseQuestions'
  const inputId = isSummary || isDefenseQuestions ? id : `${id}.comment`

  return (
    <div className={styles.reportFormStepItem}>
      <TextInput
        labelText={reportInLocale(language).commentLabel}
        inputProps={{
          name: inputId,
          id: inputId,
          ref: register,
        }}
        multiline
        extraClassNames={[formStyle.formInput, isSummary ? styles.submitRequired : '']}
      />
    </div>
  )
}

interface CriterionItemProps {
  id: ReportFormSectionIds
  section: ThesisReportSection
  register: UseFormMethods['register']
  language: AvailableLocale
}

// radio button group for selecting one of possible criterion options
const ChoiceItem = ({ id, section, register, language }: CriterionItemProps): JSX.Element => {
  const inputId = `${id}.value`
  const criterionInfo = section.choices
    ? reportInLocale(language).choiceInfo(Object.keys(section.choices).length)
    : ''
  const criterionOptions = Object.entries(section.choices ?? {}).map(([key, value]) => `${key}. ${value}`)

  return (
    <div className={styles.reportFormStepItem}>
      <div className={styles.criteriaInfo}>
        <p dangerouslySetInnerHTML={{ __html: section.description }} />
        <p>{criterionInfo}</p>
      </div>
      <BoxInput
        labelText={reportInLocale(language).evaluationLabel}
        inputProps={{
          type: 'radio',
          name: inputId,
          id: inputId,
          ref: register,
        }}
        options={criterionOptions}
        extraClassNames={[styles.submitRequired]}
      />
    </div>
  )
}

// number input for score
const ScoreItem = ({
  id, section, register, language, watch, errors,
}: CriterionItemProps & { watch: UseFormMethods['watch'], errors?: UseFormMethods['errors'] }) => {
  const { l } = useLocale()
  const formLocale = l.form.validation
  const isSummary = id === 'summary'
  const inputId = isSummary ? 'score' : `${id}.value`
  const error = get(errors, inputId, {}) as FieldError

  return (
    <div className={styles.reportFormStepItem}>
      <div className={styles.criteriaInfo}>
        <p dangerouslySetInnerHTML={{ __html: section.description }} />
        <p>{reportInLocale(language).pointsInfo}</p>
      </div>
      <EvaluationInput
        labelText={reportInLocale(language).evaluationLabel}
        inputProps={{
          'id': inputId,
          'name': inputId,
          'aria-invalid': !isEmpty(error) || undefined,
          'ref': register({
            min: {
              value: 0,
              message: formLocale.minValue(0),
            },
            max: {
              value: 100,
              message: formLocale.maxValue(100),
            },
            pattern: {
              value: /^([0-9]|([1-9][0-9])|100)$/,
              message: formLocale.integerOnly,
            },
            setValueAs: (value) => Number.isNaN(parseInt(value)) ? '' : String(value),
          }),
        }}
        currentValue={watch(inputId) as string | undefined}
        extraClassNames={[styles.submitRequired]}
      />
      <Hint error={error.message} />
    </div>
  )
}

interface ReportSectionProps {
  id: ReportFormSectionIds
  section: ThesisReportSection
  register: UseFormMethods['register']
  language: AvailableLocale
  watch: UseFormMethods['watch']
  errors?: UseFormMethods['errors']
  onChange?: FormEventHandler
}

// choice selection/score number input/nothing + text comment
const ReportSection = (props: ReportSectionProps): JSX.Element => {

  return (
    <>
      {{ /* select suitable component by section.type */
        choice: <ChoiceItem {...props} />,
        plain: <></>,
        score: <ScoreItem {...props} />,
      }[props.section.type]}
      <CommentItem {...props} />
    </>
  )
}

interface ReportSectionsProps {
  templateData: ThesisReportTexts
  register: UseFormMethods['register']
  language: AvailableLocale
  watch: UseFormMethods['watch']
  errors?: UseFormMethods['errors']
}

// list of sections
const ReportSections = ({
  templateData, register, language, watch, errors,
}: ReportSectionsProps): JSX.Element => (
  <>
    {templateData.sectionsOrder.map((key, index) => {
      const reportSection = templateData.sections[key]

      return (
        <section className={styles.reportFormStep} key={index}>
          <h3 className={styles.reportFormStepTitle}>{index + 1}. {reportSection.title}</h3>
          <ReportSection
            id={key}
            section={reportSection}
            register={register}
            language={language}
            watch={watch}
            errors={errors}
          />
        </section>
      )
    })}
  </>
)

interface Props {
  backupKey: string
  reportType: ThesisReportType
  templateData: ThesisReportTexts
  onSave: (data: ReportFields) => Promise<void>
  onReportSubmit: () => void
  reportLanguage: AvailableLocale
  onReportLanguageChange: (e: ChangeEvent<HTMLSelectElement>) => void
  defaultValues?: ThesisReportPatch
  downloadLink?: string
  onModifiedState?: (isModified: boolean) => void
}

const ThesisReportForm = ({
  backupKey, reportType, onSave, onReportSubmit, reportLanguage, onReportLanguageChange, templateData, defaultValues,
  downloadLink, onModifiedState,
}: Props): JSX.Element => {
  const { register, reset, setValue, trigger, handleSubmit, formState, watch, errors } = useForm<ThesisReportPatch>({
    defaultValues,
    mode: 'all',
  })
  const { clear: clearFormBackup } = useFormBackup(backupKey, { watch, setValue, reset, trigger }, {
    defaultValues,
    validate: true,
  })
  const { l, currentLang } = useLocale()
  const { showModal } = useModals()
  const isFormModified = !isEmpty(formState.dirtyFields)
  const submitModalText = reportType === 'supervisor'
    ? `${l.thesis.reports.submitModalText}\n\n${reportInLocale(currentLang).supervisorSubmitModalText}`
    : l.thesis.reports.submitModalText

  useEffect(() => {
    onModifiedState?.(isFormModified && !formState.isSubmitting)
  }, [isFormModified, formState.isSubmitting])

  const onSubmit = async (data: ThesisReportPatch & ReportFields) => {
    try {
      await onSave(data)
    } catch {
      return
    }

    clearFormBackup()
    // Reset form to the new values to avoid backup grabbing the old ones
    reset({ ...data }, {
      isDirty: false,
    })
  }

  function displaySubmitModal () {
    showModal({
      title: l.thesis.reports.submitModalTitle,
      text: submitModalText,
      cancelButton: {
        focus: true,
        caption: l.thesis.reports.submitModalCancelButton,
      },
      positiveButton: {
        caption: l.misc.yes,
        onClick: onReportSubmit,
      },
    })
  }

  const submitHandler = canSubmit(watch, isFormModified)
    ? displaySubmitModal
    : undefined

  const isSaveDisabled = shouldDisableSave(formState, isFormModified, Object.keys(errors).length > 0)

  const formInvalidHelpNode = !formState.isValid ? (
    <Hint error={l.form.formIsInvalid} />
  ) : null

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={cx(formStyle.form, styles.reportForm)} noValidate>
      <ReportLanguageSelect
        register={register}
        reportLanguage={defaultValues?.language ?? reportLanguage}
        errors={errors}
        onChange={onReportLanguageChange}
      />
      <ReportSections
        templateData={templateData}
        language={reportLanguage}
        register={register}
        watch={watch}
        errors={errors}
      />
      <FormControls
        isSaveDisabled={isSaveDisabled}
        onSubmit={submitHandler}
        downloadButtonText={reportInLocale(currentLang).checkPdfButton}
        downloadLink={downloadLink}
        onClear={isFormModified ? clearFormBackup : undefined}
      />
      <ModificationIndicator isModified={isFormModified} />
      {/* wrapped in a div to avoid direct form descendant styling */}
      <div>
        <Hint hint={l.thesis.reports.submitRequiredInfo} />
        {formInvalidHelpNode}
      </div>
    </form>
  )
}

export default ThesisReportForm
