import { cx } from 'linaria'

import * as style from './ProgressBar.style'


interface ProgressBarProps {
  isActive: boolean
  progress: number
}

const ProgressBar = ({
  isActive, progress,
}: ProgressBarProps): JSX.Element => (
  <div className={style.progressBar}>
    <div className={style.progressContainer}>
      <div
        className={cx(style.progress, isActive ? style.active : null, progress === 1 ? style.done : null)}
        style={{ width: `${(progress * 100).toFixed(3)}%` }}
      />
    </div>
  </div>
)


export default ProgressBar
