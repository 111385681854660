import { useEffect, useCallback, useState } from 'react'
import { useHistory } from 'react-router-dom'
import ulog from 'ulog'
import { Specialization, Topic, TopicPatch } from '@cvut/profit-api-types/lib/theses'
import { Except } from '@cvut/profit-utils'

import { addNotification } from '../../features/toastNotifications'
import { errorCardRenderer } from '../../components/RequestErrorCard'
import { getTopic, updateTopic, useDeleteTopic, topicsApiPrefix } from '../../api/topics'
import { getAllSpecializations, specializationsApiPrefix } from '../../api/specializations'
import LeavingPrompt from '../../components/form/LeavingPrompt'
import { useWrappedMultipleRequests } from '../../api/useRequest'
import AccessControlledRender from '../../access-control/AccessControlledRender'
import MultipleRequestsDependentRender from '../../api/MultipleRequestsDependentRender'
import PageLoadSpinner from '../../components/PageLoadSpinner'
import TopicEdit from '../../features/topics/TopicEdit'
import ValidatedPathParams from '../utils/ValidatedPathParams'
import { ACLoaded as AccessControlLoaded } from '../../access-control'
import { useLocale } from '../../locale'
import pagePaths from '../paths'


const logger = ulog('TopicEditPage')

interface Props {
  topicId: number
}

const TopicEditPage = ({ topicId }: Props): JSX.Element => {
  const history = useHistory()
  const getDataWrapper = useCallback(() => [getTopic(topicId), getAllSpecializations()], [])
  const [getResults] = useWrappedMultipleRequests<[Topic, Specialization[]]>(getDataWrapper)
  const [deleteStatus, deleteTopic] = useDeleteTopic()
  const { l } = useLocale()
  const [isFormModified, setIsFormModified] = useState(false)

  const handleModifiedState = (isModified: boolean) => setIsFormModified(isModified)

  const topicInLocale = l.topic.topic.toLocaleLowerCase()

  useEffect(() => {
    if (deleteStatus.state === 'success') {
      history.push(pagePaths.topics.my)
      addNotification({
        type: 'POSITIVE',
        message: l.successMessages.api.defaultDelete(topicInLocale, topicId.toString()),
      })
    }
  }, [deleteStatus.state])

  const handleSave = async (topic: TopicPatch) => {
    if (getResults.state !== 'success') {
      logger.warn('Tried to update a topic which was not loaded')
      return
    }

    try {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      await updateTopic(getResults.data![0].id, topic)
    } catch (err) {
      addNotification({
        type: 'NEGATIVE',
        message: l.errorMessages.api.defaultPatch(topicInLocale, topicId.toString()),
      })

      throw err
    }

    addNotification({
      type: 'POSITIVE',
      message: l.successMessages.api.defaultPatch(topicInLocale, topicId.toString()),
    })
    history.replace(pagePaths.topics.view(topicId))
  }

  function canEditCurrentTopic (ac: Except<AccessControlLoaded, 'state'>, topic: Topic): boolean {
    if (getResults.state !== 'success') {
      return false
    }

    return ac.topicRoles.isTopicAuthor(topic)
  }

  const canDeleteCurrentTopic = canEditCurrentTopic

  const deleteCurrentTopic = async () => {
    if (getResults.state !== 'success') {
      logger.warn('Tried to delete a topic which was not loaded')
      return
    }

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return await deleteTopic(getResults.data![0].id)
  }

  return (
    <AccessControlledRender loaderColor='primary'>
      {(ac) => (
        <MultipleRequestsDependentRender<[Topic, Specialization[]]>
          requestStatus={getResults}
          renderOnLoading={() => <PageLoadSpinner message={l.topic.loadingTopic} />}
          renderOnError={(response) => {
            let responseMessage = l.errorMessages.api.errorGettingData

            if (response != null) {
              if (response.url.includes(topicsApiPrefix)) {
                responseMessage = l.topic.errorMessages.get
              }
              if (response.url.includes(specializationsApiPrefix)) {
                responseMessage = l.specialization.errorLoadingSpecializations
              }
            }

            return errorCardRenderer(responseMessage, pagePaths.topics.edit(topicId))(response)
          }}
          renderOnSuccess={([topic, specializations]) => {
            if (!canEditCurrentTopic(ac, topic)) {
              addNotification({
                type: 'NEGATIVE',
                message: l.topic.errorMessages.onlyAuthorCanEdit,
              })

              history.push(pagePaths.topics.view(topicId))
              return null
            }

            return (
              <>
                <TopicEdit
                  mode='edit'
                  onSave={handleSave}
                  onDelete={canDeleteCurrentTopic(ac, topic) ? deleteCurrentTopic : undefined}
                  backLink={{ to: pagePaths.topics.view(topicId) }}
                  formBackupKey={`${ac.person.username}-topic-edit-${topicId}`}
                  specializations={specializations}
                  defaultValues={topic}
                  onModifiedState={handleModifiedState}
                />
                <LeavingPrompt when={isFormModified} />
              </>
            )
          }}
        />
      )}
    </AccessControlledRender>
  )
}

const TopicEditPageWrapper = (): JSX.Element => (
  <ValidatedPathParams<Props>
    expectedPathPattern={pagePaths.topics.edit()}
    expectedParams={{
      topicId: {
        validate: (param: string) => !isNaN(parseInt(param, 10)),
        convert: (param: string) => parseInt(param, 10),
      },
    }}
  >
    {(params) => (
      <TopicEditPage topicId={params.topicId} />
    )}
  </ValidatedPathParams>
)

export default TopicEditPageWrapper
