import { css } from 'linaria'

import * as colors from '../../../styles/colors'
import { rel } from '../../../styles/dimensions'
import { fontWeights } from '../../../styles/typography'


export const wrapper = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${colors.primaryColor[2]};

  & > * {
    width: 100%;
  }
`

export const inputWrapper = css`
  background: ${colors.white};
  border: ${rel(1)}rem solid ${colors.backgroundColor[2]};
  border-radius: ${rel(8)}rem;
`

export const input = css`
  width: 100%;
  padding: ${rel(16)}rem ${rel(8)}rem;
  color: ${colors.primaryColor[2]};
  font-weight: ${fontWeights.bold};
  font-size: ${rel(16)}rem;
  background: ${colors.backgroundColor[2]};
  border: none;
  border-radius: ${rel(8)}rem;

  ::placeholder {
    color: ${colors.primaryColor[2]};
    font-weight: ${fontWeights.normal};
  }
`

export const required = css`
  &:invalid {
    border-color: ${colors.negativeColor[2]};
  }

  &:valid {
    border-color: ${colors.positiveColor[2]};
  }
`

export const optionsWrapper = css`
  padding: ${rel(8)}rem;
  background: ${colors.white};
`

export const hidden = css`
  display: none;
`

export const textMsg = css`
  padding: ${rel(8)}rem ${rel(16)}rem;
`
