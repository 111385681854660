import type { TopicPatch } from '@cvut/profit-api-types/lib/theses/topic'

import Result, { SearchResult } from './Result'
import usePromise from '../../../hooks/usePromise'
import { useLocale } from '../../../locale'
import Spinner from '../../../components/Spinner'
import Card from '../../../components/Card'
import * as styles from './ResultList.style'


export interface Props {
  results: SearchResult[]
  getMoreTopics?: () => Promise<unknown>
  topicLinkCreator: (t: SearchResult) => string
  updateTopicVisibility?: (t: SearchResult['id'], v: NonNullable<TopicPatch['visibility']>) => Promise<void>
}

/**
 * If `updateTopicVisibility` is true, then we assume we are working in "my topics
 * mode".
 */
const ResultList = ({ results, getMoreTopics, topicLinkCreator, updateTopicVisibility }: Props): JSX.Element => {
  const [getMoreTopicsState, fire] = usePromise<unknown>(getMoreTopics)
  const { l } = useLocale()

  const resultNodes = results.length > 0
    ? results.map(t => (
      <Result
        topic={t}
        viewTopicLink={topicLinkCreator(t)}
        key={t.id}
        setVisibility={updateTopicVisibility ? async (newVisibility) => {
          await updateTopicVisibility(t.id, newVisibility)
        } : undefined}
      />
    ))
    : (
      <Card extraClassNames={[styles.noResults]}>
        {l.topic.search.results.noResults}
      </Card>
    )

  const loadMoreButtonContent = getMoreTopicsState.state === 'loading'
    ? <Spinner.DualRings color='primary' title={l.pagination.loadMore} />
    : l.pagination.loadMore

  const loadMoreButton = getMoreTopics
    ? (
      <button
        type='button'
        onClick={fire}
        disabled={getMoreTopicsState.state === 'loading'}
        className={styles.loadMoreButton}
      >
        {loadMoreButtonContent}
      </button>
    )
    : null

  return (
    <>
      <article className={styles.resultList}>
        {resultNodes}
      </article>
      <aside className={styles.loadMore}>
        {loadMoreButton}
      </aside>
    </>
  )
}

export default ResultList
