import { FormEventHandler, useEffect, useState } from 'react'
import { LinkProps } from 'react-router-dom'
import { pick } from 'lodash'
import {
  isPerson,
  isStudy,
  Thesis,
  ThesisAssignment,
  ThesisMainState,
  ThesisNew,
  ThesisPatch,
} from '@cvut/profit-api-types/lib/theses'
import { getPropertyWithSuffix } from '@cvut/profit-utils'
import { thesisLangToLocale } from '@cvut/profit-theses-common'

import pagePaths from '../../pages/paths'
import { useLocale } from '../../locale'
import { Person } from '../../types/person'
import ThesisAssigneeCard from './ThesisAssigneeCard'
import ThesisForm, { FormData } from './ThesisForm'
import Card from '../../components/Card'
import PageHeader from '../../components/PageHeader'
import { UseRequestStatus } from '../../api/useRequest'


const pickThesisAssignment = (values: Thesis): ThesisAssignment => pick(
  values,
  [
    'titleCs', 'titleEn', 'language', 'description', 'licenseType', 'assignee',
    'specialization', 'topic', 'validUntil', 'supervisorWillProposeReviewer',
  ],
)

interface CommonProps {
  formHasStoredValues: boolean
  defaultValues?: Thesis
  isSupervisor?: boolean
  onChange?: FormEventHandler
  onModifiedState?: (isModified: boolean) => void
  shouldReset?: boolean
  updateStatus?: UseRequestStatus<void>['state']
}

interface CreateProps extends CommonProps {
  mode: 'create'
  onSave: (data: ThesisNew) => unknown
}

interface EditProps extends CommonProps {
  mode: 'edit'
  backLink: LinkProps
  onSave: (data: ThesisPatch) => unknown
  onDelete?: () => unknown
  onProposeThesis?: () => void
  // FIXME: Passing the entire component is not ideal but we're running out of time.
  changeHistoryComponent?: JSX.Element
  canEditValidUntil?: boolean
}

type Props = CreateProps | EditProps

const ThesisEdit = (props: Props): JSX.Element => {
  // React component props are read-only + can contain two sets of data here
  const updateStatus = props.updateStatus ?? 'not-initiated'

  const { l } = useLocale()
  const [assignee, setAssignee] = useState<Person | null>(null)
  const [assigneeBackup, setAssigneeBackup] = useState<Person | null>(null)

  // Checks if thesis has assignee with specialization
  const assigneeWithSpecialization = props.mode === 'edit' && !!assignee?.study
  const handleProposeThesis = props.mode === 'edit' && props.defaultValues?._actions?.PROPOSE
    ? props.onProposeThesis
    : undefined

  useEffect(() => {
    if (updateStatus === 'success') {
      setAssigneeBackup(assignee)
    }

    if (updateStatus === 'error') {
      setAssignee(assigneeBackup)
    }
  }, [updateStatus])

  useEffect(() => {
    const dv = props.defaultValues
    if (props.mode === 'edit' && dv?.assignee && isStudy(dv.assignee) && isPerson(dv.assignee.person)) {
      const newAssigne = {
        titlesPre: dv.assigneeFrozenName?.titlesPre ?? '',
        firstName: dv.assigneeFrozenName?.firstName ?? '',
        lastName: dv.assigneeFrozenName?.lastName ?? '',
        titlesPost: dv.assigneeFrozenName?.titlesPost ?? '',
        username: dv.assignee.username,
        email: dv.assignee.person.email,
        study: {
          id: dv.assignee.id,
          specialization: dv.assignee.specialization,
          hasThesis: dv.assignee.hasThesis,
        },
      }

      setAssignee(newAssigne)
      setAssigneeBackup(newAssigne)
    }
  }, [])

  const handleSave = (data: FormData): void => {
    const topicId = props.defaultValues?.topic?.id
    props.onSave({
      ...data,
      assignee: assignee?.study ? { id: assignee.study.id } : null,
      topic: topicId ? { id: topicId } : data.topic,
    })
  }

  function handleSaveAssignee (assignee: Person | null) {
    if (props.mode === 'edit') {
      props.onSave({
        assignee: assignee?.study ? { id: assignee.study.id } : null,
      })
    }
  }

  const componentTitle: Record<Props['mode'], string> = {
    create: l.thesis.creation,
    edit: l.thesis.editAssignment,
  }

  const backButtonProps: Record<Props['mode'], LinkProps | undefined> = {
    create: props.mode === 'create' && props.defaultValues?.topic?.id
      ? { to: pagePaths.topics.view(props.defaultValues.topic.id) }
      : undefined,
    // TODO: Having to check `props.mode === 'edit'` is not ideal.
    edit: props.mode === 'edit' ? props.backLink : undefined,
  }

  const getThesisTitle = (thesis: Thesis) => getPropertyWithSuffix(thesis, 'title', thesisLangToLocale(thesis.language))

  const subtitlesProps = props.mode === 'create' && props.defaultValues?.topic?.id
    ? [getThesisTitle(props.defaultValues)]
    : undefined

  const sanitizedDefaultValues = props.defaultValues && {
    ...pickThesisAssignment(props.defaultValues),
    supervisorWillProposeReviewer: props.defaultValues.supervisorWillProposeReviewer ?? false,
  }

  return (
    <>
      <PageHeader
        backButton={backButtonProps[props.mode]}
        title={componentTitle[props.mode]}
        subtitles={subtitlesProps}
      />
      <Card.ColumnContainer>
        <Card.Column>
          <Card>
            <Card.Header title={l.thesis.assignment} />
            <Card.Content>
              <ThesisForm
                {...props}
                onSave={handleSave}
                defaultValues={sanitizedDefaultValues}
                hasAssigneeWithSpecialization={assigneeWithSpecialization}
                onProposeThesis={handleProposeThesis}
              />
            </Card.Content>
          </Card>
          {props.mode === 'edit' && props.changeHistoryComponent}
        </Card.Column>
        <Card.Column>
          <ThesisAssigneeCard
            assignee={assignee}
            onChange={setAssignee}
            onSave={handleSaveAssignee}
            thesisState={props.defaultValues?.mainState ?? ThesisMainState.Draft}
          />
        </Card.Column>
      </Card.ColumnContainer>
    </>
  )
}

export default ThesisEdit
