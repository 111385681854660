import { cx } from 'linaria'

import * as style from './LoadMore.style'
import Spinner from '../Spinner'
import { useLocale } from '../../locale'
import * as buttonStyle from '../Button.style'


interface LoadMoreProps {
  isLoadingMore: boolean
  onClick: () => void
}

const LoadMore = ({ isLoadingMore, onClick }: LoadMoreProps): JSX.Element => {
  const { l } = useLocale()

  return (
    <div className={style.loadMore}>
      {isLoadingMore
        ? <Spinner.DualRings title={l.thesis.loadingTheses} color='primary' />
        : (
          <button onClick={onClick} className={cx(buttonStyle.fill, buttonStyle.outlineDark)}>
            {l.pagination.loadMore}
          </button>
        )}
    </div>
  )
}

export default LoadMore
