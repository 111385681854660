import { css } from 'linaria'

import { breakpoints, rel, gridColumn } from '../../styles/dimensions'
import { fontSizes } from '../../styles/typography'


export const controls = css`
  ${gridColumn('100%')}
  margin: -${rel(4)}rem;
  padding: ${rel(8)}rem 0 0;

  @media screen and (min-width: ${breakpoints.md}) {
    ${gridColumn('auto')}
  }

  @media screen and (min-width: ${breakpoints.md}) {
    padding: 0 0 0 ${rel(8)}rem;
  }

  @media screen and (min-width: ${breakpoints.xl}) {
    padding: 0 0 0 ${rel(16)}rem;
  }

  & > * {
    margin: ${rel(4)}rem;
  }
`

export const btn = css`
  padding: ${rel(4)}rem ${rel(16)}rem;
  font-size: ${fontSizes.body.mobile}rem;
  line-height: 1.4;
  letter-spacing: ${rel(0.25)}rem;
  border-radius: ${rel(6)}rem;

  svg {
    max-width: ${rel(14)}rem;
    max-height: ${rel(14)}rem;
    margin-left: ${rel(8)}rem;

    .all {
      stroke: currentColor;
    }
  }
`
