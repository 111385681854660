import { css } from 'linaria'

import { boxGroupLabel } from '../../components/form/BoxInput/BoxInput.style'
import * as colors from '../../styles/colors'
import { fontWeights } from '../../styles/typography'
import { label } from '../../components/form/Form.style'
import { rel } from '../../styles/dimensions'
import LeaveLinkIcon from '../../images/icons/LeaveLink.svg'


export const reportForm = css`
  margin-top: ${rel(16)}rem;

  .${label} {
    display: block;
    margin-bottom: ${rel(4)}rem;
    font-weight: ${fontWeights.bold};
    font-size: ${rel(18)}rem;
    line-height: ${rel(26)}rem;
  }
`

export const reportFormStep = css`
  padding: ${rel(16)}rem;
  background-color: ${colors.backgroundColor[2]};
  border-radius: ${rel(6)}rem;
`

export const reportFormStepTitle = css`
  margin-bottom: ${rel(8)}rem;
`

export const reportFormStepItem = css`
  & + & {
    margin-top: ${rel(16)}rem;
  }
`

export const criteriaInfo = css`
  display: inline-block;
  width: 100%;
  margin-bottom: ${rel(8)}rem;
  padding: ${rel(6)}rem ${rel(10)}rem;
  font-size: ${rel(14)}rem;
  line-height: ${rel(20)}rem;
  background-color: ${colors.backgroundColor[1]};
  border-radius: ${rel(6)}rem;

  & > p {
    margin-bottom: ${rel(8)}rem;

    a {
      font-size: ${rel(14)}rem;
      line-height: ${rel(20)}rem;
      text-decoration: underline;

      &::after {
        display: inline-block;
        width: ${rel(10)}rem;
        height: ${rel(10)}rem;
        margin: 0 ${rel(2)}rem;
        background-image: url(${LeaveLinkIcon});
        background-repeat: no-repeat;
        background-size: ${rel(10)}rem ${rel(10)}rem;
        content: '';
      }
    }
  }

  & > p:last-child {
    margin-bottom: 0;
  }
`

export const submitRequired = css`
  .${label}::before,
  .${boxGroupLabel}::before {
    display: inline-block;
    color: ${colors.negativeColor[2]};
    content: '*\\00a0'; /* &nbsp; */
  }
`
