import { css } from 'linaria'

import { rel } from '../../styles/dimensions'

export const loadMore = css`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  padding: ${rel(32)}em;
`
