import type { ComponentPropsWithRef } from 'react'
import { cx } from 'linaria'
import type { Except, SetRequired } from 'type-fest'

import * as styles from './BoxInput.style'
import * as textInputStyles from '../TextInput.style'


interface Props {
  labelText: string
  inputProps: SetRequired<Except<ComponentPropsWithRef<'input'>, 'className'>, 'name' | 'id'>
  options: string[]
  extraClassNames?: string[]
  defaultChecked?: string[]
}

/**
 * Wrapper component for <input type='radio'> and <input type='checkbox'> form elements.
 * Renders <input type='radio'> element by default. Set multipleAnswers=true to render <input type='checkbox'>.
 */
const BoxInput = ({
  labelText, inputProps, options, extraClassNames, defaultChecked,
}: Props): JSX.Element => {
  // TODO: Replace required indicator by a dot according to the style guide.
  const requiredIndicator = inputProps.required
    ? <span aria-hidden className={textInputStyles.requiredIndicator}>*</span>
    : null

  const boxInputStyle = inputProps.type === 'radio' ? styles.radioboxInput : styles.boxInput

  return (
    <div className={cx(styles.boxGroup, ...(extraClassNames ?? []))}>
      <label className={styles.boxGroupLabel}>
        {requiredIndicator}
        {labelText}
      </label>
      <div className={styles.boxInputItems}>
        {options.map((label, index) => {
          index += 1
          const boxInputId = `${inputProps.id}${index}`

          return (
            <div className={styles.boxInputItem} key={boxInputId}>
              <input
                {...inputProps}
                id={boxInputId}
                className={boxInputStyle}
                value={index}
                defaultChecked={defaultChecked?.includes(boxInputId)}
              />
              <label className={styles.label} htmlFor={boxInputId}>{label}</label>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default BoxInput
