import { css, cx } from 'linaria'

import { breakpoints, rel } from '../../styles/dimensions'


export const content = css`
  margin-bottom: ${rel(8)}rem;

  & > * {
    margin: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }
`

export const contentLargerSpace = cx(content, css`
  @media screen and (min-width: ${breakpoints.xl}) {
    margin-bottom: ${rel(16)}rem;
  }
`)
