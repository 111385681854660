import { css, cx } from 'linaria'

import { neutralColor, white } from '../../styles/colors'
import { breakpoints, rel, gridColumn } from '../../styles/dimensions'
import { fontSizes } from '../../styles/typography'
import { cardPadding } from '../../components/Card/Card.style'
import { column } from '../../components/Card/Column.style'


export const actionPanel = cx(css`
  font-size: ${rel(16)}rem;
  line-height: ${rel(20)}rem;
  background-color: ${white};
  border: ${rel(1)}rem solid ${neutralColor[2]};
  border-radius: ${rel(8)}rem;

  & + & {
    margin-top: ${rel(24)}rem;
  }

  .${column} > &,
  .${column} > & + & {
    margin-top: 0;
  }
`, cardPadding)

export const header = css`
  display: flex;
  flex-wrap: wrap;
`

export const title = css`
  display: flex;
  flex-basis: 0;
  flex-grow: 1;
  flex-wrap: wrap;
  align-items: flex-start;
  width: 100%;
  max-width: 100%;

  @media screen and (min-width: ${breakpoints.md}) {
    padding-right: ${rel(8)}rem;
  }

  @media screen and (min-width: ${breakpoints.xl}) {
    padding-right: ${rel(16)}rem;
  }

  svg {
    ${gridColumn(`${rel(28)}rem`)}
    height: auto;
    margin-top: ${rel(1)}rem;
    margin-right: ${rel(8)}rem;

    @media screen and (min-width: ${breakpoints.md}) {
      margin-right: ${rel(12)}rem;
    }
  }

  h2 {
    flex-basis: 0;
    flex-grow: 1;
    width: 100%;
    max-width: 100%;
    margin-bottom: 0;
    line-height: ${rel(34)}rem;
  }
`

export const description = css`
  ${gridColumn('100%')}
  margin-top: ${rel(8)}rem;
  font-size: ${fontSizes.body.mobile}rem;
  line-height: 1.4;

  @media screen and (min-width: ${breakpoints.xl}) {
    margin-top: ${rel(12)}rem;
  }

  p {
    margin-bottom: 0;
  }
`
