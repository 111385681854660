import type { Person, PersonRef } from './person'
import type { Thesis, ThesisRef } from './thesis'
import { ProposalState } from './common'
import type { Page } from '../common'
import { ValueOf } from '../internal/utils'

type AcceptanceState = Exclude<ProposalState, (typeof ProposalState)['Cancelled']>

/**
 * Actions that can be invoked on a ReviewerProposal.
 * CREATE, READ and LIST are CRUD actions mapped to HTTP methods (POST, GET),
 * all others are state actions.
 */
export const ReviewerProposalAction = {
  CREATE: 'CREATE',
  READ: 'READ',
  LIST: 'LIST',
  PROPONENT_REJECT: 'PROPONENT_REJECT',
  REVIEWER_ACCEPT: 'REVIEWER_ACCEPT',
  REVIEWER_REJECT: 'REVIEWER_REJECT',
  OFFICER_APPROVE: 'OFFICER_APPROVE',
  OFFICER_REJECT: 'OFFICER_REJECT',
  CANCEL: 'CANCEL',
} as const
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type ReviewerProposalAction = ValueOf<typeof ReviewerProposalAction>

/**
 * Thesis Reviewer Proposal.
 * This is a dependent entity which is created when someone proposes a Reviewer for a Thesis.
 */
export interface ReviewerProposal {

  /** Unique ID. */
  readonly id: number

  /** The Thesis for which this proposal was created. */
  readonly thesis: ThesisRef | Thesis

  /** State of the proposal. */
  state: ProposalState

  /** State of acceptance by the Reviewer. */
  reviewerAcceptance: AcceptanceState

  /** State of approval by the Specialization Officer. */
  specOfficerApproval: AcceptanceState

  /** The proposed Reviewer. */
  readonly reviewer: PersonRef | Person

  /** The Person who proposed this Reviewer. */
  readonly proponent: PersonRef | Person

  /**
   * The last date and time at which this object was modified. This is being updated automatically.
   */
  readonly modifiedAt: Date
}

/**
 * A ReviewerProposal to be created.
 */
export type ReviewerProposalNew = {
  /** The proposed Reviewer. */
  reviewer: PersonRef,
}

/**
 * Collection of ReviewerProposal objects.
 */
export type ReviewerProposalList = Page<ReviewerProposal>
