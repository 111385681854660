import { css } from 'linaria'
import { darken } from 'polished'

import * as colors from '../styles/colors'
import { rel } from '../styles/dimensions'
import { fontWeights } from '../styles/typography'
import { error } from './form/Hint/Hint.style'


export const disabled = css`
  /* not empty */
`

export const wrapper = css`
  display: flex;
  align-items: center;
  color: ${colors.primaryColor[2]};

  svg {
    max-width: ${rel(56)}rem;
  }

  &.${disabled} svg {
    filter: grayscale(1);
  }
`

export const info = css`
  margin-left: ${rel(16)}rem;

  & > * {
    display: block;
  }

  .${disabled} & *:not(.${error}) {
    color: ${darken(0.2, colors.backgroundColor[1])};
    filter: grayscale(1);
  }
`

export const name = css`
  font-weight: ${fontWeights.bold};
`
