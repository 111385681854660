import { ComponentType, ReactNode, createContext, useContext } from 'react'


const EMPTY: unique symbol = Symbol('Helps detect whether a used container is wrapped by an appropriate `Provider`')

export interface ContainerProviderProps<State = void> {
  initialState?: State
  children: ReactNode
}

export interface Container<Value, State = void> {
  Provider: ComponentType<ContainerProviderProps<State>>
  useContainer: () => Value
}

export function createContainer<Value, State = void> (
  useHook: (initialState?: State) => Value,
  displayName?: string,
): Container<Value, State> {
  const Context = createContext<Value | typeof EMPTY>(EMPTY)
  if (displayName) {
    Context.displayName = displayName
  }

  const Provider = (props: ContainerProviderProps<State>) => {
    const value = useHook(props.initialState)
    return <Context.Provider value={value}>{props.children}</Context.Provider>
  }

  const useContainer = (): Value => {
    const value = useContext(Context)
    if (value === EMPTY) {
      throw new Error('Component must be wrapped with <Container.Provider>')
    }
    return value
  }

  return { Provider, useContainer }
}

export function useContainer<Value, State = void> (
  container: Container<Value, State>,
): Value {
  return container.useContainer()
}
