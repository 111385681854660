import { useLocation } from 'react-router-dom'

import { useLocale } from '../locale'
import PageHeader from '../components/PageHeader'


const NotFound = (): JSX.Element => {
  const location = useLocation()
  const { l } = useLocale()

  return (
    <article>
      <PageHeader
        title={l.pageStatus[404].title}
      />
      <p>{l.pageStatus[404].message}: <code>{location.pathname}</code>.</p>
    </article>
  )
}
export default NotFound
