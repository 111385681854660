import { useState } from 'react'


interface UsePromiseNotInitiated {
  state: 'not-initiated'
}

interface UsePromiseLoading {
  state: 'loading'
}

interface UsePromiseSuccess<T = any> {
  state: 'success'
  result?: T
}

interface UsePromiseError {
  state: 'error'
  error?: Error | unknown
}

export type UsePromiseStatus<T = any> =
  | UsePromiseNotInitiated
  | UsePromiseLoading
  | UsePromiseSuccess<T>
  | UsePromiseError

// Inspired by https://github.com/bsonntag/react-use-promise.
function usePromise<T> (promise?: (...args: any[]) => Promise<T>): [
  promiseState: UsePromiseStatus<T>,
  firePromise: (...args: any[]) => Promise<unknown>,
] {
  const [state, setState] = useState<UsePromiseStatus<T>>({ state: 'not-initiated' })

  return [state, async function (...args) {
    setState({ state: 'loading' })
    let canceled = false

    if (!promise) {
      setState({ state: 'not-initiated' })
      return
    }

    let result
    try {
      result = await promise(...args)
    } catch (error) {
      if (canceled) {
        return
      }

      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      setState({ state: 'error', error })
      return
    }

    setState({ state: 'success', result })

    return () => {
      canceled = true
    }
  }]
}

export default usePromise
