import { Enum } from 'typescript-string-enums'
import { ActionMessage, Thesis, ThesisAction } from '@cvut/profit-api-types/lib/theses'
import { Role } from '@cvut/profit-theses-common'

import { Props as ActionPanelProps } from './ActionPanel'
import { mapStateAndRoleToPanelId } from './common'
import { ThesisPanelAction } from '../../api/theses'
import { ACLoaded } from '../../access-control'


type ThesisActionMap = Partial<Record<ThesisAction, unknown>>

/**
 * Returns only valid panel actions
 */
function validPanelActions (actionsMap: ThesisActionMap): ThesisPanelAction[] {
  return Enum.keys(ThesisPanelAction).filter(action => action in actionsMap)
}

/**
 * Checks if our frontend-defined actions are allowed by matching them
 * with allowed actions received from backend
 */
function areActionsAllowedByBackend (backendActions: ThesisAction[], frontendActions: ThesisPanelAction[]): boolean {
  return frontendActions.every(frontendAction => backendActions.includes(frontendAction))
}

/**
 * Returns an array of ActionPanel Props
 * @param thesis - pass current Thesis
 * @param relations - pass an array of Thesis relations
 * @param actions - pass an array of ActionPanel actions
 * @param onSendRequest - pass handler for request buttons
 */
function getPanelsToRender (
  thesis: Thesis,
  accessControl: Pick<ACLoaded, 'thesisRoles' | 'globalRoles'>,
  actions: ThesisPanelAction[],
  onSendRequest: (action: ThesisPanelAction, bodyData?: ActionMessage) => void,
): ActionPanelProps[] {
  const panels = []
  const roles = [
    ...accessControl.thesisRoles.list(thesis),
    ...(accessControl.globalRoles.isFtOfficer ? [Role.FT_OFFICER] : []),
  ]

  for (const state of thesis.states) {
    const stateKey = state as keyof typeof mapStateAndRoleToPanelId
    const stateActionsMap = mapStateAndRoleToPanelId[stateKey]

    for (const relation of roles) {
      const panelDefinition = stateActionsMap?.[relation]

      if (panelDefinition && areActionsAllowedByBackend(actions, panelDefinition.actions)) {
        panels.push({
          panelId: panelDefinition.panelId,
          actions: panelDefinition.actions,
          thesisId: thesis.id,
          onRequestReady: onSendRequest,
        })
      }
    }
  }

  return panels
}

export function getPanels (
  thesis: Thesis,
  accessControl: Pick<ACLoaded, 'thesisRoles' | 'globalRoles'>,
  handleSendRequest: (action: ThesisPanelAction, bodyData?: ActionMessage) => void
): ActionPanelProps[] {
  const backendActions = validPanelActions(thesis._actions ?? {})
  return getPanelsToRender(thesis, accessControl, backendActions, handleSendRequest)
}
