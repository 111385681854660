import { Grade } from '@cvut/profit-api-types/lib/common'

import { useLocale } from '../../../locale'
import * as style from './FinalGrade.style'


interface Props {
  grade: Grade
}

const FinalGrade = ({ grade }: Props): JSX.Element => {
  const { l } = useLocale()

  return (
    <section className={style.wrapper}>
      <h2 className={style.title}>
        {l.thesis.reports.evaluationCard.finalLabel}{'\n'}{l.thesis.reports.evaluationLabel.toLocaleLowerCase()}
      </h2>
      <div className={style.grade}>{grade}</div>
    </section>
  )
}

export default FinalGrade
