import { css } from 'linaria'

import { rel } from '../../../styles/dimensions'


export const reportList = css`
  padding-left: 0;
  list-style: none;
`

export const reportItem = css`
  & + & {
    margin-top: ${rel(20)}em;
  }
`
