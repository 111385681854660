import { Enum } from 'typescript-string-enums'
import { PersonName } from '@cvut/profit-api-types/lib/common'
import { ThesisMainState } from '@cvut/profit-api-types/lib/theses'


/**
 * Returns the given person name in the reversed format:
 * `<lastName> <firstName>[, <titlesPre>][, <titlesPost>][ (<username>)]`.
 */
export const formatPersonFullNameReversed = (name: PersonName, username = ''): string => {
  let fullName = ''

  fullName += `${name.lastName} ${name.firstName}`

  if (name.titlesPre) {
    fullName += `, ${name.titlesPre}`
  }
  if (name.titlesPost) {
    fullName += `, ${name.titlesPost}`
  }
  if (username) {
    fullName += ` (${username})`
  }

  return fullName
}

export function sameContents<T> (set1: Set<T>, set2: Set<T>): boolean {
  return set1.size === set2.size && [...set1.values()].every(entry => set2.has(entry))
}

export const isMainState = (state: string): boolean => {
  return Enum.isType(ThesisMainState, state)
}

// FIXME: function partially duplicates deriveMainState function in profit-theses-backend/src/statecharts/thesis.ts
export const deriveMainState = (statePath: string): ThesisMainState => {
  const subs = statePath.split('.', 3)
  const mainState = subs[0] === 'changeable' ? subs[1] : subs[0]

  if (!isMainState(mainState)) {
    throw new Error(`State '${mainState}' is not ThesisMainState`)
  }

  return mainState as ThesisMainState
}
