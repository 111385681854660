import { css } from 'linaria'

import { rel, gridColumn, breakpoints } from '../../../styles/dimensions'
import { cardPadding } from '../../../components/Card/Card.style'
import * as colors from '../../../styles/colors'


export const infoMessageContainer = css`
  ${gridColumn('100%')}

  margin-top: ${rel(14)}rem;
`

export const filterColumn = css`
  ${gridColumn('100%')}

  @media screen and (min-width: ${breakpoints.sm}) {
    display: flex;
    flex-basis: 0;
    flex-grow: 1;
    flex-wrap: wrap;
    align-items: flex-start;
    width: 100%;
    max-width: 100%;
  }
`

export const filters = css`
  .${cardPadding} {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    padding: ${rel(16)}rem;

    @media screen and (min-width: ${breakpoints.md}) {
      padding: ${rel(20)}rem;
    }
  }
`

export const mainFilter = css`
  ${gridColumn('100%')}

  @media screen and (min-width: ${breakpoints.xl}) {
    display: flex;
    flex-basis: 0;
    flex-grow: 1;
    flex-wrap: wrap;
    align-items: flex-start;
    width: 100%;
    max-width: 100%;
  }

  > div,
  input,
  select,
  textarea {
    width: 100%;
  }
`

export const filter = css`
  ${gridColumn('100%')}

  @media screen and (min-width: ${breakpoints.sm}) {
    ${gridColumn('auto')}
    max-width: ${rel(320)}rem;
    margin-right: ${rel(20)}rem;
  }

  @media screen and (min-width: ${breakpoints.xl}) {
    max-width: ${rel(260)}rem;
    margin-right: 0;
  }

  > div,
  input,
  select,
  textarea {
    width: 100%;
  }

  * + & {
    margin-top: ${rel(16)}rem;

    @media screen and (min-width: ${breakpoints.xl}) {
      margin-top: 0;
      margin-left: ${rel(20)}rem;
    }
  }

  & + & {
    margin-top: ${rel(16)}rem;
    margin-left: 0;

    @media screen and (min-width: ${breakpoints.xl}) {
      margin-top: 0;
      margin-left: ${rel(20)}rem;
    }
  }
`

export const resetFilter = css`
  ${gridColumn('auto')}
  margin-top: ${rel(16)}rem;
  padding: 0;
  background-color: transparent;
  border: 0;

  @media screen and (min-width: ${breakpoints.sm}) {
    margin-top: ${rel(21)}rem;
    margin-left: ${rel(16)}rem;
  }

  @media screen and (min-width: ${breakpoints.md}) {
    margin-left: ${rel(20)}rem;
  }
`

export const asyncLabel = css`
  display: block;
  margin-bottom: ${rel(4)}rem;
  font-size: ${rel(14)}rem;
  line-height: 1.1;

  @media screen and (min-width: ${breakpoints.xl}) {
    font-size: ${rel(16)}rem;
  }
`

export const multiselect = css`
  min-width: ${rel(126)}rem;
`

export const authorFilter = css`
  min-width: ${rel(200)}rem;
`

export const filterInput = css`
  input,
  input:valid,
  input:invalid {
    background-color: ${colors.backgroundColor[2]};
    border-color: ${colors.backgroundColor[2]};
  }
`
