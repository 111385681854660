import urlcat from 'urlcat'
import {
  Thesis, ThesisReport, ThesisReportPatch, ThesisReportType, ThesisReportList,
} from '@cvut/profit-api-types/lib/theses'

import { apiPrefix } from '../../config'
import { createRequestor } from '../makeRequest'
import { useRequest, UseRequestConfig, UseRequestStatus } from '../useRequest'
import { useLocale } from '../../locale'


const thesesApiPrefix = `${apiPrefix}/theses`
const thesisReportsRequestor = createRequestor(thesesApiPrefix)

function useThesisReportRequest<T> (config: UseRequestConfig) {
  return useRequest<T>(thesisReportsRequestor, config)
}

export function useThesisReport (notFoundHandler: () => void): [
  UseRequestStatus<ThesisReport>,
  (thesisId: Thesis['id'], type: ThesisReportType) => Promise<void>,
] {
  const { l } = useLocale()
  const [requestStatus, sendRequest] = useThesisReportRequest<ThesisReport>({
    defaultErrorMessage: () => l.thesis.reports.errorMessages.load,
    errorHandlers: {
      404: notFoundHandler,
    },
  })
  return [
    requestStatus,
    async (thesisId, type) => await sendRequest('GET', ':thesisId/reports/:type', {
      thesisId, type,
    }),
  ]
}

export function createThesisReportPdfUrl (thesisId: Thesis['id'], type: ThesisReportType): string {
  return urlcat(`${thesesApiPrefix}/:thesisId/reports/:type.pdf`, { thesisId, type })
}

/**
 * Reports are sorted by `type` in reverse order.
 *
 * At the moment of writing, it means that supervisor's report comes before reviewer's
 * report.
 */
export function useThesisReports (): [
  UseRequestStatus<ThesisReportList>,
  (thesisId: Thesis['id']) => Promise<void>,
] {
  const { l } = useLocale()
  const [requestStatus, sendRequest] = useThesisReportRequest<ThesisReportList>({
    defaultErrorMessage: () => l.thesis.reports.errorMessages.load,
  })

  return [
    requestStatus,
    async (thesisId) => await sendRequest('GET', ':thesisId/reports', { thesisId }),
  ]
}

export async function updateReport (
  thesisId: Thesis['id'],
  type: ThesisReportType,
  data: ThesisReportPatch,
  requestor = thesisReportsRequestor,
): Promise<unknown> {
  return await requestor('PUT', ':thesisId/reports/:type', { thesisId, type }, {
    body: JSON.stringify(data),
  })
}

export function useSaveThesisReport (): [
  UseRequestStatus<void>,
  (thesisId: Thesis['id'], type: ThesisReportType, data: ThesisReportPatch) => Promise<void>,
] {
  const { l } = useLocale()
  const [requestStatus, sendRequest] = useThesisReportRequest<void>({
    defaultErrorMessage: () => l.thesis.reports.errorMessages.save,
  })

  return [
    requestStatus,
    async (thesisId, type, data) => await sendRequest('PUT', ':thesisId/reports/:type', {
      thesisId, type,
    }, {
      body: JSON.stringify(data),
    }),
  ]
}

export function useSubmitThesisReport (): [
  UseRequestStatus<void>,
  (thesisId: Thesis['id'], type: ThesisReportType) => Promise<void>,
] {
  const { l } = useLocale()
  const [requestStatus, sendRequest] = useThesisReportRequest<void>({
    defaultErrorMessage: () => l.thesis.reports.errorMessages.submit,
  })

  return [
    requestStatus,
    async (thesisId, type) => await sendRequest('POST', ':thesisId/reports/:type/actions/submit', {
      thesisId, type,
    }),
  ]
}
