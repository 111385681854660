import { useEffect } from 'react'
import { useForm, Controller, Control } from 'react-hook-form'
import type { Except, SetRequired } from 'type-fest'
import { isEmpty } from 'lodash'
import { TopicNew, TopicPatch, TopicVisibility } from '@cvut/profit-api-types/lib/theses/topic'
import { StudyDegree, SpecializationRef } from '@cvut/profit-api-types/lib/theses/specialization'
import { ThesisLanguage } from '@cvut/profit-api-types/lib/theses/thesis'
import { definitions } from '@cvut/profit-api-types/schema/theses.json'
import { Enum, objectOmit } from '@cvut/profit-utils'
import ReactSelect from 'react-select'

import Hint from '../../components/form/Hint'
import ModificationIndicator from '../../components/form/ModificationIndicator'
import useFormBackup from '../../hooks/useFormBackup'
import Select from '../../components/form/Select'
import TextInput from '../../components/form/TextInput'
import Spinner from '../../components/Spinner'
import type { OptionType } from '../../components/form/types'
import { useLocale } from '../../locale'
import * as buttonStyle from '../../components/Button.style'
import * as formStyle from '../../components/form/Form.style'
import * as reactSelectStyle from '../../components/form/ReactSelect.style'
import * as style from './TopicForm.style'
import type { ReactSelectOptionType } from './TopicSearch/Filter'
import { ReactComponent as DeleteIcon } from '../../images/icons/Delete.svg'
import { ReactComponent as InfoIcon } from '../../images/icons/InfoHint.svg'
import { useModals } from '../../features/modals'


interface MultiSelectProps {
  control: Control
  label: string
  name: string
  options: ReactSelectOptionType<string>[]
  isDisabled?: boolean
  valueToLabelMapper?: (value: string) => string
}

// FIXME: Current implementation does not generate errors if needed
const MultiSelect = ({
  control, label, name, options, isDisabled = false, valueToLabelMapper = x => x,
}: MultiSelectProps): JSX.Element => {
  const { l } = useLocale()
  const filterLocale = l.topic.search.filter

  return (
    <div className={reactSelectStyle.multiselectWrapper}>
      <label className={reactSelectStyle.multiselectLabel} htmlFor={name}>{label}</label>
      <Controller
        {...{ control, name, defaultValue: [] }}
        render={(
          { onChange, onBlur, value: selectValue = [] }
        ) => (
          <ReactSelect
            {...{ onChange, onBlur, options, isDisabled }}
            className={reactSelectStyle.multiselect}
            styles={reactSelectStyle.reactSelectStyles}
            closeMenuOnSelect={false}
            closeMenuOnScroll={false}
            isMulti
            placeholder={filterLocale.select}
            noOptionsMessage={() => filterLocale.noResult}
            // next 2 props provide translation between external string[] and internal ReactSelectOptionType<string>[]
            onChange={(selected: ReactSelectOptionType[]) => onChange(selected.map(x => x.value))}
            value={(selectValue as string[]).map(value => ({
              label: valueToLabelMapper(value),
              value,
            }))}
            // next 2 props are needed for tests with `react-select-event`
            inputId={name}
            name={name}
          />
        )}
      />
    </div>
  )
}

export type FormData =
  & SetRequired<Except<TopicNew | TopicPatch, 'keywords' | 'specializations' | 'studyDegrees'>, 'title' | 'language'>
  & { keywords: string }
  & { specializations: SpecializationRef['code'][] }
  & { studyDegrees: StudyDegree[] }

// Dear reader, please forgive us the sins of our youth as this or similar fix needs to stay here to handle
// stored topic form data where studyDegrees and specializations were saved as ReactSelectOptionType[]s instead
// of string[]...
const preprocess = (formData: FormData): unknown => ({
  ...formData,

  studyDegrees: (formData.studyDegrees ?? []).map((d: StudyDegree | ReactSelectOptionType<StudyDegree>) => (
    typeof d === 'string' ? d : d.value)
  ),
  specializations: (formData.specializations ?? [])
    .map((s: SpecializationRef['code'] | ReactSelectOptionType<SpecializationRef['code']>) => (
      typeof s === 'string'
        ? s
        : s.value
    )),
})
// The rest of this abomination remains in the lands of useFormBackup.ts - look for the sinful `preprocess`

interface Props {
  onSave: (data: FormData) => Promise<unknown>
  onDelete?: () => Promise<unknown>
  defaultValues?: Partial<FormData>
  specializationOptions?: OptionType[]
  formBackupKey?: string
  onModifiedState?: (isModified: boolean) => void
}

// TODO: Add an icon to the discard button.
const TopicForm = ({
  onSave, onDelete, defaultValues, specializationOptions, onModifiedState, formBackupKey = 'topic-form',
}: Props): JSX.Element => {
  const { l } = useLocale()
  const { showModal } = useModals()

  const { register, reset, handleSubmit, formState, watch, setValue, errors, trigger, control } = useForm<FormData>({
    defaultValues,
    // Giving user feedback right after input, gives better user experience
    mode: 'all',
  })

  const { clear: clearFormBackup } = useFormBackup(formBackupKey, {
    watch, setValue, reset, trigger,
  }, {
    dirty: true, defaultValues, preprocess,
  })

  const onSubmit = async (data: FormData) => {
    await onSave(data)

    clearFormBackup()
    reset({ ...data }, {
      isDirty: false,
    })
  }

  function displayDeleteModal () {
    showModal({
      title: l.topic.deleteActionModal.title,
      text: l.topic.deleteActionModal.text,
      cancelButton: {
        focus: true,
      },
      positiveButton: {
        caption: l.topic.deleteActionModal.positiveButton,
        onClick: onDelete,
      },
    })
  }

  const isFormModified = !isEmpty(formState.dirtyFields)

  useEffect(() => {
    onModifiedState?.(isFormModified && !formState.isSubmitting)
  }, [isFormModified, formState.isSubmitting])

  const descriptionInputName: keyof FormData = 'description'
  const watchedDescription: string | undefined = watch(descriptionInputName)

  const titleMinLength = definitions.Topic.properties.title.minLength
  const descriptionMaxLength = definitions.Topic.properties.description.maxLength

  const specializationSelectOptionsList = (specializationOptions ?? []).map(({ displayText, value }) => ({
    label: displayText,
    value,
  }))

  const studyDegreeSelectOptionsList = Enum(StudyDegree).keys().map((studyDegree) => ({
    label: l.topic.studyDegreeOptions[StudyDegree[studyDegree]],
    value: StudyDegree[studyDegree],
  }))

  const visibilityOptionsNode = Enum(objectOmit(TopicVisibility, 'Deleted')).keys().map((visibility) => (
    <option key={visibility} value={TopicVisibility[visibility]}>
      {l.topic.visibilityOptions[TopicVisibility[visibility]]}
    </option>
  ))

  // Forgive me Father, for I have sinned ------+
  //                                            |
  //                               vvvvvvvvvvvvvv
  const languageOptionsNode = Enum(ThesisLanguage).keys().map((lang) => (
    <option key={lang} value={ThesisLanguage[lang]}>
      {l.thesis.languageOptions[ThesisLanguage[lang]]}
    </option>
  ))

  const deleteButtonNode = onDelete ? (
    <button type='button' onClick={displayDeleteModal} className={formStyle.deleteButton}>
      {l.topic.delete}
      <DeleteIcon />
    </button>
  ) : null

  const formInvalidHelpNode = !formState.isValid ? (
    <Hint error={l.form.formIsInvalid} />
  ) : null

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={formStyle.form} noValidate>
      <div className={formStyle.inlineFieldGroup}>
        <div>
          <Select
            selectProps={{
              'name': 'language',
              'id': 'topic-language',
              'defaultValue': defaultValues?.language ?? '',
              'required': true,
              'ref': register({
                required: {
                  value: true,
                  message: l.form.required,
                },
              }),
              'aria-required': true,
              'aria-invalid': !!errors.language || undefined,
            }}
            labelText={l.topic.language}
            extraClassNames={[formStyle.formInput]}
          >
            <option hidden value=''>{l.topic.selectLanguagePrompt}</option>
            {languageOptionsNode}
          </Select>
          <Hint error={errors.language?.message} />
        </div>

        <div>
          <Select
            selectProps={{
              'name': 'visibility',
              'id': 'topic-visibility',
              'defaultValue': defaultValues?.visibility ?? '',
              'required': true,
              'ref': register({
                required: {
                  value: true,
                  message: l.form.required,
                },
              }),
              'aria-required': true,
              'aria-invalid': !!errors.visibility || undefined,
            }}
            labelText={l.topic.visibility}
            extraClassNames={[formStyle.formInput]}
          >
            <option hidden value=''>{l.topic.selectVisibilityPrompt}</option>
            {visibilityOptionsNode}
          </Select>
          <Hint error={errors.visibility?.message} />
        </div>
      </div>

      <div>
        <TextInput
          inputProps={{
            'name': 'title',
            'id': 'topic-title',
            'required': true,
            'ref': register({
              required: {
                value: true,
                message: l.form.required,
              },
              minLength: {
                value: titleMinLength,
                message: l.form.validation.minLength(titleMinLength),
              },
            }),
            'aria-required': true,
            'aria-invalid': errors.title && true,
          }}
          labelText={l.topic.title}
          extraClassNames={[formStyle.formInput]}
        />
        <Hint error={errors.title?.message} hint={l.form.validation.minLength(titleMinLength)} />
      </div>
      <TextInput
        inputProps={{
          name: descriptionInputName,
          id: 'topic-description',
          maxLength: descriptionMaxLength,
          rows: 8,
          ref: register,
        }}
        labelText={l.topic.description}
        multiline
        extraClassNames={[formStyle.formInput]}
        characterCount={watchedDescription?.length ?? 0}
      />

      <div>
        <TextInput
          inputProps={{
            name: 'keywords',
            id: 'topic-keywords',
            ref: register,
          }}
          labelText={l.topic.keywords}
          extraClassNames={[formStyle.formInput]}
        />
        <Hint hint={l.thesis.submission.form.help.keywords} />
      </div>

      <fieldset className={formStyle.fieldset}>
        <legend className={formStyle.legend}>
          {l.topic.recommendedGroupTitle}
          <InfoIcon className={style.hintIcon} title={l.topic.recommendedGroupHint} />
        </legend>

        <div className={style.multiselectTopInfo}>
          <Hint hint={l.topic.recommendedGroupHint} />
        </div>
        <div className={style.fieldsetRow}>
          <div className={style.fieldsetDegreeCol}>
            <MultiSelect
              control={control}
              label={l.topic.studyDegree}
              name='studyDegrees'
              options={studyDegreeSelectOptionsList}
              // User should only be able to select either `specializations` or `studyDegrees`
              isDisabled={(watch('specializations') ?? []).length > 0}
              valueToLabelMapper={(x: string) => l.topic.studyDegreeOptions[x as StudyDegree]}
            />
          </div>
          <div className={style.fieldsetSpecializationsCol}>
            <MultiSelect
              control={control}
              label={l.topic.specialization}
              name='specializations'
              options={specializationSelectOptionsList}
              // User should only be able to select either `specializations` or `studyDegrees`
              isDisabled={(watch('studyDegrees') ?? []).length > 0}
            />
          </div>
        </div>
      </fieldset>

      <div className={formStyle.controls}>
        <div className={formStyle.controlsLeft}>
          {deleteButtonNode}
          <button
            type='button'
            className={buttonStyle.outlineDark}
            onClick={clearFormBackup}
            disabled={!isFormModified}
          >
            {l.form.discardChanges}
          </button>
        </div>

        <div className={formStyle.controlsRight}>
          <button
            type='submit'
            className={buttonStyle.fill}
            disabled={formState.isSubmitting || !formState.isValid || !isFormModified}
          >
            {formState.isSubmitting
              ? <Spinner.DualRings color='white' title={l.form.saving + '…'} />
              : l.form.save}
          </button>
        </div>
      </div>
      <ModificationIndicator isModified={isFormModified} />
      {/* wrapped in a div to avoid direct form descendant styling */}
      <div>
        <Hint hint={l.form.formInfoRequired} />
        {formInvalidHelpNode}
      </div>
    </form>
  )
}

export default TopicForm
