import { css, cx } from 'linaria'

import * as colors from '../../../styles/colors'
import { rel } from '../../../styles/dimensions'
import { fontWeights } from '../../../styles/typography'
import * as multiselectStyle from './MultiselectFilter.style'


export const mainState = css`
  font-weight: ${fontWeights.bold};
`

export const subState = css`
  & + .${mainState} {
    margin-top: ${rel(8)}rem;
  }
`

export const selectLabelCentered = cx(multiselectStyle.selectLabel, css`
  padding: ${rel(6)}rem ${rel(30)}rem;
  text-align: center;
`)

export const stateFill = css`
  width: 100%;
  height: 100%;
`

export const stateIndicator = css`
  display: inline-block;
  box-sizing: border-box;
  width: ${rel(12)}rem;
  height: ${rel(12)}rem;
  margin: ${rel(8)}rem ${rel(2)}rem;
  background: transparent;
  border-style: solid;
  border-width: ${rel(2)}rem;
  border-radius: 50%;
`

export const draft = css`
  color: ${colors.draftColor};
  border-color: ${colors.draftColor};

  .${stateFill} {
    background: ${colors.draftColor};
  }

  .all {
    stroke: ${colors.draftColor};
  }

  .background {
    fill: ${colors.draftColor};
    stroke: ${colors.draftColor};
  }
`

export const proposed = css`
  color: ${colors.proposedColor};
  border-color: ${colors.proposedColor};

  .${stateFill} {
    background: ${colors.proposedColor};
  }

  .all {
    stroke: ${colors.proposedColor};
  }

  .background {
    fill: ${colors.proposedColor};
    stroke: ${colors.proposedColor};
  }
`

export const assigned = css`
  color: ${colors.assignedColor};
  border-color: ${colors.assignedColor};

  .${stateFill} {
    background: ${colors.assignedColor};
  }

  .all {
    stroke: ${colors.assignedColor};
  }

  .background {
    fill: ${colors.assignedColor};
    stroke: ${colors.assignedColor};
  }
`

export const review = css`
  color: ${colors.reviewColor};
  border-color: ${colors.reviewColor};

  .${stateFill} {
    background: ${colors.reviewColor};
  }

  .all {
    stroke: ${colors.reviewColor};
  }

  .background {
    fill: ${colors.reviewColor};
    stroke: ${colors.reviewColor};
  }
`

export const readyForDefence = css`
  color: ${colors.readyForDefenceColor};
  border-color: ${colors.readyForDefenceColor};

  .${stateFill} {
    background: ${colors.readyForDefenceColor};
  }

  .all {
    stroke: ${colors.readyForDefenceColor};
  }

  .background {
    fill: ${colors.readyForDefenceColor};
    stroke: ${colors.readyForDefenceColor};
  }
`

export const evaluated = css`
  color: ${colors.evaluatedColor};
  border-color: ${colors.evaluatedColor};

  .${stateFill} {
    background: ${colors.evaluatedColor};
  }

  .all {
    stroke: ${colors.evaluatedColor};
  }

  .background {
    fill: ${colors.evaluatedColor};
    stroke: ${colors.evaluatedColor};
  }
`

export const archived = css`
  color: ${colors.archivedColor};
  border-color: ${colors.archivedColor};

  .${stateFill} {
    background: ${colors.archivedColor};
  }

  .all {
    stroke: ${colors.archivedColor};
  }

  .background {
    fill: ${colors.archivedColor};
    stroke: ${colors.archivedColor};
  }
`
