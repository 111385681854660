import { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { cx } from 'linaria'
import { Notification as INotification, ResourceType } from '@cvut/profit-api-types/lib/theses'

import { useMarkNotificationVisited } from '../../api/notifications'
import { formatDate } from '../../utils/date'
import pagePaths from '../../pages/paths'
import * as style from './Notification.style'
import { ReactComponent as ArrowRightIcon } from '../../images/icons/ArrowRight.svg'
import { ReactComponent as ProfileIcon } from '../../images/icons/Profile.svg'


const notificationVisitedDelay = 2000

const notificationLink = (notification: INotification) =>
  notification.targetType !== ResourceType.Thesis && notification.targetType !== ResourceType.ReviewerProposal
    ? ''
    : pagePaths.theses.view(
        notification.targetType === ResourceType.Thesis
          ? notification.targetId
          : notification.templateData.thesisId
    )

interface Props {
  notification: INotification
  onRedirect?: () => void
}

const unvisitedStyle: Record<INotification['type'], string> = {
  notice: style.unvisitedNotice,
  task: style.unvisitedTask,
}

const Notification = ({ notification, onRedirect }: Props): JSX.Element => {
  const [, markVisited] = useMarkNotificationVisited()

  useEffect(() => {
    let handle: number | null = null

    if (notification.state !== 'visited') {
      handle = window.setTimeout(invokeNotification, notificationVisitedDelay)
    }

    return () => {
      if (handle) {
        window.clearTimeout(handle)
      }
    }
  }, [notification])

  const invokeNotification = (willRedirect = false) => {
    if (notification.state !== 'visited') {
      // TODO: handle request error
      void markVisited(notification.id)
      notification.state = 'visited'
    }
    willRedirect && onRedirect?.()
  }

  const notificationClasses = [
    style.notification,
    notification.state === 'visited' ? style.visitedNotification : unvisitedStyle[notification.type],
  ]

  const notificationEl = (
    <div className={cx(...notificationClasses)}>
      <div className={style.header}>
        <span className={style.title}>{notification.subject}</span>
        <span className={style.date}>{formatDate(new Date(notification.createdAt))}</span>
      </div>
      <div className={style.content}>
        <ProfileIcon className={style.profileIcon} />
        <p className={style.description}>{notification.body}</p>
        <ArrowRightIcon />
      </div>
    </div>
  )

  return (
    <Link to={notificationLink(notification)} className={style.link} onClick={() => invokeNotification(true)}>
      {notificationEl}
    </Link>
  )
}

export default Notification
