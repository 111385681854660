import { useEffect } from 'react'
import { Configuration, Specialization, Thesis } from '@cvut/profit-api-types/lib/theses'

import Card from '../../components/Card'
import RequestDependentRender from '../../api/RequestDependentRender'
import Spinner from '../../components/Spinner'
import { assert } from '../../utils'
import { localeToLocaleString } from '../../locale/utils'
import { useLocale } from '../../locale'
import { useConfiguration } from '../../api/configuration'


interface Props {
  thesis: Thesis
}

const ThesisReportsDeadlineCard = ({ thesis }: Props): JSX.Element | null => {
  const { l, currentLang } = useLocale()
  const [configurationState, getConfiguration] = useConfiguration()

  const studyDegree = (thesis.specialization as Specialization)?.studyDegree
  if (!studyDegree) {
    return null
  }

  useEffect(() => {
    void getConfiguration()
  }, [])

  return (
    <Card>
      <Card.Header title={l.thesis.reports.deadline.header} />
      <Card.Content>
        <RequestDependentRender
          requestStatus={configurationState}
          renderOnLoading={() => (
            <Spinner.DualRings
              title={l.thesis.reports.deadline.loadingMessage}
              color='primary'
            />
          )}
          renderOnError={() => <p>{l.thesis.reports.deadline.loadingError}</p>}
          renderOnSuccess={(configuration: Configuration) => {
            const deadline = {
              bachelor: configuration.reportDeadlineBachelor,
              master: configuration.reportDeadlineMaster,
            }[studyDegree]

            assert(deadline, 'Report deadline cannot be empty')

            const deadlineDate = new Date(deadline)
            const reportsDeadlineText = deadlineDate.toLocaleDateString(localeToLocaleString(currentLang))

            return (
              <p>
                <time dateTime={deadlineDate.toISOString()}>{reportsDeadlineText}</time>
              </p>
            )
          }}
        />
      </Card.Content>
    </Card>
  )
}

export default ThesisReportsDeadlineCard
