import { css, cx } from 'linaria'

import { breakpoints, rel } from '../../styles/dimensions'
import { thesesTable } from './ThesesTable.style'


export const officerThesesTable = cx(thesesTable, css`
  table {
    width: ${rel(1200)}rem;

    @media screen and (min-width: ${breakpoints.xl}) {
      width: 100%;
    }
  }
`)
