import { useSession } from '../session'
import { useLocale } from '../locale'
import PageHeader from '../components/PageHeader'


const Homepage = (): JSX.Element => {
  const { l } = useLocale()
  const { isAuthenticated } = useSession()

  return (
    <article>
      <PageHeader title={l.homepage.welcome} />
      {!isAuthenticated() && (
        <p>{l.homepage.loginRequest}</p>
      )}
    </article>
  )
}

export default Homepage
