import { Route, RouteProps } from 'react-router-dom'

import { goToAuthSaveCurrentLocation } from '../../api/auth'
import { useSession } from '../../session'


const PrivateRoute = (props: RouteProps): JSX.Element | null => {
  const { isAuthenticated } = useSession()

  if (isAuthenticated()) {
    return <Route {...props} />
  }

  goToAuthSaveCurrentLocation()
  return null // this will never happen as `goToAuth` sends us to the authorization server
}

export default PrivateRoute
