import { Person, Study } from '@cvut/profit-api-types/lib/theses'
import { formatPersonFullName } from '@cvut/profit-theses-common'

import { useLocale } from '../../locale'
import { assert } from '../../utils'
import { ReactComponent as FileIcon } from '../../images/icons/File.svg'
import * as style from './ThesisPDFButton.style'


interface ThesisPDFButtonProps {
  assignee: Study
  onClick: () => unknown
}

const ThesisPDFButton = ({ assignee, onClick }: ThesisPDFButtonProps): JSX.Element => {
  assert(assignee.person, 'thesis.assignee.person should contain a Person instance')

  const { l } = useLocale()
  const author = formatPersonFullName(assignee.person as Person)

  return (
    <button
      type='button'
      onClick={onClick}
      className={style.downloadButton}
      title={`${l.thesis.thesis} ${author}`}
    >
      <FileIcon className={style.downloadIcon} />
      <div className={style.title}>{l.thesis.thesis}</div>
      <div className={style.author}>{author}</div>
    </button>
  )
}

export default ThesisPDFButton
