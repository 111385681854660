import { useState, useEffect } from 'react'
import urlcat, { ParamMap } from 'urlcat'
import type { Page } from '@cvut/profit-api-types/lib/common'
import type { Except } from '@cvut/profit-utils'

import {
  useRequest, UseRequestConfig, UseRequestNotInitiated, UseRequestLoading, UseRequestAborted, UseRequestError,
  UseRequestSuccess,
} from './useRequest'
import { createRequestor, MakeRequest } from './makeRequest'
import { addPaginationParams } from './paginatedRequests'


export type UsePaginatedRequestStatus<T> =
  (
    | UseRequestNotInitiated
    | UseRequestLoading
    | UseRequestAborted
    | UseRequestError
    | Except<UseRequestSuccess<T>, 'data'>
  ) & { data: T[] }

const defaultRequestor = createRequestor('')

/**
 * The main use case of this function is to implement infinite scrolling. Fetching a specific page doesn't need an
 * abstraction. However, there's a helper function for adding pagination query parameters, see
 * `api/paginatedRequests.ts`.
 *
 * @param url Api prefix is automatically added.
 */
export function usePaginatedRequest<T> (
  config: UseRequestConfig,
  pageSize: number,
  // In `makeRequest` this is the first argument but here we keep it close to the optional `params` argument.
  pathTemplate: string,
  params?: ParamMap,
  // If this thing ever needs to make request outside of api, convert url's type to `StructuredUrl` (see
  // `api/makeRequest.ts` and read `baseUrl` from there to create the requestor.
  makeRequest: MakeRequest = defaultRequestor
): [
    result: UsePaginatedRequestStatus<T>,
    isLastPage: boolean,
    getNextPage: () => void,
  ] {
  const [requestStatus, sendRequest] = useRequest<Page<T>>(makeRequest, config)
  const [nextPageToDownload, setNextPageToDownload] = useState(0)
  const [result, setResult] = useState<UsePaginatedRequestStatus<T>>({
    state: 'not-initiated',
    data: [],
  })

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const isLastPage = requestStatus.state === 'success' && !requestStatus.data!.pagination.hasNextPage

  const getNextPage = () => {
    setResult(prevResult => ({
      state: 'loading',
      data: prevResult.data,
    }))

    void sendRequest(
      'GET',
      pathTemplate,
      addPaginationParams(params ?? {}, pageSize, pageSize * nextPageToDownload)
    )
  }

  const resetPagination = (): void => {
    setResult({
      state: 'not-initiated',
      data: [],
    })

    setNextPageToDownload(0)
  }

  useEffect(() => {
    if (requestStatus.state === 'success') {
      setResult(prevResult => ({
        state: 'success',
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        data: [...prevResult.data, ...requestStatus.data!.data],
        response: requestStatus.response,
      }))

      setNextPageToDownload(page => page + 1)
    }

    if (requestStatus.state === 'error') {
      setResult(prevResult => ({
        state: 'error',
        requestError: requestStatus.httpProblem,
        response: requestStatus.response,
        data: prevResult.data,
      }))
    }
  }, [requestStatus.state])

  useEffect(resetPagination, [urlcat('', params ?? {})])

  return [result, isLastPage, getNextPage]
}
