
export type StringEnum<T> = Record<StringKeyOf<T>, string>

type StringKeyOf<T> = Extract<keyof T, string>
type nil = undefined | null

/* eslint-disable @typescript-eslint/method-signature-style */
interface EnumUtils <T extends StringEnum<T>> {
  /**
   * Returns a list of the enum's keys.
   */
  keys: () => ReadonlyArray<StringKeyOf<T>>
  /**
   * Returns a list of the enum's values.
   */
  values: () => ReadonlyArray<T[keyof T]>
  /**
   * Returns true if the given `key` is actually a valid key for the enum,
   * false otherwise. It also acts as a type guard.
   */
  isKey: (key: string | nil) => key is StringKeyOf<T>
  /**
   * Returns true if the given `value` is actually a valid value for the enum,
   * false otherwise. It also acts as a type guard.
   */
  isValue: (value: string | nil) => value is T[keyof T]
  /**
   * Returns a properly-typed enum value for the given `key`. If the `key` is
   * not a valid key for the enum and the `defaultValue` is provided, then the
   * `defaultValue` is returned. Otherwise a RangeError is thrown.
   */
  fromKey (key: string | nil): T[keyof T]
  fromKey <Default> (key: string | nil, defaultResult: Default): T[keyof T] | Default
  /**
   * Casts the given `value` into a properly-typed value of this enum. If the
   * `value` is not a valid value for the enum and the `defaultValue` is
   * provided, then the `defaultValue` is returned. Otherwise a RangeError
   * is thrown.
   */
  fromValue (value: string | nil): T[keyof T]
  fromValue <Default> (value: string | nil, defaultValue: Default): T[keyof T] | Default
}
/* eslint-enable */

/**
 * Decorates the given string enum type (enums with numeric values are not
 * supported) with handful functions for working with that enum. It creates
 * a new object and does not modify the given enum.
 */
export const Enum = <T extends StringEnum<T>> (enumType: T): EnumUtils<T> & T => ({
  ...enumType,

  values: () => Object.values(enumType) as ReadonlyArray<T[keyof T]>,

  keys: () => Object.keys(enumType) as Array<StringKeyOf<T>>,

  isKey: (key): key is StringKeyOf<T> => !!(key && enumType[key as keyof T]),

  isValue: (value): value is T[keyof T] => Object.values(enumType).includes(value),

  fromKey: (key: string | nil, ...args: any[]) => {
    const value = enumType[key as keyof T]
    if (value !== undefined) {
      return value
    } else if (args.length > 0) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      return args[0]
    } else {
      throw RangeError(`Invalid enum key "${key}", expected one of: ${Object.keys(enumType)}`)
    }
  },

  fromValue: (value: string | nil, ...args: any[]) => {
    const values = Object.values(enumType)
    if (values.includes(value)) {
      return value
    } else if (args.length > 0) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      return args[0]
    } else {
      throw RangeError(`Invalid enum value "${value}", expected one of: ${values}`)
    }
  },
})
