import { noop } from 'lodash'
import { useState, useRef, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { cx } from 'linaria'
import type { ThesisAssignment } from '@cvut/profit-api-types/lib/theses'
import { isSpecialization } from '@cvut/profit-api-types/lib/theses'

import { useLocale } from '../../locale'
import Card from '../../components/Card'
import { MetadataPair } from './MetadataPair'
import pagePaths from '../../pages/paths'
import TruncatedText from '../../components/TruncatedText'
import { ReactComponent as DownloadIcon } from '../../images/icons/DownloadRound.svg'
import { ReactComponent as EditIcon } from '../../images/icons/EditRound.svg'
import { ReactComponent as SecondaryOptionsIcon } from '../../images/icons/OptionsRound.svg'
import * as styles from './ThesisAssignmentView.style'
import useOnClickOutside from '../../hooks/useOnClickOutside'
import useOnKeyPress from '../../hooks/useOnKeyPress'
import { useTopic } from '../../api/topics'


const topicUrl = (topicId?: number) => {
  let url

  if (topicId != null) {
    url = pagePaths.topics.view(topicId)
  }

  return url
}

const shortDescriptionLength = 101

interface Props {
  thesis: ThesisAssignment & { validUntil?: string | null }
  editLink?: string
  assignmentPDFLink?: string
  onThesisArchive?: () => void
  extraClassNames?: string[]
}

// TODO: Thesis topic (module A).
// TODO: Populate “more” menu.
// eslint-disable-next-line @typescript-eslint/no-unused-vars-experimental
const ThesisAssignmentView = ({
  thesis, editLink, assignmentPDFLink, onThesisArchive, extraClassNames,
}: Props): JSX.Element => {
  const { l } = useLocale()

  const [isOpen, setIsOpen] = useState(false)
  const nodeRef = useRef<HTMLDivElement | null>(null)
  const handleDropdownToggle = () => setIsOpen(!isOpen)
  const handleDropdownClose = () => setIsOpen(false)
  useOnClickOutside(nodeRef, handleDropdownClose)
  useOnKeyPress('Escape', handleDropdownClose)

  const validUntilNode = thesis.validUntil && (
    <MetadataPair
      label={l.thesis.validUntil}
      tooltip={l.thesis.validUntilTooltip}
      value={thesis.validUntil}
    />
  )

  const studyDegreeNode = thesis.specialization && isSpecialization(thesis.specialization)
    && <MetadataPair label={l.thesis.thesisType} value={l.thesis.thesisTypeOption[thesis.specialization.studyDegree]} />

  const hasMoreOptions = !!onThesisArchive

  const [getTopicStatus, getTopic] = useTopic({ 403: noop, 404: noop })

  useEffect(() => {
    if (thesis.topic) {
      void getTopic(thesis.topic.id)
    }
  }, [thesis.topic])

  return (
    <Card>
      <Card.Header title={l.thesis.assignment}>
        {editLink && (
          <Link to={editLink} title={l.thesis.edit}>
            <EditIcon className={styles.controlIcon} title={l.thesis.edit} />
          </Link>
        )}
        {assignmentPDFLink && (
          <a href={assignmentPDFLink} title={l.thesis.download} download>
            <DownloadIcon className={styles.controlIcon} title={l.thesis.download} />
          </a>
        )}
        {hasMoreOptions && (
          <div ref={nodeRef} className={styles.moreOptions}>
            <button
              type='button'
              aria-haspopup
              aria-expanded={isOpen}
              onClick={handleDropdownToggle}
              title={l.thesis.secondaryOptions}
              className={cx(styles.moreOptionsToggleButton, isOpen && styles.dropdownOpen)}
              id='moreOptions'
            >
              <SecondaryOptionsIcon className={cx(styles.controlIcon)} title={l.thesis.secondaryOptions} />
            </button>
            <div
              className={cx(styles.moreOptionsDropwdown, isOpen && styles.dropdownOpen)}
              aria-labelledby='moreOptions'
            >
              {onThesisArchive && (
                <button type='button' onClick={onThesisArchive}>{l.thesis.archiveThesis}</button>
              )}
            </div>
          </div>
        )}
      </Card.Header>
      <Card.Content>
        <article className={cx(...(extraClassNames ?? []))}>
          <dl className={styles.metadataPairs}>
            {studyDegreeNode}
            <MetadataPair label={l.thesis.thesisLanguage} value={l.thesis.languageOptions[thesis.language]} />
            {getTopicStatus.state === 'success' && (
              <MetadataPair
                href={topicUrl(getTopicStatus.data?.id)}
                label={l.topic.topic}
                value={getTopicStatus.data?.title ?? '-'}
              />
            )}
            {/* TODO: Uncomment when license type is supported
            <MetadataPair label={l.thesis.licenseType} value={l.thesis.licenseTypeOptions[thesis.licenseType]} />*/}
            {validUntilNode}
          </dl>
          <TruncatedText length={shortDescriptionLength} text={thesis.description} defaultExpanded>
            {(description) => (
              <p className={styles.description}>{description}</p>
            )}
          </TruncatedText>
        </article>
      </Card.Content>
    </Card>
  )
}

export default ThesisAssignmentView
