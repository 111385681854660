import { css, cx } from 'linaria'

import { breakpoints, rel } from '../../styles/dimensions'
import { fontWeights } from '../../styles/typography'
import * as colors from '../../styles/colors'


// #root parent in selectors used to override the <style/>#_goober in production build
export const toast = css`
  #root & {
    max-width: 100%;
    color: ${colors.white};

    @media screen and (min-width: ${breakpoints.md}) {
      max-width: ${rel(600)}rem;
    }
  }
`

export const infoToast = cx(toast, css`
  #root & {
    background-color: ${colors.neutralColor[2]};
  }
`)

export const successToast = cx(toast, css`
  #root & {
    background-color: ${colors.positiveColor[2]};
  }
`)

export const errorToast = cx(toast, css`
  #root & {
    background-color: ${colors.negativeColor[2]};
  }
`)

export const dismissToastButton = css`
  #root & {
    color: ${colors.white};
    font-weight: ${fontWeights.bold};
    font-size: ${rel(24)}rem;
    background-color: transparent;
    border: 0;
    opacity: 0.5;
    transition: opacity 0.35s ease-in-out;

    &:hover,
    &focus,
    &:active {
      opacity: 1;
    }
  }
`

export const infoIcon = css`
  #root & {
    min-width: ${rel(20)}rem;

    .background {
      fill: ${colors.white};
    }

    .info {
      fill: ${colors.neutralColor[2]};
    }
  }
`
