import { css } from 'linaria'

import { breakpoints, rel } from '../../styles/dimensions'


export const columnContainer = css`
  display: grid;
  grid-template-columns: auto;
  align-items: start;
`

export const twoColumns = css`
  @media screen and (min-width: ${breakpoints.xl}) {
    grid-template-columns: 3fr 1fr;
    column-gap: ${rel(24)}rem;
  }
`
