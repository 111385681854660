import type { ComponentPropsWithRef } from 'react'
import { cx } from 'linaria'

import Hint from './Hint'
import { useLocale } from '../../locale'
import * as styles from './TextInput.style'


type InputType = keyof Pick<JSX.IntrinsicElements, 'input' | 'textarea'>

interface BaseProps<T extends InputType> {
  labelText: string
  inputProps: { name: string, id: string } & Omit<ComponentPropsWithRef<T>, 'className'>
  extraClassNames?: string[]
  /** The character count of the input element. It is displayed when maximum length of the input is specified. */
  characterCount?: number
}

type Props =
  | { multiline?: false } & BaseProps<'input'>
  | { multiline: true } & BaseProps<'textarea'>

/**
 * Wrapper component for <input> and <textArea> form elements.
 * Renders <input> element by default. Set multiline=true to render <textArea>.
 */
const TextInput = (props: Props): JSX.Element => {
  const { l } = useLocale()
  // TODO: Replace required indicator to a dot according to the style guide.
  const requiredIndicator = props.inputProps.required
    ? <span aria-hidden className={styles.requiredIndicator}>*</span>
    : null

  // We do not allow overriding className of `<input />` on purpose.
  const inputElement = props.multiline
    ? <textarea {...props.inputProps} className={styles.input} />
    : <input {...props.inputProps} className={styles.input} />

  const characterCounter = props.characterCount !== undefined && props.inputProps.maxLength !== undefined
    ? <Hint hint={`${l.form.input.characterCount}: ${props.characterCount}/${props.inputProps.maxLength}`} />
    : null

  return (
    <div className={cx(...(props.extraClassNames ?? []))}>
      <label htmlFor={props.inputProps.id} className={styles.label}>
        {requiredIndicator}
        {props.labelText}
      </label>
      {inputElement}
      {characterCounter}
    </div>
  )
}

export default TextInput
