import {
  Person, ReviewerProposal, ReviewerProposalList, Thesis,
} from '@cvut/profit-api-types/lib/theses'

import { apiPrefix } from '../../config'
import { createRequestor } from '../makeRequest'
import { useRequest, UseRequestStatus, UseRequestConfig } from '../useRequest'
import { useLocale } from '../../locale'


const thesesApiPrefix = `${apiPrefix}/theses`
const reviewerProposalsApiPrefix = `${apiPrefix}/reviewer-proposals`

function useReviewerProposalsRequest<T> (config: UseRequestConfig, baseUrl = thesesApiPrefix) {
  return useRequest<T>(createRequestor(baseUrl), config)
}

export function useThesisReviewerProposals (): [
  UseRequestStatus<ReviewerProposalList>,
  (thesisId: Thesis['id']) => Promise<void>,
] {
  const { l } = useLocale()
  const [requestStatus, sendRequest] = useReviewerProposalsRequest<ReviewerProposalList>({
    defaultErrorMessage: () => l.thesisReviewerProposalsList.errorMessages.load,
  })

  return [
    requestStatus,
    async (thesisId) => await sendRequest('GET', ':thesisId/reviewer-proposals/', { thesisId }),
  ]
}

export function useProposeThesisReviewer (): [
  UseRequestStatus<void>,
  (thesisId: Thesis['id'], username: Person['username']) => Promise<void>,
] {
  const { l } = useLocale()
  const [requestStatus, sendRequest] = useReviewerProposalsRequest<void>({
    defaultErrorMessage: () => l.thesisReviewerProposal.errorMessages.propose,
  })

  return [
    requestStatus,
    async (thesisId, username) => {
      // debugger
      return await sendRequest('POST', ':thesisId/reviewer-proposals', { thesisId }, {
        body: JSON.stringify({ reviewer: { username } }),
      })
    },
  ]
}

export function useReviewerProposalAction (action: 'accept' | 'cancel' | 'reject'): [
  UseRequestStatus<void>,
  (proposalId: ReviewerProposal['id'], role: 'proponent' | 'reviewer' | 'specOfficer') => Promise<void>,
] {
  const { l } = useLocale()
  const [requestStatus, sendRequest] = useReviewerProposalsRequest<void>({
    defaultErrorMessage: () => ({
      accept: l.thesisReviewerProposal.errorMessages.accept,
      cancel: l.thesisReviewerProposal.errorMessages.cancel,
      reject: l.thesisReviewerProposalsList.errorMessages.reject,
    }[action]),
  }, reviewerProposalsApiPrefix)

  return [
    requestStatus,
    async (proposalId, role) => await sendRequest('POST', ':proposalId/actions/:action', {
      action, proposalId, role,
    }),
  ]
}
