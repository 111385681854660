import { FileAttachment } from '@cvut/profit-api-types/lib/theses'
import { formatFileSize } from '@cvut/profit-theses-common'

import { ReactComponent as FileIcon } from '../../images/icons/File.svg'
import * as style from './ThesisSubmitForm.style'


interface AttachmentFileDownloadIconProps {
  item: FileAttachment
}

const AttachmentFileDownloadIcon = ({ item }: AttachmentFileDownloadIconProps): JSX.Element => {
  const { currentSize, totalSize, filename, href } = item
  const sizeInfo = currentSize === totalSize
    ? formatFileSize(totalSize)
    : `${formatFileSize(currentSize)} / ${formatFileSize(totalSize)}`

  return (
    <a href={href} className={style.uploadedFile} download rel='noreferrer' target='_blank'>
      <FileIcon className={style.downloadIcon} />
      <div className={style.fileName} title={filename}>
        {filename}
      </div>
      <div className={style.fileSize} title={sizeInfo}>
        {sizeInfo}
      </div>
    </a>
  )
}

export default AttachmentFileDownloadIcon
