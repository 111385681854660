import { ChangeEvent } from 'react'
import { UseFormMethods, useFieldArray } from 'react-hook-form'
import { isEmpty, omit } from 'lodash'
import { cx } from 'linaria'
import { Attachment, AttachmentType, FileAttachment } from '@cvut/profit-api-types/lib/theses'
import { formatFileSize } from '@cvut/profit-theses-common'

import AttachmentRow from './AttachmentRow'
import { addNotification } from '../toastNotifications'
import type { UploadQueueItem } from '../../api/theses'
import locale, { useLocale } from '../../locale'
import * as buttonStyle from '../../components/Button.style'
import * as formStyle from '../../components/form/Form.style'
import * as style from './ThesisSubmitForm.style'


const attachmentFileSizeLimit = 100 * Math.pow(2, 30)
const attachmentsListName = 'attachments'
const attachmentDefaultValues: Attachment = {
  type: 'sourceCode',
  titleCs: locale.cs.thesis.submission.form.attachmentType.sourceCode.title,
  titleEn: locale.en.thesis.submission.form.attachmentType.sourceCode.title,
  href: '',
}
const getAttachmentFieldName = (index: number, id: string) => `${attachmentsListName}[${index}].${id}`


interface AttachmentsProps {
  control: UseFormMethods['control']
  register: UseFormMethods['register']
  setValue: UseFormMethods['setValue']
  errors: UseFormMethods['errors']
  watch: UseFormMethods['watch']
  addUploadItem: (uploadItem: UploadQueueItem) => void
  getQueuedFile: (filename: string) => File | undefined
  removeUploadItem: (filename: string) => void
}

const Attachments = ({
  addUploadItem, getQueuedFile, control, errors, removeUploadItem, register, setValue,
}: AttachmentsProps): JSX.Element => {
  const { l } = useLocale()
  const submissionFormLocale = l.thesis.submission.form
  const { append, fields, remove } = useFieldArray<Attachment>({ control, name: attachmentsListName })

  const handleAddLink = () => {
    append(attachmentDefaultValues, true)
  }

  const handleAddFile = () => {
    append({
      ...attachmentDefaultValues,
      filename: '',
      currentSize: 0,
      totalSize: 0,
    }, true)
  }

  const handleDelete = (index: number) => {
    remove(index)
    if ('filename' in fields[index]) {
      const filename = (fields[index] as FileAttachment).filename
      removeUploadItem(filename)
    }
  }

  const changeHandler = (item: Attachment, index: number) => {
    const handleChange = (e: ChangeEvent<HTMLSelectElement | HTMLInputElement>) => {
      if (e.target.nodeName === 'SELECT') {
        const attachmentType = e.currentTarget.value as AttachmentType
        // TODO - use getPropertyWithSuffix
        setValue(getAttachmentFieldName(index, 'titleCs'),
          locale.cs.thesis.submission.form.attachmentType[attachmentType].title)
        setValue(getAttachmentFieldName(index, 'titleEn'),
          locale.en.thesis.submission.form.attachmentType[attachmentType].title)
        return
      }

      if (e.target.nodeName === 'INPUT') {
        const fileInput = (e as ChangeEvent<HTMLInputElement>).currentTarget
        const file = fileInput.files?.[0]

        if (!file) {
          return
        }

        if (!fileInput.accept.split(',').some(acceptedSuffix => file.name.endsWith(acceptedSuffix))) {
          e.currentTarget.value = ''
          return
        }

        if (file.size > attachmentFileSizeLimit) {
          e.currentTarget.value = ''
          const size = formatFileSize(attachmentFileSizeLimit)
          addNotification({
            type: 'NEGATIVE',
            message: l.thesis.submission.errorMessages.attachmentSizeExceedsLimit(size),
          })
          return
        }

        const isFilenameAlreadyUsed = isEmpty((item as FileAttachment).filename) && hasAttachment(file.name)
        if (isFilenameAlreadyUsed) {
          e.currentTarget.value = ''
          addNotification({
            type: 'NEGATIVE',
            message: l.thesis.submission.errorMessages.attachmentAlreadyExists(file.name),
          })
          return
        }

        const isTryingToUseDifferentFilename = !isEmpty((item as FileAttachment).filename)
          && ((item as FileAttachment).filename !== file.name)
        if (isTryingToUseDifferentFilename) {
          e.currentTarget.value = ''
          addNotification({
            type: 'NEGATIVE',
            message: l.thesis.submission.errorMessages.attachmentFilenameShouldMatch((item as FileAttachment).filename),
          })
        }

        const nameRoot = `${attachmentsListName}[${index}]`

        setValue(`${nameRoot}.filename`, file.name)

        if ('totalSize' in item && item.totalSize === 0) {
          setValue(`${nameRoot}.totalSize`, file.size)
        }

        addUploadItem({
          file,
          currentSize: ('currentSize' in item) ? (item.currentSize ?? 0) : 0,
          totalSize: ('totalSize' in item && item.totalSize) ? item.totalSize : file.size,
        })

        // TODO - refactor this into a separate function updating the attachments list
        const newAttachments = fields.map((item, i) => (
          i === index
            ? {
              ...omit(item, 'id'),
              ...file && 'filename' in item
                ? {
                  totalSize: item.totalSize === 0 ? file.size : item.totalSize,
                  filename: file.name,
                }
                : {},
            }
            : omit(item, 'id')
        ))
        remove()
        append(newAttachments, true)
      }
    }
    return handleChange
  }

  const hasAttachment = (filename: string) => (
    fields.some(attachment => (
      'filename' in attachment && attachment.filename === filename
    ))
  )

  // TODO - support for dragging file into the attachments area and adding rows automatically would be cool
  return (
    <fieldset className={cx(formStyle.fieldset, style.attachments)}>
      <legend className={formStyle.legend}>
        {l.thesis.submission.form.label.attachments.title}
      </legend>
      {fields.map((item, index) => (
        <AttachmentRow
          {...{
            addUploadItem,
            getQueuedFile,
            index, // FIXME - why both index and item??
            item: {
              ...attachmentDefaultValues,
              ...item,
            },
            errors,
            register,
            setValue,
          }}
          key={item.id}
          hasAttachment={hasAttachment}
          onDelete={handleDelete}
          onChange={changeHandler({ ...attachmentDefaultValues, ...item }, index)}
        />
      ))}
      {!fields.length && (
        <p className={style.noResult}>
          {submissionFormLocale.label.attachments.noResult}
        </p>
      )}
      <div className={style.buttons}>
        <button
          className={buttonStyle.outlineDark}
          onClick={handleAddLink}
          type='button'
        >
          {submissionFormLocale.attachmentButtons.addLink}
        </button>
        <button
          className={buttonStyle.outlineDark}
          onClick={handleAddFile}
          type='button'
        >
          {submissionFormLocale.attachmentButtons.addFile}
        </button>
      </div>
    </fieldset>
  )
}

export default Attachments
