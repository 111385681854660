import type { ReactNode } from 'react'

import * as style from './Column.style'


// NOTE: It must be exported due to TS4023 in index.tsx.
export interface Props {
  children: ReactNode
}

const Column = ({ children }: Props): JSX.Element => (
  <div className={style.column}>
    {children}
  </div>
)

export default Column
