import { css } from 'linaria'
import { StylesConfig } from 'react-select'

import { rel, gridColumn, breakpoints } from '../../styles/dimensions'
import * as colors from '../../styles/colors'


export const multiselectWrapper = css`
  ${gridColumn('auto')}
`

export const multiselect = css`
  width: 100%;
  min-width: ${rel(300)}rem;
`

export const multiselectLabel = css`
  display: block;
  margin-bottom: ${rel(4)}rem;
  font-size: ${rel(14)}rem;
  line-height: 1.1;

  @media screen and (min-width: ${breakpoints.xl}) {
    font-size: ${rel(16)}rem;
  }
`

const optionsStyles: StylesConfig<any, any> = {
  option: (provided, state) => ({
    ...provided,
    color: colors.primaryColor[2],
    backgroundColor: state.isFocused ? colors.backgroundColor[2] : colors.white,
    fontSize: `${rel(16)}rem`,
    lineHeight: `${rel(26)}rem`,
    padding: `${rel(6)}rem ${rel(10)}rem`,
    whiteSpace: 'nowrap',
    overflowX: 'hidden',
    textOverflow: 'ellipsis',
  }),
}

const controlStyles: StylesConfig<any, any> = {
  control: (provided, state) => ({
    ...provided,
    'minHeight': `${rel(1)}rem`,
    'width': '100%',
    'margin': '0',
    'padding': '0',
    'backgroundColor': state.isFocused ? colors.backgroundColor[2] : colors.white,
    'borderWidth': `${rel(2)}rem`,
    'borderColor': colors.primaryColor[2],
    'borderRadius': `${rel(6)}rem`,
    'boxShadow': 'none',
    'opacity': state.isDisabled ? 0.5 : 1,
    'cursor': 'pointer',
    '&:hover': {
      backgroundColor: colors.backgroundColor[2],
    },
  }),
}

const dropdownIndicatorStyles: StylesConfig<any, any> = {
  dropdownIndicator: (provided) => ({
    ...provided,
    'padding': `${rel(7)}rem`,
    'color': colors.primaryColor[2],
    '&:hover': {
      color: colors.primaryColor[2],
    },
  }),
}

const clearIndicatorStyles: StylesConfig<any, any> = {
  clearIndicator: (provided) => ({
    ...provided,
    'padding': `${rel(7)}rem`,
    'color': colors.primaryColor[2],
    '&:hover': {
      color: colors.negativeColor[2],
    },
  }),
}

const valueContainerStyles: StylesConfig<any, any> = {
  valueContainer: (provided) => ({
    ...provided,
    padding: `${rel(2)}rem 0 ${rel(2)}rem ${rel(10)}rem`,
  }),
}

const placeholderStyles: StylesConfig<any, any> = {
  placeholder: (provided) => ({
    ...provided,
    margin: '0',
    color: colors.primaryColor[2],
  }),
}

const menuListStyles: StylesConfig<any, any> = {
  menuList: (provided) => ({
    ...provided,
    margin: '0',
    padding: '0',
    maxHeight: `${rel(190)}rem`,
    borderRadius: `${rel(6)}rem`,
  }),
}

const menuStyles: StylesConfig<any, any> = {
  menu: (provided) => ({
    ...provided,
    padding: '0',
    borderWidth: `${rel(2)}rem`,
    borderStyle: 'solid',
    borderColor: colors.primaryColor[2],
    borderRadius: `${rel(6)}rem`,
    boxShadow: `0 ${rel(5)}rem ${rel(5)}rem ${colors.boxShadow}`,
  }),
}

const inputStyles: StylesConfig<any, any> = {
  input: (provided) => ({
    ...provided,
    margin: `${rel(1)}rem`,
  }),
}

const multiValueStyles: StylesConfig<any, any> = {
  multiValue: (provided) => ({
    ...provided,
    backgroundColor: colors.white,
    boxShadow: `0 0 0 ${rel(1)}rem ${colors.secondaryColor[2]}`,
    borderRadius: `${rel(4)}rem`,
  }),
}

const multiValueRemoveStyles: StylesConfig<any, any> = {
  multiValueRemove: (provided) => ({
    ...provided,
    '&:hover': {
      color: colors.negativeColor[2],
      backgroundColor: 'transparent',
    },
  }),
}

export const reactSelectStyles: StylesConfig<any, any> = {
  ...optionsStyles,
  ...controlStyles,
  ...dropdownIndicatorStyles,
  ...clearIndicatorStyles,
  ...valueContainerStyles,
  ...placeholderStyles,
  ...menuListStyles,
  ...menuStyles,
  ...inputStyles,
  ...multiValueStyles,
  ...multiValueRemoveStyles,
}
