import { ParamMap } from 'urlcat'
import type { Pagination } from '@cvut/profit-api-types/lib/common'


// TODO: Make `addPaginationParams` use this type for its `limit` and `offset`
// parameters.
export type PaginationParams = Pick<Pagination, 'limit' | 'offset'>

/**
 * Adds request parameters for paginated requests.
 */
export function addPaginationParams (
  params: ParamMap,
  limit: Pagination['limit'],
  offset: Pagination['offset'] = 0
): ParamMap {
  return {
    ...params,
    limit,
    offset,
  }
}
