import {
  Language, Notification, NotificationList, NotificationMeta, NotificationsStatus, NotificationSubtype,
} from '@cvut/profit-api-types/lib/theses'

import { apiPrefix } from '../config'
import { createRequestor } from './makeRequest'
import { useRequest, UseRequestStatus, UseRequestConfig } from './useRequest'
import { useLocale } from '../locale'


const notificationsUrl = `${apiPrefix}/me/notifications`
const notificationsRequestor = createRequestor(notificationsUrl)

function useNotificationsRequest<T> (config: UseRequestConfig) {
  return useRequest<T>(notificationsRequestor, config)
}

export function useNotificationStatus (): [
  UseRequestStatus<NotificationsStatus>,
  () => Promise<void>,
] {
  const { l } = useLocale()
  const [requestStatus, sendRequest] = useNotificationsRequest<NotificationsStatus>({
    defaultErrorMessage: () => l.notification.errorMessages.getStatus,
  })

  return [
    requestStatus,
    async () => await sendRequest('GET', '/status'),
  ]
}

export function useNotifications (): [
  UseRequestStatus<NotificationList>,
  (
    lang: Language,
    type: Notification['type'],
    subtype?: NotificationSubtype,
    limit?: number,
    offset?: number
  ) => Promise<void>,
] {
  const { l } = useLocale()
  const notificationInLocale = l.notification.notification.toLocaleLowerCase()
  const [requestStatus, sendRequest] = useNotificationsRequest<NotificationList>({
    defaultErrorMessage: () => l.errorMessages.api.defaultGetList(notificationInLocale),
  })

  return [
    requestStatus,
    async (lang, type, subtype, limit, offset) => await sendRequest('GET', '/', {
      lang,
      type,
      subtype,
      limit,
      offset,
      sort: '-createdAt',
    }),
  ]
}

export function useMarkNotificationsSeen (): [
  UseRequestStatus<void>,
  (ids: Array<Notification['id']>) => Promise<void>,
] {
  const { l } = useLocale()
  const notificationInLocale = l.notification.notification.toLocaleLowerCase()
  const [requestStatus, sendRequest] = useNotificationsRequest<void>({
    defaultErrorMessage: () => l.errorMessages.api.defaultPatchList(notificationInLocale),
  })

  return [
    requestStatus,
    async (ids) => await sendRequest('PUT', '/seen', { ids }),
  ]
}

export function useMarkNotificationVisited (): [
  UseRequestStatus<void>,
  (notificationId: Notification['id']) => Promise<void>,
] {
  const { l } = useLocale()
  const notificationInLocale = l.notification.notification.toLocaleLowerCase()
  const [requestStatus, sendRequest] = useNotificationsRequest<void>({
    defaultErrorMessage: err =>
      l.errorMessages.api.defaultPatch(notificationInLocale, err.url.params?.notificationId ?? ''),
  })

  return [
    requestStatus,
    async (notificationId) => await sendRequest('PUT', '/:notificationId/visited', { notificationId }),
  ]
}

export function useNotificationMeta (): [
  UseRequestStatus<NotificationMeta>,
  () => Promise<void>,
] {
  const { l } = useLocale()
  const [requestStatus, sendRequest] = useNotificationsRequest<NotificationMeta>({
    defaultErrorMessage: () => l.notification.errorMessages.getMetadata,
  })

  return [
    requestStatus,
    async () => await sendRequest('GET', '/meta'),
  ]
}
