import { useState, useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import { ActionMessage } from '@cvut/profit-api-types/lib/theses'

import ActionButton from './ActionButton'
import * as actionButtonStyle from './ActionButton.style'
import locale, { useLocale } from '../../locale'
import pagePaths from '../../pages/paths'
import { ReactComponent as NotificationIcon } from '../../images/icons/Task.svg'
import ResponseSection from './ResponseSection'
import * as style from './ActionPanel.style'
import { ThesisPanelAction } from '../../api/theses'


export type LocalePanelId = keyof typeof locale.default.actionPanel.panelId

export type ButtonProperties = {
  actionType: 'accept' | 'action' | 'reject',
  onClick: () => unknown,
  hasResponseSection: boolean,
}

/**
 * panelId, title, description is automatically generated from 'state' and 'role'
 */
export interface Props {
  panelId: LocalePanelId
  actions: ThesisPanelAction[]
  thesisId: number
  onRequestReady: (action: ThesisPanelAction, bodyData?: ActionMessage) => void
}

const ActionPanel = ({ panelId, actions, thesisId, onRequestReady }: Props): JSX.Element => {
  const [activeResponseSection, setActiveResponseSection] = useState<ThesisPanelAction | null>(null)
  const { l } = useLocale()
  const history = useHistory()

  function handleToggleResponseSection (action: ThesisPanelAction) {
    setActiveResponseSection(activeResponseSection === null ? action : null)
  }

  function goTo (path: string) {
    history.push(path)
  }

  const buttonPropertiesByAction: Record<ThesisPanelAction, ButtonProperties> = useMemo(() => ({
    OFFICER_APPROVE: {
      actionType: 'accept',
      onClick: () => onRequestReady('OFFICER_APPROVE'),
      hasResponseSection: false,
    },
    ASSIGNEE_ACCEPT: {
      actionType: 'accept',
      onClick: () => onRequestReady('ASSIGNEE_ACCEPT'),
      hasResponseSection: false,
    },
    ASSIGNEE_REJECT: {
      actionType: 'reject',
      onClick: () => handleToggleResponseSection('ASSIGNEE_REJECT'),
      hasResponseSection: true,
    },
    REVISE: {
      actionType: 'reject',
      onClick: () => handleToggleResponseSection('REVISE'),
      hasResponseSection: true,
    },
    WITHDRAW: {
      actionType: 'reject',
      onClick: () => onRequestReady('WITHDRAW'),
      hasResponseSection: false,
    },
    PROPOSE: {
      actionType: 'action',
      onClick: () => goTo(pagePaths.theses.edit(thesisId)),
      hasResponseSection: false,
    },
    ASSIGN_REVIEWER: {
      actionType: 'action',
      onClick: () => goTo(pagePaths.theses.reviewerProposals(thesisId)),
      hasResponseSection: false,
    },
    SUBMIT: {
      actionType: 'action',
      onClick: () => goTo(pagePaths.theses.submit(thesisId)),
      hasResponseSection: false,
    },
    APPROVE_PRINTS: {
      actionType: 'action',
      onClick: () => onRequestReady('APPROVE_PRINTS'),
      hasResponseSection: false,
    },
    SUBMIT_SUPERVISOR_REPORT: {
      actionType: 'action',
      onClick: () => goTo(pagePaths.theses.reportCreate(thesisId, 'supervisor')),
      hasResponseSection: false,
    },
    SUBMIT_REVIEWER_REPORT: {
      actionType: 'action',
      onClick: () => goTo(pagePaths.theses.reportCreate(thesisId, 'reviewer')),
      hasResponseSection: false,
    },
    SUPERSEDE: {
      actionType: 'action',
      onClick: () => onRequestReady('SUPERSEDE'),
      hasResponseSection: false,
    },
  }), [thesisId, activeResponseSection])

  const controls = (
    <div className={actionButtonStyle.controls}>
      {actions.map(action =>
        <ActionButton
          key={action}
          action={action}
          title={l.actionPanel.action[action]}
          activeResponseSection={activeResponseSection}
          responseSectionIsOpen={action === activeResponseSection}
          {...buttonPropertiesByAction[action]}
        />
      )}
    </div>
  )

  return (
    <div className={style.actionPanel}>
      <div className={style.header}>
        <div className={style.title}>
          <NotificationIcon />
          <h2>{l.actionPanel.panelId[panelId].title}</h2>
        </div>
        {controls}
        <div className={style.description}>
          {l.actionPanel.panelId[panelId].description}
        </div>
      </div>
      {activeResponseSection && <ResponseSection action={activeResponseSection} onRequestReady={onRequestReady} />}
    </div>
  )
}

export default ActionPanel
