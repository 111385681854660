// Unless specified otherwise, in each color object the higher the number the darker the color.

export const primaryColor = {
  1: '#03143c',
  2: '#002d56',
  3: '#0f426e',
} as const

export const secondaryColor = {
  2: '#98c3d9',
  3: '#b0d4e6',
} as const

export const backgroundColor = {
  1: '#dae0e3',
  2: '#f2f7fa',
  3: '#fff',
} as const

export const negativeColor = {
  1: '#b61827',
  2: '#ef5350',
  3: '#fc867c',
} as const

export const positiveColor = {
  1: '#00600f',
  2: '#00a851',
  3: '#00c863',
} as const

export const actionColor = {
  1: '#c6691b',
  2: '#ff9800',
  3: '#fac446',
} as const

export const neutralColor = {
  1: '#1730bc',
  2: '#304ffe',
  3: '#647bff',
} as const

export const white = '#fff'

export const boxShadow = '#082d5617'

export const draftColor = '#ff9800'
export const proposedColor = '#ff62a7'
export const assignedColor = '#0af'
export const reviewColor = '#a0f'
export const readyForDefenceColor = '#00a851'
export const evaluatedColor = '#2f4ffa'
export const archivedColor = '#707070'
export const deletedColor = '#000'
