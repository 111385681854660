import type { ReactNode, Ref } from 'react'
import { cx } from 'linaria'

import * as style from './Card.style'
import ColumnContainer from './ColumnContainer'
import Column from './Column'
import Content from './Content'
import Header from './Header'


interface Props {
  children: ReactNode
  extraClassNames?: string[]
  cardRef?: Ref<HTMLDivElement>
}

const Card = ({ children, extraClassNames, cardRef }: Props): JSX.Element => (
  <div className={cx(style.card, ...(extraClassNames ?? []))} ref={cardRef}>
    {children}
  </div>
)

Card.ColumnContainer = ColumnContainer
Card.Column = Column
Card.Content = Content
Card.Header = Header

export default Card
