import { css } from 'linaria'

import * as colors from '../styles/colors'
import { rel } from '../styles/dimensions'
import { fontWeights } from '../styles/typography'


export const label = css`
  display: inline-block;
  padding: 0 ${rel(12)}rem;
  font-weight: ${fontWeights.bold};
  font-size: ${rel(14)}rem;
  line-height: ${rel(23)}rem;
  letter-spacing: ${rel(0.2)}rem;
  white-space: nowrap;
  text-align: center;
  border-style: solid;
  border-width: ${rel(2)}rem;
  border-radius: ${rel(16)}rem;
`

export const draft = css`
  color: ${colors.draftColor};
  border-color: ${colors.draftColor};
`

export const proposed = css`
  color: ${colors.proposedColor};
  border-color: ${colors.proposedColor};
`

export const assigned = css`
  color: ${colors.assignedColor};
  border-color: ${colors.assignedColor};
`

export const review = css`
  color: ${colors.reviewColor};
  border-color: ${colors.reviewColor};
`

export const readyForDefence = css`
  color: ${colors.readyForDefenceColor};
  border-color: ${colors.readyForDefenceColor};
`

export const evaluated = css`
  color: ${colors.evaluatedColor};
  border-color: ${colors.evaluatedColor};
`

export const archived = css`
  color: ${colors.archivedColor};
  border-color: ${colors.archivedColor};
`

export const deleted = css`
  color: ${colors.deletedColor};
  border-color: ${colors.deletedColor};
`
