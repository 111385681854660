import { css } from 'linaria'

import { breakpoints, rel } from './dimensions'


export const transitionEasing = '0.25s ease-in-out'

export const containerStyle = {
  width: '100%',
  maxWidth: '100%',
  marginRight: 'auto',
  marginLeft: 'auto',
  padding: '0 calc(1rem + env(safe-area-inset-right)) 0 calc(1rem + env(safe-area-inset-left))',
}

const anchorTargetHeight = 4

export const anchorTargetStyle = {
  '&::before': {
    display: 'block',
    height: `${anchorTargetHeight}em`,
    content: '',
    marginTop: `-${anchorTargetHeight}em`,
  },
}

export const anchorTarget = css`
  &::before {
    display: block;
    height: ${anchorTargetHeight}em;
    margin-top: -${anchorTargetHeight}em;
    content: '';
  }
`

export const container = css`
  ${containerStyle};

  @media screen and (min-width: ${breakpoints.xl}) {
    padding: 0 calc(${rel(32)}rem + env(safe-area-inset-right)) 0 calc(${rel(32)}rem + env(safe-area-inset-left));
  }
`
