import type { ComponentProps } from 'react'
import { Except } from '@cvut/profit-utils'

import { useAccessControl, ACLoaded as AccessControlLoaded } from '.'
import LoadingUserInfoMessage from '../access-control/LoadingUserInfoMessage'


interface Props {
  children: (ac: Except<AccessControlLoaded, 'state'>) => JSX.Element | null
  noUserRender?: () => JSX.Element | null
  loaderColor: ComponentProps<typeof LoadingUserInfoMessage>['loaderColor']
}

const AccessControlledRender = ({ noUserRender = () => null, children, loaderColor }: Props): JSX.Element | null => {
  const accessControl = useAccessControl()

  switch (accessControl.state) {
    case 'not-initiated':
    case 'none':
      return noUserRender()
    case 'loading':
      return <LoadingUserInfoMessage loaderColor={loaderColor} />
    case 'loaded':
      return children(accessControl)
  }
}

export default AccessControlledRender
