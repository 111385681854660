import { css } from 'linaria'

import { breakpoints, rel } from '../../styles/dimensions'
import * as colors from '../../styles/colors'
import { fontWeights } from '../../styles/typography'


export const wrapper = css`
  flex: 1 1 auto;
  width: 100%;
  max-width: 100%;
  margin-top: ${rel(8)}rem;
  overflow-x: auto;
  background-color: ${colors.backgroundColor[3]};

  * + & {
    margin-top: ${rel(8)}rem;

    @media screen and (min-width: ${breakpoints.lg}) {
      margin-top: ${rel(16)}rem;
    }
  }
`

export const table = css`
  width: ${rel(976)}rem;
  border-spacing: 0;

  @media screen and (min-width: ${breakpoints.lg}) {
    width: 100%;
  }

  td:empty:not(:last-child) {
    text-align: center;

    &::before {
      content: '\\2014';
    }
  }
`

export const specializationCell = css`
  width: ${rel(109)}rem;
  text-align: center;
`

export const titleCell = css`
  width: ${rel(280)}rem;
  font-weight: ${fontWeights.bold};
  font-size: ${rel(14)}rem;
  line-height: ${rel(20)}rem;

  @media screen and (min-width: ${breakpoints.xl}) {
    font-size: ${rel(15)}rem;
  }
`

export const mainStateCell = css`
  width: ${rel(208)}rem;
  text-align: center;
`

export const statesCell = css`
  text-align: center;

  @media screen and (min-width: ${breakpoints.xxl}) {
    width: ${rel(300)}rem;
  }
`

export const typeCell = css`
  width: ${rel(60)}rem;
  text-align: center;
`

export const verifiedByFtOfficerCell = css`
  width: ${rel(78)}rem;
`

export const printApprovedCell = css`
  width: ${rel(78)}rem;
  text-align: center;
`

export const validUntilCell = css`
  width: ${rel(60)}rem;
  text-align: center;
`

export const actionsCell = css`
  width: ${rel(36)}rem;
  text-align: center;
`

export const assigneeFrozenNameCell = css`
  @media screen and (min-width: ${breakpoints.xxl}) {
    width: ${rel(260)}rem;
  }
`

export const supervisorFrozenNameCell = css`
  @media screen and (min-width: ${breakpoints.xxl}) {
    width: ${rel(260)}rem;
  }
`

export const reviewerFrozenNameCell = css`
  @media screen and (min-width: ${breakpoints.xxl}) {
    width: ${rel(260)}rem;
  }
`

export const centerAlignedTextCell = css`
  width: ${rel(106)}rem;
  text-align: center;
`

export const emptyBodyRow = css`
  padding-top: ${rel(48)}rem;
  padding-bottom: ${rel(48)}rem;
  color: ${colors.primaryColor[3]};
  font-size: ${rel(24)}rem;
  line-height: 1.5;
  text-align: center;
`

export const loadingOverlay = css`
  & tbody {
    opacity: 0.4;
  }
`

