import { useMemo } from 'react'
import { Thesis } from '@cvut/profit-api-types/lib/theses'

import { useAccessControl } from '.'


// FIXME - this logic should be replaced by adding _actions array to reviewerProposal objects
// and basing allowed actions on them
export function useThesisReviewerProposalActionsAccessControl (thesis: Thesis): {
  shouldDisplayReviewerSearchCard: boolean,
  shouldDisplayReviewerActionsCard: boolean,
  shouldDisplayOfficerButtons: boolean,
} {
  const accessControl = useAccessControl()

  const shouldDisplayReviewerSearchCard = useMemo<boolean>(() => {
    if (thesis.states.includes('changeable.assigned.reviewer.assigned')) {
      return false
    }

    if (accessControl.state !== 'loaded') {
      return false
    }

    return accessControl.thesisRoles.isThesisSupervisor(thesis)
        || accessControl.thesisRoles.isThesisSpecOfficer(thesis)
        || accessControl.globalRoles.isFtOfficer
  }, [accessControl, thesis.id])

  const shouldDisplayReviewerActionsCard = accessControl.state === 'loaded'
    && accessControl.globalRoles.isReviewer
    && !thesis.states.includes('changeable.assigned.reviewer.assigned')

  const shouldDisplayOfficerButtons = accessControl.state === 'loaded'
    && accessControl.thesisRoles.isThesisSpecOfficer(thesis)
    && !thesis.states.includes('changeable.assigned.reviewer.assigned')

  return {
    shouldDisplayReviewerSearchCard,
    shouldDisplayReviewerActionsCard,
    shouldDisplayOfficerButtons,
  }
}
