import { css } from 'linaria'

import { breakpoints, rel } from '../../styles/dimensions'
import { fontWeights } from '../../styles/typography'
import { secondaryColor, positiveColor, negativeColor, neutralColor, white } from '../../styles/colors'
import { transitionEasing } from '../../styles/utils'


export const requiredIndicator = css`
  &::before {
    margin-right: ${rel(4)}rem;
    color: ${negativeColor[2]};
    content: '*';
  }
`

export const input = css`
  min-width: ${rel(205)}rem;
  padding: ${rel(4)}rem ${rel(10)}rem;
  font-weight: ${fontWeights.light};
  font-size: ${rel(14)}rem;
  line-height: ${rel(26)}rem;
  background-color: ${white};
  border: ${rel(2)}rem solid ${white};
  border-radius: ${rel(6)}rem;
  transition: border-color ${transitionEasing};

  @media screen and (min-width: ${breakpoints.xl}) {
    font-size: ${rel(16)}rem;
  }

  &::placeholder {
    color: ${secondaryColor[2]};
  }

  &:valid {
    border-color: ${positiveColor[2]};
  }

  &:invalid,
  &[aria-invalid=true] {
    border-color: ${negativeColor[2]};
  }

  &:focus {
    border-color: ${neutralColor[2]};
    outline: none;
  }
`
