import { css } from 'linaria'

import { gridColumn, rel } from '../../../styles/dimensions'
import { label, input } from '../TextInput.style'


export const studentMark = css`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  .${label} {
    ${gridColumn('100%')};
  }

  .${input} {
    ${gridColumn(`${rel(70)}rem`)};
  }
`

export const grade = css`
  ${gridColumn('auto')};
  padding: ${rel(4)}rem ${rel(6)}rem;
  font-size: ${rel(22)}rem;
  line-height: ${rel(30)}rem;
`
