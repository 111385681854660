import { useHistory } from 'react-router-dom'

import AccessControlledRender from '../../access-control/AccessControlledRender'
import { addNotification } from '../../features/toastNotifications'
import PageHeader from '../../components/PageHeader'
import OfficerThesesTable from '../../features/thesis/OfficerThesesTable'
import { useLocale } from '../../locale'
import pagePaths from '../paths'
import * as style from '../../features/thesis/ThesesTable.style'


interface OfficerThesesListPageProps {
  canCreateNewAssignment: boolean
}

const OfficerThesesListPage = ({ canCreateNewAssignment }: OfficerThesesListPageProps): JSX.Element => {
  const { l } = useLocale()

  return (
    <article className={style.thesesTableArticle}>
      <PageHeader title={l.thesesList.officerThesesTitle} />
      <OfficerThesesTable
        displayNewAssignmentButton={canCreateNewAssignment}
        displayExportCsvButton
      />
    </article>
  )
}

// This wrapper helps with access control
const ThesisCreatePageWrapper = (): JSX.Element => {
  const history = useHistory()
  const { l } = useLocale()

  return (
    <AccessControlledRender loaderColor='primary'>
      {(ac) => {
        if (!ac.globalRoles.isFtOfficer) {
          addNotification({
            type: 'NEGATIVE',
            message: l.accessControl.noPermissionDefault,
          })

          history.replace(pagePaths.home)
          return null
        }

        return (
          <OfficerThesesListPage canCreateNewAssignment={ac.globalRoles.isProponent} />
        )
      }}
    </AccessControlledRender>
  )
}

export default ThesisCreatePageWrapper
