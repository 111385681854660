import { without } from 'lodash'
import { ReportCriteriaEvaluation } from '@cvut/profit-api-types/lib/theses'
import {
  extractReportAuthorName,
  formatPersonFullName,
  scoreToGrade,
  ValidThesisReport,
} from '@cvut/profit-theses-common'
import { ThesisReportTexts, versions } from '@cvut/profit-theses-common/lib/texts/thesisReports'
import { formatLongDate, getPropertyWithSuffix } from '@cvut/profit-utils'

import Card from '../../components/Card'
import { useLocale } from '../../locale'
import { ReactComponent as DownloadIcon } from '../../images/icons/DownloadRound.svg'
import * as style from './ThesisReportDetail.style'
import * as thesisAssignmentStyle from './ThesisAssignmentView.style'


interface Props {
  report: ValidThesisReport
  downloadLink: string
}

const ThesisReportDetail = ({ report, downloadLink }: Props): JSX.Element => {
  const { l } = useLocale()
  const t = versions[report.templateVersion][report.type][report.language]

  return (
    <section>
      <Card.ColumnContainer>
        <Card.Column>
          <Card>
            <Card.Header title={t.headers.document}>
              <a href={downloadLink} download>
                <DownloadIcon className={thesisAssignmentStyle.controlIcon} title={l.thesis.reports.download} />
              </a>
            </Card.Header>
            <Card.Content>
              <Header {...report} t={t} />
              <Criteria {...report} t={t} />
              <Summary {...report} t={t} />
              {report.defenseQuestions && (
                <Questions {...report} t={t} />
              )}
            </Card.Content>
          </Card>
        </Card.Column>
        <Card.Column>
          <InstructionsCard t={t} />
        </Card.Column>
      </Card.ColumnContainer>
    </section>
  )
}

type ReportSectionProps = ValidThesisReport & { t: ThesisReportTexts }

const Header = ({ thesis, type, language, submittedAt, t }: ReportSectionProps): JSX.Element => {
  const submittedDate = submittedAt ? new Date(submittedAt) : undefined

  return (
    <section>
      <dl className={style.thesisInfo}>
        <dt>{t.headers.reportAuthor}</dt>
        <dd>{extractReportAuthorName({ thesis, type })}</dd>

        <dt>{t.headers.student}</dt>
        <dd>{formatPersonFullName(thesis.assigneeFrozenName)}</dd>

        <dt>{t.headers.thesisTitle}</dt>
        <dd>{getPropertyWithSuffix(thesis, 'title', language)}</dd>

        <dt>{t.headers.specialization}</dt>
        <dd>{getPropertyWithSuffix(thesis.specialization, 'name', language)}</dd>

        <dt>{t.headers.createdOn}</dt>
        <dd>
          {submittedDate ? (
            <time dateTime={submittedDate.toISOString()}>{formatLongDate(submittedDate, language)}</time>
          ) : '–'}
        </dd>
      </dl>
    </section>
  )
}

const Criteria = ({ criteriaEvaluation, t }: ReportSectionProps): JSX.Element => {
  const criteria = t.sectionsOrder
    .filter(key => key in criteriaEvaluation)
    .map(key => ({ key, ...t.sections[key], ...criteriaEvaluation[key] }))

  return (
    <section>
      <h3>{t.headers.evaluationCriteria}</h3>
      {criteria.map((data, idx) => (
        // eslint-disable-next-line react/jsx-key
        <Criterion {...data} title={`${idx + 1}. ${data.title}`} />
      ))}
    </section>
  )
}

type CriterionProps =
  & ThesisReportTexts['sections'][string]
  & ReportCriteriaEvaluation[string]

const Criterion = ({ title, choices, value, comment, type }: CriterionProps): JSX.Element => {
  const criteria: Record<typeof type, JSX.Element | null> = {
    plain: null,
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    choice: <Choices choices={choices!} checked={value} />,
    score: <Score score={value} />,
  }

  return (
    <section>
      <div className={choices ? undefined : style.headingRow}>
        <h4>{title}</h4>
        {criteria[type]}
      </div>
      <p className={style.text}>{comment}</p>
    </section>
  )
}

interface ChoicesProps {
  choices: Record<number, string>
  checked: number
}

const Choices = ({ choices, checked }: ChoicesProps): JSX.Element => (
  <ol className={style.choices}>
    {Object.entries(choices).map(([value, title], idx) => (
      <li key={idx} className={value === String(checked) ? style.checked : undefined}>
        {title}
      </li>
    ))}
  </ol>
)

interface ScoreProps {
  score: number
}

const Score = ({ score }: ScoreProps): JSX.Element => (
  <div className={style.score}>
    <span>{score}</span>
    <span className={style.scoreMax}> /100</span>
    <span> ({scoreToGrade(score)})</span>
  </div>
)

const Summary = ({ score, summary, t }: ReportSectionProps): JSX.Element => (
  <section>
    <div className={style.headingRow}>
      <h3>{t.sections.summary.title}</h3>
      <Score score={score ?? -1} />
    </div>
    <p className={style.text}>{summary}</p>
  </section>
)

const Questions = ({ defenseQuestions, t }: ReportSectionProps): JSX.Element => (
  <section>
    <h3>{t.sections.defenseQuestions?.title}</h3>
    <p className={style.text}>{defenseQuestions}</p>
  </section>
)

type InstructionsCardProps = {
  t: ThesisReportTexts,
}

const InstructionsCard = ({ t }: InstructionsCardProps): JSX.Element => {
  const sectionKeys = without(t.sectionsOrder, 'defenseQuestions')

  return (
    <Card>
      <Card.Header title={t.headers.instructions} />
      <Card.Content extraClassNames={[style.instructions]}>
        {sectionKeys.map(key => (
          <section key={key}>
            <h3>{t.sections[key].title}</h3>
            <p
              className={style.text}
              dangerouslySetInnerHTML={{ __html: t.sections[key].description }}
            />
          </section>
        ))}
      </Card.Content>
    </Card>
  )
}

export default ThesisReportDetail
