import { useState } from 'react'
import { Enum } from 'typescript-string-enums'

import cs from './cs'
import en from './en'
import defaultLocale from './default'
import { createContainer } from '../state-management'

export type Locale = typeof defaultLocale

export const AvailableLocale = Enum('en', 'cs')
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type AvailableLocale = Enum<typeof AvailableLocale>

const locales: Record<AvailableLocale | 'default', Locale> = {
  cs,
  en,
  default: defaultLocale,
}

function useCurrentLang (initialLang?: AvailableLocale) {
  return useState<AvailableLocale>(initialLang ?? 'cs')
}

export const CurrentLanguageContainer = createContainer(useCurrentLang, 'CurrentLanguageContainer')

type LocaleState = {
  currentLang: AvailableLocale,
  l: Locale,
  setCurrentLang: (lang: AvailableLocale) => void,
}

export function useLocale (): LocaleState {
  const [currentLang, setCurrentLang] = CurrentLanguageContainer.useContainer()

  return {
    currentLang,
    l: locales[currentLang],
    setCurrentLang,
  }
}

export default locales
