import { css } from 'linaria'

import { rel } from '../../styles/dimensions'

export { controlIcon, description } from '../thesis/ThesisAssignmentView.style'

export const keywordsTitle = css`
  margin-top: ${rel(16)}rem;
`

export const metadataPairs = css`
  margin: 0;

  > div {
    display: flex;

    > dt {
      min-width: ${rel(176)}rem;
    }
  }
`

export const thesesList = css`
  li {
    line-height: 2;
  }
`
