import type { ValueOf } from '../internal/utils'
import type { Person, PersonRef } from './person'
import type { ThesisRef, Thesis, ThesisAssignment } from './thesis'
import { ProposalState } from './common'
import type { Page } from '../common'

/**
 * Type of a Thesis Change Request
 */
export const ChangeRequestType = {
  /** Request for a change in a Thesis Assignment. */
  Revision: 'revision',

  /** Request for cancellation of an assigned Thesis. */
  Cancellation: 'cancellation',

  /** Any unusual request of an assigned Thesis. */
  Other: 'other',
} as const
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type ChangeRequestType = ValueOf<typeof ChangeRequestType>

/**
 * Thesis Change Request
 */
export interface ChangeRequest {

  /** Unique ID. */
  readonly id: number

  /** Type of the request. */
  readonly type: ChangeRequestType

  /** Comment on the change. */
  message: string

  /**
   * Proposed changes for the Thesis Assignment described by JSON Merge Patch, i.e. only the
   * changed fields are present and `null` means removal of the value.
   * This is valid only for type 'revision'.
   */
  patch?: Partial<Omit<ThesisAssignment, 'assignee' | 'specialization'>>

  /** The current state of the request. */
  state: ProposalState

  /** The Thesis for which this request was created. */
  readonly thesis: ThesisRef | Thesis

  /** The person who created this request. */
  readonly proponent: PersonRef | Person

  /** The last date and time at which this object was modified. This is being updated automatically. */
  readonly modifiedAt: Date
}

/**
 * Thesis Change Request to be created.
 */
export type ChangeRequestNew =
  | Pick<ChangeRequest, 'type' | 'message'>
  | Pick<ChangeRequest, 'message' | 'patch'> & { type: (typeof ChangeRequestType)['Revision'] }

/**
 * [Merge Patch](https://tools.ietf.org/html/rfc7386) for a Thesis Change Request.
 * This is used for updating an existing Change Request using the PATCH method; merge patch
 * contains only the properties that should be modified.
 */
export type ChangeRequestPatch = Partial<Pick<ChangeRequest, 'message' | 'patch'>>

/**
 * Collection of ChangeRequest objects.
 */
export type ChangeRequestList = Page<ChangeRequest>
