import { MutableRefObject, ReactNode, useEffect, useRef, useState } from 'react'
import FocusTrap from 'focus-trap-react'
import { isEmpty } from 'lodash'
import { Link } from 'react-router-dom'
import {
  Language,
  Notification as INotification, NotificationMeta, NotificationSubtype,
  TaskNotificationSubtype, NoticeNotificationSubtype,
} from '@cvut/profit-api-types/lib/theses'
import notificationSubjects from '@cvut/profit-theses-common/lib/texts/notifications'

import { ChangeEvent } from 'react'
import locale, { useLocale } from '../../locale'
import Notification from './Notification'
import pagePaths from '../../pages/paths'
import { useMarkNotificationsSeen, useNotifications, useNotificationMeta } from '../../api/notifications'
import { useOnClickOutside, useOnKeyPress } from '../../hooks'
import { ghost } from '../../components/Button.style'
import * as style from './NotificationsPanel.style'
import { ReactComponent as InfoIcon } from '../../images/icons/InfoBellRound.svg'
import { ReactComponent as SettingsIcon } from '../../images/icons/Settings.svg'
import { ReactComponent as TaskIcon } from '../../images/icons/TaskRound.svg'


type SubtypeKey = TaskNotificationSubtype & NoticeNotificationSubtype

interface NotificationFilterOptionsProps {
  lang: Language
  notificationCounts: NotificationMeta['notice'] | NotificationMeta['task']
}

const NotificationFilterOptions = ({ lang, notificationCounts }: NotificationFilterOptionsProps) => (
  <>
    <option value=''>{locale[lang].form.all}</option>
    {Object.entries(notificationCounts).map(([subtype, count]) => (
      <option key={subtype} value={subtype}>
        {notificationSubjects.subject[lang][subtype as SubtypeKey]}&nbsp;({count})
      </option>
    ))}
  </>
)

interface TaskNotificationFilterProps {
  children: ReactNode
  onChange: (e: ChangeEvent<HTMLSelectElement>) => void
}

const NotificationFilter = ({ children, onChange }: TaskNotificationFilterProps) => (
  <select onChange={onChange} className={style.notificationFilter}>
    {children}
  </select>
)

interface Props {
  type: INotification['type']
  htmlId: string
  triggerBtnRef: MutableRefObject<HTMLButtonElement | null>
  onMarkedSeen: () => void
  onClose: () => void
}

const PAGE_SIZE = 10

const NotificationsPanel = ({ type, htmlId, triggerBtnRef, onMarkedSeen, onClose }: Props): JSX.Element => {
  const [notifications, setNotifications] = useState<INotification[]>([])
  const nullMeta = { task: {}, notice: {} }
  const [notificationCounts, setNotificationCounts] = useState<NotificationMeta>(nullMeta)
  const [page, setPage] = useState(0)
  const [getStatus, getNotifications] = useNotifications()
  const [getMetaStatus, getMeta] = useNotificationMeta()
  const [, markNotificationsSeen] = useMarkNotificationsSeen()
  const [focusTrapActive, setFocusTrapActive] = useState(false)
  const nodeRef = useRef<HTMLDivElement | null>(null)
  const { currentLang, l } = useLocale()
  const [subtype, setSubtype] = useState<NotificationSubtype | undefined>(undefined)

  const handleFilterOnChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setPage(0)
    setNotifications([])
    setSubtype(e.currentTarget.value as NotificationSubtype)
  }

  const fetchNotifications = () => {
    void getNotifications(currentLang, type, subtype, PAGE_SIZE, page * PAGE_SIZE)
    void getMeta()
  }

  useEffect(() => {
    fetchNotifications()
  }, [currentLang, page, subtype])

  useEffect(() => {
    /* eslint-disable @typescript-eslint/no-non-null-assertion */
    if (getStatus.state === 'success') {
      setNotifications([...notifications, ...getStatus.data!.data])

      const unseen = getStatus.data!.data.filter(n => n.state === 'unseen')
      if (unseen.length > 0) {
        // TODO: handle request error
        void markNotificationsSeen(unseen.map(n => n.id))
          .then(() => onMarkedSeen())
      }
    }
    /* eslint-enable @typescript-eslint/no-non-null-assertion */
  }, [getStatus.state])

  useEffect(() => {
    if (getMetaStatus.state === 'success') {
      setNotificationCounts(getMetaStatus.data ?? nullMeta)
    }
  }, [getMetaStatus])

  const activateFocusTrap = () => {
    if (!focusTrapActive && notifications.length > 0) {
      setFocusTrapActive(true)
    }
  }

  useOnClickOutside(nodeRef, onClose, [triggerBtnRef])
  useOnKeyPress('Escape', onClose)
  useOnKeyPress('Tab', activateFocusTrap, false)

  const headerId = `${type}-notifications-panel-header`

  let icon = null
  let title = null
  let loadingMsg = null
  let errorMsg = null
  let noResultsMsg = null
  switch (type) {
    case 'notice':
      title = l.notification.notices.title
      loadingMsg = l.notification.notices.loading
      errorMsg = l.notification.notices.errorGetAll
      noResultsMsg = l.notification.notices.noResults
      icon = <InfoIcon title={title} className={style.infoIcon} />
      break
    case 'task':
      title = l.notification.tasks.title
      loadingMsg = l.notification.tasks.loading
      errorMsg = l.notification.tasks.errorGetAll
      noResultsMsg = l.notification.tasks.noResults
      icon = <TaskIcon title={title} className={style.taskIcon} />
      break
  }

  const settingsButtonNode = (
    <div className={style.settingsButton}>
      <Link
        to={pagePaths.userSettings.notifications}
        title={l.userSettings.notificationSettings.goTo}
        onClick={onClose}
      >
        <SettingsIcon />
      </Link>
    </div>
  )

  return (
    <FocusTrap active={focusTrapActive} focusTrapOptions={{ clickOutsideDeactivates: true }}>
      <div
        id={htmlId}
        ref={nodeRef}
        className={style.panel}
        role='region'
        aria-labelledby={headerId}
      >
        <div className={style.header}>
          {icon}
          <span id={headerId} className={style.title}>{title}</span>
          {!isEmpty(notificationCounts[type]) && (
            <NotificationFilter onChange={handleFilterOnChange}>
              <NotificationFilterOptions lang={currentLang} notificationCounts={notificationCounts[type]} />
            </NotificationFilter>
          )}
          {settingsButtonNode}
        </div>
        <div className={style.content}>
          {getStatus.state === 'loading' && (
            <div className={style.messageWrapper}>{loadingMsg}</div>
          )}
          {getStatus.state === 'error' && (
            <div className={style.messageWrapper}>{errorMsg}</div>
          )}
          {getStatus.state === 'success' && notifications.length === 0 && (
            <div className={style.messageWrapper}>{noResultsMsg}</div>
          )}
          {notifications.length > 0 && (
            <ul className={style.list}>
              {notifications.map(n => (
                <li key={n.id} className={style.item}>
                  <Notification notification={n} onRedirect={onClose} />
                </li>
              ))}
            </ul>
          )}
          {/* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */}
          {getStatus.state === 'success' && getStatus.data!.pagination.hasNextPage && (
            <div className={style.paginationWrapper}>
              <button className={ghost} onClick={() => setPage(page + 1)}>
                {l.pagination.loadMore}
              </button>
            </div>
          )}
        </div>
      </div>
    </FocusTrap>
  )
}

export default NotificationsPanel
