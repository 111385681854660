import { ReactNode, MouseEvent, useState, useEffect, useRef } from 'react'
import { Enum } from 'typescript-string-enums'
import { cx } from 'linaria'

import useOnClickOutside from '../../hooks/useOnClickOutside'
import useOnKeyPress from '../../hooks/useOnKeyPress'
import { useLocale, AvailableLocale } from '../../locale'
import * as style from './LangSwitcher.style'
import { ReactComponent as LangENIcon } from '../../images/icons/LangEN.svg'
import { ReactComponent as LangCSIcon } from '../../images/icons/LangCS.svg'


const switchToLangTitle: Record<AvailableLocale, string> = {
  cs: 'Přepnout na češtinu',
  en: 'Switch to English',
}

const langIcons: Record<AvailableLocale, ReactNode> = {
  cs: <LangCSIcon />,
  en: <LangENIcon />,
}

const LangSwitcher = (): JSX.Element => {
  const { currentLang, l, setCurrentLang } = useLocale()
  const [isOpen, setIsOpen] = useState(false)
  const nodeRef = useRef<HTMLDivElement | null>(null)

  const handleCloseDropdown = () => {
    setIsOpen(false)
  }

  useOnClickOutside(nodeRef, handleCloseDropdown)
  useOnKeyPress('Escape', handleCloseDropdown)

  const handleDropdownToggle = (evt: MouseEvent<HTMLButtonElement>) => {
    evt.preventDefault()
    setIsOpen(prevIsOpen => !prevIsOpen)
  }


  useEffect(() => {
    const localStateLang = window.localStorage.getItem('lang')
    if (localStateLang) {
      setCurrentLang(localStateLang as AvailableLocale)
    }
  }, [])


  const langOptions = Enum.keys(AvailableLocale).map((lang) => (
    <a
      href='#'
      key={lang}
      onClick={e => {
        e.preventDefault()
        window.localStorage.setItem('lang', lang)
        setCurrentLang(lang)
        handleCloseDropdown()
      }}
      className={cx(style.langDropdownItem, currentLang === lang ? style.isActive : null)}
      title={switchToLangTitle[lang]}
    >
      {langIcons[lang]}
    </a>
  ))

  return (
    <div ref={nodeRef} className={style.langDropdown}>
      <button
        type='button'
        aria-haspopup
        aria-expanded={isOpen}
        onClick={handleDropdownToggle}
        title={l.langSwitcher.tooltip}
        className={style.curLang}
        id='languageSwitcherButton'
      >
        {langIcons[currentLang]}
      </button>
      <div className={cx(style.langDropdownMenu, isOpen ? style.show : null)} aria-labelledby='languageSwitcherButton'>
        {langOptions}
      </div>
    </div>
  )
}

export default LangSwitcher
