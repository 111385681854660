import { parse } from 'content-disposition-header'


export const attachmentFilenameFromHeaders = (headers: Headers): string => (
  // backend is expected to send file name in the content-disposition header
  parse(headers.get('content-disposition') ?? '-').parameters.filename ?? 'thesis.pdf'
)

export function offerFileDownload (filename: string, blob: Blob): void {
  const objectUrl = URL.createObjectURL(blob)

  const link = document.createElement('a')
  link.href = objectUrl
  link.download = filename
  link.click()

  URL.revokeObjectURL(objectUrl)
}
