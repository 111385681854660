import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { TopicNew } from '@cvut/profit-api-types/lib/theses'

import { addNotification } from '../../features/toastNotifications'
import { createTopic } from '../../api/topics'
import { errorCardRenderer } from '../../components/RequestErrorCard'
import LeavingPrompt from '../../components/form/LeavingPrompt'
import PageLoadSpinner from '../../components/PageLoadSpinner'
import { useAllSpecializations } from '../../api/specializations'
import RequestDependentRender from '../../api/RequestDependentRender'
import AccessControlledRender from '../../access-control/AccessControlledRender'
import TopicEdit from '../../features/topics/TopicEdit'
import { useLocale } from '../../locale'
import pagePaths from '../paths'


interface Props {
  currentUsername: string
}

const TopicCreatePage = ({ currentUsername }: Props): JSX.Element => {
  const history = useHistory()
  const [specReqStatus, getAllSpecializations] = useAllSpecializations()
  useEffect(() => {
    void getAllSpecializations()
  }, [])

  const { l } = useLocale()
  const [isFormModified, setIsFormModified] = useState(false)

  const handleModifiedState = (isModified: boolean) => setIsFormModified(isModified)

  const topicInLocale = l.topic.topic.toLocaleLowerCase()
  const specializationInLocale = l.specialization.specialization.toLocaleLowerCase()

  const handleSave = async (topic: TopicNew) => {
    try {
      const [, topicRef] = await createTopic(topic)

      addNotification({
        type: 'POSITIVE',
        message: l.successMessages.api.defaultCreate(topicInLocale),
      })

      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      history.replace(pagePaths.topics.view(topicRef?.id))
    } catch (err) {
      addNotification({
        type: 'NEGATIVE',
        message: l.errorMessages.api.defaultCreate(topicInLocale),
      })

      throw err
    }
  }

  const thesisCreatePath = pagePaths.theses.create

  return (
    <RequestDependentRender
      requestStatus={specReqStatus}
      renderOnLoading={() => <PageLoadSpinner message={l.specialization.loadingSpecializations} />}
      renderOnError={errorCardRenderer(l.errorMessages.api.defaultGetList(specializationInLocale), thesisCreatePath)}
      renderOnSuccess={(specializations) => (
        <>
          <TopicEdit
            mode='create'
            onSave={handleSave}
            specializations={specializations}
            formBackupKey={`${currentUsername}-create-topic`}
            onModifiedState={handleModifiedState}
          />
          <LeavingPrompt when={isFormModified} />
        </>
      )}
    />
  )
}

const TopicCreatePageWrapper = (): JSX.Element => {
  const history = useHistory()
  const { l } = useLocale()

  return (
    <AccessControlledRender loaderColor='primary'>
      {(ac) => {
        if (!ac.globalRoles.isProponent) {
          addNotification({
            type: 'NEGATIVE',
            message: l.accessControl.noPermission(l.topic.create),
          })

          history.replace(pagePaths.home)
          return null
        }

        return (
          <TopicCreatePage currentUsername={ac.person.username} />
        )
      }}
    </AccessControlledRender>
  )
}

export default TopicCreatePageWrapper
