const monthsCs = [
  'ledna', 'února', 'března', 'dubna', 'května', 'června',
  'července', 'srpna', 'září', 'října', 'listopadu', 'prosince',
]
const monthsEn = [
  'January', 'February', 'March', 'April', 'May', 'June',
  'July', 'August', 'September', 'October', 'November', 'December',
]

export function toDateISOString (value: Date | string = ''): string {
  try {
    return new Date(value).toISOString()
  } catch {
    return ''
  }
}

export function dateISOStringDatePart (d: Date): string {
  return toDateISOString(d).slice(0, 10)
}

/**
 * Returns the given `date` in a long format: `1. ledna 1970` if `lang` is
 * `cs`, `1 January 1970` otherwise.
 */
// NOTE: We don't use `Date#toLocaleDateString`, because it's not guaranteed
// to support Czech locale (e.g. Node.js on Alpine Linux doesn't).
export function formatLongDate (date: Date, lang: 'cs' | 'en'): string {
  const months = lang === 'cs' ? monthsCs : monthsEn
  const postDay = lang === 'cs' ? '.' : ''

  return `${date.getDate()}${postDay} ${months[date.getMonth()]} ${date.getFullYear()}`
}
