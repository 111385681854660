import * as style from './InfoMessage.style'


interface InfoMessageProps {
  text: string
}

const InfoMessage = ({ text }: InfoMessageProps): JSX.Element => (
  <p className={style.infoMessage}>
    {text}
  </p>
)

export default InfoMessage
