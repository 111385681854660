import { useState } from 'react'

import { createContainer } from '../../state-management'


export interface ModalParameters {
  title: string
  text?: string
  cancelButton?: ModalButtonOpts
  negativeButton?: ModalButtonOpts
  positiveButton?: ModalButtonOpts
  onClose?: () => void
}

interface ModalButtonOpts {
  caption?: string
  focus?: boolean  // the first present button will be focused by default
  onClick?: () => void
}

interface ContainerState {
  modal: ModalParameters | null
}

export const ModalsContainer = createContainer((initialState?: ContainerState) => (
  useState<ContainerState>(initialState ?? { modal: null })
), 'ModalsContainer')


interface UseModalsReturnType {
  modal: ModalParameters | null
  showModal: (modal: ModalParameters) => void
  hideModal: () => void
}

export function useModals (): UseModalsReturnType {
  const [modalContainer, setModalContainer] = ModalsContainer.useContainer()

  return {
    modal: modalContainer.modal,

    showModal: (modal) => {
      setModalContainer(prevState => {
        if (prevState.modal) {
          throw Error('Trying to show multiple modals, at most one modal at each time is allowed')
        }
        return {
          modal: { ...modal },
        }
      })
    },

    hideModal: () => {
      setModalContainer(() => ({
        modal: null,
      }))
    },
  }
}
