import { cx } from 'linaria'
import { Enum } from 'typescript-string-enums'

import { ReactComponent as ArrowActionIcon } from '../../images/icons/ArrowRight.svg'
import { ReactComponent as ArrowDownIcon } from '../../images/icons/ArrowDown.svg'
import { ReactComponent as ArrowUpIcon } from '../../images/icons/ArrowUp.svg'
import * as actionButtonStyle from './ActionButton.style'
import * as btnStyle from '../../components/Button.style'
import { ThesisPanelAction } from '../../api/theses'


const ButtonStyleType = Enum('accept', 'reject', 'action')
// eslint-disable-next-line @typescript-eslint/no-redeclare
type ButtonStyleType = Enum<typeof ButtonStyleType>

const btnStyles: Record<ButtonStyleType | string, string> = {
  ...btnStyle,
}

interface Props {
  action: ThesisPanelAction
  title: string
  responseSectionIsOpen?: boolean
  activeResponseSection: ThesisPanelAction | null
  actionType: 'accept' | 'action' | 'reject'
  onClick: () => unknown
  hasResponseSection: boolean
}

interface ActionButtonIconProps {
  actionType: string
  isOpen?: boolean
}

const ActionButtonIcon = ({ actionType, isOpen }: ActionButtonIconProps): JSX.Element | null => {
  if (actionType === 'action') {
    return <ArrowActionIcon />
  }
  if (actionType === 'reject' && isOpen !== undefined) {
    return isOpen ? <ArrowUpIcon /> : <ArrowDownIcon />
  }
  return null
}

const ActionButton = ({
  action, title, responseSectionIsOpen, activeResponseSection, actionType, onClick, hasResponseSection,
}: Props): JSX.Element => {

  return (
    <button
      className={cx(btnStyles[actionType], actionButtonStyle.btn)}
      onClick={onClick}
      aria-haspopup={hasResponseSection}
      aria-expanded={responseSectionIsOpen}
      id={`${action}_dropdown`}
      disabled={activeResponseSection !== null && !(action === activeResponseSection)}
    >
      {title}
      <ActionButtonIcon
        actionType={actionType}
        isOpen={hasResponseSection ? responseSectionIsOpen : undefined}
      />
    </button>
  )
}

export default ActionButton
