import { css } from 'linaria'

import { rel } from '../../styles/dimensions'


export const people = css`
  > * + * {
    margin-top: ${rel(16)}rem;
  }
`

export const title = css`
  margin-bottom: ${rel(8)}rem;
`
