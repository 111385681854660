import { css, cx } from 'linaria'

import { rel } from '../../styles/dimensions'
import { iconWrapper } from '../../components/Button.style'
import { cardPadding, cardInner } from './Card.style'


export const spaceFromTitle = `${rel(16)}rem`

// If the header lies inside a card, then the padding is already set by the card.
export const header = cx(css`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${spaceFromTitle};

  /* no bottom margin when there's no content (ThesisStateCard special case) */
  &:first-child:last-child {
    margin-bottom: 0;
  }

  .${cardInner} & {
    padding: 0;
  }

  > h2 {
    flex-grow: 1;
    margin: 0;
    line-height: 1.1;
  }
`, cardPadding)

export const backButton = cx(iconWrapper, css`
  margin-right: ${rel(16)}rem;
`)

export const controls = css`
  display: flex;
  align-items: center;
  margin-left: ${spaceFromTitle};

  > * + * {
    margin-left: ${rel(8)}rem;
  }
`
