
/**
 * Name of a person decomposed into four parts.
 */
export interface PersonName {
  /**
   * Pre-nominal title(s) of the person or an empty string if none.
   *
   * @default ""
   * @examples ["MSc", "prof. Ing.", ""]
   */
  titlesPre: string

  /**
   * First name(s) of the person.
   * @examples ["Meredith Rodney"]
   */
  firstName: string

  /**
   * Last name(s) of the person.
   * @examples ["McKay"]
   */
  lastName: string

  /**
   * Post-nominal title(s) of the person or an empty string if none.
   *
   * @default ""
   * @examples ["Ph.D.", "CSc.", ""]
   */
  titlesPost: string
}

const personNameKeys: Array<keyof PersonName> = ['titlesPre', 'firstName', 'lastName', 'titlesPost']

export function isPersonName (obj: any): obj is PersonName {
  return obj != null
    && typeof obj === 'object'
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    && personNameKeys.every(key => key in obj && typeof obj[key] === 'string')
}

export type Grade = 'A' | 'B' | 'C' | 'D' | 'E' | 'F'

export function isGrade (obj: any): obj is Grade {
  return typeof obj === 'string' && obj.length === 1 && obj >= 'A' && obj <= 'F'
}

/**
 * An envelope for paginated data collections.
 *
 * @internal don't expose in JSON Schema
 */
export interface Page<T> {
  /**
   * Array of results for a current page.
   */
  data: T[]

  /**
   * Pagination metadata.
   */
  pagination: Pagination
}

/**
 * Pagination metadata.
 */
export interface Pagination {
  /**
   * Offset of the first result to return.
   * @default 0
   */
  offset?: number

  /**
   * Maximum number of results to return.
   */
  limit?: number

  /**
   * True if there is more results.
   * @default false
   */
  hasNextPage?: boolean
}
