import type { Page } from '../common'
import type { ValueOf } from '../internal/utils'
import type { Person, PersonRef } from './person'
import { Specialization, SpecializationRef, StudyDegree } from './specialization'
import { ThesisLanguage } from './thesis'


export const TopicVisibility = {
  Deleted: 'deleted',
  Private: 'private',
  Public: 'public',
} as const
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type TopicVisibility = ValueOf<typeof TopicVisibility>

/**
 * Topic represents opportunities for students (and possibly others) to work on.
 * A topic can be extended to, e.g., a thesis or a semester work.
 */
export interface Topic {

  /** Unique ID. */
  readonly id: number

  /**
   * Title of a topic can be either in Czech or in English. We do not particularly
   * care.
   * @minLength 5
   */
  title: string

  /**
   * Description of the topic with instructions and requirements. It can be in any
   * language. It can even be simultaneously in multiple languages hence the maximum
   * length is set very high.
   * @default ""
   * @maxLength 6000
   */
  description: string

  /**
   * Topic description language.
   * @default "cs"
   */
  language: ThesisLanguage

  /**
   * List of terms, which aid the filtering.
   * @default []
   */
  keywords: string[]

  /**
   * Is the topic visible to a catalogue viewer?
   * @default "private"
   */
  visibility: TopicVisibility

  /**
   * For which “study degree” students (e.g., bachelor's students, master's students)
   * is the topic especially suitable?
   *
   * Note that study degrees are named `programmeType` in KOSapi.
   * @default []
   */
  studyDegrees: StudyDegree[]

  /**
   * For students of which specializations is this topic especially suitable?
   * @default []
   */
  specializations: Array<SpecializationRef | Specialization>

  /**
   * Person who created the topic.
   */
  author: PersonRef | Person

  /**
   * The date and time at which this object was created.
   */
  readonly createdAt: Date

  /**
   * The last date and time at which this object was modified. This is being updated automatically.
   */
  readonly modifiedAt: Date
}

/**
 * A Topic to be created.
 */
export type TopicNew =
  & Pick<Topic, 'title' | 'language'>
  & Partial<Pick<Topic, 'description' | 'keywords' | 'visibility' | 'studyDegrees' | 'specializations'>>


/**
 * [Merge Patch](https://tools.ietf.org/html/rfc7386) for a Topic.
 * This is used for updating an existing Topic using the PATCH method; merge patch contains only
 * the properties that should be modified.
 */
export type TopicPatch = Partial<Omit<Topic, 'id' | 'createdAt' | 'modifiedAt' >>


/**
 * Metadata of the Topic resource.
 */
export type TopicsMeta = {
  properties: {
    specializations: {
      /**
       * @uniqueItems true
       */
      values: ReadonlyArray<string | null>,
    },
    studyDegrees: {
      /**
       * @uniqueItems true
       */
      values: ReadonlyArray<string | null>,
    },
  },
}


/**
 * Collection of Topic objects.
 */
export type TopicList = Page<Topic>

/**
 * Reference to a Topic resource (used in request bodies).
 */
export type TopicRef = Pick<Topic, 'id'>

export function isTopic (topic: Topic | TopicRef): topic is Topic {
  return 'author' in topic
}

export interface TopicFilter {
  author?: string
  description?: string
  language?: ThesisLanguage
  keywords?: string[]
  visibility?: TopicVisibility[]
  q?: string
  specializations?: Specialization['code'][]
  studyDegrees?: StudyDegree[]
  title?: string
}
