import { useRef, useState } from 'react'

import Card from '../../components/Card'
import PersonCard from '../../components/PersonCard'
import PersonSearchBox from '../../components/form/PersonSearchBox'
import { Person } from '../../types/person'
import { useLocale } from '../../locale'
import { formatPersonFullName } from '../../utils/person'
import useOnClickOutside from '../../hooks/useOnClickOutside'
import useOnKeyPress from '../../hooks/useOnKeyPress'
import { ReactComponent as CloseIcon } from '../../images/icons/CloseRound.svg'
import { ReactComponent as EditIcon } from '../../images/icons/EditRound.svg'
import * as style from './ThesisAssigneeCard.style'
import { ThesisMainState } from '@cvut/profit-api-types/lib/theses'


interface Props {
  assignee: Person | null
  onChange: (assignee: Person | null) => void
  onSave: (assignee: Person | null) => void
  thesisState: ThesisMainState
}

const ThesisAssigneeCard = ({ assignee, onChange, onSave, thesisState }: Props): JSX.Element => {
  const [editing, setEditing] = useState(false)
  const { l } = useLocale()
  const cardRef = useRef<HTMLDivElement | null>(null)

  useOnClickOutside(cardRef, () => setEditing(false))
  useOnKeyPress('Escape', () => setEditing(false))

  const handlePersonSelect = (person: Person | null) => {
    // FIXME onChange and onSave do the same. onSave was added to autosave user
    // Pass onSave handler into onChange too
    onChange(person)
    onSave(person)
    setEditing(false)
  }

  return (
    <Card cardRef={cardRef}>
      <Card.Header title={l.thesis.assignee.student}>
        <button
          type='button'
          title={editing ? l.thesis.assignee.close : l.thesis.assignee.edit}
          onClick={() => setEditing(!editing)}
          className={style.controlButton}
        >
          {editing ? <CloseIcon className={style.controlIcon} /> : <EditIcon className={style.controlIcon} />}
        </button>
      </Card.Header>
      <Card.Content>
        {editing ? (
          <PersonSearchBox
            inputProps={{ id: 'assignee', placeholder: l.thesis.assignee.placeholder, autoFocus: true }}
            selectedPerson={assignee}
            onPersonSelect={handlePersonSelect}
            loadingMsg={l.thesis.assignee.searchingStudents}
            errorMsg={l.thesis.assignee.errorSearchingStudents}
            noResultsMsg={l.thesis.assignee.noStudentFound}
            selectNoneText={l.thesis.assignee.clearAssignee}
            selectedPersonText={l.thesis.assignee.assignedStudent}
            thesisState={thesisState}
          />
        ) : (
          assignee ? (
            <PersonCard
              name={formatPersonFullName(assignee)}
              contact={assignee.email}
              specialization={assignee.study?.specialization.code}
            />
          ) : (
            <span>{l.thesis.assignee.noStudentAssigned}</span>
          )
        )}
      </Card.Content>
    </Card>
  )
}

export default ThesisAssigneeCard
