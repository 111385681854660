import { FormEventHandler, useEffect, useState, ReactNode } from 'react'
import { useForm, FieldError, FormState, UseFormMethods } from 'react-hook-form'
import { definitions } from '@cvut/profit-api-types/schema/theses.json'
import { Enum } from '@cvut/profit-utils'
import { Thesis, ThesisAssignment, ThesisLanguage, ThesisNew } from '@cvut/profit-api-types/lib/theses'

import Hint from '../../components/form/Hint'
import ModificationIndicator from '../../components/form/ModificationIndicator'
import Select from '../../components/form/Select'
import Switch from '../../components/form/Switch'
import TextInput from '../../components/form/TextInput'
import * as buttonStyle from '../../components/Button.style'
import * as formStyle from '../../components/form/Form.style'
import { useLocale } from '../../locale'
import { useModals } from '../../features/modals'
import { ReactComponent as DeleteIcon } from '../../images/icons/Delete.svg'


const supervisorWillProposeReviewerId = 'supervisorWillProposeReviewer'

const isFormUnsaved = (formState: FormState<FormData>, hasStoredValues: boolean): boolean => (
  formState.isDirty || hasStoredValues
)

const shouldDisableSave = (formState: FormState<FormData>, hasStoredValues: boolean): boolean => (
  !formState.isValid
  || !(
    isFormUnsaved(formState, hasStoredValues)
    || hasStoredValues
  )
)

const shouldDisablePropose = (
  formState: FormState<FormData>,
  hasUser: boolean,
  isDescriptionFilled: boolean,
  hasStoredValues: boolean
): boolean => (
  !(
    formState.isValid
    && hasUser
    && isDescriptionFilled
    && !isFormUnsaved(formState, hasStoredValues)
  )
)

interface ThesisFormControlsProps {
  isSaveDisabled: boolean
  isProposeDisabled: boolean
  onPropose?: () => void
  onDelete?: () => void
}

const ThesisFormControls = ({
  isSaveDisabled, isProposeDisabled, onPropose, onDelete,
}: ThesisFormControlsProps): JSX.Element => {
  const { l } = useLocale()

  return (
    <div className={formStyle.controls}>
      <div className={formStyle.controlsLeft}>
        {onDelete && (
          <button type='button' onClick={onDelete} className={formStyle.deleteButton}>
            {l.thesis.delete}
            <DeleteIcon />
          </button>
        )}
      </div>
      <div className={formStyle.controlsRight}>
        <button
          type='submit'
          className={buttonStyle.fill}
          disabled={isSaveDisabled}
        >
          {l.thesis.save}
        </button>
        {onPropose && (
          <button
            type='button'
            className={buttonStyle.action}
            disabled={isProposeDisabled}
            onClick={onPropose}
          >
            {l.thesis.proposeActionsButton}
          </button>
        )}
      </div>
    </div>
  )
}

interface TextInputItemProps {
  id: string
  label: string
  register: ReturnType<typeof useForm>['register']
  onChange?: FormEventHandler
}

type ThesisAssignmentTextInput = keyof Pick<ThesisAssignment, 'titleCs' | 'titleEn'>

export const TextInputItem = ({
  id, label, register, onChange, error,
}: TextInputItemProps & { error?: FieldError }): JSX.Element => {
  const { l } = useLocale()
  const minLength = definitions.ThesisAssignment.properties[id as ThesisAssignmentTextInput].minLength

  return (
    <div>
      <TextInput
        inputProps={{
          'name': id,
          'id': id,
          'required': true,
          'minLength': minLength,
          'onChange': onChange,
          'ref': register({
            required: {
              value: true,
              message: l.form.required,
            },
            minLength: {
              value: minLength,
              message: l.form.validation.minLength(minLength),
            },
          }),
          'aria-required': true,
          'aria-invalid': !!error || undefined,
        }}
        labelText={label}
        extraClassNames={[formStyle.formInput]}
      />
      <Hint error={error?.message} hint={l.form.validation.minLength(minLength)} />
    </div>
  )
}

export const CommentItem = ({
  id, label, register, onChange, wordCount, maxLength,
}: TextInputItemProps & { wordCount?: number, maxLength: number }): JSX.Element => (
  <TextInput
    inputProps={{
      name: id,
      id,
      maxLength,
      onChange,
      rows: 8,
      ref: register,
    }}
    labelText={label}
    multiline
    extraClassNames={[formStyle.formInput]}
    characterCount={wordCount ?? 0}
  />
)

const LanguageOptions = () => {
  const { l } = useLocale()

  return (
    <>
      <option value=''>{l.thesis.selectLanguagePrompt}</option>
      {Enum(ThesisLanguage).keys().map((thesisLanguage) => (
        <option key={thesisLanguage} value={ThesisLanguage[thesisLanguage]}>
          {l.thesis.languageOptions[ThesisLanguage[thesisLanguage]]}
        </option>
      ))}
    </>
  )
}

// TODO: Uncomment when license type is supported
// const LicenseOptions = () => {
//   const { l } = useLocale()

//   return (
//     <>
//       <option value=''>{l.thesis.selectLicenseTypePrompt}</option>
//       {Enum(LicenseType).keys().map(licenseType => (
//         <option key={licenseType} value={LicenseType[licenseType]}>
//           {l.thesis.licenseTypeOptions[LicenseType[licenseType]]}
//         </option>
//       ))}
//     </>
//   )
// }

interface MetadataSelectProps {
  id: string
  label: string
  children: ReactNode // Pass options
  register: ReturnType<typeof useForm>['register']
  onChange?: FormEventHandler
  error?: FieldError
  defaultValue?: string
  isRequired?: boolean
}

export const MetadataSelect = ({
  id, label, children, register, onChange, error, defaultValue, isRequired = false,
}: MetadataSelectProps): JSX.Element => {
  const { l } = useLocale()

  return (
    <div>
      <Select
        selectProps={{
          'name': id,
          'id': id,
          'required': isRequired,
          'defaultValue': defaultValue,
          'onChange': onChange,
          'ref': register({
            required: {
              value: isRequired,
              message: l.form.required,
            },
          }),
          'aria-required': isRequired,
          'aria-invalid': !!error || undefined,
        }}
        labelText={label}
        extraClassNames={[formStyle.formInput]}
      >
        {children}
      </Select>
      <Hint error={error?.message} />
    </div>
  )
}

interface ValidUntilProps {
  register: UseFormMethods['register']
  error?: FieldError
  onChange?: FormEventHandler
}

const ValidUntil = ({ register, error, onChange }: ValidUntilProps): JSX.Element => {
  const { l } = useLocale()
  const id = 'validUntil'
  const minLength = 4
  return (
    <div>
      <TextInput
        inputProps={{
          id,
          minLength,
          onChange,
          'name': id,
          'required': true,
          'ref': register({
            pattern: {
              value: /^[A-B]{1}\d{2}[1-2]$/,
              message: l.form.validation.semesterCode,
            },
          }),
          'aria-required': true,
          'aria-invalid': !!error || undefined,
        }}
        labelText={l.thesis.validUntil}
        extraClassNames={[formStyle.formInput]}
      />
      <Hint error={error?.message} hint={l.form.validation.semesterCode} />
    </div>
  )
}

interface SupervisorReviewerProposalSwitchProps {
  register: UseFormMethods['register']
  onChange?: FormEventHandler
}

const SupervisorReviewerProposalSwitch = ({ register, onChange }: SupervisorReviewerProposalSwitchProps) => {
  const { l } = useLocale()

  return (
    <Switch
      label={l.thesis[supervisorWillProposeReviewerId]}
      inputProps={{
        id: supervisorWillProposeReviewerId,
        name: supervisorWillProposeReviewerId,
        onChange,
        ref: register,
      }}
    />
  )
}

export type FormData = (ThesisAssignment | ThesisNew) & Pick<Thesis, 'validUntil' | 'supervisorWillProposeReviewer'>

interface Props {
  onSave: (data: FormData) => any
  onDelete?: () => any
  onChange?: FormEventHandler
  onModifiedState?: (isModified: boolean) => void
  defaultValues?: FormData
  shouldReset?: boolean
  hasAssigneeWithSpecialization: boolean
  onProposeThesis?: () => void
  formHasStoredValues: boolean
  canEditValidUntil?: boolean
  isSupervisor?: boolean
}

// TODO: Indicate save error.
const ThesisForm = ({
  onSave, onDelete, onChange, onModifiedState, defaultValues, shouldReset, hasAssigneeWithSpecialization,
  onProposeThesis, formHasStoredValues, canEditValidUntil = false, isSupervisor = false,
}: Props): JSX.Element => {
  const { register, reset, handleSubmit, formState, watch, getValues, errors, trigger } = useForm<FormData>({
    defaultValues,
    mode: 'all',
  })
  const { l } = useLocale()
  const { showModal } = useModals()
  const [isFormModified, setIsFormModified] = useState(false)
  const descriptionInputName = 'description'
  const isProposeDisabled
    = shouldDisablePropose(formState, hasAssigneeWithSpecialization, !!(getValues().description), formHasStoredValues)

  /**
   * Checks if form has unsaved content.
   * If there is no unsaved content, save button will be disabled.
   * If there is unsaved content, propose button will be disabled.
   */
  useEffect(() => {
    setIsFormModified(formHasStoredValues || formState.isDirty)
  }, [formState.isDirty, formHasStoredValues])

  useEffect(() => {
    if (shouldReset) {
      reset(defaultValues)
    }
  }, [shouldReset])

  // We need to validate form when we update defaultValues using `useThesisFormBackup`
  useEffect(() => {
    void trigger()
  }, [defaultValues])

  useEffect(() => {
    onModifiedState?.(isFormModified && !formState.isSubmitting)
  }, [isFormModified, formState.isSubmitting])

  function onSubmit (data: FormData) {
    onSave(data)
    reset(data, {
      isDirty: false,
    })
  }

  function displayProposeModal () {
    showModal({
      title: l.thesis.proposeActionsModal.proposeActionsTitle,
      cancelButton: {
        focus: true,
      },
      positiveButton: {
        caption: l.thesis.proposeActionsModal.proposeActionsPositiveButton,
        onClick: onProposeThesis,
      },
    })
  }

  function displayDeleteModal () {
    showModal({
      title: l.thesis.deleteActionModal.title,
      text: l.thesis.deleteActionModal.text,
      cancelButton: {
        focus: true,
      },
      positiveButton: {
        caption: l.thesis.deleteActionModal.positiveButton,
        onClick: onDelete,
      },
    })
  }

  const proposeHelpNode = !!onProposeThesis && isProposeDisabled ? (
    <Hint hint={l.thesis.proposeHelp} />
  ) : null

  const formInvalidHelpNode = !formState.isValid ? (
    <Hint error={l.form.formIsInvalid} />
  ) : null

  // TODO Implement error message from react-hook-form https://react-hook-form.com/api#ErrorMessage
  return (
    <form onSubmit={handleSubmit(onSubmit)} className={formStyle.form} noValidate>
      <TextInputItem
        id='titleCs'
        label={l.thesis.titleInLanguage.cs}
        register={register}
        onChange={onChange}
        error={errors.titleCs}
      />
      <TextInputItem
        id='titleEn'
        label={l.thesis.titleInLanguage.en}
        register={register}
        onChange={onChange}
        error={errors.titleEn}
      />
      <div className={formStyle.inlineFieldGroup}>
        {/* TODO: Uncomment when license type is supported */}
        {/* <MetadataSelect
          id='license'
          label={l.thesis.licenseType}
          register={register}
          defaultValue={defaultValues?.licenseType}
          onChange={onChange}
        >
          <LicenseOptions />
        </MetadataSelect> */}
        <MetadataSelect
          id='language'
          label={l.thesis.language}
          register={register}
          defaultValue={defaultValues?.language}
          onChange={onChange}
          error={errors.language}
          isRequired
        >
          <LanguageOptions />
        </MetadataSelect>
      </div>
      {canEditValidUntil && (
        <ValidUntil
          register={register}
          error={errors?.validUntil}
          onChange={onChange}
        />
      )}
      <CommentItem
        id={descriptionInputName}
        label={l.thesis.description}
        register={register}
        onChange={onChange}
        wordCount={watch(descriptionInputName)?.length}
        maxLength={definitions.ThesisAssignment.properties.description.maxLength}
      />
      {isSupervisor && <SupervisorReviewerProposalSwitch {...{ register, onChange }} />}
      <ThesisFormControls
        isSaveDisabled={shouldDisableSave(formState, formHasStoredValues)}
        isProposeDisabled={isProposeDisabled}
        onPropose={onProposeThesis ? displayProposeModal : undefined}
        onDelete={onDelete ? displayDeleteModal : undefined}
      />
      <ModificationIndicator isModified={isFormModified} />
      {/* wrapped in a div to avoid direct form descendant styling */}
      <div>
        <Hint hint={l.form.formInfoRequired} />
        {proposeHelpNode}
        {formInvalidHelpNode}
      </div>
    </form>
  )
}

export default ThesisForm
