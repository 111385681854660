import { useState, useEffect } from 'react'
import type { Person } from '@cvut/profit-api-types/lib/theses'

import { addNotification } from '../features/toastNotifications'
import { useGetUserInfo } from '../api/me'
import { createContainer } from '../state-management'
import { useLocale } from '../locale'


export interface UserInfoNotInitiated {
  state: 'not-initiated'
}

export interface UserInfoLoading {
  state: 'loading'
}

export interface UserInfoLoaded {
  state: 'loaded'
  person: Person
}

export interface UserInfoNone {
  state: 'none'
}

export type UserInfoState = UserInfoNotInitiated | UserInfoLoading | UserInfoLoaded | UserInfoNone

function useUserInfoContainer (initialState?: UserInfoState) {
  return useState<UserInfoState>(initialState ?? {
    state: 'not-initiated',
  })
}

// Do not use this aside from declaring a container, use `useUserInfo` instead!
export const UserInfoContainer = createContainer(useUserInfoContainer, 'UserInfoContainer')

export type UseUserInfo = () => [
  userInfo: UserInfoState,
  setters: {
    setLoading: () => void,
    setUserInfo: (person: Person) => void,
    setNone: () => void,
  },
  loadUserInfo: () => void,
]

/**
 * It also returns `UserInfoState` from `useUserInfo` for convenience.
 */
export const useUserInfo: UseUserInfo = () => {
  const [userInfo, setUserInfo] = UserInfoContainer.useContainer()
  const [userInfoRequestStatus, _getUserInfo] = useGetUserInfo()
  const { l } = useLocale()

  const setters: ReturnType<UseUserInfo>[1] = {
    setLoading: () => setUserInfo({ state: 'loading' }),
    setUserInfo: (person: Person) => setUserInfo({ state: 'loaded', person }),
    setNone: () => setUserInfo({ state: 'none' }),
  }

  useEffect(() => {
    if (userInfoRequestStatus.state === 'success') {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      setters.setUserInfo(userInfoRequestStatus.data!)
    }

    if (userInfoRequestStatus.state === 'error') {
      setters.setNone()

      addNotification({
        type: 'NEGATIVE',
        message: l.auth.errorGettingUserInfo,
      })
    }
  }, [userInfoRequestStatus.state])

  const loadUserInfo = () => {
    setters.setLoading()
    _getUserInfo()
  }

  return [userInfo, setters, loadUserInfo]
}
