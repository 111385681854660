import type { ThesisReportSection, ThesisReportTexts } from '..'

// The types and constants in this file should be considered as package
// internal. Their purpose is to enforce symmetry between CS and EN texts.

/** @internal */
export type SupervisorReportTexts = ThesisReportTexts<SupervisorSections>

/** @internal */
export type ReviewerReportTexts = ThesisReportTexts<ReviewerSections>

/** @internal */
export const supervisorSectionsOrder: Array<keyof SupervisorSections> = [
  'assignmentFulfillment',
  'mainWrittenPart',
  'nonWrittenPart',
  'outcomeEvaluation',
  'studentActivity',
  'studentSelfReliance',
  'summary',
]

/** @internal */
export const reviewerSectionsOrder: Array<keyof ReviewerSections> = [
  'assignmentFulfillment',
  'mainWrittenPart',
  'nonWrittenPart',
  'outcomeEvaluation',
  'summary',
  'defenseQuestions',
]

interface CommonSections {
  assignmentFulfillment: ChoiceSection<1 | 2 | 3 | 4>
  mainWrittenPart: ThesisReportSection<'score'>
  nonWrittenPart: ThesisReportSection<'score'>
  outcomeEvaluation: ThesisReportSection<'score'>
  summary: ThesisReportSection<'score'>
}

interface SupervisorSections extends CommonSections {
  studentActivity: ChoiceSection<1 | 2 | 3 | 4 | 5>
  studentSelfReliance: ChoiceSection<1 | 2 | 3 | 4 | 5>
}

interface ReviewerSections extends CommonSections {
  defenseQuestions: ThesisReportSection<'plain'>
}

interface ChoiceSection<TChoice extends keyof any> extends ThesisReportSection<'choice'> {
  choices: Record<TChoice, string>
}
