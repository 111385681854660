import { css } from 'linaria'

import ArrowDownIcon from '../../images/icons/ArrowDown.svg'
import { breakpoints, rel } from '../../styles/dimensions'
import { fontWeights } from '../../styles/typography'
import { transitionEasing } from '../../styles/utils'
import * as colors from '../../styles/colors'


export { label } from '../../components/form/Form.style'

export const select = css`
  padding: ${rel(4)}rem 2rem ${rel(4)}rem ${rel(10)}rem;
  font-weight: ${fontWeights.light};
  font-size: ${rel(14)}rem;
  line-height: ${rel(26)}rem;
  background-color: ${colors.white};
  border: ${rel(2)}rem solid ${colors.primaryColor[2]};
  border-radius: ${rel(6)}rem;
  cursor: pointer;
  transition: border-color ${transitionEasing}, background-color ${transitionEasing};
  appearance: none;

  &:not([multiple]) {
    background-image: url(${ArrowDownIcon});
    background-repeat: no-repeat;
    background-position: right ${rel(10)}rem center;
    background-size: ${rel(14)}rem auto;
  }

  &:disabled {
    border-color: ${colors.backgroundColor[1]};
    cursor: not-allowed;

    option {
      color: #888;
    }
  }

  @media screen and (min-width: ${breakpoints.xl}) {
    font-size: ${rel(16)}rem;
  }

  &:valid {
    border-color: ${colors.positiveColor[2]};
  }

  &:invalid,
  &[aria-invalid=true] {
    border-color: ${colors.negativeColor[2]};
  }

  &:focus {
    border-color: ${colors.neutralColor[2]};
    outline: none;
  }

  &:hover,
  &:focus,
  &:active {
    background-color: ${colors.backgroundColor[2]};
  }
`

export const requiredIndicator = css`
  margin-right: ${rel(4)}rem;
  color: ${colors.negativeColor[2]};
`
