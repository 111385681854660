import { ChangeEventHandler } from 'react'
import { UseFormMethods } from 'react-hook-form'
import { Attachment, AttachmentKey } from '@cvut/profit-api-types/lib/theses'

import AttachmentItem from './AttachmentItem'
import type { UploadQueueItem } from '../../api/theses'
import { useLocale } from '../../locale'
import * as style from './ThesisSubmitForm.style'


const AttachmentKeys = [
  AttachmentKey.Type, AttachmentKey.TitleCs, AttachmentKey.TitleEn, AttachmentKey.Href,
]

interface AttachmentProps {
  hasAttachment: (filename: string) => boolean
  index: number
  item: Attachment
  onDelete: (index: number) => void
  onChange: ChangeEventHandler<HTMLInputElement | HTMLInputElement>
  register: UseFormMethods['register']
  setValue: UseFormMethods['setValue']
  errors: UseFormMethods['errors']
  addUploadItem: (uploadItem: UploadQueueItem) => void
  getQueuedFile: (filename: string) => File | undefined
}

const AttachmentRow = ({
  addUploadItem, getQueuedFile, errors, hasAttachment, index, item, onChange, onDelete, register, setValue,
}: AttachmentProps): JSX.Element => {
  const { l } = useLocale()
  const removeButtonLabel = l.thesis.submission.form.attachmentButtons.remove

  return (
    <div className={style.attachment}>
      {AttachmentKeys.map((id) => (
        <AttachmentItem
          {...{ addUploadItem, getQueuedFile, errors, hasAttachment, id, index, item, register, setValue, onDelete }}
          key={id}
          onChange={onChange}
        />
      ))}
      <button
        aria-label={removeButtonLabel}
        className={style.removeButton}
        onClick={() => onDelete(index)}
        title={removeButtonLabel}
        type='button'
      >
        &times;
      </button>
    </div>
  )
}

export default AttachmentRow
