import { css } from 'linaria'

import { rel } from '../../styles/dimensions'


export const settingItem = css`
  & + & {
    margin-top: ${rel(6)}rem;
  }
`

export const formControls = css`
  margin-top: ${rel(16)}rem;

  /* Center spinners if needed. */
  > button {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
`
