import { useState } from 'react'
import { cx } from 'linaria'
import { Link } from 'react-router-dom'
import type { StudyDegree } from '@cvut/profit-api-types/lib/theses/specialization'
import type { Person } from '@cvut/profit-api-types/lib/theses/person'
import { Topic, TopicPatch, TopicVisibility } from '@cvut/profit-api-types/lib/theses/topic'
import { formatPersonFullName } from '@cvut/profit-theses-common'
import { Enum, objectOmit } from '@cvut/profit-utils'

import { truncateAtWord } from '../../../utils'
import { useLocale } from '../../../locale'
import { localeToLocaleString } from '../../../locale/utils'
import Card from '../../../components/Card'
import * as styles from './Result.style'


const FEW_RECOMMENDATIONS_LENGTH = 5

export type SearchResult =
  & Pick<Topic, 'id' | 'title' | 'modifiedAt' | 'description' | 'visibility'>
  & { author: Pick<Person, 'titlesPre' | 'firstName' | 'lastName' | 'titlesPost'> }
  & { specializations: string[] }
  & { studyDegrees: StudyDegree[] }

export interface Props {
  topic: SearchResult
  viewTopicLink: string
  setVisibility?: (visibility: NonNullable<TopicPatch['visibility']>) => Promise<void>
}

/**
 * If `setVisibility` function is provided, then it is assumed that we working
 * in "my topics" mode.
 */
const Result = ({ topic, viewTopicLink, setVisibility }: Props): JSX.Element => {
  const myTopicsMode = setVisibility != null

  const { l, currentLang } = useLocale()
  const resultL = l.topic.search.results

  // The opposite of this is "display study degrees".
  const displaySpecializations = topic.specializations.length > 0

  const [recommandationsExpanded, setIsExpanded] = useState(false)
  const expandRecommendationsTooltip = recommandationsExpanded ? l.navigation.displayLess : l.navigation.displayMore
  function toggleRecommendationVisibility () {
    setIsExpanded(prevValue => !prevValue)
  }

  const titleNode = (
    <h2 className={styles.title}>
      <Link to={viewTopicLink}>{topic.title}</Link>
    </h2>
  )

  const authorNode = (
    <span title={resultL.author}>
      {formatPersonFullName(topic.author)}
    </span>
  )

  const recommendations = displaySpecializations ? topic.specializations : topic.studyDegrees.map(d => l.studyDegree[d])
  const recommendationsContentNodes = recommendations.map(r => (
    <span key={r} className={styles.capsuleLabel}>{r}</span>
  ))

  const recommendationsNode = (
    <div
      title={displaySpecializations ? resultL.specializations : resultL.studyDegrees}
      className={cx(styles.recommendations, !recommandationsExpanded && styles.fewerRecommendations)}
    >
      {recommendationsContentNodes}
      {recommendationsContentNodes.length > FEW_RECOMMENDATIONS_LENGTH && (
        <button
          type='button'
          onClick={toggleRecommendationVisibility}
          title={expandRecommendationsTooltip}
          aria-label={expandRecommendationsTooltip}
          className={styles.capsuleLabel}
        >
          {recommandationsExpanded ? 'X' : '…'}
        </button>
      )}
    </div>
  )

  const dateNode = (
    <span title={resultL.published}>
      <time dateTime={topic.modifiedAt.toISOString()}>
        {topic.modifiedAt.toLocaleDateString(localeToLocaleString(currentLang))}
      </time>
    </span>
  )

  const descriptionAndDateNode = !myTopicsMode && (
    <div className={styles.description}>
      <p>{dateNode}{topic.description.length && ' — '}{truncateAtWord(topic.description, 200)}</p>
    </div>
  )

  const topicVisibilityOptions = (
    // TODO: This omission should probably be somehow centralised.
    Enum(objectOmit(TopicVisibility, 'Deleted')).keys().map((topicVisibility) => (
      <option key={topicVisibility} value={TopicVisibility[topicVisibility]}>
        {l.topic.visibilityOptions[TopicVisibility[topicVisibility]]}
      </option>
    ))
  )

  // TODO: Disable this when the promise is firing.
  // Typescript 4.4 when?! This is already captured by `myTopicsMode`. -+
  //                                              +---------------------+
  //                          vvvvvvvvvvvvvvvvvvvvv
  const topicVisibilityNode = setVisibility != null ? (
    <select
      className={styles.select}
      name='topicVisibility'
      id='topic-visibility'
      defaultValue={topic.visibility}
      onChange={async (e) => {
        return await setVisibility(Enum(TopicVisibility).fromValue(e.target.value))
      }}
    >
      {topicVisibilityOptions}
    </select>
  ) : null

  return (
    <section className={styles.resultListItem}>
      <Card>
        {titleNode}
        <div className={styles.info}>
          <div className={styles.infoLabel}>
            {myTopicsMode ? dateNode : authorNode}
          </div>
          {recommendationsNode}
          {topicVisibilityNode}
        </div>
        {descriptionAndDateNode}
      </Card>
    </section>
  )
}

export default Result
