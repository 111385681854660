import { useEffect, useState } from 'react'
import { Thesis, ThesisNew, Topic } from '@cvut/profit-api-types/lib/theses'
import { useHistory } from 'react-router-dom'
import { thesisLangToLocale } from '@cvut/profit-theses-common'

import AccessControlledRender from '../../access-control/AccessControlledRender'
import { addNotification } from '../../features/toastNotifications'
import { errorCardRenderer } from '../../components/RequestErrorCard'
import LeavingPrompt from '../../components/form/LeavingPrompt'
import PageLoadSpinner from '../../components/PageLoadSpinner'
import pagePaths from '../paths'
import RequestDependentRender from '../../api/RequestDependentRender'
import ThesisEdit from '../../features/thesis/ThesisEdit'
import { useCreateThesis } from '../../api/theses'
import { useLocale } from '../../locale'
import { useTopic } from '../../api/topics'
import ValidatedPathParams from '../utils/ValidatedPathParams'


const getTopicDefaultValues = ({ description, id, language, title }: Topic): ThesisNew => ({
  titleCs: '',
  titleEn: '',
  [thesisLangToLocale(language) === 'en' ? 'titleEn' : 'titleCs']: title,
  description,
  language,
  topic: { id },
})

interface Props {
  topicId: number
}

const TopicThesisCreatePage = ({ topicId }: Props): JSX.Element => {
  const history = useHistory()
  const { l } = useLocale()
  const [topicStatus, getTopic] = useTopic()
  const [createStatus, createThesis] = useCreateThesis()
  const [isFormModified, setIsFormModified] = useState(false)

  const handleModifiedState = (isModified: boolean) => setIsFormModified(isModified)

  useEffect(() => {
    void getTopic(topicId)
  }, [])

  useEffect(() => {
    if (createStatus.state === 'success') {
      addNotification({
        type: 'POSITIVE',
        message: l.successMessages.api.defaultCreate(l.thesis.thesis.toLocaleLowerCase()),
      })
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      history.replace(pagePaths.theses.edit(createStatus.data!.id))
    }
  }, [createStatus.state])

  return (
    <RequestDependentRender
      requestStatus={topicStatus}
      renderOnLoading={() => <PageLoadSpinner message={l.topic.loadingTopic} />}
      renderOnError={errorCardRenderer(l.topic.errorGettingTopic, pagePaths.topics.createThesis(topicId))}
      renderOnSuccess={(topic) => (
        <>
          <ThesisEdit
            mode='create'
            onSave={createThesis}
            defaultValues={getTopicDefaultValues(topic) as Thesis} // FIXME this is an ugly lie
            formHasStoredValues={false}
            onModifiedState={handleModifiedState}
            isSupervisor
          />
          <LeavingPrompt when={isFormModified} />
        </>
      )}
    />
  )
}

const TopicThesisCreatePageWrapper = (): JSX.Element => {
  const history = useHistory()
  const { l } = useLocale()

  return (
    <AccessControlledRender loaderColor='primary'>
      {(ac) => {
        if (!ac.globalRoles.isProponent) {
          addNotification({
            type: 'NEGATIVE',
            message: l.accessControl.noPermission(l.thesis.creation),
          })

          history.replace(pagePaths.home)
          return <></>
        }

        return (
          <ValidatedPathParams<Props>
            expectedPathPattern={pagePaths.topics.createThesis()}
            expectedParams={{
              topicId: {
                validate: (param: string) => !isNaN(parseInt(param, 10)),
                convert: (param: string) => parseInt(param, 10),
              },
            }}
          >
            {(params) => (
              <TopicThesisCreatePage topicId={params.topicId} />
            )}
          </ValidatedPathParams>
        )
      }}
    </AccessControlledRender>
  )
}

export default TopicThesisCreatePageWrapper
