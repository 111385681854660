import { useState } from 'react'
import { ThesisMainState, Thesis } from '@cvut/profit-api-types/lib/theses'

import locale from '../locale'
import { LocalizedThesisState } from '../types/thesis'
import { UseRequestStatus } from './useRequest'


type ThesisStatesMeta = {
  mainState: Thesis['mainState'][],
  states: LocalizedThesisState[],
}

// TODO: useThesesMeta() should be used instead, after states are included in theses/meta API response
export function useThesisStates (): [
  UseRequestStatus<ThesisStatesMeta>,
  () => Promise<void>,
] {
  const [requestStatus, setRequestStatus] = useState<UseRequestStatus<ThesisStatesMeta>>({ state: 'not-initiated' })

  return [
    requestStatus,
    async () => {
      await Promise.resolve(
        setRequestStatus({
          state: 'success',
          response: new Response(),
          data: {
            mainState: Object.values(ThesisMainState),
            // FIXME: this could be any state, we need to translate all states
            states: Object.keys(locale.default.thesis.substate) as LocalizedThesisState[],
          },
        })
      )
    },
  ]
}
