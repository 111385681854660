import { cx } from 'linaria'
import { ThesisMainState } from '@cvut/profit-api-types/lib/theses'

import { useLocale } from '../locale'
import * as style from './ThesisStateLabel.style'


export const stateClassLookup: Record<ThesisMainState, string> = {
  draft: style.draft,
  proposed: style.proposed,
  assigned: style.assigned,
  review: style.review,
  readyForDefence: style.readyForDefence,
  evaluated: style.evaluated,
  archived: style.archived,
  deleted: style.deleted,
}


interface Props {
  state: ThesisMainState
}

const ThesisStateLabel = ({ state }: Props): JSX.Element => {
  const { l } = useLocale()

  return (
    <span className={cx(stateClassLookup[state], style.label)}>
      {l.thesis.mainState[state]}
    </span>
  )
}

export default ThesisStateLabel
