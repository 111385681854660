import { css } from 'linaria'

import { rel } from '../../styles/dimensions'
import * as colors from '../../styles/colors'


export const notification = css`
  display: flex;
  flex-direction: column;
  padding: ${rel(8)}rem;
  color: ${colors.primaryColor[2]};
  background-color: ${colors.white};
  border-left: ${rel(4)}rem solid transparent;
  cursor: pointer;
`

export const header = css`
  display: flex;
  justify-content: space-between;
`

export const title = css`
  font-weight: bold;
  font-size: ${rel(16)}rem;
  line-height: 1.25;
`

export const content = css`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding-top: ${rel(8)}rem;

  > svg {
    flex-shrink: 0;
  }
`

export const profileIcon = css`
  max-width: ${rel(40)}rem;
`

export const description = css`
  flex-grow: 1;
  margin: 0 ${rel(8)}rem;
  font-size: ${rel(14)}rem;
  line-height: ${rel(20)}rem;

  & ~ svg {
    max-width: ${rel(10)}rem;
    height: auto;
  }
`

export const date = css`
  position: relative;
  top: ${rel(2)}rem;
  color: ${colors.neutralColor[2]};
  font-weight: bold;
  font-size: ${rel(14)}rem;
  line-height: ${rel(20)}rem;
`

export const visitedNotification = css`
  border-color: ${colors.white};
`

export const unvisitedNotice = css`
  border-color: ${colors.positiveColor[2]};
`

export const unvisitedTask = css`
  border-color: ${colors.neutralColor[2]};
`

export const link = css`
  &:hover,
  &:focus {
    text-decoration: none;
    outline: none;

    & > .${notification} {
      background-color: ${colors.backgroundColor[2]};
    }

    & > .${visitedNotification} {
      border-color: ${colors.backgroundColor[2]};
    }
  }
`

export const disabledLink = css`
  cursor: not-allowed;
`
