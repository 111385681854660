import { Key } from 'react'
import { cx } from 'linaria'
import { Row as TableRowProps } from 'react-table'

import { columnsStyleMap } from './Table'
import * as style from './Row.style'


const Row = <T extends object = {}>({ row }: {
  row: TableRowProps<T>,
  key: Key,
}): JSX.Element => (
  <tr {...row.getRowProps()} className={style.row}>
    {row.cells.map(cell => ( // eslint-disable-next-line react/jsx-key
      <td {...cell.getCellProps()} className={cx(style.cell, columnsStyleMap[cell.column.id])}>
        {cell.render('Cell')}
      </td>
    ))}
  </tr>
)

export default Row
