import dedent from 'dedent'
import { objectOmit } from '@cvut/profit-utils'

import {
  reviewerSectionsOrder,
  supervisorSectionsOrder,
  ReviewerReportTexts,
  SupervisorReportTexts,
} from './internal'


const sections: (ReviewerReportTexts & SupervisorReportTexts)['sections'] = {
  assignmentFulfillment: {
    title: 'Splnění zadání',
    description: dedent`
      Posuďte, zda předložená ZP dostatečně a v souladu se zadáním obsahově vymezuje cíle, správně \
      je formuluje a v dostatečné kvalitě naplňuje. V komentáři uveďte body zadání, které nebyly \
      splněny, posuďte závažnost, dopady a případně i příčiny jednotlivých nedostatků. Pokud zadání \
      svou náročností vybočuje ze standardů pro daný typ práce nebo student případně vypracoval ZP \
      nad rámec zadání, popište, jak se to projevilo na požadované kvalitě splnění zadání a jakým \
      způsobem toto ovlivnilo výsledné hodnocení.`,
    type: 'choice',
    choices: {
      1: 'zadání splněno',
      2: 'zadání splněno s menšími výhradami',
      3: 'zadání splněno s většími výhradami',
      4: 'zadání nesplněno',
    },
  },

  mainWrittenPart: {
    title: 'Písemná část práce',
    description: dedent`
      Zhodnoťte přiměřenost rozsahu předložené ZP vzhledem k obsahu, tj. zda všechny části ZP jsou \
      informačně bohaté a ZP neobsahuje zbytečné části. Dále posuďte, zda předložená ZP je po věcné \
      stránce v pořádku, případně vyskytují-li se v práci věcné chyby nebo nepřesnosti.

      Zhodnoťte dále logickou strukturu ZP, návaznosti jednotlivých kapitol a pochopitelnost textu \
      pro čtenáře. Posuďte správnost používání formálních zápisů obsažených v práci. Posuďte \
      typografickou a jazykovou stránku ZP, viz \
      <a href="https://courses.fit.cvut.cz/SZZ/dokumenty/files/SmerniceDekana52-2021_SZZ_a_ZP.pdf" \
      target="_blank" rel="noreferrer">Směrnice děkana č. 52/2021</a>, článek 3.

      Posuďte, zda student využil a správně citoval relevantní zdroje. Ověřte, zda jsou všechny \
      převzaté prvky řádně odlišeny od vlastních výsledků, zda nedošlo k porušení citační etiky a \
      zda jsou bibliografické citace úplné a v souladu s citačními zvyklostmi a normami. Zhodnoťte, \
      zda převzatý software a jiná autorská díla, byly v ZP použity v souladu s licenčními \
      podmínkami.`,
    type: 'score',
  },

  nonWrittenPart: {
    title: 'Nepísemná část, přílohy',
    description: dedent`
      Dle charakteru práce se případně vyjádřete k nepísemné části ZP. Například: SW dílo – kvalita \
      vytvořeného programu a vhodnost a přiměřenost technologií, které byly využité od vývoje až po \
      nasazení. HW – funkční vzorek – použité technologie a nástroje, Výzkumná a experimentální \
      práce – opakovatelnost experimentů.`,
    type: 'score',
  },

  outcomeEvaluation: {
    title: 'Hodnocení výsledků, jejich využitelnost',
    description: dedent`
      Dle charakteru práce zhodnoťte možnosti nasazení výsledků práce v praxi nebo uveďte, zda \
      výsledky ZP rozšiřují již publikované známé výsledky nebo přinášející zcela nové poznatky.`,
    type: 'score',
  },

  studentActivity: {
    title: 'Aktivita studenta',
    description: dedent`
      V souvislosti s průběhem a výsledkem práce posuďte, zda byl student během řešení aktivní, \
      zda dodržoval dohodnuté termíny, jestli své řešení průběžně konzultoval a zda byl na \
      konzultace dostatečně připraven.`,
    type: 'choice',
    choices: {
      1: 'výborná aktivita',
      2: 'velmi dobrá aktivita',
      3: 'průměrná aktivita',
      4: 'slabší, ale ještě dostatečná aktivita',
      5: 'nedostatečná aktivita',
    },
  },

  studentSelfReliance: {
    title: 'Samostatnost studenta',
    description: dedent`
      V souvislosti s průběhem a výsledkem práce posuďte schopnost studenta samostatné tvůrčí \
      práce.`,
    type: 'choice',
    choices: {
      1: 'výborná samostatnost',
      2: 'velmi dobrá samostatnost',
      3: 'průměrná samostatnost',
      4: 'slabší, ale ještě dostatečná samostatnost',
      5: 'nedostatečná samostatnost',
    },
  },

  summary: {
    title: 'Celkové hodnocení',
    description: dedent`
      Shrňte stránky ZP, které nejvíce ovlivnily Vaše celkové hodnocení. Celkové hodnocení nemusí \
      být aritmetickým průměrem či jinou hodnotou vypočtenou z hodnocení v předchozích jednotlivých \
      kritériích. Obecně platí, že bezvadně splněné zadání je hodnoceno klasifikačním stupněm A.`,
    type: 'score',
  },

  defenseQuestions: {
    title: 'Otázky k obhajobě',
    description: dedent`
      Uveďte případné dotazy, které by měl student zodpovědět při obhajobě ZP před komisí (body \
      oddělte odrážkami).`,
    type: 'plain',
  },
}

export const reviewer: ReviewerReportTexts = {
  headers: {
    createdOn: 'Vytvořeno dne',
    evaluationCriteria: 'Hodnotící kritéria',
    instructions: 'Instrukce',
    specialization: 'Obor / specializace',
    student: 'Student',
    thesisTitle: 'Název práce',
    document: 'Posudek oponenta závěrečné práce',
    reportAuthor: 'Oponent práce',
  },
  sections: objectOmit(sections, 'studentActivity', 'studentSelfReliance'),
  sectionsOrder: reviewerSectionsOrder,
}

export const supervisor: SupervisorReportTexts = {
  headers: {
    ...reviewer.headers,
    document: 'Hodnocení vedoucího závěrečné práce',
    reportAuthor: 'Vedoucí práce',
  },
  sections: objectOmit(sections, 'defenseQuestions'),
  sectionsOrder: supervisorSectionsOrder,
}
