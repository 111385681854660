import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { CookieKey } from '@cvut/profit-theses-common'

import { addNotification } from '../features/toastNotifications'
import { useLogout } from '../api/auth'
import { useUserInfo, UserInfoState } from './user-info'
import { cookies } from '../utils/cookies'
import pagePaths from '../pages/paths'
import { useLocale } from '../locale'


export function useSession (): {
  userInfo: UserInfoState,
  isAuthenticated: () => boolean,
  handleLogout: () => void,
} {
  const [userInfo, userInfoSetters, loadUserInfo] = useUserInfo()
  const [logoutRequestStatus, sendLogoutRequest] = useLogout()
  const history = useHistory()
  const { l } = useLocale()

  useEffect(() => {
    if (logoutRequestStatus.state === 'success') {
      userInfoSetters.setNone()

      history.push(pagePaths.auth.logout)
      addNotification({
        type: 'POSITIVE',
        message: l.auth.logoutSuccessful,
      })
    }
  }, [logoutRequestStatus])

  const isAuthenticated = () => !!cookies.get<string>(CookieKey.AccessToken)

  // Loads user info on page refresh if tokens are in cookies.
  useEffect(() => {
    if (isAuthenticated() && userInfo.state === 'not-initiated') {
      loadUserInfo()
    }
  }, [])

  return {
    userInfo,
    isAuthenticated,
    handleLogout: sendLogoutRequest,
  }
}
