import { ReviewerProposal, Thesis } from '@cvut/profit-api-types/lib/theses'
import type { Person } from '@cvut/profit-api-types/lib/theses'

import Card from '../../components/Card'
import {
  AcceptedProposalControls,
  PendingProposalControls,
  ApplyControls,
} from './ThesisReviewerProposalControls'
import { formatPersonFullName } from '@cvut/profit-theses-common'
import { useLocale } from '../../locale'
import { useUserInfo } from '../../session/user-info'


interface Props {
  thesis: Thesis
  reviewerProposals: ReviewerProposal[]
  onActionCompleted: () => unknown
}

const ThesisReviewerActionsCard = ({ thesis, reviewerProposals, onActionCompleted }: Props): JSX.Element | null => {
  const { l } = useLocale()
  const [userInfo] = useUserInfo()

  if (userInfo.state !== 'loaded') {
    return null
  }

  if ([thesis.assignee?.username, thesis.supervisor?.username].includes(userInfo.person.username)) {
    return null
  }

  let headerTitle = l.thesisReviewerProposal.actions.title.proposeMyself
  let cardContent = (
    <ApplyControls onActionCompleted={onActionCompleted} person={userInfo.person} thesisId={thesis.id} />
  )

  // There should be a maximum of one proposal with state "accepted" or "pending"
  const acceptedProposal = reviewerProposals.find(proposal =>
    proposal.reviewer?.username === userInfo.person.username
      && proposal.state === 'pending'
      && proposal.reviewerAcceptance === 'accepted'
  )
  const pendingProposal = reviewerProposals.find(proposal =>
    proposal.reviewer?.username === userInfo.person.username
      && proposal.state === 'pending'
      && proposal.reviewerAcceptance === 'pending'
  )

  if (acceptedProposal) {
    headerTitle = l.thesisReviewerProposal.actions.title.reviewerProposal
    cardContent = (
      <AcceptedProposalControls reviewerProposalId={acceptedProposal.id} onActionCompleted={onActionCompleted} />
    )
  }

  if (pendingProposal) {
    // TODO ensure proponent really contains an expanded Person
    const proponent = pendingProposal.proponent as Person

    headerTitle = proponent.username === userInfo.person.username
      ? l.thesisReviewerProposal.actions.title.applied
      : l.thesisReviewerProposal.actions.title.proposedBy(formatPersonFullName(proponent))
    cardContent = (
      <PendingProposalControls reviewerProposalId={pendingProposal.id} onActionCompleted={onActionCompleted} />
    )
  }

  return (
    <Card>
      <Card.Header title={headerTitle} />
      <Card.Content>
        {cardContent}
      </Card.Content>
    </Card>
  )
}

export default ThesisReviewerActionsCard
