import { css } from 'linaria'

import { rel } from '../../styles/dimensions'
import * as colors from '../../styles/colors'


export const controlIcon = css`
  svg {
    transform: scale(0.8);
  }

  .background {
      fill: ${colors.white};
  }

  &:hover {
    .background {
      fill: ${colors.backgroundColor[2]};
    }
  }

  &:active {
    .background {
      fill: ${colors.backgroundColor[1]};
    }
  }
`

export const metadataPairs = css`
  display: flex;
  margin: 0;

  > * + * {
    margin-left: ${rel(32)}em;
  }

  > div {
    display: flex;
    flex-direction: column;

    > dd {
      margin-left: 0;
    }
  }
`

export const description = css`
  margin: ${rel(16)}rem 0 0 0;
  white-space: pre-wrap;
`

export const dropdownOpen = css`
  /* Not empty */
`

export const moreOptions = css`
  position: relative;
`

export const moreOptionsToggleButton = css`
  padding: 0;
  background-color: transparent;
  border: 0;

  &.${dropdownOpen} {
    .background {
      fill: ${colors.backgroundColor[2]};
    }
  }
`

export const moreOptionsDropwdown = css`
  position: absolute;
  top: 100%;
  right: 0;
  left: auto;
  display: none;
  margin: ${rel(8)}rem auto auto;
  padding: ${rel(6)}rem 0;
  background-color: ${colors.white};
  border: ${rel(1)}rem solid ${colors.backgroundColor[2]};
  border-radius: ${rel(8)}rem;
  box-shadow: 0 ${rel(2)}rem ${rel(12)}rem ${colors.boxShadow};
  opacity: 0;

  & > * {
    width: 100%;
    padding: ${rel(8)}rem ${rel(16)}rem;
    font-size: ${rel(16)}rem;
    white-space: nowrap;
    text-align: left;
    background-color: transparent;
    border: 0;
  }

  & > * + * {
    border-top: ${rel(1)}rem solid ${colors.backgroundColor[2]};
  }

  & > *:hover,
  & > *:focus {
    background-color: ${colors.backgroundColor[2]};
    cursor: pointer;
  }

  &.${dropdownOpen} {
    display: block;
    opacity: 1;
    animation: fadeIn 0.35s;

    @keyframes fadeIn {
      0% {
        display: none;
        opacity: 0;
      }

      15% {
        display: block;
      }

      30% {
        opacity: 0.3;
      }

      100% {
        opacity: 1;
      }
    }
  }
`
