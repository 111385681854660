import { css, cx } from 'linaria'

import { breakpoints, rel } from '../../styles/dimensions'
import { input } from '../../components/form/TextInput.style'
import * as colors from '../../styles/colors'


export const panel = css`
  z-index: 12;
  width: 100%;
  min-height: 8em;
  padding-bottom: ${rel(8)}rem;
  color: ${colors.primaryColor[2]};
  background-color: #fff;
  border-radius: 0 0 ${rel(8)}rem ${rel(8)}rem;
  box-shadow: 0 ${rel(2)}rem ${rel(12)}rem ${colors.boxShadow};

  @media screen and (min-width: ${breakpoints.md}) {
    width: ${rel(480)}rem;
  }
`

export const header = css`
  display: flex;

  /* Move 'notificationFilter' to next line */
  flex-wrap: wrap;
  align-items: center;
  padding: ${rel(5)}rem ${rel(8)}rem;

  @media screen and (min-width: ${breakpoints.sm}) {
    flex-wrap: unset;
  }

  svg {
    max-width: ${rel(48)}rem;
    height: auto;
  }
`

export const title = css`
  font-weight: bold;
  font-size: ${rel(24)}rem;
  line-height: ${rel(40)}rem;
`

export const infoIcon = css`
  .background {
    fill: ${colors.white};
  }

  .bell {
    fill: ${colors.positiveColor[2]};
  }
`

export const taskIcon = css`
  .backgroundOuter {
    fill: ${colors.white};
  }

  .backgroundInner {
    fill: ${colors.neutralColor[2]};
  }

  .exclamationMark {
    fill: ${colors.white};
  }
`

export const messageWrapper = css`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${rel(16)}rem;
`

export const content = css`
  max-height: 50vh;
  overflow-y: auto;
  border-top: ${rel(1)}rem solid ${colors.backgroundColor[2]};

  @media screen and (min-width: ${breakpoints.md}) {
    max-height: ${rel(420)}rem;
  }
`

export const list = css`
  margin: 0;
  padding: 0;
  list-style: none;
`

export const item = css`
  border-bottom: ${rel(1)}rem solid ${colors.backgroundColor[2]};

  &:last-child {
    border-bottom: none;
  }
`

export const paginationWrapper = css`
  display: flex;
  justify-content: center;
  padding: 0;

  button {
    display: block;
    width: 100%;
  }
`

export const settingsButton = css`
  margin-right: ${rel(10)}rem;
  margin-left: auto;
`

export const notificationFilter = cx(input, css`
  display: flex;

  /* We want it to show as the last item */
  order: 99;
  width: 100%;
  margin-top: ${rel(4)}rem;
  margin-bottom: ${rel(8)}rem;
  font-size: ${rel(16)}rem;
  text-overflow: ellipsis;

  @media screen and (min-width: ${breakpoints.sm}) {
    order: unset;
    margin: 0 ${rel(16)}rem;
  }
`)
