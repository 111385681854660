import { css } from 'linaria'


export const attachmentsUploadQueuePanel = css`
  display: grid;
  gap: 1rem;
  padding: 1.5rem 0;
`

export const attachmentsUploadQueueRow = css`
  display: grid;
  grid-template-columns: minmax(4rem, 15rem) minmax(4rem, auto) minmax(4rem, 20rem);
  gap: 2rem;
`

export const attachmentsUploadQueueRowItem = css`
  display: grid;
  align-items: center;
  height: 2rem;
  overflow: hidden;

  div {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`

export const itemName = css`
  text-align: right;
`
