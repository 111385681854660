import { css } from 'linaria'

import { rel } from '../../styles/dimensions'


export const wrapper = css`
  position: relative;
  display: flex;
  align-items: center;

  > * + * {
    margin-left: ${rel(8)}rem;
  }
`
