import { useEffect, useState } from 'react'
import { Redirect, useHistory } from 'react-router-dom'
import { Thesis, ThesisPatch, ThesisMainState, ThesisAction } from '@cvut/profit-api-types/lib/theses'
import ulog from 'ulog'

import AccessControlledRender from '../../access-control/AccessControlledRender'
import { addNotification } from '../../features/toastNotifications'
import { errorCardRenderer } from '../../components/RequestErrorCard'
import LeavingPrompt from '../../components/form/LeavingPrompt'
import { useThesis, useUpdateThesis, useDeleteThesis } from '../../api/theses'
import RequestDependentRender from '../../api/RequestDependentRender'
import { useThesisFormBackup } from '../../utils/theses'
import PageLoadSpinner from '../../components/PageLoadSpinner'
import pagePaths from '../paths'
import ValidatedPathParams from '../utils/ValidatedPathParams'
import { useAccessControl } from '../../access-control'
import ThesisEdit from '../../features/thesis/ThesisEdit'
import ThesisChangesCard from '../../features/thesis/ThesisChangesCard'
import { useLocale } from '../../locale'
import { useThesisActions } from '../../api/theses'


const logger = ulog('ThesisEditPage')

interface Props {
  thesisId: number
}

// TODO: Handle save and delete errors.
// TODO: Save and delete loaders.
const ThesisEditPage = ({ thesisId }: Props): JSX.Element => {
  const history = useHistory()
  const { l } = useLocale()
  const accessControl = useAccessControl()
  const thesisInLocale = l.thesis.thesis.toLocaleLowerCase()
  const {
    forgetStoredValues, formBackupHandler, getDefaultValues, shouldReset, storedValues,
  } = useThesisFormBackup(thesisId)
  const [hasStoredValues, setHasStoredValues] = useState(false)
  const [isFormModified, setIsFormModified] = useState(false)

  const handleModifiedState = (isModified: boolean) => setIsFormModified(isModified)

  useEffect(() => {
    setHasStoredValues(!!storedValues)
  }, [storedValues])

  const [getStatus, getThesis] = useThesis()
  useEffect(() => {
    void getThesis(thesisId)
  }, [])

  const [updateStatus, updateThesis] = useUpdateThesis()
  useEffect(() => {
    if (updateStatus.state === 'success') {
      addNotification({
        type: 'POSITIVE',
        message: l.successMessages.api.defaultPatch(thesisInLocale, thesisId.toString()),
      })
      // form backup can be forgotten now
      forgetStoredValues()
      setHasStoredValues(false)
    }
  }, [updateStatus.state])

  const [deleteStatus, deleteThesis] = useDeleteThesis()
  useEffect(() => {
    if (deleteStatus.state === 'success') {
      history.push(pagePaths.theses.my)
      addNotification({
        type: 'POSITIVE',
        message: l.successMessages.api.defaultDelete(thesisInLocale, thesisId.toString()),
      })
      // form backup can be forgotten now
      forgetStoredValues()
      setHasStoredValues(false)
    }
  }, [deleteStatus.state])

  const updateCurrentThesis = (data: ThesisPatch) => {
    if (getStatus.state !== 'success') {
      logger.warn('Tried to update a thesis which was not loaded')
      return
    }

    // Currently `makeRequest<T>` returns `T | undefined` however we certainly know that
    // the back end will return some data. We cannot remove `undefined` from the return
    // type of `makeRequest` because it is difficult to type it without runtime information.
    //
    // tl;dr: `getStatus.data` is not `undefined` if `getStatus.state === `success`
    // because that's how our API works.
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    void updateThesis(getStatus.data!.id, data)
  }

  function canEditCurrentThesis (): boolean {
    if (getStatus.state !== 'success') {
      return false
    }

    if (accessControl.state !== 'loaded') {
      return false
    }

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const thesis = getStatus.data!

    // FIXME: Do not hardcode proposed.revision state
    // If you edit this condition, you will probably want to edit also ThesisViewPage.tsx::canEditThesis
    // FIXME: When JJ bumps Typescript to 4.1 (deadline December, 2020), then replace `proposed.revision` with an enum.
    return (accessControl.globalRoles.isFtOfficer && thesis.mainState === ThesisMainState.Assigned)
      || (accessControl.thesisRoles.isThesisSupervisor(thesis) && (thesis.mainState === ThesisMainState.Draft
        || (thesis.mainState === ThesisMainState.Proposed && thesis.states.includes('proposed.revision'))))
  }

  const canDeleteCurrentThesis = () => {
    if (getStatus.state !== 'success') {
      return false
    }

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const state = getStatus.data!.mainState

    return state === ThesisMainState.Draft || state === ThesisMainState.Proposed
  }

  const canEditValidUntil = (thesis: Thesis): boolean => {
    return accessControl.state === 'loaded'
      && accessControl.globalRoles.isFtOfficer
      && !!thesis.validUntil
  }

  const deleteCurrentThesis = canDeleteCurrentThesis() ? () => {
    if (getStatus.state !== 'success') {
      logger.warn('Tried to delete a thesis which was not loaded')
      return
    }

    // Currently `makeRequest<T>` returns `T | undefined` however we certainly know that
    // the back end will return some data. We cannot remove `undefined` from the return
    // type of `makeRequest` because it is difficult to type it without runtime information.
    //
    // tl;dr: `getStatus.data` is not `undefined` if `getStatus.state === `success`
    // because that's how our API works.
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    void deleteThesis(getStatus.data!.id)
  } : undefined

  const [requestActionStatus, sendActionRequest] = useThesisActions(thesisId)

  function handleThesisProposal () {
    sendActionRequest(ThesisAction.PROPOSE)
  }

  useEffect(() => {
    if (requestActionStatus.state === 'success') {
      addNotification({
        message: l.thesis.proposeActionsSuccessMessage,
        type: 'POSITIVE',
      })

      history.push(`${pagePaths.theses.view(thesisId)}`)
    }
  }, [requestActionStatus.state])

  return (
    <AccessControlledRender loaderColor='primary'>
      {(ac) => (
        <RequestDependentRender
          requestStatus={getStatus}
          renderOnLoading={() => <PageLoadSpinner message={l.thesis.loadingThesis} />}
          renderOnError={errorCardRenderer(l.thesis.errorGettingThesis, pagePaths.theses.edit(thesisId))}
          renderOnSuccess={(thesis) => (
            canEditCurrentThesis()
              ? (
                <>
                  <ThesisEdit
                    mode='edit'
                    onSave={updateCurrentThesis}
                    onDelete={deleteCurrentThesis}
                    onChange={formBackupHandler}
                    onModifiedState={handleModifiedState}
                    backLink={{ to: pagePaths.theses.view(thesisId) }}
                    defaultValues={getDefaultValues(thesis) as Thesis} // only 6 keys from thesis are used
                    shouldReset={shouldReset}
                    onProposeThesis={handleThesisProposal}
                    changeHistoryComponent={<ThesisChangesCard thesisId={thesisId} />}
                    formHasStoredValues={hasStoredValues}
                    canEditValidUntil={canEditValidUntil(thesis)}
                    updateStatus={updateStatus.state}
                    isSupervisor={ac.thesisRoles.isThesisSupervisor(thesis)}
                  />
                  <LeavingPrompt when={isFormModified} />
                </>
              )
              : (
                // TODO: Display toast message.
                <Redirect to={pagePaths.theses.view(thesisId)} />
              )
          )}
        />
      )}
    </AccessControlledRender>
  )
}

const ThesisEditPageWrapper = (): JSX.Element => (
  <ValidatedPathParams<Props>
    expectedPathPattern={pagePaths.theses.edit()}
    expectedParams={{
      thesisId: {
        validate: (param: string) => !isNaN(parseInt(param, 10)),
        convert: (param: string) => parseInt(param, 10),
      },
    }}
  >
    {(params) => (
      <ThesisEditPage thesisId={params.thesisId} />
    )}
  </ValidatedPathParams>
)

export default ThesisEditPageWrapper
