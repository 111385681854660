import { cx } from 'linaria'
import { Thesis, ThesisMainState } from '@cvut/profit-api-types/lib/theses'

import { OptionType } from './types'
import { MultiselectFilter, MultiselectFilterProps } from './MultiselectFilter'
import { deriveMainState, isMainState } from '../../../utils/theses'
import * as style from './ThesisStatesFilter.style'


export const stateToClassMap: Record<ThesisMainState, string | null> = {
  draft: style.draft,
  proposed: style.proposed,
  assigned: style.assigned,
  review: style.review,
  readyForDefence: style.readyForDefence,
  evaluated: style.evaluated,
  archived: style.archived,
  deleted: null,
}


interface BallIconProps {
  optionValue: ThesisMainState
  isFilled: boolean
}

const BallIcon = ({ optionValue, isFilled }: BallIconProps) => (
  <div className={cx(style.stateIndicator, stateToClassMap[optionValue] ?? null)}>
    <div className={isFilled ? style.stateFill : undefined} />
  </div>
)


interface ClosedOptionsPanelProps {
  filterValue: string[]
  options: OptionType[]
}

// Show balls for main state filter options only
// Each ball is filled if the corresponding main state (or its substate) is filtered
const ClosedOptionsPanel = ({ filterValue, options }: ClosedOptionsPanelProps) => {
  const selectedDerivedMainStates = [...filterValue.reduce((set, value) => {
    return set.add(deriveMainState(value))
  }, new Set<string>())]

  return (
    <div className={style.selectLabelCentered}>
      {options
        .filter(option => isMainState(option.value))
        .map(option => (
          <BallIcon
            key={option.key}
            optionValue={option.value as ThesisMainState}
            isFilled={selectedDerivedMainStates.includes(deriveMainState(option.value))}
          />
        ))}
    </div>
  )
}

const ThesisStatesFilter = ({ column, options }: MultiselectFilterProps<Thesis>): JSX.Element => {
  const { filterValue } = column as { filterValue?: string[], setFilter: (filterValue?: string[]) => void }
  const currentFilterValue = filterValue ?? []

  return (
    <MultiselectFilter
      column={column}
      options={options}
      closedOptionsPanelComponent={
        <ClosedOptionsPanel
          filterValue={currentFilterValue}
          options={options}
        />
      }
    />
  )
}

export default ThesisStatesFilter
