import { css, cx } from 'linaria'

import { spaceFromTitle } from '../../../components/Card/Header.style'
import { transitionEasing } from '../../../styles/utils'
import * as buttonStyle from '../../../components/Button.style'
import * as colors from '../../../styles/colors'


const iconRadius = 0.5 // in rem
const barWidth = 0.3 // in rem


export const clickableCardHeader = css`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${spaceFromTitle};
  cursor: pointer;

  /* header with collapsed card body below should have no bottom margin */
  &:last-child {
    margin-bottom: 0;
  }

  /*
  header is the first child of clickable/collapsible card header and margin
  should here not be managed by the now wrapped header
  */
  & > :first-child {
    margin-bottom: 0;
  }

  svg {
    margin-left: 0.5rem;
  }
`

export const cardBodyContainer = css`
  height: 100%;
  max-height: 30rem;
  overflow: auto;
  transition: height ${transitionEasing};
`

export const datetime = css`
  vertical-align: top;
`

export const author = css`
  margin: 0;
`

export const title = css`
  margin: 0 0 0.5rem 0;
  font-size: 1rem;
`

export const description = css`
  margin: 0;
  font-style: italic;
`

export const thesisStateIcon = css`
   position: absolute;
   top: 0;
   left: 0;
   width: ${2 * iconRadius}em;
   height: ${2 * iconRadius}em;
   background: ${colors.primaryColor[1]};
   border-width: 0;
   border-radius: 50%;
`

export const thesisStateBar = css`
   position: absolute;
   top: ${iconRadius}rem;
   bottom: -${iconRadius}rem;
   left: calc(${iconRadius}rem);
   width: 0;
   margin-left: ${-barWidth / 2}rem;
   background-color: ${colors.primaryColor[1]}; /* to fix even zoom levels */
   border-right: ${barWidth / 2}rem solid ${colors.primaryColor[1]};
   border-left: ${barWidth / 2}rem solid ${colors.primaryColor[1]};
`

export const thesisStateItem = css`
  position: relative;
  padding-bottom: 1rem;
  padding-left: 2rem;

  &:last-child .${thesisStateBar} {
    border-style: none;
  }
`

export const thesisStatesList = css`
  margin: 0;
  padding: 0;
  list-style: none;
`

export const cardBodyContent = css`
  display: flex;
  flex-direction: column;
`

export const getMoreEventsButton = cx(buttonStyle.outlineDark, css`
  align-self: center;
`)
