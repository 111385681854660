import type { ColumnInstance } from 'react-table'
import { Enum } from 'typescript-string-enums'


export const ColumnSortState = Enum('ASCENDING', 'DESCENDING', 'UNSORTED')
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type ColumnSortState = Enum<typeof ColumnSortState>
export function getColumnSortState (column: ColumnInstance<any>): ColumnSortState {
  if (!column.isSorted) {
    return 'UNSORTED'
  }

  return column.isSortedDesc ? 'DESCENDING' : 'ASCENDING'
}
