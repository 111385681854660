import dedent from 'dedent'
import { objectOmit } from '@cvut/profit-utils'

import {
  reviewerSectionsOrder,
  supervisorSectionsOrder,
  ReviewerReportTexts,
  SupervisorReportTexts,
} from './internal'


const sections: (ReviewerReportTexts & SupervisorReportTexts)['sections'] = {
  assignmentDifficulty: {
    title: 'Difficulty and other comments on the assignment',
    description: dedent`
      Characterize this final thesis in detail and its relationships to previous or current \
      projects. Comment what is difficult about this thesis (in case of a more difficult thesis, \
      you may overlook some shortcomings that you would not in case of an easy assignment, and on \
      the contrary, with an easy assignment those shortcomings should be evaluated more strictly).`,
    type: 'choice',
    choices: {
      1: 'extremely challenging assignment',
      2: 'rather difficult assignment',
      3: 'assignment of average difficulty',
      4: 'easier, but still sufficient assignment',
      5: 'insufficient assignment',
    },
  },
  assignmentFulfillment: {
    title: 'Fulfilment of the assignment',
    description: dedent`
      Assess whether the thesis meets the assignment statement. In Comments indicate parts of the \
      assignment that have not been fulfilled, completely or partially, or extensions of the thesis \
      beyond the original assignment. If the assignment was not completely fulfilled, try to assess \
      the importance, impact, and possibly also the reason of the insufficiencies.`,
    type: 'choice',
    choices: {
      1: 'assignment fulfilled',
      2: 'assignment fulfilled with minor objections',
      3: 'assignment fulfilled with major objections',
      4: 'assignment not fulfilled',
    },
  },
  mainWrittenPartSize: {
    title: 'Size of the main written part',
    description: dedent`
      Compare the size of the written part with the expected size (without appendices), see the \
      Dean’s Directive No. 9/2011, Article 3. To evaluate the thesis it is also important that all \
      parts of the written part are rich on information and necessary for a final thesis. The text \
      should not contain unnecessary parts.`,
    type: 'choice',
    choices: {
      1: 'meets the criteria',
      2: 'meets the criteria with minor objections',
      3: 'meets the criteria with major objections',
      4: 'does not meet the criteria',
    },
  },
  factualAndLogicalLevel: {
    title: 'Factual and logical level of the thesis',
    description: dedent`
      Assess whether the thesis is correct as to the facts or if there are factual errors and \
      inaccuracies. Evaluate further the logical structure of the thesis, links among the chapters, \
      and the comprehensibility of the text for a reader.`,
    type: 'score',
  },
  formalLevel: {
    title: 'Formal level of the thesis',
    description: dedent`
      Assess the correctness of formalisms used in the thesis, the typographical and linguistic \
      aspects, see Dean’s Directive No. 9/2011, Article 3.`,
    type: 'score',
  },
  bibliography: {
    title: 'Bibliography',
    description: dedent`
      Evaluate the student’s activity in acquisition and use of studying materials in his thesis. \
      Characterize the choice of the sources. Discuss whether the student used all relevant \
      sources, or whether he tried to solve problems that were already solved. Verify that all \
      elements taken from other sources are properly differentiated from his own results and \
      contributions. Comment if there was a possible violation of the citation ethics and if the \
      bibliographical references are complete and in compliance with citation standards.`,
    type: 'score',
  },
  outcomeEvaluation: {
    title: 'Evaluation of results, publication outputs and awards',
    description: dedent`
      Comment on the achieved level of major results of the thesis and indicate whether the main \
      results of the thesis extend published state-of-the-art results and/or bring completely new \
      findings. Assess the quality and functionality of hardware or software solutions. \
      Alternatively, evaluate whether the software or source code that was not created by the \
      student himself was used in accordance with the license terms and copyright. Comment on \
      possible publication output or awards related to the thesis.`,
    type: 'score',
  },
  outcomeApplicability: {
    title: 'Applicability of the results',
    description: dedent`
      Indicate the potential of using the results of the thesis in practice.`,
    type: 'plain',
  },
  studentActivity: {
    title: 'Activity of the student',
    description: dedent`
      Review student’s activity while working on this final thesis, student’s punctuality when \
      meeting the deadlines and consulting continuously and also, student’s preparedness for these \
      consultations.`,
    type: 'choice',
    choices: {
      1: 'excellent activity',
      2: 'very good activity',
      3: 'average activity',
      4: 'weaker, but still sufficient activity',
      5: 'insufficient activity',
    },
  },
  studentSelfReliance: {
    title: 'Self-reliance of the student',
    description: dedent`
      Review student’s independency.`,
    type: 'choice',
    choices: {
      1: 'excellent self-reliance',
      2: 'very good self-reliance',
      3: 'average self-reliance',
      4: 'weaker, but still sufficient self-reliance',
      5: 'insufficient self-reliance',
    },
  },
  summary: {
    title: 'The overall evaluation',
    description: dedent`
      Summarize the parts of the thesis that had major impact on your evaluation. The overall \
      evaluation does not have to be the arithmetic mean or any other formula with the values from \
      the previous evaluation criteria.`,
    type: 'score',
  },
  defenseQuestions: {
    title: 'Questions for the defence',
    description: dedent`
      Formulate any question(s) that the student should answer to the committee during the defence \
      (use a bullet list).`,
    type: 'plain',
  },
}

export const reviewer: ReviewerReportTexts = {
  headers: {
    createdOn: 'Created on',
    evaluationCriteria: 'Evaluation criteria',
    instructions: 'Instructions',
    specialization: 'Branch / specialization',
    student: 'Student',
    thesisTitle: 'Thesis title',
    document: 'Review report of a final thesis',
    reportAuthor: 'Reviewer',
  },
  sections: objectOmit(sections, 'studentActivity', 'studentSelfReliance'),
  sectionsOrder: reviewerSectionsOrder,
}

export const supervisor: SupervisorReportTexts = {
  headers: {
    ...reviewer.headers,
    document: 'Supervisor’s statement of a final thesis',
    reportAuthor: 'Supervisor',
  },
  sections: objectOmit(sections, 'defenseQuestions'),
  sectionsOrder: supervisorSectionsOrder,
}
