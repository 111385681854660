import { css, cx } from 'linaria'

import { fontWeights } from '../../styles/typography'
import * as colors from '../../styles/colors'


// FIXME - common styles should go to extra file
import { thesesTable } from './ThesesTable.style'


export const acceptance = css`
  display: inline-block;
  width: 100%;
  font-weight: ${fontWeights.bold};
  text-align: center;
`

export const acceptanceAccepted = cx(css`
  color: ${colors.positiveColor[3]};
`, acceptance)

export const acceptancePending = cx(css`
  color: ${colors.neutralColor[3]};

  &::before {
    content: '\\2014';
  }
`, acceptance)

export const acceptanceRejected = cx(css`
  color: ${colors.negativeColor[2]};
`, acceptance)


export const supervisor = css`
  color: ${colors.neutralColor[2]};
  font-weight: ${fontWeights.bold};
`

export const reviewersTable = cx(css`
  /* FIXME - unify/remove... ideally add class names to cells and headers */

  table th:nth-child(1),
  table td:nth-child(1),
  table th:nth-child(2),
  table td:nth-child(2),
  table th:nth-child(3),
  table td:nth-child(3),
  table th:nth-child(4),
  table td:nth-child(4),
  table th:nth-child(5),
  table td:nth-child(5) {
    width: auto;
    max-width: none;
  }

  /* person columns */
  table td:nth-child(1):not(:last-child),
  table td:nth-child(4) {
    font-weight: unset;
    text-align: left;
  }

  /* empty set single-cell info */
  table td:first-child:last-child {
    text-align: center;
  }
`, thesesTable)
