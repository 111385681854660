import { cx, css } from 'linaria'

import { breakpoints, rel } from '../../styles/dimensions'


const section = css`
  h3 {
    font-size: ${rel(16)}rem;
    line-height: ${rel(20)}rem;

    @media screen and (min-width: ${breakpoints.md}) {
      font-size: ${rel(18)}rem;
      line-height: ${rel(22)}rem;
    }
  }
`

export const mainSection = cx(section, css`
  h3 {
    display: flex;
    align-items: center;
    margin-bottom: 0;

    svg {
      max-width: ${rel(18)}rem;
      margin-left: ${rel(10)}rem;
    }
  }

  & + & {
    margin-top: ${rel(8)}rem;

    @media screen and (min-width: ${breakpoints.md}) {
      margin-top: ${rel(16)}rem;
    }
  }
`)

export const sidebarSection = cx(section, css`
  &:not(:first-child) {
    margin-top: ${rel(16)}rem;
  }
`)

export const attachments = css`
  display: grid;
  gap: ${rel(8)}rem;
  margin: 0;
  padding: 0;
  list-style: none;
`
