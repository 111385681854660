import type { Page } from '../common'

/**
 * Extract type of `T`'s values.
 * @internal
 */
export type ValueOf<T extends object> = T[keyof T]

/**
 * Extracts keys of `T` recursively as a string union of dot-separated paths.
 *
 * @hidden JSON Schema generator cannot process this type
 */
export type ObjectPathsDeep<T extends {}> = {
  [K in keyof T & string]: T[K] extends {} ? K | `${K}.${ObjectPathsDeep<T[K]>}` : K
}[keyof T & string]

export const typed = <T> (obj: T): T => obj

export function omit <T extends object, K extends Extract<keyof T, string>> (
  obj: T, ...propsToOmit: K[]
): Omit<T, K> {
  const res = {} as T

  for (const key in obj) {
    if (!propsToOmit.includes(key as K)) {
      res[key] = obj[key]
    }
  }
  return res
}

export function pick <T extends object, K extends Extract<keyof T, string>> (
  obj: T, ...propsToPick: K[]
): Pick<T, K> {
  const res = {} as T

  for (const key of propsToPick) {
    res[key] = obj[key]
  }
  return res
}

// eslint-disable-next-line @typescript-eslint/no-unsafe-return
export const listStub = <T extends Page<object>> (data: T['data']): T => ({
  data: data.map((obj, idx) => {
    return 'id' in obj
      ? { ...obj, id: idx + 1 }
      : obj
  }),
  pagination: {
    hasNextPage: false,
  },
}) as any
