import { useEffect } from 'react'
import { formatPersonFullName } from '@cvut/profit-theses-common'
import type { SpecializationRef, StudyDegree } from '@cvut/profit-api-types/lib/theses/specialization'
import type { Topic, TopicFilter, TopicPatch } from '@cvut/profit-api-types/lib/theses/topic'

import AccessControlledRender from '../../access-control/AccessControlledRender'
import { errorCardRenderer } from '../../components/RequestErrorCard'
import PageHeader from '../../components/PageHeader'
import PageLoadSpinner from '../../components/PageLoadSpinner'
import TopicSearch from '../../features/topics/TopicSearch'
import { usePaginatedTopics, useTopicsMeta, useUpdateTopic } from '../../api/topics'
import { getPeople } from '../../api/people'
import { useQueryAsFilter } from '../../api/filter'
import RequestDependentRender from '../../api/RequestDependentRender'
import pagePaths from '../paths'
import { useLocale } from '../../locale'


const TOPICS_PAGE_SIZE = 20

interface Props {
  myTopics?: boolean
}

const TopicSearchPage = ({ myTopics = false }: Props): JSX.Element => {
  const { l } = useLocale()
  const [topicsMetaResult, getTopicsMeta] = useTopicsMeta()
  useEffect(() => { void getTopicsMeta() }, [])
  const [filter, setFilter] = useQueryAsFilter<TopicFilter>([
    'keywords', 'specializations', 'studyDegrees', 'visibility',
  ])
  const [topicsStatus, noMoreTopics, getMoreTopics] = usePaginatedTopics(TOPICS_PAGE_SIZE, filter, myTopics)
  useEffect(() => {
    if (topicsStatus.state === 'not-initiated') {
      getMoreTopics()
    }
  }, [topicsStatus.state])
  const [, patchTopic] = useUpdateTopic()

  const handleQueryPeople = async (q: string) => {
    const [, personList] = await getPeople({ includeInactive: false, q })

    return personList.data
  }

  const handleSetTopicVisibility = async (topicId: Topic['id'], visibility: TopicPatch['visibility']) => (
    await patchTopic(topicId, { visibility })
  )

  return (
    <AccessControlledRender loaderColor='primary'>
      {(ac) => (
        <RequestDependentRender
          requestStatus={topicsMetaResult}
          renderOnLoading={() => <PageLoadSpinner message={l.topic.loadingTopics} />}
          renderOnError={errorCardRenderer(l.topic.errorMessages.getMetadata, pagePaths.topics.search)}
          renderOnSuccess={({ properties: { specializations, studyDegrees } }) => {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            const programs = studyDegrees.values.map(p => p!) as StudyDegree[]
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            const specs = specializations.values.map(specializationCode => ({ code: specializationCode! }))

            return (
              <>
                <PageHeader
                  title={myTopics ? l.topic.search.title.myTopics : l.topic.search.title.publicTopics}
                  subtitles={myTopics ? [formatPersonFullName(ac.person)] : undefined}
                />
                <TopicSearch
                  results={topicsStatus.data.map(t => ({
                    ...t,
                    modifiedAt: new Date(t.modifiedAt),
                    specializations: (t.specializations as SpecializationRef[]).map(s => s.code),
                  }))}
                  getMoreTopics={noMoreTopics ? undefined : async () => await Promise.resolve(getMoreTopics())}
                  filterValue={filter}
                  onFilterValueChange={setFilter}
                  programs={programs}
                  specializations={specs}
                  queryPeople={myTopics ? undefined : handleQueryPeople}
                  topicLinkCreator={t => pagePaths.topics.view(t.id)}
                  updateTopicVisibility={myTopics ? handleSetTopicVisibility : undefined}
                  myTopics={myTopics}
                />
              </>
            )
          }}
        />
      )}
    </AccessControlledRender>
  )
}

export default TopicSearchPage
