import { Enum } from 'typescript-string-enums'
import {
  Language, LicenseType, ThesisAction, ThesisLanguage, ThesisMainState, StudyDegree, ThesisReportType, PersonSettings,
  TopicVisibility,
} from '@cvut/profit-api-types/lib/theses'
import { Except, objectOmit } from '@cvut/profit-utils'

import { LocalizedThesisState } from '../types/thesis'


const thesisStates: Record<LocalizedThesisState, string> = {
  'proposed.awaitsSpecOfficerApproval': 'Assignment awaits approval by the specialization officer',
  'proposed.revision': 'Assignment returned for revision',
  'proposed.awaitsAssigneeAcceptance': 'Assignment awaits acceptance by the assignee',
  'proposed.acceptedByAssignee': 'Assignment accepted by the assignee',
  'proposed.rejectedByAssignee': 'Assignment rejected by the assignee',
  'changeable.assigned.reviewer.pending': 'Has no reviewer',
  'changeable.assigned.reviewer.assigned': 'Reviewer assigned',
  'changeable.assigned.submission.pending': 'Awaiting submission',
  'changeable.assigned.submission.awaitsPrintsApproval':
    'Awaiting approval of the printed copies by the Final Thesis Officer',
  'changeable.assigned.submission.printsApproved': 'Printed copies approved the Final Thesis Officer',
  'changeable.review.supervisor.pending': 'Awaits the supervisor\'s report',
  'changeable.review.supervisor.submitted': 'Has the supervisor\'s report',
  'changeable.review.reviewer.pending': 'Awaits the reviewer report',
  'changeable.review.reviewer.submitted': 'Has the reviewer report',
}

export const ThesisPanelActions = objectOmit(ThesisAction,
  'CREATE', 'READ', 'READ_FILES', 'LIST', 'UPDATE', 'ARCHIVE', 'ASSIGN_DIRECTLY', 'REQUEST_APPROVAL')
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type ThesisPanelActions = Enum<typeof ThesisPanelActions>

const licenseTypeOptions: Record<LicenseType, string> = {
  open: 'Open',
  restricted: 'Restricted',
}

const thesisLanguageOptions: Record<ThesisLanguage, string> = {
  cs: 'Czech',
  en: 'English',
  sk: 'Slovak',
}

const thesisTitleInLanguage: Record<Language, string> = {
  cs: 'Title in Czech',
  en: 'Title in English',
}

const thesisMainState: Record<ThesisMainState, string> = {
  draft: 'Draft',
  proposed: 'Proposed',
  assigned: 'Assigned',
  review: 'Review',
  readyForDefence: 'Ready for defence',
  evaluated: 'Evaluated',
  archived: 'Archived',
  deleted: 'Deleted',
}

// Why not "Bachelor's"? I used what I found on SFE website
// (https://courses.fit.cvut.cz/SFE/thesis-topic.html).
//   ~Tung
const thesisTypeOption: Record<StudyDegree, string> = {
  bachelor: 'Bachelor thesis',
  master: 'Master thesis',
}

const thesisTypeOptionInitialism: Record<StudyDegree, string> = {
  bachelor: 'BT',
  master: 'MT',
}

// `'EVALUATE'` is defined in `ThesisEvent` in profit-theses-backend.
const thesisActionTitle: Except<Record<ThesisAction | 'EVALUATE', string>,
'READ' | 'READ_FILES' | 'LIST' | 'UPDATE'> = {
  ARCHIVE: 'Thesis archived',
  ASSIGN_DIRECTLY: 'Thesis assigned directly',
  ASSIGNEE_ACCEPT: 'Accepted by the student',
  ASSIGNEE_REJECT: 'Rejected by the student',
  CREATE: 'Thesis created',
  DELETE: 'Thesis deleted',
  OFFICER_APPROVE: 'Approved by a specialization officer',
  PROPOSE: 'Proposed for approval',
  REVISE: 'Assignment revised',
  ASSIGN_REVIEWER: 'Reviewer assigned',
  SUBMIT: 'Thesis submitted',
  REQUEST_APPROVAL: 'Requested approval of the printed copies',
  APPROVE_PRINTS: 'Printed copies approved',
  SUBMIT_REVIEWER_REPORT: 'Reviewer\'s report submitted',
  SUBMIT_SUPERVISOR_REPORT: 'Supervisor\'s report submitted',
  EVALUATE: 'Thesis evaluated',
  SUPERSEDE: 'Thesis superseded by another',
  WITHDRAW: 'Assignment withdrawn',
}

const appName = 'Projects FIT'

const panelActions: Record<ThesisPanelActions, string> = {
  PROPOSE: 'Edit and propose',
  OFFICER_APPROVE: 'Approve',
  REVISE: 'Return for revision',
  ASSIGNEE_ACCEPT: 'Accept',
  ASSIGNEE_REJECT: 'Reject',
  WITHDRAW: 'Abort approval',
  DELETE: 'Delete assignment',
  ASSIGN_REVIEWER: 'Propose reviewer',
  SUBMIT: 'Submit thesis',
  APPROVE_PRINTS: 'Approve printed copies',
  SUBMIT_SUPERVISOR_REPORT: 'Submit report',
  SUBMIT_REVIEWER_REPORT: 'Submit report',
  SUPERSEDE: 'Duplicate and supersede',
}

const evaluationCardReportType: Record<ThesisReportType, string> = {
  reviewer: 'Reviewer’s report',
  supervisor: 'Supervisor’s report',
}

const evaluationCardDisplayReportTitle: Record<ThesisReportType, string> = {
  reviewer: 'Display reviewer’s thesis report',
  supervisor: 'Display supervisor’s thesis report',
}

const studyDegree: Record<StudyDegree, string> = {
  bachelor: 'Bachelor',
  master: 'Master',
}

const userSettingsLabels: Record<keyof PersonSettings, string> = {
  noticeEmailsEnabled: 'Receive notifications by e-mail',
  taskEmailsEnabled: 'Receive tasks by e-mail',
}

const topicVisibilityOptions: Record<TopicVisibility, string> = {
  private: 'Private',
  public: 'Public',
  deleted: 'Deleted',
}

export default {
  appName,
  configuration: {
    configuration: 'Configuration',
    currentSemester: 'Current semester',
    editConfiguration: 'Edit configuration',
    errorGettingConfiguration: 'An error has occurred while loading configuration',
    loadingConfiguration: 'Loading configuration',
    reportDeadlineBachelor: 'Deadline for bachelor thesis reports submission',
    reportDeadlineMaster: 'Deadline for master thesis reports submission',
  },
  errorMessages: {
    networkError: (url: string): string => `A network error has occurred while sending a request to '${url}'.`,
    api: {
      errorGettingData: 'An error has occurred while getting data',
      forbidden: (objectType: string, id: string): string => (
        `You do not have permission to access the instance of '${objectType}' with id '${id}'`
      ),
      notFound: (objectType: string, id: string): string => (
        `The instance of '${objectType}' with id '${id}' was not found`
      ),
      defaultGetList: (objectType: string): string => (
        `An error has occurred while getting a list of objects '${objectType}'`
      ),
      defaultCreate: (objectType: string): string => (
        `An error has occurred while creating an instance of '${objectType}'`
      ),
      defaultPatch: (objectType: string, id: string): string => (
        `An error has occurred while updating the instance of '${objectType}' with id '${id}'`
      ),
      defaultPatchList: (objectType: string): string => (
        `An error has occurred while updating a list of objects '${objectType}'`
      ),
      defaultDelete: (objectType: string, id: string): string => (
        `An error has occurred while deleting the instance of '${objectType}' with id '${id}'`
      ),
      defaultDownload: (objectType: string, id: string): string => (
        `An error has occurred while downloading '${objectType}' with id '${id}'`
      ),
      defaultUpload: (objectType: string, id: string): string => (
        `An error has occurred while uploading file for '${objectType}' with id '${id}'`
      ),
      defaultThesisActionsErrorMessage: 'Request for action has failed.',
    },
  },
  successMessages: {
    api: {
      defaultCreate: (objectType: string): string => (
        `An object of type '${objectType}' was created`
      ),
      defaultPatch: (objectType: string, id: string): string => (
        `An object of type '${objectType}' with id '${id}' was updated`
      ),
      defaultDelete: (objectType: string, id: string): string => (
        `An object of type '${objectType}' with id '${id}' was deleted`
      ),
    },
  },
  homepage: {
    welcome: `Welcome to the ${appName} application!`,
    loginRequest: 'Please log in to access more actions.',
  },
  userSettings: {
    title: 'User settings',
    notificationSettings: {
      title: 'Notification settings',
      goTo: 'Go to notification settings',
    },
    labels: userSettingsLabels,
    loading: 'Loading user settings',
    noUserSettings: 'You do not have any user settings',
    requestMessages: {
      success: 'Settings saved',
      error: 'An error has occurred while saving settings',
      saving: 'Saving settings…',
    },
  },
  langSwitcher: {
    tooltip: 'Change app language',
  },
  person: 'Person',
  personSearchBox: {
    error: 'An error has occurred while searching people',
    loading: 'Searching people…',
    selectNone: 'Select none',
    noResults: 'No person found',
    noSpecialization: 'No specialization chosen yet',
    selectedPerson: 'Selected person',
    hasThesisAssigned: 'Already has a thesis assigned',
  },
  siteHeader: {
    theses: 'Theses',
    configuration: 'Configuration',
    createThesis: 'Create a thesis',
    home: 'Homepage',
    seeMyTheses: 'My theses',
    searchTheses: 'Search theses',
    searchThesesAsOfficer: 'Search theses (FTO)',
    seeNotices: 'See notifications',
    seeTasks: 'See my tasks',
    profile: 'Profile',
    logout: 'Log out',
    login: 'Log in',
    youAreNotLoggedIn: 'You are not logged in',
    expandMenu: 'Open menu',
    closeMenu: 'Close menu',
    expand: 'Open',
    close: 'Close',
    navigation: 'Website navigation',
    topics: 'Topics',
    createTopic: 'Create a topic',
    myTopics: 'My topics',
    searchTopics: 'Search topics',
  },
  notification: {
    errorMessages: {
      getStatus: 'An error has occurred while getting the notifications status',
      getMetadata: 'An error has occurred while loading notifications metadata',
    },
    notification: 'Notification',
    notices: {
      title: 'Notices',
      loading: 'Loading notices…',
      errorGetAll: 'An error has occurred while getting notices.',
      noResults: 'You currently do not have any notices.',
    },
    tasks: {
      title: 'Tasks',
      loading: 'Loading task…',
      errorGetAll: 'An error has occurred while getting tasks.',
      noResults: 'You currently do not have any tasks.',
    },
  },
  study: 'Study',
  toasts: {
    close: 'Close the toast notification',
  },
  studyDegree: studyDegree,
  thesis: {
    thesis: 'Thesis',

    assignment: 'Assignment',
    description: 'Description',
    titleInLanguage: thesisTitleInLanguage,
    thesisType: 'Thesis type',
    thesisTypeOption,
    thesisTypeOptionInitialism,
    thesisLanguage: 'Thesis language',
    language: 'Language',
    supervisorWillProposeReviewer: 'Reviewer will be assigned by the supervisor',
    validUntil: 'Valid until',
    validUntilTooltip: 'Thesis is valid until this semester',
    languageOptions: thesisLanguageOptions,
    licenseType: 'License type',
    licenseTypeOptions,
    archiveThesis: 'Archive thesis',

    theses: 'Theses',
    loadingTheses: 'Loading theses',
    errorGettingTheses: 'An error has occurred while downloading theses',
    noTheses: 'No theses',
    details: 'Details',

    edit: 'Edit',
    download: 'Download',

    secondaryOptions: 'Other options',
    loadingThesis: 'Loading thesis',
    errorGettingThesis: 'An error has occurred while downloading the thesis',

    selectLicenseTypePrompt: 'Please select license type',
    selectLanguagePrompt: 'Please select a language',

    editAssignment: 'Edit thesis assignment',
    delete: 'Delete',
    errorDeletingThesis: 'An error has occurred while deleting the thesis',
    save: 'Save',
    errorSavingThesis: 'An error has occurred while saving the thesis',
    proposeActionsButton: 'Propose for approval',
    proposeActionsSuccessMessage: 'Proposal for approval has succeeded',
    proposeHelp: 'To be able to propose a work for approval, fill in all the fields, assign a student and '
    + 'save the work.',
    proposeActionsModal: {
      proposeActionsPositiveButton: 'Yes',
      proposeActionsTitle: 'Are you sure you want to propose thesis assignment for approval?',
    },
    officerPrintValidationModal: {
      title: 'Are you sure you want to approve thesis assignment print?',
      actionNo: 'No, cancel',
      actionYes: 'Yes, approve',
    },
    deleteActionModal: {
      title: 'Delete thesis',
      text: 'Are you sure you want to delete this thesis? This action is irreversible.',
      positiveButton: 'Yes',
    },
    reviewerProposalAcceptancePanel: {
      title: 'Reviewer role acceptance',
      description: 'Do you accept the proposal to review this thesis?',
    },

    creation: 'Create assignment',
    errorCreatingThesis: 'An error has occurred while creating the thesis',

    backupRestoreDialog: {
      title: 'Backup found',
      text: 'There has been found a backup of last unsaved version. Do you want '
          + 'to restore form contents from this backup?',
      forget: 'Forget changes',
      restore: 'Restore changes',
    },

    state: 'State',
    states: 'Substates',
    substate: thesisStates,

    people: 'People',
    reviewer: 'Reviewer',
    student: 'Student',
    supervisor: 'Supervisor',
    unassigned: 'Unassigned',

    specialization: 'Specialization',
    status: 'State',
    title: 'Title',
    type: 'Type', // Kind?
    approvalDate: 'Approval date',
    approvalDateShort: 'Approved',
    evaluationYear: 'Evaluation year',

    assignee: {
      student: 'Student',
      noStudentAssigned: 'No student assigned to this thesis assignment',
      placeholder: 'Enter student\'s name or username',
      searchingStudents: 'Searching students…',
      errorSearchingStudents: 'An error has occurred while searching students',
      noStudentFound: 'No student found',
      assignedStudent: 'Assigned student',
      clearAssignee: 'Remove assignee',
      edit: 'Edit',
      close: 'Close',
    },
    mainState: thesisMainState,

    submission: {
      errorMessages: {
        attachmentAlreadyExists: (name: string): string => `Attachment with name "${name}" already exists`,
        attachmentFilenameShouldMatch: (name: string): string => (
          `This attachment upload should continue with file "${name}"`
        ),
        attachmentSizeExceedsLimit: (size: number | string): string => (
          `File exceeds maximum allowed size limit (${size})`
        ),
        onlyAssigneeCanSubmit: 'Only the thesis’ assignee can submit the thesis',
        submitOnlyAssignedStateTheses: `Thesis can only be submitted in '${thesisMainState.assigned}' state`,
        thesisNotSubmitted: 'Thesis has not yet been submitted in KOS',
        invalidURL: 'Must be a valid URL',
        uploadFailure: 'An error occurred while uploading attachments',
      },
      successMessages: {
        save: 'Final thesis was saved successfully',
        submit: 'Final thesis was submitted successfully',
        upload: 'File attachments have been successfully uploaded',
      },
      title: 'Thesis submission',
      subTitle: 'Data for thesis submission',
      view: {
        title: 'Submission',
        label: {
          abstract: 'Abstract',
          keywords: 'Keywords',
          thesisFile: 'Thesis',
          attachments: 'Attachments',
          dspace: 'Thesis DSpace page',
        },
        thesisFileTitle: 'Submitted thesis',
      },
      form: {
        label: {
          inputs: {
            abstractCs: 'Abstract in Czech',
            abstractEn: 'Abstract in English',
            keywordsCs: 'Keywords in Czech',
            keywordsEn: 'Keywords in English',
          },
          files: {
            finalText: 'Final thesis PDF file',
          },
          attachments: {
            title: 'Attachments',
            noResult: 'Thesis has no attachments',
            attachment: {
              titleCs: 'Title in Czech',
              titleEn: 'Title in English',
              type: 'Type',
              href: 'URL',
              file: 'File',
            },
            uploadState: {
              complete: 'Complete',
              error: 'Error during upload',
              waiting: 'Waiting',
            },
          },
        },
        attachmentType: {
          sourceCode: {
            option: 'source code',
            title: 'source code',
          },
          dataset: {
            option: 'dataset',
            title: 'dataset created or used as part of the work',
          },
          website: {
            option: 'website',
            title: 'website of the project',
          },
          demo: {
            option: 'demo',
            title: 'functional demo of the created software work',
          },
          paper: {
            option: 'scientific paper',
            title: 'scientific paper published as part of the work',
          },
          other: {
            option: 'custom attachment type',
            title: '',
          },
        },
        help: {
          keywords: 'use comma as keywords separator',
          thesisFile: 'file size up to 50MiB',
        },
        modal: {
          title: 'Are you sure you want to submit the Thesis?',
          description: 'Check all content before submission, it will no longer be editable once submitted.',
          requiredInfo: '* Required to submit thesis',
        },
        submitButton: 'Submit Thesis',
        attachmentButtons: {
          addLink: 'Add link',
          addFile: 'Add file',
          remove: 'Remove attachment',
        },
      },
    },

    supersession: {
      confirmationTitle: 'Archive this assignment and create a duplicate superseding it?',
      errorMessage: 'Error superseding current assignment with a duplicate',
      loadingMessage: 'Creating assignment duplicate superseding the current one...',
      successMessage: 'Successfully created a duplicate superseding the original assignment',
    },
    reviewerPageTitle: 'Final thesis reviewer candidates',
    candidatesList: 'Candidates list',

    actionTitle: thesisActionTitle,

    reports: {
      thesisStudentLabel: 'Student:',
      thesisTitleLabel: 'Title:',
      thesisBranchLabel: 'Branch / specialization:',
      supervisorReportTitle: 'Supervisor’s statement of a final thesis',
      supervisorReportNameLabel: 'Supervisor:',
      reviewerReportTitle: 'Review report of a final thesis',
      reviewerReportNameLabel: 'Reviewer:',
      choiceInfo: (length: number): string => `Evaluation method: the following scale 1 to ${length}.`,
      pointsInfo: 'Evaluation method: 0 to 100 points (marks A to F).',
      evaluationLabel: 'Evaluation',
      commentLabel: 'Comment',
      language: 'Report language',
      saveSuccessToast: 'Report was saved',
      checkPdfButton: 'Check PDF',
      submitButton: 'Submit report',
      submitModalTitle: 'Are you sure you want to submit the report?',
      submitModalText: 'Check the text before submission, it will no longer be editable once submitted.',
      supervisorSubmitModalText: 'Does the assignment text in the resulting PDF match the approved assignment?',
      submitModalCancelButton: 'Back',
      submitSuccessToast: 'Report was submitted successfully',
      submitSupervisorInfo: 'Report can be submitted after confirmation that the submitted assignment '
        + 'matches the assignment in ProjectsFIT.',
      submitRequiredInfo: '* Required to submit report',

      loadingReport: 'Loading report',

      errorMessages: {
        load: 'An error occurred while loading thesis report',
        save: 'An error occurred while saving thesis report',
        submit: 'An error occurred while submitting thesis report',
      },

      download: 'Download report',

      evaluationCard: {
        loading: 'Loading thesis reports',
        errorGettingReports: 'An error has occurred while getting thesis reports',
        noReports: 'This thesis does not have any reports',
        reportType: evaluationCardReportType,
        displayLinkTitle: evaluationCardDisplayReportTitle,
        pointsAbbrev: 'pts.',
        finalLabel: 'Final',
      },

      deadline: {
        header: 'Deadline for report submission',
        loadingMessage: 'Loading report deadline',
        loadingError: 'An error occurred while loading report deadline',
      },
    },
  },
  thesesFilters: {
    withoutReviewer: 'Without reviewer',
  },
  errorGettingThesesMetadata: 'An error has occurred while getting theses metadata',
  thesisReviewerProposal: {
    actions: {
      title: {
        applied: 'You applied for reviewer of this thesis',
        proposeMyself: 'You can apply for the reviewer position',
        proposedBy: (name: string): string => `${name} proposed you as the reviewer of this thesis`,
        reviewerProposal: 'Application for reviewer position',
      },
      proposeMyself: 'Apply for reviewer position',
      proposalAccepted: 'You have applied for reviewer position',
      accept: 'Accept',
      cancel: 'Cancel',
      reject: 'Reject',
    },
    search: {
      title: 'Propose a new reviewer candidate',
      placeholder: 'Search…',
      searching: 'Searching people…',
      error: 'An error has occurred while searching people',
      noResults: 'No person found',
    },
    errorMessages: {
      propose: 'An error has occurred while proposing new reviewer candidate',
      accept: 'An error has occurred while accepting reviewer candidate proposal',
      cancel: 'An error has occurred while removing reviewer candidate proposal',
    },
    successMessages: {
      propose: (name: string): string => `${name} has been proposed as a reviewer candidate`,
      proposeMyself: 'Your application for reviewer position has been registered',
      // FIXME: unable to compare with the old system; it would be nice to go through the messages
      // with a native speaker with some copywriting skills
      accept: 'Your application for reviewer position has been accepted',
      cancel: 'Your application for reviewer position has been withdrawn',
    },
  },
  thesisReviewerProposalsList: {
    errorMessages: {
      load: 'An error has occurred while loading list of reviewer proposals',
      reject: 'An error has occurred while rejecting reviewer candidate proposal',
    },
    successMessages: {
      accept: 'Reviewer candidate has been approved',
      reject: 'Reviewer candidate has been disapproved',
      cancel: 'Reviewer candidate has been removed',
    },
    emptyResultsMessage: 'No review proposals',
    reviewerAcceptanceCaption: {
      accepted: 'Yes',
      pending: '',
      rejected: 'No',
    },
    columnCaptions: {
      name: 'Name',
      reviewerAcceptance: 'Accepted by candidate',
      specOfficerApproval: 'Approved by department',
      proponent: 'Proposal author',
    },
    actions: {
      approve: 'Approve',
      disapprove: 'Disapprove',
      remove: 'Remove candidate',
      canceledByProponent: 'Withdrawn by proponent',
      rejectedByReviewerCandidate: 'Rejected by the candidate',
      rejectedBySpecOfficer: 'Disapproved by the specialization officer',
      waitingForSpecOfficerApproval: 'Waiting for specialization officer approval',
      waitingForCandidatesAcceptance: 'Waiting for candidate\'s acceptance',
    },
  },
  navigation: {
    goBack: 'Go back',
    displayMore: 'Display more',
    displayLess: 'Display less',
    invalidPath: {
      title: 'Current path is invalid',
      currentPath: 'Current path',
      correctFormat: 'Correct path format',
    },
  },
  defaultStatus: 'An error has occurred',
  pageStatus: {
    403: {
      title: 'Permission denied',
      message: 'You do not have sufficient permissions for this action or page',
    },
    404: {
      title: 'Page not found',
      message: 'No such page',
    },
  },
  pagination: {
    loadMore: 'Load more',
  },
  modals: {
    cancelCaption: 'Cancel',
    closeModal: 'Close dialog',
    defaultPositiveCaption: 'Accept',
    defaultNegativeCaption: 'Reject',
  },
  thesesList: {
    // TODO: Use `filters` one level higher.
    filters: {
      search: 'Search…',
      select: 'Select…',
      selected: 'Selected ',
      reset: 'Reset filters',
      expandMenu: 'Expand menu',
    },
    newAssignment: 'New assignment',
    newAssignmentTooltip: 'Create new assignment',
    editThesis: 'Edit thesis',
    exportCsv: 'Export to CSV',
    sortAscending: 'Sort ascending',
    sortDescending: 'Sort descending',
    sortReset: 'Disable sort',
    emptyResultsMessage: 'No theses match the given criteria',
    myThesesTitle: 'My final theses',
    allThesesTitle: 'Final theses search',
    officerThesesTitle: 'Final theses search – final theses officer',
    verifiedByFtOfficer: 'AC',
    printApproved: 'PC',
    visitThesis: (thesisTitle: string): string => (`Visit thesis “${thesisTitle}”`),
  },

  specialization: {
    specialization: 'Specialization',
    loadingSpecializations: 'Loading specializations',
    errorLoadingSpecializations: 'An error has occurred while getting specializations',
  },

  topic: {
    topic: 'Topic',
    loadingTopic: 'Loading topic',
    loadingTopics: 'Loading topics',
    create: 'Create topic',
    edit: 'Edit topic',
    delete: 'Delete',
    errorMessages: {
      get: 'An error has occurred while downloading the topic',
      getMetadata: 'An error has occurred while getting topics metadata',
      onlyAuthorCanEdit: 'Only the topics’ author can edit the topic',
    },
    recommendedGroupTitle: 'Recommended for',
    recommendedGroupHint: 'Study program(s) or study specialization(s) for which this topic is intended can be selected'
    + ' here. Values can only be selected in one of the lists at a time.',
    author: 'Author',
    title: 'Title',
    description: 'Description',
    email: 'e-mail',
    keywords: 'Keywords',
    language: 'Topic language',
    languageOptions: thesisLanguageOptions,
    assignmentLanguageNote: 'Thesis language can usually be agreed with its supervisor',
    selectLanguagePrompt: 'Please select a language',
    specialization: 'Specialization',
    selectSpecializationPrompt: 'Please select a specialization',
    studyDegree: 'Study program',
    studyDegreeOptions: studyDegree,
    selectStudyDegreePrompt: 'Please select a degree',
    visibility: 'Visibility',
    visibilityOptions: topicVisibilityOptions,
    selectVisibilityPrompt: 'Please select topic visibility',
    thesesList: 'Theses list',
    emptyThesesListMessage: 'No theses connected with this topic',
    actions: 'Actions',
    createAssignment: 'Create thesis assignment',
    search: {
      title: {
        publicTopics: 'Search topics',
        myTopics: 'My topics',
      },
      filter: {
        author: 'Author',
        authorNoResult: 'Nobody was found',
        authorPlaceholder: 'Search…',
        visibility: 'Visibility',
        specialization: 'Specialization',
        studyDegree: 'Study programme',
        q: 'Search in title, description, and keywords',

        noResult: 'No results',
        select: 'Select…',
      },
      results: {
        author: 'Author',
        published: 'Publication date',
        specializations: 'Suitable specializations',
        studyDegrees: 'Suitable study programs',

        noResults: 'There is no topic satisfying your query',
      },
    },
    errorGettingTopic: 'An error has occurred while downloading the topic',
    missingStudentError: 'There must be a student assigned to save the thesis',
    unsuitableSpecialization: 'Selected student does not have a suitable specialization',
    deleteActionModal: {
      title: 'Are you sure you want to delete this topic?',
      text: 'This action is irreversible',
      positiveButton: 'Yes',
    },
  },

  auth: {
    gettingUserInfo: 'Getting user info',
    loginSuccessful: 'You have been successfully logged in',
    logoutSuccessful: 'You have been successfully logged out',
    authenticationFailed: 'An error has occurred during the authentication process',
    errorRetrievingOAuthToken: 'An error has occurred while retrieving an authentication token',
    errorGettingUserInfo: 'An error has occurred while getting user information',
    errorLoggingOut: 'An error has occurred while logging out',
  },
  accessControl: {
    noPermission: (action: string): string => (
      `You do not have permission to perform the '${action}' action`
    ),
    noPermissionDefault: 'You do not have permission to view this page',
    reportAlreadySubmitted: 'Report already submitted',
  },

  filter: {
    reset: 'Reset filters',
  },

  form: {
    all: 'All',
    input: {
      characterCount: 'Character count',
    },
    required: 'Field is required',
    formInfoRequired: '* Field is required',
    formUnsaved: 'unsaved',
    formIsInvalid: 'Form contains field with invalid value',
    // Generic submit button text.
    save: 'Save',
    saving: 'Saving',
    submit: 'Submit',
    discardChanges: 'Discard changes',
    validation: {
      maxKeywordsCount: (count: number): string => `At most ${count} keywords are allowed`,
      maxLength: (count: number): string => `At most ${count} characters per keyword is allowed`,
      minLength: (count: number): string => `At least ${count} characters are required`,
      minValue: (min: number): string => (`Minimum value is ${min}`),
      maxValue: (max: number): string => (`Maximum value is ${max}`),
      integerOnly: 'Integer value only',
      semesterCode: 'The semester code has the form CYYS, where C denotes the century '
      + '(A = 20th century, B = 21st century), YY the two digits of the year and S the semester '
      + '(1 = winter, 2 = summer). For example, the winter semester of 2010 is called B101.',
    },
    leaveBeforeSavingWarning: 'You have unsaved changes. Are you sure you want to leave?',
  },

  eventLog: {
    title: 'Event log',
    loadingEventLog: 'Loading the event log',
    getMoreEvents: 'Get more events',
    errorGettingEventLog: (resourceType: string, resourceId: number): string => (
      `An error has occurred while getting the event log of resource '${resourceType}' with id '${resourceId}'`
    ),
    unknownAction: (action: string): string => (
      `Unknown action '${action}'`
    ),
  },

  actionPanel: {
    action: panelActions,
    panelId: {
      assignmentSupervisorProposal: {
        title: 'Propose the assignment for approval',
        description: 'The assignment is waiting to be proposed for approval. Make sure that the student agrees with '
        + 'the wording. Then go to the editing page where you have an action to propose the assignment for approval.',
      },
      assignmentSpecOfficerApproval: {
        title: 'Assignment approval',
        description: 'The assignment is waiting for your approval. If you do not agree with the content, return the '
        + 'assignment for revision and state in the comment, what changes are required.',
      },
      assignmentSupervisorWaitingForApproval: {
        title: 'The assignment is waiting for approval',
        description: 'The assignment needs to be approved by the specialization officer and accepted by the student. '
        + 'In the meantine, you can revise the assignment. You can also abort the approval process and return the '
        + 'entry to the Draft state.',
      },
      assignmentSupervisorRevision: {
        title: 'Assignment revision',
        description: 'If the specialization officer has returned your assignment for revision, it is mandatory to add '
        + 'the necessary changes. If it was you, who initiated the revision, you now have the option to edit the '
        + 'assignment. Once you are done with editing, propose the assignment for approval again. '
        + 'You can also abort the approval.',
      },
      assignmentAssigneeAcceptance: {
        title: 'Assignment acceptance',
        description: 'The assignment of the final thesis is waiting for your binding acceptance. If you do not agree '
        + 'with the wording, contact your supervisor. If you do not want to work on the assignment, reject it.',
      },
      assignmentAssigneeAcceptanceSupervisor: {
        title: 'Assignment acceptance',
        description: 'The assignment of the final thesis is waiting for student’s acceptance. '
        + 'If you want to edit the assignment, you can abort the approval.',
      },
      assignmentAssigneeSecondAcceptance: {
        title: 'Assignment acceptance (after rejection)',
        description: 'You have rejected to work on the assignment and currently, no action is required on your part. '
        + 'However, you still have the option to accept.',
      },
      assignmentRejectedByStudent: {
        title: 'Assignment rejected by the student',
        description: 'The student has rejected the assignment, but still has the option to accept if you come to '
        + 'agreement. To edit the assignment and assign someone else, abort the approval.',
      },
      thesisSupervisorReviewerProposal: {
        title: 'Reviewer proposal',
        description: 'The thesis is waiting for an assignment of a reviewer. You can propose a reviewer yourself.',
      },
      thesisAssigneeSubmission: {
        title: 'Thesis submission',
        description: 'You are expected to submit your thesis.',
      },
      thesisAssigneeResubmission: {
        title: 'Thesis resubmission',
        description: 'If the supervisor any has issues with your work, you have the opportunity to edit the work and '
        + 'upload a new version. At the same time, you have the right not to resolve the issues, but this will '
        + 'probably be reflected in the supervisor\'s report.',
      },
      thesisPrintApprovalAcceptance: {
        title: 'Waiting for assessment',
        description: 'Your thesis is now submitted. You will be awarded your assessment in next hours or days.',
      },
      thesisSupervisorReport: {
        title: 'Supervisor report submission',
        description: 'You are expected to submit your report.',
      },
      thesisReviewerReport: {
        title: 'Reviewer report submission',
        description: 'You are expected to submit your report.',
      },
      reviewerProposalReviewerAcceptance: {
        title: 'Reviewer proposal acceptance',
        description: 'You were proposed to be the reviewer of this thesis. There may be other candidates so do not '
        + 'postpone the decision for later.',
      },
      thesisSupersession: {
        title: 'Supersede assignment after failed defense',
        description: 'If the student has failed to defend the thesis and wishes to work on the same assignment, you as '
        + 'a final thesis officer have the option to create a new instance of the assignment by duplicating and '
        + 'superseding the current assignment.',
      },
    },
    form: {
      label: 'Comment reason',
      btn: 'Submit',
    },
  },
  footer: {
    authorshipAndMaintenanceNote: 'Created & maintained by Office of ICT Services',
    copyrightNote: (year: number): string => `© 2021${year > 2021 ? '-' + String(year) : ''} `
    + 'Faculty of Information Technology, Czech Technical University in Prague',
    personRegistration: 'Person registration',
    issuesLinkCaption: 'Report issues',
    manualLinkCaption: 'Manual',
    stateExamsLinkCaption: 'State exams',
  },
  misc: {
    all: 'All',
    no: 'No',
    yes: 'Yes',
  },
}
