import { Prompt } from 'react-router-dom'

import { useLocale } from '../../../locale'


interface Props {
  when: boolean
}

const LeavingPrompt = ({ when }: Props): JSX.Element => {
  const { l } = useLocale()

  return <Prompt when={when} message={l.form.leaveBeforeSavingWarning} />
}

export default LeavingPrompt
