import { sortBy } from 'lodash'
import { Thesis, ThesisReportList, ThesisReportType } from '@cvut/profit-api-types/lib/theses'

import { UseRequestStatus } from '../../../api/useRequest'
import Card from '../../../components/Card'
import FinalGrade from './FinalGrade'
import RequestDependentRender from '../../../api/RequestDependentRender'
import Spinner from '../../../components/Spinner'
import ThesisReportLink from './ThesisReportLink'
import { useLocale } from '../../../locale'
import * as style from './ThesisEvaluationCard.style'


type PersonNameKeys = 'reviewerFrozenName' | 'supervisorFrozenName'
type ThesisKeys = PersonNameKeys | 'id' | 'finalGrade'

const reportTypeToAuthorName: Record<ThesisReportType, PersonNameKeys> = {
  reviewer: 'reviewerFrozenName',
  supervisor: 'supervisorFrozenName',
} as const

interface Props {
  // `Pick` is only used to simplify testing/fixtures.
  thesis: Pick<Thesis, ThesisKeys>
  reports: UseRequestStatus<ThesisReportList>
  reportDetailLinkCreator: (thesisId: Thesis['id'], type: ThesisReportType) => string
}

const ThesisEvaluationCard = ({ thesis, reports, reportDetailLinkCreator }: Props): JSX.Element => {
  const { l } = useLocale()
  const finalGradeNode = thesis.finalGrade && <FinalGrade grade={thesis.finalGrade} />

  const renderThesisReportLinks = ({ data }: ThesisReportList) => {
    let submittedReports = data.filter(report => !!report.submittedAt)

    if (!submittedReports.length) {
      return (
        <p>{l.thesis.reports.evaluationCard.noReports}</p>
      )
    }
    // Display supervisor report first.
    submittedReports = sortBy(submittedReports, x => x.type === 'supervisor' ? 0 : 1)

    return (
      <>
        {finalGradeNode}
        <ul className={style.reportList}>
          {submittedReports.map(report => {
            const authorName = thesis[reportTypeToAuthorName[report.type]]
            if (!authorName) {
              return
            }

            const reportDetailLink = report.pdfUrl == null
              ? { type: 'internal', link: reportDetailLinkCreator(thesis.id, report.type) } as const
              : { type: 'external', link: report.pdfUrl } as const

            return (
              <li key={report.id} className={style.reportItem}>
                <ThesisReportLink
                  reportType={report.type}
                  author={authorName}
                  score={report.score}
                  reportDetailLink={reportDetailLink}
                  extraClassNames={[style.reportItem]}
                />
              </li>
            )
          })}
        </ul>
      </>
    )
  }

  return (
    <Card>
      <Card.Header title={l.thesis.reports.evaluationLabel} />
      <Card.Content>
        <RequestDependentRender
          requestStatus={reports}
          renderOnLoading={() => (
            <Spinner.DualRings color='primary' title={l.thesis.reports.evaluationCard.loading} />
          )}
          renderOnError={() => <p>{l.thesis.reports.evaluationCard.errorGettingReports}</p>}
          renderOnSuccess={renderThesisReportLinks}
        />
      </Card.Content>
    </Card>
  )
}

export default ThesisEvaluationCard
