import { css } from 'linaria'

import { rel } from '../styles/dimensions'
import * as colors from '../styles/colors'


export const progressBar = css`
  display: flex;
  align-items: center;
  padding: ${rel(4)}rem ${rel(6)}rem;
`

export const progressContainer = css`
  width: 100%;
  height: ${rel(4)}rem;
  background: ${colors.backgroundColor[1]};
  border-radius: ${rel(2)}rem;
`

export const progress = css`
  height: 100%;
  background: ${colors.primaryColor[3]};
  border-radius: ${rel(4)}rem;
`

export const active = css`
  background: ${colors.neutralColor[1]};
`

export const done = css`
  background: ${colors.positiveColor[2]};
`
