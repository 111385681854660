import { css } from 'linaria'

import { rel } from '../../../styles/dimensions'


export const stateList = css`
  margin: ${rel(4)}rem 0 0;
  padding: 0 0 0 ${rel(32)}rem;
  font-size: ${rel(14)}rem;
  text-align: left;

  > li {
    line-height: ${rel(16)}rem;
  }
`
