import { ChangeEvent, useEffect, useMemo, useState } from 'react'
import _ from 'lodash'
import { CellProps, Column, FilterProps, FilterType, TableState } from 'react-table'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { Enum } from 'typescript-string-enums'
import { ParamMap } from 'urlcat'
import { cx } from 'linaria'
import { Person, Study, Specialization, Thesis, ThesisMainState } from '@cvut/profit-api-types/lib/theses'

import { typingDelay } from '../../config'
import { DisplayedThesesStates, DisplayedMyThesesStates } from '../../types/thesis'
import { getThesesExportCsvUrl, usePaginatedTheses, useThesesMeta } from '../../api/theses'
import { useStudyDegrees } from '../../api/studyDegrees'
import { useThesisStates } from '../../api/thesisStates'
import pagePaths from '../../pages/paths'
import { formatPersonFullName } from '../../utils/person'
import { sameContents } from '../../utils/theses'
import { useLocale } from '../../locale'
import MultiselectFilter from '../../components/table/filter/MultiselectFilter'
import ThesisStatesFilter, { stateToClassMap } from '../../components/table/filter/ThesisStatesFilter'
import type { OptionType } from '../../components/table/filter/types'
import Table, { TextFilter, ThesisStateRenderer } from '../../components/table'
import * as buttonStyle from '../../components/Button.style'
import * as style from './ThesesTable.style'
import { ReactComponent as DownloadIcon } from '../../images/icons/Download.svg'
import { ReactComponent as PlusIcon } from '../../images/icons/Plus.svg'
import SwitcherFilters from '../../components/table/filter/SwitcherFilters'
import { ReactComponent as ResetFiltersIcon } from '../../images/icons/FilterClear.svg'
import LoadMore from '../../components/table/LoadMore'


const withoutReviewerFilterId = 'withoutReviewer'

export const localizedTitle: Record<string, string> = {
  cs: 'titleCs',
  en: 'titleEn',
}


/**
 * TODO
 * - unify ids between Thesis, REST API query attributes, URL search query parameters
 * - q does not belong to TState
 * - keys should belong to some subset of Thesis attribute names
 */
export type TState = Pick<TableState<Thesis>, 'filters' | 'sortBy'>
// TODO: ids do not match Thesis attributes, title contains titleCs/titleEn, type contains studyDegree
const defaultState: TState = {
  filters: [
    { id: 'title', value: '' },
    { id: 'type', value: [] },
    { id: 'specialization', value: [] },
    { id: 'mainState', value: [] },
    { id: 'supervisorFrozenName', value: '' },
    { id: 'reviewerFrozenName', value: '' },
    { id: 'assigneeFrozenName', value: '' },
  ],
  sortBy: [],
}

// TODO: refactor into extra files (separate dir, index.ts exporting the component, importing utility functions etc),
// if possible, remove global variable, do together with fixing history navigation bug
const defaultStateFullValues: Pick<TableState<Thesis>, 'filters'> = { filters: [] }
const defaultSortBy: string[] = defaultState.sortBy?.map(({ id, desc }) => `${desc ? '-' : ''}${id}`) ?? []

interface ThesesListSearchProps {
  handleSearch: (q: string) => void
  value?: string
}

export const ThesesListSearch = ({ handleSearch, value }: ThesesListSearchProps): JSX.Element => {
  const { l } = useLocale()
  const [inputValue, setInputValue] = useState(value ?? '')

  const onChange = _.debounce(() => handleSearch(inputValue), typingDelay)

  useEffect(() => {
    onChange()
    return onChange.cancel
  }, [inputValue])

  // clear inputValue state if filterValue is reset (otherwise navigation in history doesn't affect this input)
  useEffect(() => {
    if (value !== undefined) {
      setInputValue(value)
    }
  }, [value])

  return (
    <div className={style.thesisListSearchWrapper}>
      <input type='button' className={style.thesisListSearchInputButton} />
      <input
        type='text'
        className={style.thesesListSearchInput}
        placeholder={l.thesesList.filters.search}
        onChange={e => setInputValue(e.target.value)}
        value={inputValue}
      />
    </div>
  )
}

interface ExportCsvLinkProps {
  url: HTMLAnchorElement['href']
}

export const ExportCsvLink = ({ url }: ExportCsvLinkProps): JSX.Element => {
  const { l } = useLocale()

  return (
    <a href={url} className={cx(buttonStyle.outlineDark, style.thesisHeaderBtn)} download>
      {l.thesesList.exportCsv}
      <DownloadIcon />
    </a>
  )
}

export const NewAssignmentLink = (): JSX.Element => {
  const { l } = useLocale()

  return (
    <Link
      to={pagePaths.theses.create}
      title={l.thesesList.newAssignmentTooltip}
      className={cx(buttonStyle.fill, style.thesisHeaderBtn)}
    >
      {l.thesesList.newAssignment}
      <PlusIcon />
    </Link>
  )
}

interface ResetFiltersLinkProps {
  onClick: () => void
}

export const ResetFiltersLink = ({ onClick }: ResetFiltersLinkProps): JSX.Element => {
  const { l } = useLocale()
  const location = useLocation()

  // filtering and sorting state is read from the URL search string, clearing it causes them to reset
  return (
    <Link
      to={location.pathname}
      title={l.thesesList.filters.reset}
      className={style.thesesListResetFiltersLink}
      onClick={onClick}
    >
      <ResetFiltersIcon />
    </Link>
  )
}


// decodes query string argument as arrays to leave commas untouched and human-readable
export function decodeArrayParameter (value: string): string[] {
  return value.split(',') // split string into array
    .filter((v: string) => !!v) // remove empty argument values
    .map(val => decodeURIComponent(val)) // decode arguments one-by-one
}

// TODO unify parameter parsing with backend, unify parameter names, how to handle q that does not belong to table etc
export function stateFromSearchString (searchString: string): TState {
  const query = new URLSearchParams(searchString)
  query.delete('q') // q doesn't belong to table state

  // build lookup from the default state
  const stateLookup: Record<string, string | string[]> = {}
  for (const { id, value } of defaultState.filters.values()) {
    stateLookup[id] = Array.isArray(value) ? value : String(value)
  }
  stateLookup.sortBy = defaultState.sortBy.map(({ id, desc }) => `${desc ? '-' : ''}${id}`)

  const keyMap: Record<string, string> = {
    'specialization.code': 'specialization',
    'specialization.studyDegree': 'type',
    'titleCs': 'title',
    'titleEn': 'title',
    'studyDegree': 'type',
    'states': 'mainState',
    'assignee': 'assigneeFrozenName',
    'reviewer': 'reviewerFrozenName',
    'supervisor': 'supervisorFrozenName',
  }

  const mappedColumnId = (id: string) => keyMap[id] ?? id

  // overwrite the lookup with values parsed from query string
  for (const [param, value] of query.entries()) {
    if (param === 'sort') {
      stateLookup.sortBy = value.split(',').filter((v: string) => !!v).map(val => decodeURIComponent(val))
    } else {
      // FIXME - url parameters an thesis attribute names should to be unified with backend to get rid of this nonsense
      const mappedParam = mappedColumnId(param)
      // FIXME: should not take type from current value in default array which is not necessarily set
      // interpret parameter value as either string or single value
      stateLookup[mappedParam] = Array.isArray(stateLookup[mappedParam])
        ? decodeArrayParameter(value)
        : decodeURIComponent(value)
    }
  }

  // build new state from the merged lookup
  const newInitialState: TState = { filters: [], sortBy: [] }
  for (const [id, value] of Object.entries(stateLookup)) {
    if (id === 'sortBy') {
      const sortBy: string[] = (stateLookup.sortBy.length > 0
        ? stateLookup.sortBy
        : defaultSortBy
      )
      newInitialState.sortBy.push(...sortBy.map(id => ({
        id: mappedColumnId(id.startsWith('-') ? id.substring(1) : id),
        desc: id.startsWith('-'),
      })) as typeof newInitialState.sortBy)
    } else {
      newInitialState.filters.push({
        id: id,
        value: value,
      })
    }
  }

  return newInitialState
}


/** @internal exported only for tests */
export function stringifyFilterValue (id: string, obj: (string | number) | Array<string | number>): string {
  let value = null

  if (!Array.isArray(obj)) {
    obj = [obj]
  }

  const initialFilterState = defaultStateFullValues.filters?.filter(f => f.id === id) ?? []
  const initialFilterValue: string[] = initialFilterState.length > 0 ? initialFilterState[0].value as string[] : []

  // is obj same as default state ? null : serialized value
  if (!sameContents(new Set(obj), new Set(initialFilterValue)) && obj.length !== 0) {
    value = Array.prototype.join.call(obj.map(value => encodeURIComponent(value)), ',')
  }

  const keyMap: Record<string, string> = {
    type: 'studyDegree',
    mainState: 'states',
  }

  return (value !== null) ? [keyMap[id] ?? id, value].join('=') : ''
}


/** @internal exported only for tests */
export function searchStringFromState (state: TState, q: string, lang: string): string {
  // FIXME - unify parameter and attribut names between backend and frontend to get rid of this mess
  const keyMap: Record<string, string> = {
    title: localizedTitle[lang],
    specialization: 'specialization.code',
    type: 'specialization.studyDegree',
    mainState: 'states',
  }
  const sortBy = state.sortBy
    .map(({ id, desc }) => {
      return { id: keyMap[id] ?? id, desc }
    })
    .map(({ id, desc }) => `${desc ? '-' : ''}${id}`)
    .join(',')

  const searchString = `${state.filters
    .concat({ id: 'q', value: q })
    .map(f => f.value ? stringifyFilterValue(f.id, f.value) : '')
    .filter(f => !!f)
    .concat(sortBy ? [`sort=${sortBy}`] : [])
    .join('&')}`

  return searchString ? `?${searchString}` : ''
}


export type TPersonKey = Extract<keyof Thesis, 'assignee' | 'reviewer' | 'supervisor'>
export type TPersonNameKey = Extract<keyof Thesis, 'assigneeFrozenName' | 'reviewerFrozenName' | 'supervisorFrozenName'>

export const personAccessor = (who: TPersonKey) => (thesis: Thesis): string => {
  const personName = thesis[`${who}FrozenName` as TPersonNameKey]
  if (!personName) {
    return ''
  }

  return formatPersonFullName(personName, (thesis[who] as Person | Study).username)
}

const apiParametersFromLocationSearch = (search: string, myThesesOnly?: boolean): ParamMap => {
  const originalQuery = new URLSearchParams(search)
  const keyMap: Record<string, string> = { // FIXME
    mainState: 'states',
    assigneeFrozenName: 'assignee',
    reviewerFrozenName: 'reviewer',
    supervisorFrozenName: 'supervisor',
  }
  const query = new URLSearchParams()
  for (const [key, value] of originalQuery.entries()) {
    query.append(keyMap[key] ?? key, value)
  }

  if (!query.has('states')) {
    query.append('states', Object.values(myThesesOnly ? DisplayedMyThesesStates : DisplayedThesesStates).join(','))
  }

  const queryEntries = [...query.entries()]

  if (!queryEntries.length) {
    return {}
  }

  return queryEntries.reduce<ParamMap>((acc, [k, v]) => {
    acc[k] = `${Array.isArray(v) ? v.join(',') : v}`
    return acc
  }, {})
}

// FIXME react-table handles its state internally and we need to control it fully
type ThesesTableProps = (props: {
  thesisSpecializationOptions?: OptionType[],
  thesisMainStateOptions?: OptionType[],
  thesisStudyDegreeOptions?: OptionType[],
  data?: Thesis[],
  myThesesOnly?: boolean,
  displayNewAssignmentButton?: boolean,
  displayExportCsvButton?: boolean,
}) => JSX.Element

const ThesesTable: ThesesTableProps = ({
  thesisSpecializationOptions,
  thesisMainStateOptions,
  thesisStudyDegreeOptions,
  data,
  myThesesOnly = false,
  displayNewAssignmentButton = false,
  displayExportCsvButton = false,
}) => {
  const { currentLang, l } = useLocale()

  // TODO: DRY - thesis states and study degrees are loaded in the same way
  // (maybe move that to where they are used?)

  const [thesesMetaStatus, getThesesMeta] = useThesesMeta()
  const [specializationOptions, setSpecializationOptions] = useState<OptionType[]>(thesisSpecializationOptions ?? [])

  // TODO: use searchString to load only relevant specializations
  useEffect(() => {
    if (thesesMetaStatus.state === 'not-initiated') {
      void getThesesMeta()
    } else if (thesesMetaStatus.state === 'success') {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const thesesMeta = thesesMetaStatus.data!.properties

      if (!thesisSpecializationOptions) {
        const specializationOptions = thesesMeta.specialization.values
          .filter(_.isString)
          .sort((a, b) => a.localeCompare(b))
          .map(value => ({ key: value, value, text: value }))
        setSpecializationOptions(specializationOptions)
      }
    }
  }, [thesesMetaStatus.state])

  const [thesisStateOptions, setThesisStateOptions] = useState<OptionType[]>(thesisMainStateOptions ?? [])
  const [thesisStates, getThesisStates] = useThesisStates()

  // TODO: use searchString to load only relevant thesis states
  useEffect(() => {
    if (thesisMainStateOptions) {
      return
    }

    if (thesisStates.state === 'not-initiated') {
      void getThesisStates()
    } else if (thesisStates.state === 'success') {
      const thesisStateOptions = (thesisStates.data?.mainState ?? [])
        .filter(state => Enum.isType(myThesesOnly ? DisplayedMyThesesStates : DisplayedThesesStates, state))
        .map(state => ({
          key: state,
          value: state,
          text: l.thesis.mainState[state],
          extraClassNames: [stateToClassMap?.[state]].filter(_.isString),
        }))
      setThesisStateOptions(thesisStateOptions)
    }
  }, [thesisMainStateOptions, thesisStates.state, currentLang])

  // TODO: use searchString to load only relevant studyDegrees
  const [studyDegreeOptions, setStudyDegreeOptions] = useState<OptionType[]>(thesisStudyDegreeOptions ?? [])
  const [studyDegrees, getStudyDegrees] = useStudyDegrees()

  useEffect(() => {
    if (thesisStudyDegreeOptions) {
      return
    }

    if (studyDegrees.state === 'not-initiated') {
      void getStudyDegrees()
    } else if (studyDegrees.state === 'success') {
      const studyDegreeOptions = (studyDegrees.data ?? [])
        .sort((a, b) => {
          return a.localeCompare(b)
        })
        .map(studyDegree => ({
          key: studyDegree,
          value: studyDegree,
          text: l.thesis.thesisTypeOptionInitialism[studyDegree],
        }))
      setStudyDegreeOptions(studyDegreeOptions)
    }
  }, [thesisStudyDegreeOptions, studyDegrees.state, currentLang])


  // prepare default state - all options checked
  defaultStateFullValues.filters.splice(0)
  const keyMap: {[key: string]: { id: string, value: Array<string | number> }} = {
    type: {
      id: 'studyDegree',
      value: [...studyDegreeOptions.values()].map(x => x.value),
    },
    specialization: {
      id: 'states',
      value: [...specializationOptions.values()].map(x => x.value),
    },
    mainState: {
      id: 'states',
      value: [...thesisStateOptions.values()].map(x => x.value),
    },
  }
  defaultState.filters.forEach(f => {
    defaultStateFullValues.filters.push(keyMap[f.id] ?? {
      id: f.id,
      value: f.value as string,
    })
  })

  const history = useHistory()
  const currentPage = history.location.pathname.replace(/\/$/, '')
  const query = new URLSearchParams(history.location.search)
  const [fullTextSearchQuery, setFullTextSearchQuery] = useState(query.get('q') ?? '')

  const [tableData, setTableData] = useState<Thesis[]>(data ?? [])
  const searchString = history.location.search
  const initialState = searchString ? stateFromSearchString(searchString) : { ...defaultState }
  const [tableState, setTableState] = useState<TState>(initialState)
  const params = apiParametersFromLocationSearch(searchString, myThesesOnly)
  const [thesesState, isLastPage, getNextPage] = usePaginatedTheses(params, myThesesOnly)
  const exportCsvUrl = getThesesExportCsvUrl(params, myThesesOnly)
  const [shouldOverrideState, setShouldOverrideState] = useState(false)
  const [isLoadingMore, setIsLoadingMore] = useState(false)

  useEffect(() => {
    if (thesesState.state === 'success') {
      setIsLoadingMore(false)
    }
  }, [thesesState.state])

  useEffect(() => {
    setTableData(data ?? thesesState.data)
  }, [thesesState.data])

  // this handles the initial render and langugage change rerenders
  useEffect(() => {
    if (data) {
      setTableData(data)
      return
    }

    if (thesesState.state === 'not-initiated') {
      getNextPage()
    }
  }, [thesesState.state, currentLang, myThesesOnly])

  // history navigation handing
  useEffect(() => {
    // history.listen returns reference to unlisten() -> clean up at exit
    return history.listen((location, action) => {
      if (data) {
        setTableData(data)
        return
      }

      // set fulltext search field's value from search string on page open
      if (action === 'POP') {
        const query = new URLSearchParams(location.search)
        setFullTextSearchQuery(query.get('q') ?? '')
      } else if (action === 'PUSH') {
        const newInitialState = stateFromSearchString(location.search)
        setTableState({ ...newInitialState })
      }

      // while history navigation occurs on this page (search string changes only),
      // load data matching state in search string
      // FIXME: table state does not change, thus filters are not updated (fix planned)
      const isSamePage = location.pathname.replace(/\/$/, '') === currentPage
      if (isSamePage) {
        const newTableState = stateFromSearchString(location.search)
        setTableState(newTableState)
        setShouldOverrideState(true)
      }
    })
  }, [history])

  useEffect(() => {
    const newInitialState = stateFromSearchString(history.location.search)
    setTableState(newInitialState)
  }, [])

  useEffect(() => {
    if (shouldOverrideState) {
      setShouldOverrideState(false)
    }
  })

  const MainStateFilter = ({ column }: FilterProps<Thesis>): JSX.Element => (
    <ThesisStatesFilter
      column={column}
      options={thesisStateOptions}
    />
  )


  const filterMethod: FilterType<Thesis> = (rows, ids, filterValue: string[]) => rows.filter(
    row => filterValue.length > 0 ? filterValue.includes(row?.values[ids[0]] ?? '') : true
  )

  const ThesisTypeCell = ({ row }: CellProps<Thesis>) => (
    <>
      {l.thesis.thesisTypeOptionInitialism[(row.original.specialization as Specialization)?.studyDegree] ?? ''}
    </>
  )

  const ThesisTypeFilter = ({ column }: FilterProps<Thesis>) => (
    <MultiselectFilter
      column={column}
      options={studyDegreeOptions}
    />
  )

  const StudySpecializationCell = ({ row }: CellProps<Thesis>) => (
    <>
      {row?.original?.specialization?.code ?? ''}
    </>
  )

  const StudySpecializationFilter = ({ column }: FilterProps<Thesis>) => (
    <MultiselectFilter
      column={column}
      options={specializationOptions}
    />
  )

  const EvaluationYearCell = ({ row }: CellProps<Thesis>) => (
    <>
      {row.original.evaluationYear ?? ''}
    </>
  )

  const EvaluationYearFilter = ({ column }: FilterProps<Thesis>) => (
    <TextFilter
      column={column}
      validate={value => !!/^([1-9]\d*|)$/.exec(value)}
    />
  )

  const ApprovedAtCell = ({ row }: CellProps<Thesis>) => {
    const date = row.original.approvedAt ? new Date(row.original.approvedAt) : null
    const tooltip = l.thesis.approvalDate

    return date && (
      <time dateTime={date.toISOString()} title={tooltip} aria-label={tooltip}>
        {date.toLocaleDateString(currentLang)}
      </time>
    )
  }

  const ActionsCell = ({ row }: CellProps<Thesis>) => (
    row.original.mainState === ThesisMainState.Draft
      ? (
        <Link
          to={pagePaths.theses.edit(row.original.id)}
          className={style.editThesis}
          title={l.thesesList.editThesis}
        >
          {/* Detail */}
        </Link>
      )
      : ''
  )


  const ThesisTitleCell = ({ row }: CellProps<Thesis>) => {
    const thesisTitle: string = currentLang === 'cs' ? row.original.titleCs : row.original.titleEn

    return (
      <Link to={pagePaths.theses.view(row.original.id)} title={l.thesesList.visitThesis(thesisTitle)}>
        {thesisTitle}
      </Link>
    )
  }

  // TODO: localization
  const columns = useMemo(
    (): Array<Column<Thesis>> => [
      {
        Header: l.thesis.title,
        id: 'title',
        // id: currentLang === 'cs' ? 'titleCs' : 'titleEn',
        accessor: (t: Thesis) => currentLang === 'cs' ? t.titleCs : t.titleEn,
        Cell: ThesisTitleCell,
      },
      {
        Header: l.thesis.type,
        id: 'type',
        accessor: (t: Thesis) => {
          return (t?.specialization as Specialization)?.studyDegree ?? ''
        },
        Cell: ThesisTypeCell,
        Filter: ThesisTypeFilter,
        filter: filterMethod,
      },
      {
        Header: l.thesis.specialization,
        id: 'specialization',
        accessor: (t: Thesis) => t?.specialization?.code,
        Cell: StudySpecializationCell,
        Filter: StudySpecializationFilter,
        filter: filterMethod,
      },
      {
        Header: l.thesis.status,
        accessor: 'mainState',
        Cell: ThesisStateRenderer,
        Filter: MainStateFilter,
        filter: filterMethod,
      },
      {
        Header: l.thesis.supervisor,
        id: 'supervisorFrozenName',
        accessor: personAccessor('supervisor'),
      },
      {
        Header: l.thesis.reviewer,
        id: 'reviewerFrozenName',
        accessor: personAccessor('reviewer'),
      },
      {
        Header: l.thesis.student,
        id: 'assigneeFrozenName',
        accessor: personAccessor('assignee'),
      },
      {
        Header: l.thesis.evaluationYear,
        id: 'evaluationYear',
        accessor: 'evaluationYear',
        Cell: EvaluationYearCell,
        Filter: EvaluationYearFilter,
        filter: filterMethod,
      },
      {
        Header: l.thesis.approvalDateShort,
        id: 'approvedAt',
        accessor: 'approvedAt',
        Cell: ApprovedAtCell,
        disableFilters: true,
      },
      {
        id: 'actions',
        accessor: 'id', // FIXME: this is workaround, Filter is not visible without this property
        Cell: ActionsCell,
        Filter: <ResetFiltersLink onClick={() => setFullTextSearchQuery('')} />,
        disableSortBy: true,
      },
    ],
    [currentLang, thesisStateOptions, specializationOptions, studyDegreeOptions, myThesesOnly]
  )

  const addStateToBrowserHistory = (state: TState, searchString: string) => {
    history.push(`${history.location.pathname}${searchString}`, { ...state })
  }

  const handleSearch = (q: string) => {
    setFullTextSearchQuery(q)

    const query = new URLSearchParams(history.location.search)
    query.delete('q')
    q && query.append('q', q)

    const currentSearchString = history.location.search
    const state = stateFromSearchString(query.toString())
    const newSearchString = searchStringFromState(state, q, currentLang)

    if (newSearchString !== currentSearchString) {
      addStateToBrowserHistory(state, newSearchString)
    }
  }

  const ThesesListActions = () => {
    if (!displayNewAssignmentButton && !displayExportCsvButton) {
      return null
    }

    return (
      <div className={style.thesesListActionsWrapper}>
        {displayExportCsvButton && <ExportCsvLink url={exportCsvUrl} />}
        {displayNewAssignmentButton && <NewAssignmentLink />}
      </div>
    )
  }

  function handleSwitcherFilter (e: ChangeEvent<HTMLInputElement>) {
    const checked = e.currentTarget.checked
    const query = new URLSearchParams(history.location.search)

    if (checked) {
      query.set(withoutReviewerFilterId, 'true')
    } else {
      query.delete(withoutReviewerFilterId)
    }

    history.push(`${history.location.pathname}?${query.toString().replace(/%2C/g, ',')}`)
  }

  function handleLoadMoreClick () {
    getNextPage()
    setIsLoadingMore(true)
  }

  const shouldShowLoadMore = thesesState.state === 'loading' && !isLoadingMore

  return (
    <div className={style.thesesTable}>
      <div className={style.thesesForm}>
        <div className={style.thesesFormHeader}>
          <div className={style.thesesFormHeaderLeft}>
            <ThesesListSearch
              handleSearch={handleSearch}
              value={fullTextSearchQuery}
            />
            <SwitcherFilters
              switcherIdList={[withoutReviewerFilterId]}
              onSwitcherChange={handleSwitcherFilter}
            />
          </div>
          <div className={style.thesesFormHeaderRight}>
            <ThesesListActions />
          </div>
        </div>
        <Table
          columns={columns}
          data={[...tableData]}
          emptyDataMessage={l.thesesList.emptyResultsMessage}
          initialState={tableState}
          isLoadingMore={isLoadingMore}
          loadingStatus={thesesState.state}
          manual
          onStateChange={(state: TState) => {
            const newSearchString = searchStringFromState(state, fullTextSearchQuery, currentLang)
            const currentSearchString = history.location.search

            if (newSearchString !== currentSearchString) {
              addStateToBrowserHistory(state, newSearchString)
            }
          }}
          useControlledState={(state: TableState) => {
            // override filters with state from browser history
            if (shouldOverrideState) {
              state.filters = [...tableState?.filters]
              state.sortBy = [...tableState?.sortBy]
            }

            return state
          }}
        >
          {!(isLastPage || shouldShowLoadMore) && (
            <LoadMore isLoadingMore={isLoadingMore} onClick={handleLoadMoreClick} />
          )}
        </Table>
      </div>
    </div>
  )
}

export default ThesesTable
