import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Thesis } from '@cvut/profit-api-types/lib/theses'

import { addNotification } from '../../features/toastNotifications'
import LeavingPrompt from '../../components/form/LeavingPrompt'
import { useCreateThesis } from '../../api/theses'
import { useThesisFormBackup } from '../../utils/theses'
import ThesisEdit from '../../features/thesis/ThesisEdit'
import AccessControlledRender from '../../access-control/AccessControlledRender'
import { useLocale } from '../../locale'
import pagePaths from '../paths'


// TODO: Handle save errors.
// TODO: Save loaders.
const ThesisCreatePage = (): JSX.Element => {
  const history = useHistory()
  const { l } = useLocale()
  const { forgetStoredValues, formBackupHandler, getDefaultValues, shouldReset, storedValues } = useThesisFormBackup()
  const thesisInLocale = l.thesis.thesis.toLocaleLowerCase()
  const [hasStoredValues, setHasStoredValues] = useState(false)
  const [isFormModified, setIsFormModified] = useState(false)

  const handleModifiedState = (isModified: boolean) => setIsFormModified(isModified)

  useEffect(() => {
    setHasStoredValues(!!storedValues)
  }, [storedValues])

  const [createStatus, createThesis] = useCreateThesis()
  useEffect(() => {
    if (createStatus.state === 'success') {
      addNotification({
        type: 'POSITIVE',
        message: l.successMessages.api.defaultCreate(thesisInLocale),
      })
      // form backup can be forgotten now
      forgetStoredValues()
      setHasStoredValues(false)
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      history.replace(pagePaths.theses.edit(createStatus.data!.id))
    }
  }, [createStatus.state])

  return (
    <>
      <ThesisEdit
        mode='create'
        onSave={createThesis}
        onChange={formBackupHandler}
        onModifiedState={handleModifiedState}
        defaultValues={getDefaultValues() as Thesis}
        shouldReset={shouldReset}
        formHasStoredValues={hasStoredValues}
        isSupervisor
      />
      <LeavingPrompt when={isFormModified} />
    </>
  )
}

// This wrapper helps with access control
const ThesisCreatePageWrapper = (): JSX.Element => {
  const history = useHistory()
  const { l } = useLocale()

  return (
    <AccessControlledRender loaderColor='primary'>
      {(ac) => {
        if (!ac.globalRoles.isProponent) {
          addNotification({
            type: 'NEGATIVE',
            message: l.accessControl.noPermission(l.thesis.creation),
          })

          history.replace(pagePaths.home)
          return null
        }

        return (
          <ThesisCreatePage />
        )
      }}
    </AccessControlledRender>
  )
}

export default ThesisCreatePageWrapper
