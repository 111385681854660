import { Link, LinkProps } from 'react-router-dom'

import { useLocale } from '../../locale'
import { ReactComponent as GoBackIcon } from '../../images/icons/GoBack.svg'
import * as style from './PageHeader.style'


interface HeaderSubtitleProps {
  label: string
  href?: string
}

const HeaderSubtitle = ({ label, href = undefined }: HeaderSubtitleProps) => {
  const titleSpan = <span className={style.subtitle}>{label}</span>

  return href
    ? (
      <Link to={href}>
        {titleSpan}
      </Link>
    )
    : titleSpan
}

interface Props {
  backButton?: Omit<LinkProps, 'component' | 'className' | 'title'>
  title: string
  subtitles?: (string | { label: string, href: string })[]
}

/**
 * @param backButton Pass the same object of props as to `<Link />` from `react-router-dom`.
 */
const PageHeader = ({ backButton, title, subtitles }: Props): JSX.Element => {
  const { l } = useLocale()

  return (
    <div className={style.pageHeader}>
      {backButton ? (
        <Link
          title={l.navigation.goBack}
          className={style.backButton}
          {...backButton}
        >
          <GoBackIcon />
        </Link>
      ) : null}
      <div className={style.titlesWrapper}>
        <h1>{title}</h1>
        {subtitles?.map((subtitle, index) => (
          typeof subtitle === 'string'
            ? <HeaderSubtitle label={subtitle} key={index} />
            : <HeaderSubtitle label={subtitle.label} href={subtitle.href} key={index} />
        ))}
      </div>
    </div>
  )
}

export default PageHeader
