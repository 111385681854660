import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { pick } from 'lodash'
import { Enum } from 'typescript-string-enums'
import { Except } from '@cvut/profit-utils'
import { Thesis, ThesisReportType, ThesisReportPatch } from '@cvut/profit-api-types/lib/theses'

import AccessControlledRender from '../../access-control/AccessControlledRender'
import { addNotification, ToastNotificationType } from '../../features/toastNotifications'
import { ACLoaded as AccessControlLoaded } from '../../access-control'
import { errorCardRenderer } from '../../components/RequestErrorCard'
import LeavingPrompt from '../../components/form/LeavingPrompt'
import PageHeader from '../../components/PageHeader'
import PageLoadSpinner from '../../components/PageLoadSpinner'
import pagePaths from '../paths'
import RequestDependentRender from '../../api/RequestDependentRender'
import ThesisReportCreate from '../../features/thesis/ThesisReportCreate'
import locales, { useLocale, AvailableLocale } from '../../locale'
import { useThesis } from '../../api/theses'
import {
  createThesisReportPdfUrl,
  updateReport,
  useSubmitThesisReport,
  useThesisReport,
} from '../../api/theses/thesisReports'
import ValidatedPathParams from '../utils/ValidatedPathParams'


const supervisorReportFields = ['criteriaEvaluation', 'score', 'summary', 'language', 'templateVersion']

const isReportTypePending = (
  thesis: Thesis,
  reportType: ThesisReportType
) => thesis.states.includes({
  reviewer: 'changeable.review.reviewer.pending',
  supervisor: 'changeable.review.supervisor.pending',
}[reportType])

const canWriteReport = (
  ac: Except<AccessControlLoaded, 'state'>,
  thesis: Thesis,
  reportType: ThesisReportType
) => ({
  reviewer: ac.thesisRoles.isThesisReviewer(thesis),
  supervisor: ac.thesisRoles.isThesisSupervisor(thesis),
}[reportType])

interface Props {
  thesisId: number
  reportType: ThesisReportType
}

const ThesisReportCreatePage = ({ thesisId, reportType }: Props): JSX.Element => {
  const { l, currentLang } = useLocale()
  const [reportLanguage, setReportLanguage] = useState<AvailableLocale>(currentLang)
  const [thesisState, getThesis] = useThesis()
  const [reportStatus, getThesisReport] = useThesisReport(() => null)
  const [submitStatus, submitReport] = useSubmitThesisReport()
  const history = useHistory()
  const [isFormModified, setIsFormModified] = useState(false)
  const thesisDetailPageUrl = pagePaths.theses.view(thesisId)

  const handleModifiedState = (isModified: boolean) => setIsFormModified(isModified)

  const returnToThesisDetailWithMessage = (type: ToastNotificationType, message: string) => {
    addNotification({ type, message })
    history.push(thesisDetailPageUrl)
  }

  useEffect(() => {
    if (reportStatus.state === 'success') {
      setReportLanguage(reportStatus.data?.language ?? reportLanguage)
    }
  }, [reportStatus.state])

  useEffect(() => {
    void getThesis(thesisId)
    void getThesisReport(thesisId, reportType)
  }, [])

  // return to thesis detail after submitting report
  useEffect(() => {
    if (thesisState.state === 'success' && submitStatus.state === 'success') {
      returnToThesisDetailWithMessage('POSITIVE', l.thesis.reports.submitSuccessToast)
    }
  }, [thesisState.state, submitStatus.state])

  const handleReportSubmit = () => {
    if (thesisState.state === 'success') {
      void submitReport(thesisId, reportType)
    }
  }

  const reportCreatePath = pagePaths.theses.reportCreate(thesisId, reportType)

  return (
    <AccessControlledRender loaderColor='primary'>
      {(ac) => (
        <RequestDependentRender
          requestStatus={thesisState}
          renderOnLoading={() => <PageLoadSpinner message={l.thesis.loadingThesis} />}
          renderOnError={errorCardRenderer(l.thesis.errorGettingThesis, reportCreatePath)}
          renderOnSuccess={thesis => {
            // FIXME modifying state of higher-level component while rendering this one
            if (!canWriteReport(ac, thesis, reportType)) {
              returnToThesisDetailWithMessage('NEGATIVE', l.accessControl.noPermissionDefault)
              return null
            }

            if (!isReportTypePending(thesis, reportType)) {
              returnToThesisDetailWithMessage('NEGATIVE', l.accessControl.reportAlreadySubmitted)
              return null
            }

            const handleReportSave = async (data: ThesisReportPatch) => {
              try {
                await updateReport(thesisId, reportType, data)
                addNotification({
                  type: 'POSITIVE',
                  message: l.thesis.reports.saveSuccessToast,
                })
                void getThesisReport(thesisId, reportType)
              } catch (err) {
                addNotification({
                  type: 'NEGATIVE',
                  message: l.thesis.reports.errorMessages.save,
                })
                throw err
              }
            }

            const panelLocale = locales[reportLanguage].actionPanel.panelId
            const isReportLoaded = reportStatus.state === 'success'
            const fields = ({
              supervisor: supervisorReportFields,
              reviewer: [...supervisorReportFields, 'defenseQuestions'],
            })[reportType]
            const defaultValues = reportStatus.state === 'success' ? pick(reportStatus.data, fields) : undefined

            if (['not-initiated', 'loading', 'aborted'].includes(reportStatus.state)) {
              return null
            }

            return (
              <>
                <PageHeader
                  backButton={{ to: thesisDetailPageUrl }}
                  title={{
                    supervisor: panelLocale.thesisSupervisorReport.title,
                    reviewer: panelLocale.thesisReviewerReport.title,
                  }[reportType]}
                  subtitles={[{ cs: thesis.titleCs, en: thesis.titleEn }[reportLanguage]]}
                />
                <ThesisReportCreate
                  {...{ defaultValues, reportLanguage, reportType, thesis }}
                  backupKey={`${ac.person.username}-${reportType}-report-${thesisId}`}
                  onSave={handleReportSave}
                  onReportSubmit={handleReportSubmit}
                  onReportLanguageChange={e => setReportLanguage(e.currentTarget.value as AvailableLocale)}
                  downloadLink={isReportLoaded ? createThesisReportPdfUrl(thesisId, reportType) : undefined}
                  onModifiedState={handleModifiedState}
                />
                <LeavingPrompt when={isFormModified} />
              </>
            )
          }}
        />
      )}
    </AccessControlledRender>
  )
}

const ThesisReportCreatePageWrapper = (): JSX.Element => (
  <ValidatedPathParams<Props>
    expectedPathPattern={pagePaths.theses.reportCreate()}
    expectedParams={{
      thesisId: {
        validate: (param: string) => !isNaN(parseInt(param, 10)),
        convert: (param: string) => parseInt(param, 10),
      },
      reportType: {
        validate: (param: string) => Enum.isType(ThesisReportType, param),
        convert: param => param as ThesisReportType,
      },
    }}
  >
    {(params) => (
      <ThesisReportCreatePage {...params} />
    )}
  </ValidatedPathParams>
)

export default ThesisReportCreatePageWrapper
