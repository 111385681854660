import { Role } from '@cvut/profit-theses-common'
import type { Person, Thesis, Topic } from '@cvut/profit-api-types/lib/theses'
import { ThesisRel } from '@cvut/profit-theses-common'

import { useUserInfo, UserInfoNotInitiated, UserInfoLoading, UserInfoLoaded, UserInfoNone } from '../session/user-info'


interface ACNotInitiated extends UserInfoNotInitiated {
}

interface ACLoading extends UserInfoLoading {
}

type ThesisRole = (thesis: Thesis) => boolean
type TopicRole = (topic: Topic) => boolean

export interface ACLoaded extends UserInfoLoaded {
  globalRoles: {
    isProponent: boolean,
    isFtOfficer: boolean,
    isReviewer: boolean,
    isStudent: boolean,
  }
  thesisRoles: {
    isThesisSpecOfficer: ThesisRole,
    isThesisSupervisor: ThesisRole,
    isThesisReviewer: ThesisRole,
    isThesisAssignee: ThesisRole,
    list: (thesis: Thesis) => ThesisRel[],
  }
  topicRoles: {
    isTopicAuthor: TopicRole,
  }
}

interface ACNone extends UserInfoNone {
}

function checkIsSpecOfficer (thesis: Thesis, person: Person): boolean {
  return !!thesis?.specialization
  && person.roles.includes(`${Role.SPEC_OFFICER_}-${thesis.specialization.code}`)
}

function checkIsSupervisor (thesis: Thesis, person: Person): boolean {
  return thesis.supervisor.username === person.username
}

function checkIsReviewer (thesis: Thesis, person: Person): boolean {
  return thesis.reviewer?.username === person.username
}

function checkIsAssignee (thesis: Thesis, person: Person): boolean {
  return !!thesis.assignee
  && !!person.studies?.some(study => study.id === thesis.assignee?.id)
}

function checkIsTopicAuthor (topic: Topic, person: Person): boolean {
  return topic.author.username === person.username
}

export type UseAccessControl = ACNotInitiated | ACLoading | ACLoaded | ACNone

export function useAccessControl (): UseAccessControl {
  const [userInfo] = useUserInfo()

  if (userInfo.state !== 'loaded') {
    return {
      state: userInfo.state,
    }
  }

  const person = userInfo.person

  function hasRole (role: Role) {
    return person.roles.includes(role)
  }

  return {
    ...userInfo,
    globalRoles: {
      isFtOfficer: hasRole(Role.FT_OFFICER),
      isProponent: hasRole(Role.PROPONENT),
      isReviewer: hasRole(Role.REVIEWER),
      isStudent: hasRole(Role.STUDENT),
    },
    thesisRoles: {
      isThesisSpecOfficer: (thesis: Thesis) => checkIsSpecOfficer(thesis, person),
      isThesisSupervisor: (thesis: Thesis) => checkIsSupervisor(thesis, person),
      isThesisReviewer: (thesis: Thesis) => checkIsReviewer(thesis, person),
      isThesisAssignee: (thesis: Thesis) => checkIsAssignee(thesis, person),
      list: (thesis: Thesis) => {
        const rels: ThesisRel[] = []
        checkIsAssignee(thesis, person) && rels.push(ThesisRel.ASSIGNEE)
        checkIsReviewer(thesis, person) && rels.push(ThesisRel.REVIEWER)
        checkIsSupervisor(thesis, person) && rels.push(ThesisRel.SUPERVISOR)
        checkIsSpecOfficer(thesis, person) && rels.push(ThesisRel.SPEC_OFFICER)
        return rels
      },
    },
    topicRoles: {
      isTopicAuthor: (topic: Topic) => checkIsTopicAuthor(topic, person),
    },
  }
}
