import { css } from 'linaria'

import { breakpoints, rel } from '../../styles/dimensions'


export const column = css`
  display: grid;
  grid-template-columns: auto;
  row-gap: ${rel(24)}rem;

  & + & {
    margin-top: ${rel(24)}rem;
  }

  @media screen and (min-width: ${breakpoints.xl}) {
    & + & {
      margin-top: 0;
    }
  }

  & > *,
  & > * + * {
    margin-top: 0;
  }
`
