import { useEffect, useState } from 'react'
import {
  ReviewerProposal, Thesis, ThesisAction, ThesisReportList, ThesisReportType,
} from '@cvut/profit-api-types/lib/theses'
import { getPropertyWithSuffix } from '@cvut/profit-utils'
import { thesisLangToLocale } from '@cvut/profit-theses-common'
import { ActionMessage } from '@cvut/profit-api-types/lib/theses'

import { ACLoaded } from '../../access-control'
import ThesisAssignmentView from './ThesisAssignmentView'
import ThesisEvaluationCard from './ThesisEvaluationCard'
import ThesisPeopleCard from './ThesisPeopleCard'
import ThesisReportsDeadlineCard from './ThesisReportsDeadlineCard'
import ThesisStateCard from './ThesisStateCard'
import ThesisSubmitView from './ThesisSubmitView'
import Card from '../../components/Card'
import PageHeader from '../../components/PageHeader'
import ActionPanelWrapper from '../ActionPanels/ActionPanelsWrapper'
import { PendingProposalControls } from './ThesisReviewerProposalControls'
import { ReactComponent as NotificationIcon } from '../../images/icons/Task.svg'
import { getPanels } from '../ActionPanels/utils'
import { ThesisPanelAction } from '../../api/theses'
import { useThesisReviewerProposals } from '../../api/theses/reviewerProposals'
import { UseRequestStatus } from '../../api/useRequest'
import { useLocale } from '../../locale'
import * as actionPanelStyle from '../ActionPanels/ActionPanel.style'


const canReport = (actions: NonNullable<Thesis['_actions']>) => [
  ThesisAction.SUBMIT_SUPERVISOR_REPORT,
  ThesisAction.SUBMIT_REVIEWER_REPORT,
].some(action => action in actions)

interface ReviewerCandidateActionPanelProps {
  proposalId: ReviewerProposal['id']
  onProposalAccept: () => unknown
}

const ReviewerCandidateActionPanel = ({
  proposalId, onProposalAccept,
}: ReviewerCandidateActionPanelProps): JSX.Element => {
  const { l } = useLocale()

  return (
    <div className={actionPanelStyle.actionPanel}>
      <div className={actionPanelStyle.header}>
        <div className={actionPanelStyle.title}>
          <NotificationIcon />
          <h2>{l.thesis.reviewerProposalAcceptancePanel.title}</h2>
        </div>
        <PendingProposalControls reviewerProposalId={proposalId} onActionCompleted={onProposalAccept} />
        <div className={actionPanelStyle.description}>
          <p>{l.thesis.reviewerProposalAcceptancePanel.description}</p>
        </div>
      </div>
    </div>
  )
}

interface Props {
  onDownload: (url: string) => unknown
  thesis: Thesis
  editLink?: string
  assignmentPDFLink?: string
  reviewerLink?: string
  thesisArchiveHandler?: () => void
  // FIXME: Passing the entire component is not ideal but we're running out of time.
  changeHistoryComponent?: JSX.Element
  accessControl: Pick<ACLoaded, 'globalRoles' | 'person' | 'thesisRoles'>
  thesisReports?: UseRequestStatus<ThesisReportList>
  reportDetailLinkCreator: (thesisId: Thesis['id'], type: ThesisReportType) => string
  handleSendRequest: (action: ThesisPanelAction, bodyData?: ActionMessage) => void
}

// TODO: Adjust back button size to match height of h1.
const ThesisDetail = ({
  thesis, editLink, assignmentPDFLink, reviewerLink, thesisArchiveHandler,
  changeHistoryComponent, accessControl, thesisReports,
  reportDetailLinkCreator, handleSendRequest, onDownload,
}: Props): JSX.Element => {
  const locale = thesisLangToLocale(thesis.language)

  const titleInThesisLanguage = getPropertyWithSuffix(thesis, 'title', locale)
  const titleInOtherLanguage = getPropertyWithSuffix(thesis, 'title', locale === 'cs' ? 'en' : 'cs')

  const thesisEvaluationNode = thesisReports ? (
    <ThesisEvaluationCard
      thesis={thesis}
      reports={thesisReports}
      reportDetailLinkCreator={reportDetailLinkCreator}
    />
  ) : null

  const [reviewerProposals, getReviewerProposals] = useThesisReviewerProposals()
  const [reviewerProposalId, setReviewerProposalId] = useState<ReviewerProposal['id'] | null>(null)

  useEffect(() => {
    reloadReviewerProposals()
  }, [])

  const reloadReviewerProposals = () => {
    void getReviewerProposals(thesis.id)
  }

  useEffect(() => {
    if (reviewerProposals.state === 'success') {
      const reviewerProposal = reviewerProposals.data?.data.find(({ reviewer, reviewerAcceptance }) => (
        reviewerAcceptance === 'pending' && reviewer.username === accessControl.person.username
      ))

      setReviewerProposalId(reviewerProposal ? reviewerProposal.id : null)
    }
  }, [reviewerProposals.state])

  return (
    <Card.ColumnContainer>
      <Card.Column>
        <PageHeader
          title={titleInThesisLanguage}
          subtitles={[titleInOtherLanguage]}
        />
        <ActionPanelWrapper panels={getPanels(thesis, accessControl, handleSendRequest)} />
        {reviewerProposalId !== null && (
          <ReviewerCandidateActionPanel proposalId={reviewerProposalId} onProposalAccept={reloadReviewerProposals} />
        )}
        <ThesisAssignmentView
          {...{ assignmentPDFLink, editLink, thesis }}
          onThesisArchive={thesisArchiveHandler}
        />
        <ThesisSubmitView {...{ accessControl, onDownload, thesis }} />
        {changeHistoryComponent}
      </Card.Column>
      <Card.Column>
        <ThesisStateCard thesis={thesis} />
        {canReport(thesis._actions ?? {}) && (
          <ThesisReportsDeadlineCard thesis={thesis} />
        )}
        <ThesisPeopleCard thesis={thesis} reviewerLink={reviewerLink} />
        {thesisEvaluationNode}
      </Card.Column>
    </Card.ColumnContainer>
  )
}

export default ThesisDetail
