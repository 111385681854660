import { css, cx } from 'linaria'

import * as colors from '../../styles/colors'
import { rel } from '../../styles/dimensions'
import * as styleUtils from '../../styles/utils'


export const header = cx(css`
  position: sticky;
  top: 0;
  left: 0;
  z-index: 997;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-top: ${rel(8)}rem;
  padding-bottom: ${rel(8)}rem;
  color: ${colors.white};
  background-color: ${colors.primaryColor[2]};
  box-shadow: 0 ${rel(3)}rem ${rel(8)}rem -${rel(3)}rem ${colors.primaryColor[1]};

  @media print {
    display: none;
  }
`, styleUtils.container)
