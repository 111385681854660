import { Children, ReactNode } from 'react'
import { cx } from 'linaria'

import * as styles from './ColumnContainer.style'


// NOTE: It must be exported due to TS4023 in index.tsx.
export interface Props {
  children: ReactNode
}

const ColumnContainer = ({ children }: Props): JSX.Element => {
  const childrenCount = Children.count(children)
  if (childrenCount < 1 || childrenCount > 2) {
    throw new RangeError('Column container supports only one or two columns!')
  }

  const classes = [styles.columnContainer]
  if (childrenCount > 1) {
    classes.push(styles.twoColumns)
  }

  return (
    <div className={cx(...(classes))}>
      {children}
    </div>
  )
}

export default ColumnContainer
